import React, { useState, useEffect } from "react";

import {getInfosheetsFromUser} from '../../../../api/content'
import lupa from "../../../../img/lupa.png";
import ContentListStyle from "../../../chapter-components/AttachInfosheet/style/ContentListStyle";

import { useAuth } from '../../../../context/AuthContext';

export default function InfosheetList({handleItemClick}) {
  const { user } = useAuth();

  const [contentList, setContentList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getInfosheetsFromUser(user.id);
        setContentList(response.data);
        setFilteredList(response.data);      
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  const filterList = (term) => {
    const filtered = contentList.filter(infosheet => {
      return infosheet.public_title.toLowerCase().includes(term.toLowerCase())
    })
    setFilteredList(filtered)
  }

  return (
    <ContentListStyle className="width-100">
      <div className="infosheet-list-container">
        <div className='search-bar-container margin-bottom-20 width-100'>
          <img className="lupa" alt="Lupa" src={lupa}/>
          <input 
            type="text" 
            className='buscadorInput'
            placeholder="Buscar ficha"
            onChange={(e) => filterList(e.target.value)} 
          />
        </div>
        
        <div className="items-list-container">
          {filteredList.map((infosheet, index) => (
            <div className='item-container' key={infosheet.infosheet_id} onClick={() => handleItemClick(filteredList[index])}>
              <img alt="item-icon" className="file-btn"/>
              <div className="item-info">
                <h2 className="public-title" title={infosheet.public_title}>
                  {infosheet.public_title}
                </h2>
                <p className="private-title text-light align-left" title={infosheet.private_title}>
                  {infosheet.private_title || "Sin título privado"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ContentListStyle>
  )
}
