import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useAuth } from '../../../context/AuthContext';

import ItemCard from '../../general-components/ItemCard';
import defaultImg from '../../../img/sinPortada01.png';

export default function MyTranslations() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [languages, setLanguages] = useState([]);

  const [translations, setTranslations] = useState([]);

  const [search, setSearch] = useState('');
  const [originLanguage, setOriginLanguage] = useState('');
  const [destinationLanguage, setDestinationLanguage] = useState('');
  const [infosheetType, setInfosheetType] = useState('');

  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!user) return;
    axios.get(`http://localhost:5000/api/get_translations_by_user/${user.id}`, {
      params: {
        infosheetTitle: queryParams.get('search'),
        originLanguage: queryParams.get('ol'),
        destinationLanguage: queryParams.get('dl'),
        infosheetType: queryParams.get('it'),
      }
    })
    .then(res => {
      setTranslations(res.data);
    })
    .catch(err => {
      console.log(err);
    });

    axios.get('http://localhost:5000/api/get_languages')
    .then(res => {
      setLanguages(res.data);
    })
    .catch(err => {
      console.log(err);
    });
  }, [user]);


  const searchContent = () => {
    const queryParams = new URLSearchParams(location.search);
    console.log(queryParams);
    if (search !== "") {
      queryParams.set('search', search) 
    } else {
      queryParams.delete('search');
    }
    

    if (originLanguage !== "") {
      queryParams.set('ol', originLanguage);
    } else {
      queryParams.delete('ol');
    }

    if (destinationLanguage !== "") {
      queryParams.set('dl', destinationLanguage);
    } else {
      queryParams.delete('dl');
    }

    if (infosheetType !== "") {
      queryParams.set('it', infosheetType);
    } else {
      queryParams.delete('it');
    }
    
    const newSearch = `?${queryParams.toString()}`; 
    navigate({ search: newSearch });
    window.location.reload()
  }

  const handleSearchChange = (e) => {
    setSearch(e);
  }

  const handleOriginLanguageChange = (e) => {
    setOriginLanguage(e);
  }

  const handleDestinationLanguageChange = (e) => {
    setDestinationLanguage(e);
  }

  const handleInfosheetTypeChange = (e) => {
    setInfosheetType(e);
  }

  const handleItemClick = () => {

  }

  const srcImg = (ref) => {
    if (ref) {
      const imgsrc = "http://localhost:5000" + (ref).substring(1);
      return imgsrc;
    } else {
      return defaultImg;
    }
  }

  return (
    <TranslationList>
      <div className='content-container'>
        <h2>My Translations</h2>
        <input type="text" placeholder="Search" onChange={(e) => handleSearchChange(e.target.value)} />
        <select name="originLanguage" onChange={(e) => handleOriginLanguageChange(e.target.value)}>
          <option value="">none</option>
          {languages.map((language) => (
            <option key={language.id_language} value={language.id_language}>{language.name}</option>
          ))}
        </select>
        <select name="destinationLanguage" onChange={(e) => handleDestinationLanguageChange(e.target.value)}>
          <option value="">none</option>
          {languages.map((language) => (
            <option key={language.id_language} value={language.id_language}>{language.name}</option>
          ))}
        </select>
        <select name="infosheetType" onChange={(e) => handleInfosheetTypeChange(e.target.value)}>
          <option value="">none</option>
          <option value="1">Character</option>
          <option value="2">Item</option>
          <option value="3">Location</option>
        </select>
        <button onClick={searchContent}>Search</button>

        <div className='gallery'>
          {translations.map((translation) => (
            <div className="" key={translation.translation_infosheet_id}>
              <ItemCard 
                handleClick = {handleItemClick} 
                img = {srcImg(translation.cropped_reference)} 
                item_title = {translation.translation_infosheet_public_title} 
                // overlay = {}
              />
            </div>
          ))}
        </div>
      </div>
    </TranslationList>
  )
}

const TranslationList = styled.div`
.gallery {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  // outline: 1px solid red;
  overflow: auto;
  gap: 10px;
}
`;