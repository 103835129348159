import React from "react";

import OverlayStyle from "../UserCreations/style/OverlayStyle";

export default function PublicStoryOverlay ({title, author, desc, tags, genres, stats}) {
  /**
   * expected props:
   * - title
   * - author
   * - description
   * - tags
   * - stats
   */
  return(
    <OverlayStyle>
      <div className="item-overlay manito">
        <div className="item-overlay-title-xl">
          <div className="padding-10 width-100">
            <p className="subtitle story-title-overlay" title={title}>{title}</p>
            <p className="text font-weight-bold margin-bottom-10">{author}</p>
            <p className="text story-description-overlay">{desc}</p>
          
            <div className="flex-row tags-overlay">
              {tags.map((tag, index) => (
                <p key={index}className="text margin-right-5 margin-bottom-5" title={tags}>{tag}</p>
              ))}
            </div>
            
            <div className="flex-row tags-overlay">
              {genres.map((genre, index) => (
                <p key={index} className="text margin-right-5 margin-bottom-10" title={genres}>{genre}</p>
              ))}
            </div>

            <div className="flex-row stats-overlay">
              <p className="text font-weight-bold margin-right-5">{`${stats.views} Vistas`}</p>
              <p className="text font-weight-bold">{`${stats.likes} Likes`}</p>
            </div>

          </div>
        </div>

      </div>
    </OverlayStyle>
  )
}