import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import styled from 'styled-components';
import ChapterItemBoxStyle from '../../style/chapterItemBoxStyle';

export default function ChapterItemBox({story_id, itemNumber, chapter, swapChapterOrder, isAbleToSwap}) {
  const MOVE_UP = -1;
  const MOVE_DOWN = 1;

  return (
    <ChapterItemBoxStyle>
      <div className='chapter-item-box-container text item'>
        <div className='column col-chapter'>
          <ChapterOrdering>
            <div className='chapter-ordering'>
              <button className='arrow-up' onClick={() => swapChapterOrder(chapter, MOVE_UP)} disabled={!isAbleToSwap(chapter, MOVE_UP)}>▲</button>
              <button className='arrow-down' onClick={() => swapChapterOrder(chapter, MOVE_DOWN)} disabled={!isAbleToSwap(chapter, MOVE_DOWN)}>▼</button>
            </div>
          </ChapterOrdering>
          <div className='chapter-number'>Capítulo {itemNumber} -</div>
          <div className='chapter-title'>{chapter.title}</div>
        </div>
        <div className='column col-stats'>
          <div className='column mismoAncho'>{chapter.state_name}</div>
          <div className='column mismoAncho'>{chapter.views}</div>
          <div className='column mismoAncho'>{chapter.likes}</div>
          <div className='column mismoAncho'>{chapter.comment_count}</div>
          <div className='column mismoAncho'>
            <input type="checkbox" id="checkbox" name="checkbox" className='checkbox margin-auto'></input>
          </div>

        </div>
        <div className='column col-actions'>
            <Link to={`/${story_id}/${chapter.chapter_id}/read`}>
              <button className='preview_btn_table circleButton margin-auto'></button>
            </Link>
            <Link to={`/${story_id}/${chapter.chapter_id}/edit`}>
              <button className='edit_btn circleButton margin-auto'></button>
            </Link>
        </div>
      </div>
    </ChapterItemBoxStyle>
  )
}
const ChapterOrdering = styled.div`
.chapter-ordering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.arrow-up {
  background-size: contain;
  background-repeat: no-repeat;
  // width: 1rem;
  // height: 1rem;
  // margin-bottom: 0.5rem;
}
.arrow-down {
  background-size: contain;
  background-repeat: no-repeat;
  // width: 1rem;
  // height: 1rem;
}
`;
