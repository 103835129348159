export default function ImageReferenceItemComponent(props) {


  return (
    //OPCIÓN 1
    <div className="tag" key={props.index}>
        {`${props.item.name} (${props.readableBytes(props.item.size)})`}
        <button className="remove-element-btn" onClick={() => props.removeFileAttachment(props.index)}></button> 
    </div>

    //OPCIÓN 2
    // <div className="misma-linea fondo-gris" key={props.index}>
    //     <div className="margin-right-20">
    //         <img className="file_attachment_preview" src={props.item.data} alt="file preview"/>
    //     </div>
        
    //     <div className="misma-linea flex-1 flex-wrap margin-right-10">
    //         <p className="text font-weight-bold margin-right-10">Nombre </p>
    //         <p className="text">{`${props.item.name}`}</p>
    //     </div>
        
    //     <div className="misma-linea flex-1 flex-wrap margin-right-10">
    //         <p className="text font-weight-bold margin-right-10">Tamaño </p>
    //         <p className="text">{`${props.readableBytes(props.item.size)}`}</p>
    //     </div>
      
    //   <div className="margin-right-10">
    //     <button className="remove-element-btn" onClick={() => props.removeFileAttachment(props.index)}></button>
    //   </div>
    // </div>
  );
}