import React, { useEffect, useState } from "react";
import {getTagList} from '../../api/type'
import { TagContainer } from "./style/tagStyle";

import lupa from "../../img/lupa.png";
import ButtonsStyle from "../style/buttonsStyle";

export default function TagComponent ({tagList, setTagList, type, id, placeholder, editable=true}) {
    /**
     * Actualmente usamos este componente para permitir al usuario añadir géneros y etiquetas a una historia, 
     * pero sospecho que su uso para etiquetas será más reutilizable que para géneros.
     * Probablemente podremos reutilizar la funcionalidad de etiquetas para los otros tipos de contenidos, como imagenes, musica y voces.
     * Generos, por otra parte, creo que se manejarian ligeramente distino. Hay generos literarios y musicales. En cuanto a imagenes, 
     * quizas baste con usar etiquetas.
     * 
     * En este caso, sugiero separar este componente en dos: TagComponent y GenreComponent, permitiendo diferenciar el comportamiento
     * del componente segun el tipo, de forma más ordenada.   
     */
    const tag_type = type

    const [databaseTagList, setDatabaseTagList] = useState([]) // [ tag1, tag2, tag3, ...
    const [tagSuggestions, setTagSuggestions] = useState([""]) // [ tag1, tag2, tag3, ...
    useEffect(() => {
        const fetchData = async () => {
            // console.log('tag_type: ',tag_type)
            try {
                const response = await getTagList(tag_type)
                const formattedTags = response.data.map((tagInfo) => (tagInfo.name));
                setDatabaseTagList(formattedTags)
            } catch (error) {
                console.log('Error fetching tag/genre collection: ', error);
            }
        }
        fetchData();
    }, [])

    const _addTag = () => {
        const newTag = document.getElementById(`${tag_type}-input-box`).value.toLowerCase().replace(/\s/g, '');
        if (newTag === '') return
        if (tag_type === 'genres' && !databaseTagList.includes(newTag)) {
            document.getElementById(`${tag_type}-input-box`).value = ''
            return
        }
        if (tagList.includes(newTag)) {
            document.getElementById(`${tag_type}-input-box`).value = ''
            return
        }
        setTagList([...tagList, newTag])
        document.getElementById(`${tag_type}-input-box`).value = ''
    }

    const _removeTag = (tag) => {
        const newTagList = tagList.filter((t) => t !== tag)
        setTagList(newTagList)
    }

    const handleInputChange = (e) => {
        const input = e.target.value
        if (input === '') return
        const filteredTags = databaseTagList.filter((tag) => tag.startsWith(input))
        setTagSuggestions(filteredTags)
    }

    const handleKeyDown = (e) => { 
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            _addTag()
        }
    }

    return (
        <ButtonsStyle>
            <TagContainer>
                <div className="tag-component-content" id={id}>
                    {editable && <div className="tag-adder">
                        <img className="lupa" alt="Lupa" src={lupa}/>
                        <input 
                            id={`${tag_type}-input-box`}
                            className="tagInput" 
                            type="text" 
                            list={`${tag_type}_suggestions`} 
                            onChange={handleInputChange} 
                            placeholder={placeholder}
                            maxLength='50'
                            // regular expression to prevent whitespaces
                            onKeyDown={handleKeyDown}
                        />
                        <button className="add-element-button" onClick={_addTag}></button>
                        
                        <datalist id={`${tag_type}_suggestions`}>
                            {tagSuggestions.map((tag) => <option key={tag_type + '_' + tag} value={tag}/>)}
                        </datalist>
                    </div>}
                    <div className="tag-list">
                        {tagList.map((tag) => 
                            <div className="tag" key={tag_type + '_' + tag}>
                                {tag}
                                {editable && <button className="remove-element-btn" onClick={() => _removeTag(tag)}/>}
                            </div>
                        )}
                    </div>
                </div>
            </TagContainer>
        </ButtonsStyle>
    )
}
