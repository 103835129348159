import styled from "styled-components";

import { colors } from "../../style/colors";

import mas01 from "../../../img/+01.png";
import mas02 from "../../../img/+02.png";
import xtag01 from "../../../img/xtag01.png";
import xtag02 from "../../../img/xtag02.png";

export const TagContainer = styled.div`
//contenedor externo de tags que alinea los elementos a la izquierda
.tag-component-content {
    text-align: left;
}

// //buscador de tags
// .tagInput{
//     text-align: left;
//     border: 1px solid ${colors.c5};
//     color: ${colors.c3};
//     margin: 0px;
//     padding-left: 40px;
//     padding-right: 10px;
//     font-size: 12px;
//     height: 30px;
//     width: 150px;
//     border-radius: 10px;
//     margin-right: 10px;
//     text-transform: lowercase;
// }
// //buscador de tags con foco (al hacer click)
// .tagInput:focus {
//     outline: none;
// }

// //texto propuesto para buscador
// .tagInput::placeholder {
//     text-transform: none;
// }

// .lupa{
//     position: absolute;
//     margin: 10px;    
// }

.tag-list {
    display: flex;
    flex-wrap: wrap;
}
.tag {
    background: ${colors.c7};
    color: ${colors.c3};
    font-size: 12px;
    height: 30px;
    padding: 0px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.tag-adder{
    align-items: center;
    margin-bottom: 10px;
}
`;