import styled from "styled-components";
import { colors } from "../../style/colors";

const StoryDetailsStyle = styled.div`
    //contenedor de botones para seleccionar imagen (banner y portada)
    .img_selecting_thing {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    //boton para seleccionar banner
    .banner_img_btn {
        height: 100%;
    }
    .banner_img {
        max-width: 100%;
        max-height: 100%;
        margin: 0px;
        padding: 0px;
        border-radius: 10px;
    }
    .tags {
        display: flex;
        flex-direction: column;
        align-items: left;
    }

    .search_box{
        max-width: 100px;
    }
`;

export default StoryDetailsStyle;
