import styled from "styled-components";
import React from "react";
import ImgCropper from "../../../utils/img-cropper-component/ImgCropper";


export default function ProfilePicCropper({ mode, imageSetter, togglePopup, selectedImg }) {
  const selectedImgId = null

  return (
    <PFPStyleContainer>
      <div className="overlay">
        <div className="component-content">
          <div className="titleBar">
            {/* <h3>Selecciona una imagen</h3> */}
            <button onClick={togglePopup} className="close-icon">&times;</button>
          </div>
          <div className="gallery-cropper">
            <div className="cropper">
              {/*
              */}
              <ImgCropper mode={mode} img={{src: selectedImg, id: selectedImgId}} imageSetter={imageSetter}/>
            </div>
          </div>
        </div>
      </div>
    </PFPStyleContainer>
  )
}

const PFPStyleContainer = styled.div`
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 999;
}
.component-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  max-height: 70vh;
  background: white;
  transform: translate(-50%, -50%);
}
.titleBar {
  display:flex;
  justify-content: space-between;
}
.gallery-cropper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cropper {
  width: 100%;
  border: 1px solid black;
}
`