import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import {getUserRoleServiceList, updateUserRoleServiceList} from '../../../../api/service';
import styled from "styled-components";
import { useAuth } from "../../../../context/AuthContext";
import IllustratorServiceItemComponent from './IlustratorServiceItemComponent';
// import { useParams } from 'react-router-dom';
import {aplicarAjusteAltura} from '../../../../functions/textAreaHeight';

export default function IllustratorServices({ targetUser }) {
  const { user } = useAuth();

  const NotificationBubble = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })

  const [roleDescription, setRoleDescription] = useState("")

  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    roleDescription ? roleDescription.length : 0
  );

  useEffect(() => {
    console.log("target user: ", targetUser)
    if (roleDescription) {
      setCharacterDescriptionCount(roleDescription.length);
    }
  }, [roleDescription]);

  const emptyService = {
    serviceName: "", 
    serviceDescription: "", 
    attachment: {name: "", size: 0, type: "", data: null, reference: null,}, 
    servicePrice: "0",
  };

  const [serviceList, setServiceList] = useState([
    // {
    //   serviceName: "", 
    //   serviceDescription: "", 
    //   attachment: {name: "example.jpg", size: 100000, type: "image/jpeg", data: "data:image/jpeg;base64, ..."}, 
    //   servicePrice: "1000"
    // },
  ])

  useEffect(() => {
    if(!user || !targetUser) return

    const fetchData = async () => {
      try{
        const response = await getUserRoleServiceList(targetUser.user_id, 4);
        if(response.data.services) {
          const initializeServices = response.data.services.map(service => {
            return {
              serviceName: service.service_name,
              serviceDescription: service.service_description,
              servicePrice: service.price,
              attachment: {
                name: service.attachment_name,
                size: service.attachment_size,
                type: service.attachment_type,
                data: null,
                reference: service.example_reference,
              },
              serviceID: service.service_ilustrator_id,
            }
          })
          setServiceList(initializeServices);
        }
        setRoleDescription(response.data.user_role_description);
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [user, targetUser])

  const updateRoleDescription = (e) => {
    setRoleDescription(e.target.value)
  }
    
  const addService = () => {
    setServiceList([...serviceList, emptyService])
  }

  const removeService = (index) => {
    setServiceList(serviceList.filter((service, i) => i !== index))
  }

  const updateServiceName = (index, newName) => {
    const newServiceList = [...serviceList];
    newServiceList[index].serviceName = newName;
    setServiceList(newServiceList);
  }

  const updateServiceDescription = (index, newDescription) => {
    const newServiceList = [...serviceList];
    newServiceList[index].serviceDescription = newDescription;
    setServiceList(newServiceList);
  }

  const updateAttachement = (index, e) => {
    if (e.target.files.length > 0) {
      let newServiceList = [...serviceList];
      const file = e.target.files[0];

      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function(e) {
        const file_info = {
          name: file.name,
          size: file.size,
          type: file.type,
          // extension: file.name.split('/')[0],
          data: reader.result
        }

        newServiceList[index].attachment = file_info
        // console.log(file_info.data)
        setServiceList(newServiceList)
      }
    }

  }


  const removeAttachement = (index) => {
    const newServiceList = [...serviceList];
    newServiceList[index].attachment = {name: "", size: 0, type: "", data: null, reference: null,};
    setServiceList(newServiceList);
  }

  const updateServicePrice = (index, newPrice) => {
    const newServiceList = [...serviceList];
    newServiceList[index].servicePrice = newPrice;
    setServiceList(newServiceList);
  }

  const saveChangesToListOfServices = async () => {
    const msg = {
      user_id: user.id,
      role_id: 4, // 4 es el role_id de ilustrador
      new_service_list: serviceList,
      role_description: roleDescription//{description: roleDescription},
    }
    // console.log(msg)

    try{
      const response = await updateUserRoleServiceList(msg);
      NotificationBubble.fire({
        icon: "success",
        title: "Cambios guardados"
      })

      // console.log(response.data)
    } catch (error) {
      console.log(error)
      NotificationBubble.fire({
        icon: "error",
        title: "Error al guardar los cambios"
      })
    }
  }

  const sourceServiceImg = (index) => { 
    if (serviceList[index].attachment.reference) {
      return serviceList[index].attachment.reference
    }
    else if (serviceList[index].attachment.data) {
      return serviceList[index].attachment.data
    }
    return "https://via.placeholder.com/150"
  }
  
  const targetUserIsLoggedInUser = () => {
    if (!user || !targetUser) return false
    return user.id === targetUser.user_id
  }

  const readableBytes = (bytes) => {
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round((bytes / Math.pow(1024, i)) * 10, 2) / 10 + ' ' + sizes[i]
  }
 
  useEffect(() => {
    aplicarAjusteAltura('textarea.textareaInputMedium', 200, true);
  }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded


  // MARK: - Render
  return (
    <div className="flex-row">
      <div className="width-100">

        <h3 className="subtitle">Descripción</h3>
        {targetUserIsLoggedInUser() &&
          <>
            <textarea type="text" name="message" id="message" className="textareaInput textareaInputMedium" placeholder="Descripción" maxLength="1000"
              value={roleDescription} onChange={(e) => {updateRoleDescription(e);  setCharacterDescriptionCount(e.target.value.length)}}
            />
            <p className='count'>{characterDescriptionCount}/1000</p>
          </>
        }
        {!targetUserIsLoggedInUser() &&
          <div className="textareaInput textareaInputMedium">{`${roleDescription}`}</div>
        }

        <hr className='separador'/>

        <div className="misma-linea">
          <h3 className="subtitle margin-right-10">Servicios</h3>
          <button className="add-element-button-small" onClick={() => addService()}></button>
          {/* <button onClick={() => test()}>Test</button> */}
        </div>

        <div className="flex-column gap-10">
          {serviceList && serviceList.map((service, index) => (
            <IllustratorServiceItemComponent
              isEditable={targetUserIsLoggedInUser()}
              key={index}
              index={index}
              serviceList={serviceList}
              updateServiceName={updateServiceName}
              updateServiceDescription={updateServiceDescription}
              updateAttachement={updateAttachement}
              sourceServiceImg={sourceServiceImg}
              removeAttachement={removeAttachement}
              updateServicePrice={updateServicePrice}
              removeService={removeService}
            />
          ))}
        </div>

<hr className='separador'/>

      {targetUserIsLoggedInUser() &&
        <div className="sin-ancho">
          <div className="btns">
            <button className="save_btn circleButton" onClick={() => saveChangesToListOfServices()}></button>
          </div>
        </div>
      }
      </div>

    </div>
  );
}
