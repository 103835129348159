// eslint-disable-next-line no-unused-vars
import React from "react";
import { BubbleMenu } from '@tiptap/react'

import BubbleMenuStyle from "./style/BubbleMenuStyle";
import ButtonsStyle from "./../style/buttonsStyle";

// import { icons } from '../icons'
import b_alignLeft from '../../img/bubbleMenu/alignLeft-01.png'
import b_alignCenter from '../../img/bubbleMenu/alignCenter-01.png'
import b_alignRight from '../../img/bubbleMenu/alignRight-01.png'
import b_alignJustify from '../../img/bubbleMenu/alignJustify-01.png'

import h1 from '../../img/bubbleMenu/h1-01.png'
import h2 from '../../img/bubbleMenu/h2-01.png'
import h3 from '../../img/bubbleMenu/h3-01.png'

export default function MiBubbleMenu(prop) {
    const editor = prop.editor
    const currentAlign = (editor) => {
        if (editor.isActive({ textAlign: 'left' })) {
            return b_alignLeft
        }
        if (editor.isActive({ textAlign: 'center' })) {
            return b_alignCenter
        }
        if (editor.isActive({ textAlign: 'right' })) {
            return b_alignRight
        }
        if (editor.isActive({ textAlign: 'justify' })) {
            return b_alignJustify
        }
        return b_alignLeft

    }

    const currentHeader = (editor) => {
        if (editor.isActive('heading', { level: 1 })) {
            return <img alt="h1" src={h1}/>
        }
        if (editor.isActive('heading', { level: 2 })) {
            return <img alt="h2" src={h2}/>
        }
        if (editor.isActive('heading', { level: 3 })) {
            return <img alt="h3" src={h3}/>
        }
        return 'text'
    }    

    return (
        <>
            { editor && <BubbleMenu 
            editor={editor} 
            shouldShow={({ editor, view, state, }) => { return !editor.isActive('image') && !editor.view.state.selection.empty }}
            tippyOptions={{ duration: 100, maxWidth: 'auto' }}
            >
                <BubbleMenuStyle>
                    <ButtonsStyle>
                    <div className="background-bubble-menu">
                    <button
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        className={editor.isActive('bold') ? 'is-active bold-btn bubble-menu-btn' : 'bold-btn bubble-menu-btn'}>
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        className={editor.isActive('italic') ? 'is-active italic-btn bubble-menu-btn' : 'italic-btn bubble-menu-btn'}>
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        className={editor.isActive('strike') ? 'is-active strike-btn bubble-menu-btn' : 'strike-btn bubble-menu-btn'}>
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleUnderline().run()}
                        className={editor.isActive('underline') ? 'is-active underline-btn bubble-menu-btn' : 'underline-btn bubble-menu-btn'}>
                    </button>
                    
                    <div className="dropdown">
                        <button className="dropbtn" id="style-dropbtn">
                            {currentHeader(editor)}
                        </button>
                        <div className="dropdown-content">
                            <button
                                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                                className={editor.isActive('heading', { level: 1 }) ? 'is-active h1-btn bubble-menu-btn' : 'h1-btn bubble-menu-btn'}>
                            </button>
                            <button
                                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                                className={editor.isActive('heading', { level: 2 }) ? 'is-active h2-btn bubble-menu-btn' : 'h2-btn bubble-menu-btn'}>
                            </button>
                            <button
                                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                                className={editor.isActive('heading', { level: 3 }) ? 'is-active h3-btn bubble-menu-btn' : 'h3-btn bubble-menu-btn'}>
                            </button>
                        </div>
                    </div>

                    <div className="dropdown">
                        <button className="dropbtn" id="align-dropbtn">
                            <img alt="A" src={currentAlign(editor)}/>
                        </button>
                        <div className="dropdown-content">
                            <button
                                onClick={() => editor.chain().focus().setTextAlign('left').run()}
                                className={editor.isActive({ textAlign: 'left' }) ? 'is-active align-left-btn bubble-menu-btn' : 'align-left-btn bubble-menu-btn'}>
                            </button>
                            <button
                                onClick={() => editor.chain().focus().setTextAlign('center').run()}
                                className={editor.isActive({ textAlign: 'center' }) ? 'is-active align-center-btn bubble-menu-btn' : 'align-center-btn bubble-menu-btn'}>
                            </button>
                            <button
                                onClick={() => editor.chain().focus().setTextAlign('right').run()}
                                className={editor.isActive({ textAlign: 'right' }) ? 'is-active align-right-btn bubble-menu-btn' : 'align-right-btn bubble-menu-btn'}>
                            </button>
                            <button
                                onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                                className={editor.isActive({ textAlign: 'justify' }) ? 'is-active align-justify-btn bubble-menu-btn' : 'align-justify-btn bubble-menu-btn'}>
                            </button>
                        </div>
                    </div>

                   
                    
                    {/*
                    <button
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className={editor.isActive('bulletList') ? 'is-active' : ''}
                    >
                        bullet list
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className={editor.isActive('orderedList') ? 'is-active' : ''}
                    >
                        ordered list
                    </button>
                    */}
                    </div>
                    </ButtonsStyle>
                </BubbleMenuStyle>
            </BubbleMenu>}
        </>
    );
}