import React from "react";
import sinimagen from "../../../../img/sinimagen.png";
import OverlayStyle from "../../style/OverlayStyle";

export default function UploadImageOverlay (props) {

  return(
    <OverlayStyle>
    <div className="upload_image_overlay">
        <div className="flex-column center">
        {/* <input id="file-Input" type="file" accept="image/*" onChange={props.imageSelected} /> */}

            <img className="sinImagen" src={sinimagen} draggable="false"></img>
            <div class="container-input">
                <input type="file" name="file-1" id="file-Input" accept="image/*" class="inputfile inputfile-1" onChange={props.imageSelected} max="1"/>
                <label htmlFor="file-Input">
                    <span class="subtitle upload_button_text">Adjuntar imagen</span>
                </label>
                {/* <p className="text">(Ancho máximo 900px)</p> */}
                <p className="text">(Peso máximo 20mb)</p>
            </div>
        </div>
    </div>
    </OverlayStyle>
  )
}
