import React, { useEffect, useState } from "react";

import GeneralStyle from "../../../style/generalStyle";

export default function ServiceRequestList({ serviceRequestList, reviewServiceRequest, mode='sent'}) {
// el prop mode='sent' es para indicar si se trata de una lista de solicitudes enviadas o recibidas
// sus posibles valores son 'sent' o 'received'

  return (
    <GeneralStyle>
      <div className="flex-column gap-10">
      {serviceRequestList.map((serviceRequest, index) => (
            <div className="fondo-blanco" key={index}>
              <div className="margin-auto">
                <img src="https://via.placeholder.com/50" className="CircleImage margin-right-20" alt="user profile" />
              </div>

              <div className="flex-1 flex-column margin-auto">
                <div className="flex-row margin-bottom-10 flex-wrap">
                  <p className="subtitle sin-margen margin-right-10">Servicio</p>
                  <p className="text"> {`${serviceRequest.service_name}`}</p>
                </div>

                <div className="flex-row flex-wrap">
                  {mode === 'sent' &&
                    <>
                      <p className="subtitle sin-margen margin-right-10">{`${serviceRequest.provider_role_name}`}</p>
                      <p className="text">{`${serviceRequest.provider_name} (id: ${serviceRequest.provider_id})`}</p>
                    </>
                  }
                  {mode === 'received' &&
                    <>
                      <p className="subtitle sin-margen margin-right-10">{`Cliente`}</p>
                      <p className="text">{`${serviceRequest.requester_name} (id: ${serviceRequest.requester_id})`}</p>
                    </>
                  }
                </div>
              </div>
              
              <div className="flex-1 flex-column margin-auto">
                <div className="flex-row flex-wrap margin-bottom-10">
                  <p className="subtitle sin-margen margin-right-10">Precio Inicial</p>
                  <p className="text flex-1 flex-end">{`$${serviceRequest.initial_price}`}</p>
                </div>
                <div className="flex-row flex-wrap">
                  <p className="subtitle sin-margen margin-right-10">Precio Actual</p>
                  <p className="text flex-1 flex-end">{`$${serviceRequest.current_price}`}</p>
                </div>
              </div>

              <div className="flex-1 flex-column margin-auto">
                <div className="flex-row flex-wrap margin-bottom-10">
                  <p className="subtitle sin-margen margin-right-10">Estado</p>
                  <p className="text">{serviceRequest.state_name}</p>
                </div>
              </div>

              <div className="margin-auto">
                <button className="preview_btn_table circleButton" onClick={() => reviewServiceRequest(serviceRequest)}></button>
              </div>
            </div>
          ))}
          </div>
    </GeneralStyle>
  )
}
