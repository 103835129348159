import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import {getImageDetails, uploadImage, updateImageDetails} from "../../../../api/content";
import { BACKEND_HOST } from "../../../../api/host";

import styled from "styled-components";

import TagComponent from "../../../tag-components/TagComponent";
import GeneralStyle from "../../../style/generalStyle";
import ButtonsStyle from "../../../style/buttonsStyle";
import UploadImageOverlay from "./UploadImageOverlay";
import OverlayStyle from "../../style/OverlayStyle";

import defaultImg from "../../../../img/pantallaSinImagen02.png";

import { useAuth } from "../../../../context/AuthContext";

import Swal from "sweetalert2";

export default function EditImage() {
  const { user } = useAuth();
  const { img_id } = useParams();

  const navigate = useNavigate();
  
  const [fullScreenView, setFullScreenView] = useState(false)

  const [imgData, setImgData] = useState(defaultImg)
  const [inputFile, setInputFile] = useState(null)
  const [tagList, setTagList] = useState([])
  const [imgDetails, setImgDetails] = useState({
    img_id: img_id, 
    img_title: '',
    img_description: '',
    img_url: '', 
    // img_tags: [],
  })

  const [characterTitleCount, setCharacterTitleCount] = useState(
    imgDetails.img_title ? imgDetails.img_title.length : 0
  )

  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    imgDetails.img_description ? imgDetails.img_description.length : 0
  )

  const [isSavingImg, setIsSavingImg] = useState(false)

  const SaveConfirmation = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })

  useEffect(() => {
    if (!user) return
    
    if (img_id !== "new") {
      const id = img_id.split('.')[0]
      const fetchData = async () => {
        try {
          const response = await getImageDetails(id)
          setImgDetails(
            {
              img_id: response.data.id_image,
              img_title: response.data.title,
              img_description: response.data.description || '',
              img_url: response.data.image_url,
            }
          )
          setCharacterTitleCount(response.data.title.length);
          if(response.data.description){
            setCharacterDescriptionCount(response.data.description.length);
          }
          // console.log('getImageDetails', response.data)
          setTagList(response.data.tag_list.filter(tag => tag != null))
          // console.log('tagList', response.data.tag_list)
          setImgData("existing_image")
        }
        catch (error) {
          console.log('Error:', error)
        }
      }
      fetchData()
    } else {
      setImgDetails({
        img_id: img_id,
        img_title: '',
        img_description: '',
        img_url: '',
      })
      setImgData(defaultImg)
      setInputFile(null)
      setCharacterTitleCount(0)
      setCharacterDescriptionCount(0)
      setTagList([])
    }
  }, [user, img_id])
  
  const handleUploadImg = async () => {
    setIsSavingImg(true)
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const img = new Image()
      img.onload = async () => {
        const msg = {
          user_id: user.id,
          imgData: reader.result?.toString(),
          width: img.width,
          height: img.height,
          img_title: imgDetails.img_title,
          img_description: imgDetails.img_description,
          img_tags: tagList,
        }

        try{
          const response = await uploadImage(msg)

          SaveConfirmation.fire({
            icon: "success",
            title: "Imagen guardada exitosamente!"
          })
          
          navigate(`/mycreations/myimages/${response.data.image_id}`)
        } catch (error) {
          console.log('Error:', error)
          SaveConfirmation.fire({
            icon: "error",
            title: "Error al subir la imagen"
          })
        } finally {
          setIsSavingImg(false)
        }
      }
      img.src = reader.result?.toString();
    })
    reader.readAsDataURL(inputFile)
  }

  const handleUpdateImgDetails = async () => {
    console.log('tagListsssssssss',tagList)
    const msg = {
      img_id: img_id.split('.')[0],
      img_title: imgDetails.img_title,
      img_description: imgDetails.img_description,
      // img_tags: imgDetails.img_tags,
      img_tags: tagList,
    }

    try{
      const response = await updateImageDetails(msg)
      console.log('Server response:', response)
      SaveConfirmation.fire({
        icon: "success",
        title: "Cambios guardados exitosamente!"
      })
      navigate(`/mycreations/myimages/${img_id}`)
    } catch (error) {
      SaveConfirmation.fire({
        icon: "error",
        title: "Error al guardar los cambios"
      })
      console.log('Error:', error)
    }
  }

  const saveChanges = () => {
    if (img_id === "new") {
      handleUploadImg()
    } else {
      handleUpdateImgDetails()
    }

  }

  const imageSelected = (e) => {
    setInputFile(e.target.files[0])
    
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgData(reader.result?.toString() || '') // used to display uploaded image on screen after selecting it
    })

    reader.readAsDataURL(e.target.files[0])
  }

  const openImage = () => {
    if (imgData !== defaultImg) {
      setFullScreenView(true)
    } else {

    }
    // console.log(`Opening image ${img_id}`)
  }

  const sourceImage = () => {
    if (img_id !== "new") {
      return imgDetails.img_url
    } else {
      return  imgData
    }
  }

  const seePreview = (id) => {
    ///:story_id/overview
    navigate(`/mycreations/myimages/preview/${id}`)
  }

  const [isHovered, setIsHovered] = useState(false);

  // MARK: - Render
  return (
    <GeneralStyle>
      <ButtonsStyle>
    <ViewImageContainer>
      {/* <h1>ViewImage</h1> */}
      {fullScreenView && 
        <div className="overlay" onClick={() => setFullScreenView(false)}>
          <div className="overlay-content">
            <img 
              className="img-display-fullscreen"
              src={sourceImage()} 
              alt={`img_${img_id}`}
            />
          </div>
        </div>
      }
      <div className="container">
        <div className="leftNullContainer"></div>

        <div className="intContainer">
          <div className="title-n-select">
            {(img_id === "new")  && <h1 className="title">Nueva ilustración</h1>}
            {!(img_id === "new")  && <h1 className="title">Modificar ilustración</h1>}
            {/* <div className="selectContainer">
              <select id="language" 
              // value={infosheetType} onChange={(e) => updateInfoSheetType(e.target.value)}
              >
                <optgroup label="Idioma">
                  <option value="1">Español</option>
                  <option value="2">English</option>
                  <option value="3">Italiano</option>
                </optgroup>
              </select>
            </div> */}
          </div>

          <hr className='separador'/> 

          <h3 className="subtitle">Título</h3>
          <input type="text" id="title_input_box" className="inlineInput" placeholder="Título" maxLength="100" 
            value={imgDetails.img_title}
            onChange={(e) => {setImgDetails({...imgDetails, img_title: e.target.value}); setCharacterTitleCount(e.target.value.length)}}>
          </input>
          <p className='count'>{characterTitleCount}/100</p>

          <hr className='separador'/> 

          {(img_id === "new") && 
          <div className="upload_image_container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <img className="img-display center margin-auto" src={sourceImage()}  alt={`img_${img_id}`}></img>
            {isHovered && <UploadImageOverlay imageSelected={imageSelected}/>}
          </div>
          }

          {!(img_id === "new") && 
          <div className="view_image_container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <img className="img-display center margin-auto" src={sourceImage()}  alt={`img_${img_id}`} onClick={openImage}></img>
          </div>
          }

          <hr className='separador'/> 

          <h3 className="subtitle">Descripción</h3>
          <textarea type="text" id="desc_input_box" className="textareaInput" placeholder="Descripción" maxLength="1000"
              value={imgDetails.img_description} onChange={(e) => {setImgDetails({...imgDetails, img_description: e.target.value});  setCharacterDescriptionCount(e.target.value.length)}}/>
          <p className='count'>{characterDescriptionCount}/1000</p>

          <hr className='separador'/> 
                
          <div className="tags">
              <h3 className="subtitle">Etiquetas</h3>
              <TagComponent tagList={tagList} setTagList={setTagList} id="img-tags" type="tags" placeholder="Buscar..." />
          </div>

          <hr className='separador'/> 

          <div className="btns">          
            <button className="save_btn circleButton" onClick={saveChanges} disabled={isSavingImg} ></button>
            <button className="preview_btn circleButton" onClick={() => seePreview(img_id)}></button>
          </div>

        </div>


      </div>

    </ViewImageContainer>
      </ButtonsStyle>
    </GeneralStyle>
  );
}

const ViewImageContainer = styled.div`
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  cursor: zoom-out;
  align-items: center;
}
.overlay-content {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow-x: auto;
}

.img-display-fullscreen {
  // position: absolute;
  // display: flex;
  // flex-shrink: 0; 
}

.upload_image_container{
  position: relative; 
  width: 100%;
  max-height: 100%;
  max-width: 100%; /* Ancho del contenedor */
  min-height: 300px;
}

.view_image_container{
  position: relative; 
  width: 100%;
  max-height: 100%;
  max-width: 100%; /* Ancho del contenedor */
}

.img-display {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  // cursor: zoom-in;
  border-radius: 10px;
}

.upload_image_overlay {
  position: absolute; /* Posición absoluta con respecto al contenedor */
  top: 0;
  left: 0;
  width: 100%; /* Ocupar todo el ancho del contenedor */
  height: 100%; /* Ocupar toda la altura del contenedor */
  background-color: rgba(221, 221, 221, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  border-radius: 10px;
}
`