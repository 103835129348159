import React, { useState, useEffect } from "react";

import {getUserRoleServiceList, submitServiceRequest} from '../../../../api/service';
import GeneralStyle from "../../../style/generalStyle";
import ButtonsStyle from "../../../style/buttonsStyle";
import AccessPermissionItemComponent from "../AccessPermissionItemComponent";
import OtherServiceItemComponent from "../OtherServiceItemComponent";
import EditionServiceItemComponent from "./EditionServiceItemComponent";

import { useParams } from 'react-router-dom';

import AttachReferenceContent from "../../attach-reference/AttachReferenceContent";
import { useAuth } from "../../../../context/AuthContext";


export default function EditionRequest() {
  const { user } = useAuth();
  const { provider_id } = useParams();

  const [referenceContentPopup, setReferenceContentPopup] = useState(false);
  const [selectedSharedContentBox, setSelectedSharedContentBox] = useState(null)

  const [sharedContent, setSharedContent] = useState([
    // {title: "select content", type: {name: "---", id: null}, language: {name: "---", id: null}, words: "1000"},
  ])

  const newSharedContent_Empty = {
    title: "select content", 
    type: {name: "---", id: null}, 
    language: {name: "---", id: null}, 
    words: "---"
  }
  

  const [additionalTextList, setAdditionalTextList] = useState([
    // {text: "doom", words: "1"},
  ])

  const newAdditionalText_Empty = {
    text: "", 
    words: "0",
  } 

  const [selectedService_index, setSelectedService_index] = useState(null)
  const [selectedEditionService, setSelectedEditionService] = useState(null)
  const [editionServiceList, setEditionServiceList] = useState([
    // {name:"Quick", description: "hola esta es la descripcion del servicio... :v hue hue hue", price: "10000"},
    // {name:"Full Review", description: "hola esta es la descripcion del segundo servicio... :v hue hue hue 2", price: "20000"}, 
  ])


  const [message, setMessage] = useState("")

  
  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    message ? message.length : 0
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserRoleServiceList(provider_id, 2)
        if(response.data.services){
          const initializeServices = response.data.services.map(service => {
            return {
              name: service.service_name,
              description: service.service_description,
              price: service.price,
            }
          })
          setEditionServiceList(initializeServices)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])


  const selectService = (service, index) => {
    setSelectedService_index(index)
    setSelectedEditionService(service)

  }


  const submitRequest = async () => {
    if (selectedEditionService === null) {
      console.log("No service selected")
      return
    }
    const service = selectedEditionService
    
    // const formData = new FormData()
    // fileAttachments.forEach((file, index) => {
      
    // })

    // service provider role id's:
    // "escritor" 1	
    // "editor" 2	
    // "traductor" 3	
    // "ilustrador" 4	
    // "músico" 5	
    // "doblador" 6	
    let requestContent = {
      requester_id: user.id,
      provider_id: provider_id, // provider_id vendria del parametro de la url. Se entiende que la pagina de solicitud de servicio se accede desde el perfil del proveedor
      provider_role_id: 2,
      selectedService: service,
      initial_price: service.price,
      current_price: service.price,
      
      // message: document.getElementById('message').value,
      message: message,
      sharedContentList: sharedContent,
      additionalTextList: additionalTextList,
    }
    console.log('requestContent',requestContent)
    try{
      const submitRequestResponse = await submitServiceRequest(requestContent)
      // console.log(submitRequestResponse)  
    } catch(error){
      console.log(error)
    }    
  }

  const loadSharedContentInfo = (info) => {
    let newList = [...sharedContent]
    newList[selectedSharedContentBox].title = info.title
    newList[selectedSharedContentBox].type = info.type
    newList[selectedSharedContentBox].language = info.language
    newList[selectedSharedContentBox].words = info.words
    newList[selectedSharedContentBox].content_id = info.content_id
    newList[selectedSharedContentBox].content_specific_id = info.content_specific_id

    setSharedContent(newList)
  }

  const addSharedContent = () => {
    setSharedContent([...sharedContent, newSharedContent_Empty])
  }

  const removeSharedContent = (index) => {
    setSharedContent(sharedContent.filter((content, i) => i !== index))
  }


  const openContentSharePopup = (index) => {
    setSelectedSharedContentBox(index)
    setReferenceContentPopup(true)
  }

  const closeContentSharePopup = () => {
    setReferenceContentPopup(false)
    setSelectedSharedContentBox(null)
  }


  const addAdditionalText = () => {
    setAdditionalTextList([...additionalTextList, newAdditionalText_Empty])
  }

  const removeAdditionalText = (index) => {
    setAdditionalTextList(additionalTextList.filter((item, i) => i !== index))
  }

  const updateAdditionalText = (index, text) => {
    let newList = [...additionalTextList]
    newList[index].text = text
    newList[index].words = text.split(' ').length
    setAdditionalTextList(newList)
  }

  const readableBytes = (bytes) => {
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round((bytes / Math.pow(1024, i)) * 10, 2) / 10 + ' ' + sizes[i]
  }

  // MARK: - Render
  return (
    <GeneralStyle>
      <ButtonsStyle>
        <div className="container">
        <div className="leftNullContainer"></div>

        <div className="intContainer">
          <div className="title-n-select">
            <h1 className="title">Solicitar edición</h1>
          </div>

          <hr className='separador'/>
          
          <div className="text advertencia misma-linea">
            <img className="info_btn margin-right-10"></img>
            <p  className="sin-margen">Puedes dar acceso anticipado de lectura a tus creaciones para que el editor las trabaje.</p>
          </div>

          <h3 className="subtitle">Descripción</h3>
          <textarea type="text" name="message" id="message" className="textareaInput" placeholder="Descripción" maxLength="1000"
              value={message} onChange={(e) => {setMessage(e.target.value);  setCharacterDescriptionCount(e.target.value.length)}}/>
          <p className='count'>{characterDescriptionCount}/1000</p>

          <hr className='separador'/>

          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Permiso de acceso</h3>
            <button className="add-element-button-small" onClick={() => addSharedContent()}></button>
          </div>

          <div className="flex-column gap-10">
            {sharedContent.map((content, index) => (
              <AccessPermissionItemComponent
                index = {index}
                openContentSharePopup = {openContentSharePopup}
                removeSharedContent = {removeSharedContent}
                words = {`${content.words}`}
                language = {`${content.language.name}`}
                type = {`${content.type.name}`}
                title = {`${content.title}`}
              />
            ))}
          </div>

          <hr className='separador'/>

          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Pedido Libre</h3>
            <button className="add-element-button-small" onClick={() => addAdditionalText()}></button>
          </div>

          <div className="flex-column gap-10">
            {additionalTextList.map((item, index) => (
              <OtherServiceItemComponent
                index = {index}
                text = {`${item.text}`}
                words = {`${item.words}`}
                updateAdditionalText = {updateAdditionalText}
                removeAdditionalText = {removeAdditionalText}
              />
            ))}
          </div>

          <hr className='separador'/>

          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Servicios</h3>
          </div>

          <div className="flex-column gap-10">
            {editionServiceList.map((service, index) => (
              <EditionServiceItemComponent
                index = {index}
                service = {service}
                selectService = {selectService}
                selectedService_index = {selectedService_index}
              />
            ))}
          </div>

        </div>

        <div className="btns">
          <button className='solicitarServicio_btn circleButton' title="Solicitar servicio" type="submit" onClick={() => submitRequest()}></button>
        </div>

        </div>
        { 
          referenceContentPopup &&
          <AttachReferenceContent
            closePopup={() => closeContentSharePopup()}
            loadContent={(info) => loadSharedContentInfo(info)}
          />
        }
      </ButtonsStyle>      
    </GeneralStyle>
  );
}
