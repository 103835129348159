import React, { useState, useEffect } from "react";

import {getContentTypeList} from '../../../api/type'
import {getInfosheet, getChapter} from '../../../api/content'
import styled from "styled-components";

import InfosheetList from "./content-lists/InfosheetList";
import ChapterList from "./content-lists/ChapterList";
import PopupStyle from "../../style/popupStyle";

import InfosheetPreview from "./content-previews/InfosheetPreview";
import ChapterPreview from "./content-previews/ChapterPreview";


export default function AttachReferenceContent({closePopup, loadContent}) {
  const [contentTypeList, setContentTypeList] = useState([
    // {name: 'IMAGE', id: 1}, 
    // {name: 'INFOSHEET', id: 2}, 
    // {name: 'STORY', id: 3}, 
    // {name: 'CHAPTER', id: 4}
  ])
  const [selectedType, setSelectedType] = useState({id: 2, name: 'Ficha'});
  const [selectedContent, setSelectedContent] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);

  const [typeFilter, setTypeFilter] = useState('0'); //this will be used to filter infosheets by type [0=all, 1=character, 2=item, 3=location]

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getContentTypeList();
        setContentTypeList(response.data);
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])


  const handleItemClick = async (content) => {
    setSelectedContent(content);

    switch (selectedType.id) {
      case 2: // (infosheet)
        try{
          const response = await getInfosheet(content.infosheet_id);
          setPreviewContent(response.data);
        } catch (error) {
          console.log(error)
        }
        break;
      case 4: // (chapter)
        try{
          const response = await getChapter(content.chapter_id);
          setPreviewContent(response.data);
        } catch (error) {
          console.log(error)
        }
        break;
      default: return null
    }
  }


  const confirmSelection = () => {
    if (selectedContent) {
      const contentInfo = {
        title: selectedContent.public_title || selectedContent.chapter_title,
        type: selectedType,
        language: {name: selectedContent.language_name, id: selectedContent.language_id},
        words: selectedContent.word_count,
        content_id: selectedContent.content_id,
        content_specific_id: selectedContent.infosheet_id || selectedContent.chapter_id,
      }
      loadContent(contentInfo);
      closePopup();
    }
  }

  const renderList = () => {
    switch (selectedType.id) {
      case 2: // infosheet
        return <InfosheetList handleItemClick={handleItemClick} />
      case  4: // chapter
        return <ChapterList handleItemClick={handleItemClick} />
      default: return null
    }
  }

  const renderPreviewWindow = () => {
    switch (selectedType.id) {
      case 2: // infosheet
        return (
          <>
            { previewContent &&
              <>
                <InfosheetPreview
                  title={previewContent.publicTitle}
                  // privateTitle={previewContent.private_title}
                  content={previewContent.content}
                />
              </>
            }
          </>
        )
      case 4: // chapter
        return (
          <>
            { previewContent && 
              <>
                <ChapterPreview 
                  title={previewContent.chapterTitle}
                  content={previewContent.content}
                />
              </>
            }
          </>
        )
      default: return null
    }
  }

  const changeContentType = (type) => {
    setSelectedContent(null);
    setPreviewContent(null);
    setSelectedType(type);
  }

  return (
    <PopupStyle>
    <AttachReferenceContainer>
      <div className="overlay">
        <div className="popup-containter-n-btns">
          <div className="leftNullContainer-40"></div>

          <div className="popup-container">
            <div className="left-column">
              {renderList()}
            </div>

            <div className="right-column margin-left-20">
              {renderPreviewWindow()} 
            </div>

          </div>

          <div className="btns-10">
            <button onClick={closePopup} className="close-btn circleButton"></button>            
            <button className="fichas_btn circleButton" onClick={() => changeContentType({name: 'Ficha', id: 2})}></button>
            
            <button onClick={() => changeContentType({name: 'Capítulo', id: 4})} className="capitulos_btn circleButton"></button>
            <button className="save_btn circleButton" onClick={() => confirmSelection()}></button>

          </div>
        </div>
      </div>
    </AttachReferenceContainer>
    </PopupStyle>
  )
}


const AttachReferenceContainer = styled.div`
.box-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1000px;
  height: 500px;
  background-color: #fff;
  outline: 1px solid red;
  // align-items: center;
  // width: 70vw;
  // height: 20vh;
  
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  align-items: center;
}


.control-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  height: 100%;
  // outline: 1px solid black;
  overflow-y: auto;
}
`