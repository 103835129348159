import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from 'react-router-dom';

import IllustratorServices from './illustrator-services/IllustratorServices';
import TranslatorServices from './translator-services/TranslatorServices';
import EditorServices from './editor-services/EditorServices';

import GeneralStyle from '../../style/generalStyle';
import ButtonsStyle from "../../style/buttonsStyle";
import TabBar from '../../utils/TabBar';

import { getUserDetails } from "../../../api/user";
import { useAuth } from "../../../context/AuthContext";

export default function CreateServices() {
  const { serviceType } = useParams();

  const [targetUser, setTargetUser] = useState(null)

  const { user } = useAuth()
  // const serviceType = 'ilustrador';

  // const jobCategories = [
  //   { display: 'Ilustrador', value: 'ilustrador' },
  //   { display: 'Traductor', value: 'traductor' },
  //   { display: 'Editor', value: 'editor' },
  //   // { display: 'Músico', value: 'musico' },
  // ];

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return
    fetchTargetUserData()
  }, [user])

  const fetchTargetUserData = async () => {
    try {
      if (user) {
        const response = await getUserDetails(user.id)
        console.log('response:', response.data)
        setTargetUser(response.data)
      } else {
        console.log('you should not be here')
        // const response = await getUserDetails(user.id)
        // setTargetUser(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleJobSelectionChange = (type) => {
    navigate(`/myservices/${type}`)
    // setSelectedJob(index)
  }

  const renderSelectedServiceType = () => {
    switch (serviceType) {
      case 'ilustrador':
        return <IllustratorServices
          targetUser={targetUser}
        />
      case 'traductor':
        return <TranslatorServices />
      case 'editor':
        return <EditorServices />
      default:
        return <h1>Not found</h1>
    }
  }
  
  const categories = [
    { name: 'Ilustrador', icon:'tabBarIlustrador_btn bubble-menu-btn circleButton', value: 'ilustrador'}, 
    { name: 'Traductor', icon:'tabBarTraductor_btn bubble-menu-btn circleButton', value: 'traductor'}, 
    { name: 'Editor', icon:'tabBarEditor_btn bubble-menu-btn circleButton', value: 'editor'},
  ]

  const handleTabChange = (selectedContentType) => {
    navigate(`/myservices/${categories[selectedContentType].value}`);
  }

  return (
      <GeneralStyle>
        <ButtonsStyle>
          <div className="container">
            <div className="intContainer">

              <div className='category-header'>
                <div className='title-n-select'>
                  {/* <h1 className='title'>Definir valores predeterminados</h1> */}
                  <h1 className='title'>Mis servicios</h1>
                </div>

                <hr className='separador'></hr>

                <TabBar 
                    categories={categories} 
                    onTabChange={handleTabChange}
                    defaultTab= {categories.findIndex((category) => category.value === serviceType)}
                />
              </div>
          
              <div className="text advertencia misma-linea">
                <img className="info_btn margin-right-10"></img>
                <p  className="sin-margen">Define las caracteristicas que contempla tu trabajo, añade precios base y publicalos para recibir solicitudes de tus clientes.</p>
              </div>

              {renderSelectedServiceType()}
            </div>
          </div>
        </ButtonsStyle>
      </GeneralStyle>
  );
}
