// This component will be a popup used to add infosheets to a chapter
import React, { useEffect, useState } from "react";
import {addInfosheetToChapter, removeInfosheetFromChapter} from "../../../api/content";

import PopupStyle from "../../style/popupStyle";

import InfosheetList from "./InfosheetList/InfosheetList";
import SelectedInfosheets from "./SelectedInfosheets/SelectedInfosheets";
import { useAuth } from "../../../context/AuthContext";

export default function AttachInfosheet({story_id, chapter_id, closePopup}) {
  const { user } = useAuth();
  const [includedInfosheets, setIncludedInfosheets] = useState([]);
  const [typeFilter, setTypeFilter] = useState('0'); //this will be used to filter infosheets by type [0=all, 1=character, 2=item, 3=location]

  /**sample infohsheet object
   * {
      "infosheet_id": 4,
      "infosheet_ref": "./content/infosheets/4.json",
      "infosheet_public_name": "the public",
      "infosheet_private_name": "the private",
      "infosheet_portrait_ref": "./content/infosheets/10_portraitImg.jpeg",
      "infosheet_portrait_original_img": null
      "infosheet_type": 2
    }
  */

  // useEffect(() => {
  // axios.get(`http://localhost:5000/api/getInfosheetsFromChapter/${chapter_id}`)
  // .then(response => {
  // // console.log(response.data);
  //     setIncludedInfosheets(response.data);
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
  // }, []);

  const includeInfosheet = async (infosheet) => {
    const alreadyIncluded = includedInfosheets.some(infosheetItem => infosheetItem.infosheet_id === infosheet.infosheet_id);
    if (!alreadyIncluded) {
      const msg = {
        user_id: user.id,
        chapter_id: chapter_id,
        infosheet_id: infosheet.infosheet_id
      }
      try {
        const response = await addInfosheetToChapter(msg);
        // console.log(response.data);
        setIncludedInfosheets([...includedInfosheets, infosheet]);
      } catch (error) {
        console.log('Error with adding infosheet to chapter: ', error);
      }
    }
  }

  const removeInfosheet = async (id) => {
    // /api/removeInfosheetFromChapter
    const msg = {
      user_id: user.id,
      chapter_id: chapter_id,
      infosheet_id: id
    }
    try{
      const response = await removeInfosheetFromChapter(msg);
      // console.log(response.data);
      const newInfosheets = includedInfosheets.filter(infosheet => infosheet.infosheet_id !== id);
      setIncludedInfosheets(newInfosheets);
    } catch (error) {
      console.log('Error with removing infosheet from chapter: ', error);
    }
  }

  return (
    <PopupStyle>
      <div className="overlay">
        <div className="popup-containter-n-btns">
          <div className="leftNullContainer-40"></div>

          <div className="popup-container">            
            <div className="left-column">
                <InfosheetList handleSelectedObject={includeInfosheet} typeFilter={typeFilter} />
            </div>

            <div className="right-column margin-left-20">
              {/* I want to add a dropdown selector with options to filter by type, those being Character, Item and Location*/}
              <div className="width-100">
                <SelectedInfosheets 
                  chapter_id={chapter_id}
                  typeFilter={typeFilter}
                  // addedInfosheets={{list: includedInfosheets, setList: setIncludedInfosheets}}
                  includedInfosheets={includedInfosheets}
                  setIncludedInfosheets={setIncludedInfosheets}
                  removeInfosheet={removeInfosheet}
                />
                <div></div>
              </div>
            </div>
          </div>

          <div className="btns-10">
            <button onClick={closePopup} className="close-btn circleButton"></button>

            {/* these options should be loaded from a type-table from server DB */}
            <button onClick={(e) => {
              if (e.currentTarget.classList.contains('activo')) {
                setTypeFilter("0");
              } else {
                setTypeFilter("1");
              }}} 
              className={`personaje-btn circleButton ${typeFilter === "1" ? 'activo' : ''}`}>
            </button>
            <button onClick={(e) => {
              if (e.currentTarget.classList.contains('activo')) {
                setTypeFilter("0");
              } else {
                setTypeFilter("2");
              }}} 
              className={`lugar-btn circleButton ${typeFilter === "2" ? 'activo' : ''}`}>
            </button> 
            <button onClick={(e) => {
              if (e.currentTarget.classList.contains('activo')) {
                setTypeFilter("0");
              } else {
                setTypeFilter("3");
              }}} 
              className={`objeto-btn circleButton ${typeFilter === "3" ? 'activo' : ''}`}>
            </button>
          </div>
        </div>
      </div>
    </PopupStyle>
  )
}
