import styled from "styled-components";
import { colors } from "../../style/colors";

const StoryReadStyle = styled.div`
    .banner-img{
        max-width: 900px;
        margin: 0px;
        padding: 0px;
        border-radius: 10px;
    };

    .container-info-n-colab{
        display: flex;
        flex-direction: row;
        max-width: 100%;
        max-height: 100%;
        //outline: 1px solid red;
    };

    .content-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        //outline: 1px solid blue;
    };

    .story-overview { //descripcion general de la historia (baner vs portada + descripcion)
        display: flex;
        flex-direction: column;
        //outline: 1px solid yellow;
    };

    .story-banner-container{
        max-width: 900px;
    };

    .story-banner-img{
        border-radius: 10px;
        max-width: 100%;
        max-height: 100%;        
    };
    
    .story-cover-n-info {
        display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: flex-start;
        // width: 100%;
        // outline: 1px solid orange;
    };

    
    .story-cover-container{
        width: 20%;
        max-width: 180px;
        margin-top: -58px;
        margin-left: 20px;
        border: 5px solid ${colors.c0};
        border-radius: 10px;
    };

    .story-cover-img{
        max-width: 100%;
        max-height: 100%;   
        border-radius: 10px;
    };

    .story-info {
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        margin-left: 14px;
    };

    .story-tags-n-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        // width: 100%;
        // outline: 1px solid pink;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .story-genres{    
        overflow: hidden; 
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        width: 60%;     
        text-align: left;    
    }

    .story-genres p{
        display: inline;
        padding-right: 5px; 
        // color:  ${colors.c5};
    }

    .story-stats{
        overflow: hidden; 
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        width: 38%;      
        text-align: right;   
        justify-content: flex-end;
    }

    // .story-stats p{        
    //     color:  ${colors.c5};
    // }

    .story-tags{
        margin-top: 20px;
        overflow: hidden; 
        display: flex;
        flex-wrap: wrap;
        overflow: hidden; 
        text-align: left;  
    }



`;

export default StoryReadStyle;
