import React from "react";
import { Link, useNavigate  } from 'react-router-dom';

import PrivateOverlayStyle from "../../style/OverlayStyle";

export default function PrivateInfosheetOverlay (props) {
  /**
   * expected props:
   * - title
   * - stats
   * - handleClickRead (function) //maybe? since this component is not meant to be generic, we can just hardcode the buttons
   * - handleClickEdit (function)
   */
  const navigate = useNavigate();

  // const goEdit = (id) => { 
  //   navigate(`/infosheet/${id}`)
  // }

  return(
    <PrivateOverlayStyle>
      <div className="item-overlay manito">
        <div>
          {/* <button className="" onClick={() => goRead(props.id)}>Read</button>  */}
          <button className="edit_btn_blue circleButton-20 item-overlay-button manito"></button>
        </div>
        <div className="item-overlay-title-m">
          <p className="subtitle public-title-overlay-s" title={props.title}>{props.title}</p>
          <p className="text private-title-overlay" title={props.private_title}>{props.private_title}</p>
        </div>
        <div className="item-overlay-stats">
        </div>
      </div>
    </PrivateOverlayStyle>
  )
}