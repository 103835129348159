import React, { useState, useEffect } from "react";

import {getLanguageList} from '../../../../api/type'
import {getUserRoleServiceList, updateUserRoleServiceList} from '../../../../api/service';
import styled from "styled-components";
import { useAuth } from "../../../../context/AuthContext";
import {aplicarAjusteAltura} from '../../../../functions/textAreaHeight';
import TranslatorServiceItemComponent from './TranslatorServiceItemComponent';
// import { useParams } from 'react-router-dom';


export default function TranslatorServices() {
  const { user } = useAuth()

  const [languages, setLanguages] = useState([
    // { name: "Español", id_language: 1 },
    // { name: "English", id_language: 2 },
    // ...
  ])

  const [roleDescription, setRoleDescription] = useState("")

  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    roleDescription ? roleDescription.length : 0
  );
  
  useEffect(() => {
    if (roleDescription) {
      setCharacterDescriptionCount(roleDescription.length);
    }
  }, [roleDescription]);

  const emptyPerWordFee = {originLanguage: "1", targetLanguage: "1", pricePerWord: "0"}

  // const [generalServiceList, setGeneralServiceList] = useState([{serviceTitle: "", serviceDescription: "", servicePrice: "10000"}])
  const [perWordServiceList, setPerWordServiceList] = useState([
    // {originLanguage: "1", ol_index: 0, targetLanguage: "1", tl_index: 0, pricePerWord: "10"}
  ])

  useEffect(() => {
    if(!user) return
    const fetchData = async () => {
      try{
        const responseLanguageList = await getLanguageList();
        // console.log(responseLanguageList.data)
        setLanguages(responseLanguageList.data)

        const responseServiceList = await getUserRoleServiceList(user.id, 3);
        // console.log(responseServiceList.data)
        if(responseServiceList.data.services) {
          const initializeServices = responseServiceList.data.services.map(service => {
            return {
              originLanguage: service.source_language_id,
              targetLanguage: service.destination_language_id,
              
              // el problema con esto es que el index puede no estar correlacionado con el id
              ol_index: service.source_language_id -1,
              tl_index: service.destination_language_id -1, 
  
              // el problema con esto es que la lista de lenguajes puede no haberse cargado aun
              // ol_index: languages.findIndex(lang => lang.id_language === service.id_source_language), 
              // tl_index: languages.findIndex(lang => lang.id_language === service.id_destination_language),
              
              pricePerWord: service.price
            }
          })
          setPerWordServiceList(initializeServices)
          // console.log('initializeServices',initializeServices)
        }
        if(responseServiceList.data.user_role_description) {
          setRoleDescription(responseServiceList.data.user_role_description);
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [user])

  const updateListOfServices = async () => {

    const newServiceList = perWordServiceList.map((fee) => {
      return {
        id_source_language: parseInt(fee.originLanguage),
        id_destination_language: parseInt(fee.targetLanguage),
        price: parseInt(fee.pricePerWord, 10),
      }
    })

    console.log('newServiceList',newServiceList)

    const msg = {
      user_id: user.id,
      role_id: 3, // 3 is the role_id for translator
      new_service_list: newServiceList,
      role_description: roleDescription//{description: roleDescription},
    }

    try{
      const response = await updateUserRoleServiceList(msg);
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const test = () => {
    console.log(perWordServiceList)
  }

  const updateRoleDescription = (e) => {
    setRoleDescription(e.target.value)
  }
    

  // const addGeneralService = () => {
  //   setGeneralServiceList([...generalServiceList, emptyGeneralService])
  // }
  
  // const removeService = (index) => {
  //   setGeneralServiceList(generalServiceList.filter((service, i) => i !== index))
  // }
  
  // const updateServiceName = (index, newName) => {
  //   const newServiceList = [...generalServiceList];
  //   newServiceList[index].serviceTitle = newName;
  //   setGeneralServiceList(newServiceList);
  // }

  // const updateServiceDescription = (index, newDescription) => {
  //   const newServiceList = [...generalServiceList];
  //   newServiceList[index].serviceDescription = newDescription;
  //   setGeneralServiceList(newServiceList);
  // }

  // const updateServicePrice = (index, newPrice) => {
  //   const newServiceList = [...generalServiceList];
  //   newServiceList[index].servicePrice = newPrice;
  //   setGeneralServiceList(newServiceList);
  // }

  const addPerWordFee = () => {
    setPerWordServiceList([...perWordServiceList, emptyPerWordFee])
  }

  const removePerWordFee = (index) => {
    setPerWordServiceList(perWordServiceList.filter((fee, i) => i !== index))
  }


  const updateOriginLanguage = (index, newLanguage_index) => {
    const newFeeList = [...perWordServiceList];
    newFeeList[index].originLanguage = languages[newLanguage_index].id_language;
    newFeeList[index].ol_index = newLanguage_index;
    setPerWordServiceList(newFeeList);
  }

  const updateTargetLanguage = (index, newLanguage_index) => {
    const newFeeList = [...perWordServiceList];
    newFeeList[index].targetLanguage = languages[newLanguage_index].id_language;
    newFeeList[index].tl_index = newLanguage_index;
    setPerWordServiceList(newFeeList);
  }

  const updatePricePerWord = (index, newPrice) => {
    const newFeeList = [...perWordServiceList];
    newFeeList[index].pricePerWord = newPrice;
    setPerWordServiceList(newFeeList);
  }

  useEffect(() => {
    aplicarAjusteAltura('textarea.textareaInputMedium', 200, true);
  }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded


  // MARK: - Render
  return (
    <IllustratorServicesContainer>
      <div className="flex-row">
        <div className="width-100">

          <h3 className="subtitle">Descripción</h3>
          <textarea type="text" name="message" id="message" className="textareaInput textareaInputMedium" placeholder="Descripción" maxLength="1000"
              value={roleDescription} onChange={(e) => {updateRoleDescription(e);  setCharacterDescriptionCount(e.target.value.length)}}/>
          <p className='count'>{characterDescriptionCount}/1000</p>

          <hr className='separador'/>

          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Servicios</h3>
            <button className="add-element-button-small" onClick={() => addPerWordFee()}></button>
            {/* <button onClick={() => test()}>Test</button> */}
          </div>

          <div className="flex-column gap-10">
            {perWordServiceList && perWordServiceList.map((fee, index) => (
              <TranslatorServiceItemComponent
                index={index}
                perWordServiceList={perWordServiceList}
                languages={languages}
                updateOriginLanguage={updateOriginLanguage}
                updateTargetLanguage={updateTargetLanguage}
                updatePricePerWord={updatePricePerWord}
                removePerWordFee={removePerWordFee}
              />
            ))}
          </div>

<hr className='separador'/>


            <div className="btns">
                <button className="save_btn circleButton" onClick={() => updateListOfServices()}></button>
            </div>
        </div>
        <div className="sin-ancho">
        </div>
      </div>

      {/* <div className="role_page_segment">
        <h2>Servicios de Traductor</h2>
        <button onClick={() => addGeneralService()}>➕</button>
      </div>
      
      <div className="general-services">
        <div className="service-list">
          <ul>
            {generalServiceList.map((service, index) => (
              <div className='service-item-card' key={index}>
                <div className="service-title">
                  <input type="text" 
                    onChange={(e) => updateServiceName(index, e.target.value)}
                    value={generalServiceList[index].serviceTitle}
                    placeholder="Nombre del servicio" 
                  />
                </div>
                <div className="service-description">
                  <input type="text" 
                    onChange={(e) => updateServiceDescription(index, e.target.value)}
                    value={generalServiceList[index].serviceDescription}
                    placeholder="Descripción del servicio" 
                  />
                </div>
                <div className="service-price">
                  <input type="text" 
                    onChange={(e) => updateServicePrice(index, e.target.value)}
                    value={generalServiceList[index].servicePrice}
                    placeholder="Precio del servicio" 
                  />
                </div>
                <button className='remove-btn' onClick={() => removeService(index)}>🗑️</button>
              </div>
            ))}
            
          </ul>
        </div>
      </div> */}
    </IllustratorServicesContainer>
  );
}

const IllustratorServicesContainer = styled.div`
.role_page_segment {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.service-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.service-item-card {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
  border: 1px solid black;
  align-items: center;
  // margin-bottom: 1rem;
}
`
