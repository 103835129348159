export default function EditionServiceItemComponent(props) {

    return (
        <div className="misma-linea fondo-gris" key={props.index}>
        <div className="misma-linea flex-1 margin-right-10">
            <div className="misma-linea">
                <input 
                    className="radio"
                    type="radio" 
                    id={`option${props.index}`} 
                    name="options" 
                    onChange={() => props.selectService(props.service, props.index)}
                    checked={props.selectedService_index === props.index}
                />
                <div>
                    <p className="text font-weight-bold margin-right-10">{`${props.service.name}`}</p>
                </div>
            </div>
        </div>

        <div className="misma-linea flex-3 flex-wrap margin-right-10">
            <p className="text align-center">{`${props.service.description}`}</p>
        </div>

        <div className="misma-linea flex-1 flex-wrap margin-right-10 flex-end">
            <p className="text align-center">{` $${props.service.price}`}</p>
        </div>

      </div>
    );
  }
  