import React, { useEffect, useState } from 'react';
import {getInfosheetsFromUser} from '../../../../api/content';

import ContentListStyle from '../style/ContentListStyle';

import { useAuth } from '../../../../context/AuthContext';

import lupa from "../../../../img/lupa.png";

export default function InfosheetList({ typeFilter, handleSelectedObject }) {
  const { user } = useAuth();
  // typeFilter is a number (as a string) that represents the infosheet type: [0=all, 1=character, 2=item, 3=location]
  const [infosheets, setInfosheets] = useState([{
    infosheet_id: null,
    type_id: null,
    language_id: null,
    public_title: null,
    private_title: null,
    portrait_reference: null,
    portrait_original_img_id: null,
  }]);

  const [filteredList, setFilteredList] = useState([]);

  // get_infosheets_from_user returns an array of objects with the following properties
  // {
  //   'infosheet_id',
  //   'type_id',
  //   'type_name',
  //   'language_id',
  //   'language_name',
  //   'public_title',
  //   'private_title',
  //   'cropped_reference',
  //   'cropped_image_id',
  // }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getInfosheetsFromUser(user.id);
        setInfosheets(response.data);
        setFilteredList(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (typeFilter === '0') {
      setFilteredList(infosheets);
    } else {
      const filtered = infosheets.filter(infosheet => {
        return infosheet.type_id === parseInt(typeFilter)
      })
      console.log("Filtered infosheets: ")
      console.log(filtered)
      setFilteredList(filtered)
    }
  }, [typeFilter])



  const handleItemClick = (infosheet) => {
    console.log(infosheet.infosheet_id)
    handleSelectedObject(infosheet);
  }

  const filterList = (term) => {
    const filtered = infosheets.filter(infosheet => {
      return infosheet.public_title.toLowerCase().includes(term.toLowerCase())
    })
    setFilteredList(filtered)
  }

  return (
    <ContentListStyle className='width-100'>
      <div className="infosheet-list-container">
        {/* <h2>Lista de Fichas</h2> */}
        <div className='search-bar-container margin-bottom-20 width-100'>
          <img className="lupa" alt="Lupa" src={lupa}/>
          <input 
            type="text" 
            className='buscadorInput'
            placeholder="Buscar ficha"
            onChange={(e) => filterList(e.target.value)} 
          />
        </div>
        
        <div className='items-list-container'>
          {filteredList.map(infosheet => (
            <div className='item-container' key={infosheet.infosheet_id} onClick={() => handleItemClick(infosheet)}>
              <img  alt="item-icon" className='file-btn'/>
              <div className="item-info">
                <h2 className="public-title" title={infosheet.public_title}>
                  {infosheet.public_title}
                </h2>
                <p className="private-title text-light align-left" title={infosheet.private_title}>
                  {infosheet.private_title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ContentListStyle>
  )
}
