import React from "react";
import { Link, useNavigate  } from 'react-router-dom';

import OverlayStyle from "../../style/OverlayStyle";

export default function PrivateStoryOverlay ({id, title, parts, stats = {likes:0, views:0}}) {
  /**
   * expected props:
   * - title
   * - stats
   * - handleClickRead (function) //maybe? since this component is not meant to be generic, we can just hardcode the buttons
   * - handleClickEdit (function)
   */
  const navigate = useNavigate();

  // const goRead = (id) => {
  //   ///:story_id/overview
  //   navigate(`/${id}/overview`)
  // }

  const goEdit = (id) => { 
    navigate(`/${id}/info`)
  }

  const pluralControl = (number, word) => {
    return number === 1 ? word : word + 's'
  }

  return(
    <OverlayStyle>
      <div className="item-overlay manito">
        <div>
          {/* <button className="" onClick={() => goRead(props.id)}>Read</button>  */}
          <button className="edit_btn_blue circleButton-20 item-overlay-button manito" onClick={() => goEdit(id)}></button>
          {/* <button className="preview_btn_table circleButton-20 item-overlay-button manito" onClick={() => goRead(id)}></button> */}
        </div>
        <div className="item-overlay-title-l">
          <p className="subtitle public-title-overlay ">{title}</p>
          <p className="text info-extra partes">{parts}</p>
          <p className="text info-extra estadísticas">
            {`${stats.views} ${pluralControl(stats.views, "lectura")}, ${stats.likes} ${pluralControl(stats.likes, "like")}`}
          </p>
        </div>
        <div className="item-overlay-stats">
        </div>
      </div>
    </OverlayStyle>
  )
}