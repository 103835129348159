//import './styles.scss'
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState , Component } from 'react'

import { EditorContent , useEditor } from '@tiptap/react'
// import StarterKit from '@tiptap/starter-kit'
// import Underline from '@tiptap/extension-underline'
// import Image from '@tiptap/extension-image'
// import Placeholder from '@tiptap/extension-placeholder'
// import TextAlign from '@tiptap/extension-text-align'
// import CharacterCount from '@tiptap/extension-character-count'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

// import SampleTextButton from './SampleText/SampleTextButton'
import MiBubbleMenu from './MiBubbleMenu'
import MiFloatingMenu from './MiFloatingMenu'


export default function ChapterTextEditor ( { editor, insertImages=true, data, setData} ) {
	/**
	 * Este componente integra el editor de texto de Tiptap (recibido como prop), con los componentes de menú flotante y menú burbuja.
	 */
	// const [infosheetPopup, setInfosheetPopup] = useState(false);
	

	// const {story_id, chapter_id } = useParams();

	useEffect(() => {
		// document.getElementById('chapter_title_box').textContent = storyInfo.title;
	}, []); 

	if (!editor) {
		// console.log('test log: Editor is null')
		return
	}

	const updateChapterTitle = (value) => {
		// console.log(value)
		setData({...data, chapterTitle: value})
	}

	const wordCount = (text) => {
    return text.split(/\s+/).filter((word) => word !== '').length
  }

	return (
		<>
			<input className='subtitle center sin-borde margin-bottom-20 width-100 inputHover' id='chapterTitleBox' contentEditable='true' onChange={(e) => updateChapterTitle(e.target.value)} value={data.chapterTitle}/>
			{/* <button onClick={() => console.log(data.chapterTitle)}>test</button> */}
			
			{/**Crea el Menu Burbuja */}
			<MiBubbleMenu editor={editor} />

			{/**Crea el Menu Flotante */}
			{insertImages && <MiFloatingMenu editor={editor} chapter_id={data.chapter_id}/>}

			{/**Crea el Editor */}    
			<EditorContent className='text sin-borde' id="editor" editor={editor} />
			<hr className='separador margin-bottom-0'/>

			<div className="" style={{textAlign: "right"}}>
				<p className='count'>{editor.storage.characterCount.words()} palabras</p>
				
			</div>
		</>
	)
}

