import React, { useState, useEffect } from "react";

import InfosheetEditableStyle from "./../style/InfosheetEditableStyle";

export default function InfosheetEditableContent({ infosheetContent, setInfosheetContent, lockFields=false, editable=true }) {
  const [hoveredElementId, setHoveredElementId] = useState(null)
  const [hoveredRowId, setHoveredRowId] = useState(null)

  const handleMouseEnter = (elementId) => {
    setHoveredElementId(elementId)
  }

  const handleMouseLeave = () => {
    setHoveredElementId(null)
  }

  const handleMouseEnterRow = (rowId) => {
    setHoveredRowId(rowId)
  }

  const handleMouseLeaveRow = () => {
    setHoveredRowId(null)
  }

  const addCategory = () => {
    if (lockFields) return;
    const newCategory = {
      name: '',
      rows: [
        {
          fields: [
            {
              name: '',
              value: '',
            }
          ]
        }
      ]
    }
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: [...prevState.categories, newCategory]
    }))
  }

  const removeCategory = (index) => {
    if (lockFields) return;
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.filter((category, i) => i !== index)
    }))
  }

  const addRow = (categoryIndex) => {
    if (lockFields) return;
    const newRow = {
      fields: [
        {
          name: '',
          value: '',
        }
      ]
    }
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            rows: [...category.rows, newRow]
          }
        }
        return category
      })
    }))
  }

  const removeRow = (categoryIndex, rowIndex) => {
    /** No utilizado ahora pero podria ser util en el futuro
     */
    if (lockFields) return;
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            rows: category.rows.filter((row, j) => j !== rowIndex)
          }
        }
        return category
      })
    }))
  }

  const addField = (categoryIndex, rowIndex) => {
    if (lockFields) return;
    if (infosheetContent.categories[categoryIndex].rows[rowIndex].fields.length >= 3) {
      return
    }
    const newField = {
      name: '',
      value: '',
    }
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            rows: category.rows.map((row, j) => {
              if (j === rowIndex) {
                return {
                  ...row,
                  fields: [...row.fields, newField]
                }
              }
              return row
            })
          }
        }
        return category
      })
    }))
  }

  const removeField = (categoryIndex, rowIndex, fieldIndex) => {
    if (lockFields) return;
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          // Si solo queda un campo en la fila, se elimina la fila
          if (category.rows[rowIndex].fields.length === 1) {
            return {
              ...category,
              rows: category.rows.filter((row, j) => j !== rowIndex)
            }
          }
          // de lo contrario, conservamos la fila y se elimina el campo 
          return {
            ...category,
            rows: category.rows.map((row, j) => {
              if (j === rowIndex) {
                return {
                  ...row,
                  fields: row.fields.filter((field, k) => {
                    return (k !== fieldIndex)
                  })
                }
              }
              return row
            })
          }
        }
        return category
      })
    }))
  }

  const updateFieldName = (categoryIndex, rowIndex, fieldIndex, newName) => { 
    // console.log(newName)
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            rows: category.rows.map((row, j) => {
              if (j === rowIndex) {
                return {
                  ...row,
                  fields: row.fields.map((field, k) => {
                    if (k === fieldIndex) {
                      return {
                        ...field,
                        name: newName
                      }
                    }
                    return field
                  })
                }
              }
              return row
            })
          }
        }
        return category
      })
    }))
  }

  const updateFieldValue = (categoryIndex, rowIndex, fieldIndex, newValue) => {
    
    // e.target.style.height = 'auto';
    // e.target.style.height = e.target.scrollHeight + 'px';

    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            rows: category.rows.map((row, j) => {
              if (j === rowIndex) {
                return {
                  ...row,
                  fields: row.fields.map((field, k) => {
                    if (k === fieldIndex) {
                      return {
                        ...field,
                        value: newValue
                      }
                    }
                    return field
                  })
                }
              }
              return row
            })
          }
        }
        return category
      })
    }))
  }

  const updateCategoryName = (categoryIndex, newName) => {
    setInfosheetContent(prevState => ({
      ...prevState,
      categories: prevState.categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            name: newName
          }
        }
        return category
      })
    }))
  }
  
  // Función para ajustar la altura del textarea automáticamente
  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  const [contador, setContador] = useState(0);
  
  useEffect(() => {
    // if(contador <= 1){
      document.querySelectorAll('textarea').forEach(function(textarea) {
        adjustTextareaHeight(textarea);
        
        textarea.addEventListener('input', function() {
            adjustTextareaHeight(textarea);
        });
      });
      setContador(contador + 1);
    // }
    
  }, [infosheetContent]); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded


  return (
    <InfosheetEditableStyle>
        {infosheetContent.categories.map((category, index) => (
          <div className="category_container" key={`c${index}`}>
            <div className="category_">
              <div className="category_name_container" onMouseEnter={() => handleMouseEnter(`c${index}`)} onMouseLeave={() => handleMouseLeave()}>
                <input className={`category_name ${editable ? 'editable' : 'not_editable'}`} type="text" placeholder="Nueva categoría" 
                  value={category.name}
                  onChange={(e) => updateCategoryName(index, e.target.value)}
                  readOnly={!editable}/>
                  <div>
                    {((hoveredElementId === `c${index}`) && !lockFields) && (<button className="remove-element-btn-white" onClick={() => removeCategory(index)}></button>)}
                  </div>
              </div>

              {category.rows.map((row, rowIndex) => (
              <div className="category_row_container" onMouseEnter={() => handleMouseEnterRow(`c${index}_r${rowIndex}`)} onMouseLeave={() => handleMouseLeaveRow()} key={`c${index}_r${rowIndex}`}>
                  {/* <p>{`c${index}_r${rowIndex}`}</p> */}
                  {row.fields.map((field, fieldIndex) => (
                    <div className="field_container" key={`c${index}_r${rowIndex}_f${fieldIndex}`} onMouseEnter={() => handleMouseEnter(`c${index}_r${rowIndex}_f${fieldIndex}`)} onMouseLeave={() => handleMouseLeave()}>
                      {/* <p>{`c${index}_r${rowIndex}_f${fieldIndex}`}</p> */}
                      <div className="value-container">
                        <div className="attr_field">
                          <textarea 
                            // key={`c${index}_r${rowIndex}_f${fieldIndex}_nameInput`}                               
                            // onKeyUp={(e) => adjustTextareaHeight(e.target)}
                            className={`attr_input attr_field_name_input text alto_textarea_ajustable  ${editable ? 'editable' : 'not_editable'}`}
                            type="text"
                            placeholder="Pregunta..." 
                            value={field.name} 
                            onChange={(e) => updateFieldName(index, rowIndex, fieldIndex, e.target.value)}
                            readOnly={!editable}
                          />
                        </div>
                      </div>

                      <div className="flex-row value-n-x-container">
                        <div className="attr_field" key={`c${index}_r${rowIndex}_f${fieldIndex}_value`}>
                          <textarea
                            // onKeyUp={(e) => adjustTextareaHeight(e.target)}
                            className={`attr_input text alto_textarea_ajustable  ${editable ? 'editable' : 'not_editable'}`}
                            type="text"
                            placeholder="Respuesta..."
                            value={field.value}
                            onChange={(e) => {updateFieldValue(index, rowIndex, fieldIndex, e.target.value); }}
                            readOnly={!editable}
                          />
                        </div>

                        <div className="margin-right-10">
                          {((hoveredElementId === `c${index}_r${rowIndex}_f${fieldIndex}`) && !lockFields) && (<button className="remove-element-btn" onClick={() => removeField(index, rowIndex, fieldIndex)}></button>)}
                        </div>
                      </div>

                    </div>
                  ))}
                  <div className="sin-ancho">
                    <div className="add-btn-ext-position">
                      {((hoveredRowId === `c${index}_r${rowIndex}`) && !lockFields) && (<button  className="add-element-button" onClick={() => addField(index, rowIndex)}></button>)}
                    </div>
                  </div>
              </div>
            ))}
              {!lockFields && <button className="new_row_list_btn" onClick={() => addRow(index)}>+</button>}
            </div>
          </div>
        ))}
        {!lockFields && <button className="new_category_list_btn" onClick={() => addCategory()}>Add a Category</button>}
  
    </InfosheetEditableStyle>
  )
}
