export default function TranslationServiceItemComponent(props) {

  return (
    <div className="misma-linea fondo-gris" key={props.index}>
        {/* {props.checkServiceIfNotCompatible(props.index) && <div>⚠️</div>} */}
        <div className="misma-linea flex-1 margin-right-10">
            <div className="misma-linea">
                <input 
                    className="radio"
                    type="radio" 
                    id={`option${props.index}`} 
                    name="options" 
                    onChange={() => props.selectService(props.service, props.index)}
                    checked={props.selectedService_index === props.index}
                />
                <div>
                    <p className="text font-weight-bold margin-right-10">{`${props.service.originLanguage.name} ➡ ${props.service.targetLanguage.name}`}</p>
                </div>
            </div>
        </div>

        <div className="misma-linea flex-1 flex-wrap margin-right-10 flex-end">
            <p className="text font-weight-bold margin-right-10">Costo por palabra </p>
            <p className="text align-center">{`$${props.service.pricePerWord}`}</p>
        </div>

        <div className="misma-linea flex-1 flex-wrap margin-right-10 flex-end">
            <p className="text font-weight-bold margin-right-10">Palabras </p>
            <p className="text">{`${props.totalWordCount()}`}</p>
        </div>

        <div className="misma-linea flex-1 flex-wrap margin-right-10 flex-end">
            {/* <p className="text font-weight-bold margin-right-10">Total </p> */}
            <p className="text">{`$${props.calcTotalServiceCost(props.index)}`}</p>
        </div>
    </div>
  );
}
