import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {getServiceRequest, submitServiceRequestMessage, getServiceRequestMessages} from "../../../../api/service";
import {getMessageTypeList} from "../../../../api/type";
import JsFileDownloader from 'js-file-downloader';

import TabBar from "../../../utils/TabBar";
import ReferenceContentPopup from "./view-reference-content/ViewReferenceContent";
import GeneralStyle from "../../../style/generalStyle";
import ButtonsStyle from "../../../style/buttonsStyle";
import ReviewAccessPermissionItemComponent from "./view-reference-content/ReviewAccessPermissionItemComponent";
import ReviewImageReferenceItemComponent from "./view-reference-content/ReviewImageReferenceItemComponent";
import ReviewServiceItemComponent from "./view-reference-content/ReviewServiceItemComponent";
import NewCommentComponent from "./view-reference-content/NewCommentComponent";
import ReviewCommentComponent from "./view-reference-content/ReviewCommentComponent";

import { useAuth } from "../../../../context/AuthContext";

export default function ReviewIllustrationRequest() {
  const { user } = useAuth();

  const { service_req_id } = useParams();

  const [referenceContentPopup, setReferenceContentPopup] = useState(null);

  const [fullScreenView, setFullScreenView] = useState(false)

  const [requestDescription, setRequestDescription] = useState("");
  const [sharedContent, setSharedContent] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const [selectedService, setSelectedService] = useState({
    originLanguage: {name: "", id: ""},
    targetLanguage: {name: "", id: ""},
    pricePerWord: "",
    word_count: "",
    initial_price: "",
    current_price: "",
  });

  const [newComment, setNewComment] = useState(
    {
      content: "",
      // attachedFiles: [],
    }
  );
  const [newCommentAttachments, setNewCommentAttachments] = useState([]);

  const [messageChain, setMessageChain] = useState([
    {
      user_id: 1,
      user_name: "user1",
      content: "este es un comentario",
      comment_attachments: [],
    }
    // {
    //   user_name: "user1",
    //   content: "este es un comentaroioooo",
    // },
    // {
    //   user_name: "user2",
    //   content: "este es un comentaroiooooo",
    // },
  ]);

  const [messageTypes, setMessageTypes] = useState([]);
  const [selectedMessageType, setSelectedMessageType] = useState(0);

  useEffect(() => {
    if (!user) return;

    fetchServiceRequestData(); 
    fetchMessageTypes();  

  }, [user])

  

  useEffect(() => {
    fetchMessageChain();
  }, [selectedMessageType]);
  

  const fetchServiceRequestData = async () => {    
    const reqParams = {service_request_id: service_req_id,}

    try {
      const response = await getServiceRequest(reqParams);
      setRequestDescription(response.data.requestDescription);
      setSharedContent(response.data.sharedContentList);
      setAttachedFiles(response.data.attachedFiles);
      setSelectedService(response.data.service);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchMessageChain = async () => {
    const reqParams = {
      service_request_id: service_req_id,
      type_id: selectedMessageType,
    }

    try {
      const response = await getServiceRequestMessages(reqParams);
      setMessageChain(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchMessageTypes = async () => {
    try {
      const response = await getMessageTypeList();
      setMessageTypes(response.data);
      // console.log("message type: ",response.data)
    } catch (error) {
      console.log(error);
    }
  }

  const submitMessage = async () => {
    const data = {
      service_request_id: service_req_id,
      user_id: user.id,
      content: newComment.content,
      new_comment_attachments: newCommentAttachments,
      message_type_id: selectedMessageType,
    }
    console.log(data)
    
    try{
      const response = await submitServiceRequestMessage(data);
      // console.log(response.data)
      fetchMessageChain();
    } catch (error) {
      console.log(error)
    }
  }

  const closeContentSharePopup = () => {
    setReferenceContentPopup(false)
  }
  
  // MARK: - Download Attachment
  // const downloadAttachment = (index) => {
  //   const binaryString = atob(attachedFiles[index].data)
  //   const byteArray = new Uint8Array(binaryString.length)

  //   for (let i = 0; i < binaryString.length; i++) {
  //     byteArray[i] = binaryString.charCodeAt(i)
  //   }

  //   const blob = new Blob ([byteArray], {type: `image/png`})

  //   const url = URL.createObjectURL(blob)

  //   // Crear un elemento ancla para activar la descarga
  //   const link = document.createElement('a')
  //   link.href = url
  //   // Especificar el nombre del archivo. Aqui podemos poner el nombre que queramos
  //   link.download = attachedFiles[index].name 

  //   // Simular un clic en el elemento ancla
  //   document.body.appendChild(link)
  //   link.click()

  //   // Limpiar revocando el Blob URL
  //   URL.revokeObjectURL(url)
  //   document.body.removeChild(link)
  // }

  const downloadAttachmentFromUrl = (url, fileName='file') => {
    new JsFileDownloader({ 
      url: url,
      filename: fileName,
    })
    .then(function () {
      // Called when download ended
      console.log('Download completed')
    })
    .catch(function (error) {
      console.log(error)
    })

  }

  const updateNewCommentText = (e) => {
    let updatedComment = newComment
    updatedComment.content = e.target.value
    // console.log(newComment.content)
    setNewComment(updatedComment)
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0]
    let newList = [...newCommentAttachments]


    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function(e) {
      const file_info = {
        // id: fileAttachment_ID_sequence,
        name: file.name,
        size: file.size,
        type: file.type,
        // extension: file.name.split('/')[0],
        data: reader.result
      }
      // console.log(file)
      newList.push(file_info)
      
      setNewCommentAttachments(newList)
    }

    const inputField = document.getElementById('file_input_field')
    inputField.value = ""
  }

  const removeFileAttachment = (index) => {
    setNewCommentAttachments(newCommentAttachments.filter((item, i) => i !== index))
  }

  // console.log(user)
  
  // MARK: - Render
  return (
    <GeneralStyle>
      <ButtonsStyle>
        {fullScreenView && 
          <div className="overlay" onClick={() => setFullScreenView(false)}>
            <div className="overlay-content">
              <img 
                className="img-display-fullscreen"
                // src={sourceImage()} 
                alt={`img_${5}`} // arbitrary test alt text
              />
            </div>
          </div>
        }

        <div className="container">
          <div className="intContainer">
            <h1 className="title">Solicitud de ilustración</h1>

            <hr className='separador'/>
            
            
            <div className="text advertencia misma-linea">
              <img className="info_btn margin-right-10"></img>
              <p  className="sin-margen">Puedes ajustar el presupuesto inicial y añadir comentarios, considerando la carga real que conlleva o aceptarlo sin hacer cambios. En ambos casos se notificará a tu cliente para que realice el pafo y puedas comenzar a trabajar.</p>
            </div>
            
            <h3 className="subtitle">Descripción</h3>
            <textarea 
              className="textareaRead"
              name="message" 
              id="message" 
              cols="150" 
              rows="10" 
              value={requestDescription}
              readOnly
            />
            
            <hr className='separador'/>

            {sharedContent &&
              <>
                <h3 className="subtitle">Contenido compartido</h3>
                <div className="flex-column gap-10">
                  {sharedContent.map((content, index) => (
                    <ReviewAccessPermissionItemComponent
                      key={index}
                      index={index}
                      title={content.title}
                      type={content.type.name}
                      language={content.language.name}
                      words={content.words}
                      setReferenceContentPopup={setReferenceContentPopup}
                      content={content}
                    />
                  ))}
                </div>
                <hr className='separador'/>
              </>
            }
            
            {attachedFiles &&
              <>
                <h3 className="subtitle">Archivos adjuntos</h3>
                <div className="flex-row gap-10">
                  {attachedFiles.map((file, index) => (
                    // console.log('aaa: ',file),
                    <ReviewImageReferenceItemComponent
                      key={index}
                      index={index}
                      // data={file.data}
                      reference={file.url}
                      // url={file.url}
                      name={file.name}
                      setFullScreenView={setFullScreenView}
                      downloadAttachmentFromUrl={downloadAttachmentFromUrl}
                    />                  
                  ))}
                </div>
              </>
            }

            <hr className='separador'/>
            <h3 className="subtitle">Servicio solicitado</h3>
            <ReviewServiceItemComponent
              serviceName={selectedService.serviceName}
              serviceDescription={selectedService.serviceDescription}
              serviceImg="pasar la imagen acá"
              initial_price={selectedService.initial_price}
              current_price={selectedService.current_price}
            />
            
            <hr className='separador'/>

            <h3 className="subtitle">Mensajes</h3>
            <NewCommentComponent
              user={user}
              updateNewCommentText={updateNewCommentText}
              handleFileUpload={handleFileUpload}
              newCommentAttachments={newCommentAttachments}
              removeFileAttachment={removeFileAttachment}
              submitMessage={submitMessage}
              messageTypes={messageTypes}
              setSelectedMessageType={setSelectedMessageType}
              // fetchMessageChain={fetchMessageChain}
            />

            {/* <hr className='separador'/> */}

            <div className="flex-column gap-10 margin-top-10">
              {messageChain.map((message, index) => (
                <ReviewCommentComponent
                  key={index}
                  index={index}
                  user_name={message.user_name}
                  content={message.content}
                  type_name={message.type_name}
                  date_creation={message.date_creation}
                  comment_attachments={message.comment_attachments}
                  downloadAttachmentFromUrl={downloadAttachmentFromUrl}
                />
              ))}
            </div>
          </div>
        </div>
        
        { 
          referenceContentPopup &&
          <ReferenceContentPopup
            closePopup={() => closeContentSharePopup()}
            contentInfo={referenceContentPopup}
          />
        }
      </ButtonsStyle>
    </GeneralStyle>
  )
}
// 'service_request_comment_id', id_service_request_comment,
// 'service_request_id', id_service_request,
// 'user_id', id_user,
// 'content', content