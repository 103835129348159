import styled from "styled-components";

import { colors } from "../../style/colors";

const InfosheetEditableStyle = styled.div`

    .category_container{
        box-sizing: border-box;
        width: 100%;
        
        
    }

    .category_name_container{
        display: flex;
        justify-content: center;
        height: 30px;
        background: ${colors.c2};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
    }

    .category_name{
        color: white;
        font-weight: bold;
        font-size: 12px;
        outline: none;
        background: none;
        border: none;
        margin: auto;
        text-align: center;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .category_name:focus {
        outline: none;
    }

    .category_name::placeholder{
        color: white;
    }

    .category {
        display: flex;
        height: 30px;
        margin-top: 10px;
        margin-bottom: 5px;
        color: white;
        font-weight: bold;
        font-size: 12px;
        outline: none;
    }

    .category_row_container{ //fila
        display: flex;
        min-height: 30px;
        width: 100%;
        padding-right: 40px; //para poder presionar boton +
        justify-content: center; /* Alinea horizontalmente al centro */
        align-items: stretch;
        
    }

    .field_container{ //conjunto de pregunta respuesta
        display: flex;
        width: 100%;
        margin-right: 10px;
        margin-top: 10px;      
    }

    //penultimo elemento
    .field_container:nth-last-child(2) {
        margin-right: 0; /* Por ejemplo, podrías anular el margen derecho */
    }

    .value-container{
        justify-content: center; /* Alinea horizontalmente al centro */
        align-items: center; /* Alinea verticalmente al centro */
        
        border: 1px solid ${colors.c5};
        background-color: ${colors.c7};
        border-right: none;
    }

    .value-n-x-container{
        justify-content: center; /* Alinea horizontalmente al centro */
        align-items: center; /* Alinea verticalmente al centro */
        border: 1px solid ${colors.c5};
    }

    .attr_input{        
        box-sizing: border-box;
        border: none;
        resize: none;
    }

    .attr_input:focus {
        outline: none;
    }
    
    .attr_field_name_input {
        width: 100%;
        max-width: 150px;
        background: none;
    }

    .attr_field {
        width: 100%;
        align-self: flex-start; 
        padding: 2px 10px;
    }


    .alto_textarea_ajustable{
        // height: 100%;
        width: 100%;
        resize: none;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        max-height: 100px;
        min-height: 10px;
    }

    .category_row_container_sin_margen.category_row_container{
        padding-right: 0px;
    }

    //para que los elementos queden hacia abajo en vez de para el lado
    @media screen and (max-width: 900px) {
        .category_row_container {
          flex-wrap: wrap;
        }

        .field_container{  
            margin-right: 0px;
        }
    }
`;

export default InfosheetEditableStyle;