import React, { useState } from "react";
import styled from "styled-components";
import ImgCropper from './ImgCropper';
import UserImgGallery from "../UserImgGallery";

export default function GalleryAndCropper({ imageSetter, mode, togglePopup }) {
    const [selectedImg, setSelectedImg] = useState('https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg')
    const [selectedImgId, setSelectedImgId] = useState('')
    // const [img, setImg] = useState({src, id})

    const loadImage = (img) => {
        // console.log(url)
        setSelectedImg(img.src)
        setSelectedImgId(img.imgId)
    }

    return(
        <GalleryAndCropperContainer>
            <div className="overlay">
                <div className="component-content">
                    <div className="titleBar">
                        <h3>Selecciona una imagen</h3>
                        <button onClick={togglePopup} className="close-icon">&times;</button>
                    </div>
                    <div className="gallery-cropper">
                        <div className="gallery">
                            <UserImgGallery loadImage={loadImage}/>
                        </div>
                        <div className="cropper">
                            {/*
                            */}
                            <ImgCropper mode={mode} img={{src: selectedImg, id: selectedImgId}} imageSetter={imageSetter}/>
                        </div>
                    </div>
                </div>
            </div>
        </GalleryAndCropperContainer>
    )
}

const GalleryAndCropperContainer = styled.div`
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 999;
}
.component-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    max-height: 70vh;
    background: white;
    transform: translate(-50%, -50%);
}
.titleBar {
    display:flex;
    justify-content: space-between;
}
.gallery-cropper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.gallery {
    width: 50%;
    border: 1px solid black;
}
.cropper {
    width: 50%;
    border: 1px solid black;
}
`;