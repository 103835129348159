import styled from "styled-components";
import { colors } from "../../style/colors";

const ChaptersListStyle = styled.div`
  .chapter-item-box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    margin-bottom: 5px;
  }

  .chapter-number {
    font-weight: bold;
    text-align: left;
    margin-right: 5px;
  }

  .chapter-title {
    text-align: left;
  }

  .item{
    background-color: ${colors.c7};
    height: 50px;
  }

  .item-change-color{
    background-color: ${colors.c7};
    height: 50px;
  }

  .item-change-color:hover{
    background: ${colors.c6};
    cursor: pointer;
  }

  .candado{
    margin-right: 10px;
    margin-bottom: 5px;
  }

`;

export default ChaptersListStyle;
