import React, { useState, useEffect } from "react";
import {aplicarAjusteAltura} from '../../../../functions/textAreaHeight';

export default function EditorServiceItemComponent(props) {

    useEffect(() => {
        aplicarAjusteAltura('textarea.textareaInputSmall', 90, false);
      }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded
    
    
  return (
    <div className='misma-linea fondo-gris' key={props.index}>
        <div className="misma-linea flex-1 margin-right-10">
            <textarea type="text" name="message" id="message" className="textareaInput textareaInputSmall overflow-y-hidden" placeholder="Nombre" maxLength="100"
                value={props.editorServiceList[props.index].service_name}
                onChange={(e) => props.updateServiceName(props.index, e)}/>
        </div>
        <div className="misma-linea flex-2 margin-right-10">
            <textarea type="text" name="message" id="message" className="textareaInput textareaInputSmall overflow-y-hidden" placeholder="Descripción" maxLength="280"
                value={props.editorServiceList[props.index].service_description}
                onChange={(e) => props.updateServiceDescription(props.index, e)}/>
        </div>
        <input type="number" className="numericInput"
            onChange={(e) => props.updatePricePerWord(props.index, e)}
            value={props.editorServiceList[props.index].service_price}
            placeholder="Precio"
        />
        <button className='remove-element-btn' onClick={() => props.removeService(props.index)}></button>
    </div>
  );
}
