import styled from "styled-components";
import { colors } from "../../style/colors";

const BubbleMenuStyle = styled.div`
    //opciones desplegables alineadas hacia abajo
    .dropdown {
        position: relative;
        display: inline-block;
    }

    //'texto' cuando no hay Hn seleccionado
    .dropbtn {
        color: ${colors.c3};
        font-size: 16px;
        border: none;
    }

    //contenedor de opciones desplegables (que se despliegan hacia abajo)
    .dropdown-content {
        display: none;
        position: absolute;
        z-index: 1;
        
        background: ${colors.c0};
        border-radius: 10px;
        border: 1px solid ${colors.c5};
    }

    /* Mostrar el menu dropdown al poner mouse sobre (on hover) */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    //botones del menú
    button {
        border: 1px solid transparent;
        font-family: inherit;
        cursor: pointer;
        width: 45px;
        height: 30px;
        background-color: transparent;
    }

    .background-bubble-menu{
        background: ${colors.c0};
        border-radius: 10px;
        border: 1px solid ${colors.c5};
        display: flex;
    }


`;

export default BubbleMenuStyle;
