import React, { useState, useEffect } from "react";

import {getChaptersFromUser} from '../../../../api/content'
import styled from "styled-components";
import ContentListStyle from "../../../chapter-components/AttachInfosheet/style/ContentListStyle";
import { useAuth } from '../../../../context/AuthContext';
import lupa from "../../../../img/lupa.png";

export default function ChapterList({handleItemClick}) {
  const { user } = useAuth();

  const [contentList, setContentList] = useState([]);
  // El contenido cargado es una lista de historias, cada una con una lista de capítulos:
  // [
  //   {
  //     story_id: 1,
  //     story_title: "Historia 1",
  //     language_id: 1,
  //     language_name: "Español",
  //     chapter_list: [
  //       {
  //         chapter_id: 1,
  //         chapter_title: "Capítulo 1"
  //       },
  //       {
  //         chapter_id: 2,
  //         chapter_title: "Capítulo 2"
  //       }
  //     ]
  //   },
  //   ...
  // ]


  const [filteredList, setFilteredList] = useState([]);
  
  const [selectedStory, setSelectedStory] = useState(null);
  const [filterMode, setFilterMode] = useState('chapter');

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getChaptersFromUser(user.id);
        setContentList(response.data);
        setFilteredList(response.data);      
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])


  const filterList = (term) => {
    if (filterMode === 'chapter') {
      filterListByChapter(term)
    } else if (filterMode === 'story') {
      filterListByStory(term)
    }
  }

  const filterListByStory = (term) => {
    const filtered = contentList.filter(story => {
      return story.story_title.toLowerCase().includes(term.toLowerCase())
    })
    setFilteredList(filtered)

  }

  const filterListByChapter = (term) => {
    const filterByTerm = contentList.map(story => {
      const filteredChapters = story.chapter_list.filter(chapter => {
        return chapter.chapter_title.toLowerCase().includes(term.toLowerCase())
      })
      return {
        ...story,
        chapter_list: filteredChapters
      }
    })

    const cleanUpEmptyStories = filterByTerm.filter(story => {
      return story.chapter_list.length > 0
    })
    setFilteredList(cleanUpEmptyStories)
  }

  const handleStoryClick = (story) => {
    if (selectedStory === story.story_id) {
      setSelectedStory(null)
    } else {
      setSelectedStory(story.story_id)
    }
  }

  const selectItem = (chapter, story) => {
    const chapterInfo = {
      ...chapter,
      language_name: story.language_name,
    }
    // console.log(chapterInfo)
    handleItemClick(chapterInfo)
  }

  const changeFilterMode = (mode) => {
    setFilterMode(mode)
    setFilteredList(contentList)
    document.getElementById('search_input_box').value = ''
    // filterList(document.getElementById('search_input_box').value)
  }

  return (
    <ContentListStyle className="width-100">
      <div className="infosheet-list-container">
        <div className='search-bar-container margin-bottom-20 width-100'>
          <img className="lupa" alt="Lupa" src={lupa}/>
          <input 
            type="text" 
            className='buscadorInput'
            placeholder="Buscar capítulo"
            onChange={(e) => filterList(e.target.value)} 
          />
        </div>

        {/* <div className='search-bar-container'>
          <div className="filter-mode">
            <input type="radio" id={`filterMode_${1}`} name="options" onChange={() => changeFilterMode('chapter')}/>
            <label for={`filterMode_${1}`}>By Chapter</label>
            <input type="radio" id={`filterMode_${2}`} name="options" onChange={() => changeFilterMode('story')}/>
            <label for={`filterMode_${2}`}>By Story</label>
          </div>
        </div> */}
        <div className="items-list-container sin-padding sin-borde">          
          {filteredList.map(story => (
            <>
              <div className='chapter-item-container stories' key={story.story_id} onClick={() => handleStoryClick(story)}>
                <div className="item-info">
                  <div className="public-title white" title={story.story_title}>
                    ▾ {story.story_title}
                  </div>
                </div>
              </div>

              {(selectedStory === story.story_id) &&
                <div className="items-list-chapter-container">
                  {story.chapter_list.map((chapter, index) => (
                    <div className='chapter-item-container chapters' key={chapter.chapter_id} onClick={() => selectItem(chapter, story)}>
                      <img alt="item-icon" className="file-btn"/>
                      <div className="item-info">
                        <h2 className="public-title" title={chapter.chapter_title}>
                          {chapter.chapter_title}
                        </h2>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </>
          ))}
        </div>
      </div>
    </ContentListStyle>
  )
}
