import styled from "styled-components";
import { colors } from "../../style/colors";

import logoBanner from "../../../img/logoBanner.png";

const NavbarStyle = styled.div`
  .navbar_container {
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100vw;
    max-width: 100%;
    max-height: 120px;
  }

  .header {
    // display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    background: ${colors.c0};
    z-index: 1;
    border-bottom: 1px solid ${colors.c2};
    align-content: space-evenly;
    justify-content: center;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: ${colors.c4};
    //box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    padding: 0px;
    height: 80px;
    width: 197px;
    margin: 0px 20px;
    background-image: url(${logoBanner});
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
  }

  .optionBanner {
    text-decoration: none;
    color: ${colors.c1};
    width: 110px;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
  }

  .optionBanner:hover {
    color: ${colors.c2};
  }

  .separador-banner{
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px;
    border-color: ${colors.c5};
  }

    
  .logout-container {
    width: 120px;
    height: 160px;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: ${colors.c0};
    border: 1px solid ${colors.c2};
    border-right: 0;
    // border-top: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom-left-radius: 20px;
    gap: 8px;
    padding-left: 16px;
    cursor: pointer;
    z-index: 1;
  }
`;

export default NavbarStyle;
