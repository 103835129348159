import React from 'react';
import styled from 'styled-components';

// import seguir from '../../img/'
import ButtonsStyle from '../style/buttonsStyle';


export default function FollowButton({user_is_following, submit_follow_action, type}) {

  const follow = () => {
    submit_follow_action()
  }

  return (
    <ButtonsStyle>
      {type === "historia" && !user_is_following && 
        // <p className='text'>Followwww</p>
        <button className="circleButton-20 seguirHistoria_btn" title='seguir' onClick={() => follow()}></button>
        // <p>seguir</p>
      }
      {type === "historia" && user_is_following && 
        // <p className='text'>Followwww</p>
        <button className="circleButton-20 desSeguirHistoria_btn" title='siguiendo' onClick={() => follow()}></button>
      }
      {type === "usuario" && !user_is_following && 
        // <p className='text'>Followwww</p>
        <button className="circleButton-20 seguirUsuario_btn" title='seguir' onClick={() => follow()}></button>
        // <p>seguir</p>
      }
      {type === "usuario" && user_is_following && 
        // <p className='text'>Followwww</p>
        <button className="circleButton-20 desSeguirUsuario_btn" title='siguiendo' onClick={() => follow()}></button>
      }

      {/* <button className="" onClick={() => follow()}>
        {!user_is_following && 
          // <p className='text'>Followwww</p>
          <img className='circleButton-20 seguirHistoria_btn'></img>
        }
        {user_is_following &&
          <p className='text'>Unfollow</p>
        }
      </button> */}
    </ButtonsStyle>
  )
}
