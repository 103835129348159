import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import TestingContainer from "./style/testingStyle";
export default function TestingComponent (prop) {
    return (
        <TestingContainer>
            <div className="testingContainer"> 
                {/* <Link to="/">Home</Link> */}
                {/* <Link to="/login">Login</Link>
                <Link to="/register">Register</Link> */}
                {/* <Link to="/stories">Stories</Link> */}
                {/* <Link to="newstory">New Story</Link> */}
                {/* <Link to="/mycreations/stories">My Creations</Link> */}
                {/* <Link to="/translateinfosheet">Translate</Link> */}
                {/* <Link to="/myservices/ilustrador">Mis Servicios</Link> */}

  {/* // Role id's:
  // 1	"escritor"
  // 2	"editor"
  // 3	"traductor"
  // 4	"ilustrador"
  // 5	"músico"
  // 6	"doblador" */}

                {/* <Link to="/editionrequest/21">Test Req Edit</Link>
                <Link to="/translationrequest/21">Test Req Trans</Link>
                <Link to="/illustrationrequest/21">Test Req Ilus</Link> */}

                {/* <Link to="/viewrequestlist">Test View Requests</Link> */}
                {/* <Link to="/usersettings">User Settings</Link> */}
                {/* <Link to="/userscatalog/translators">Users Catalog</Link> */}
            </div>
        </TestingContainer>      
    )
}
