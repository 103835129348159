import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { BACKEND_HOST } from "../../../api/host";

import { getUserDetails, updateUserDetails } from "../../../api/user";

import styled from "styled-components";

import GeneralStyle from "../../style/generalStyle";
import ButtonsStyle from "../../style/buttonsStyle";

import CommentSection from "../../user-profile/profile-pages/comment-list/CommentList";
import ProfilePicCropper from "./pfp-cropper/ProfilePicCropper"; 
// import ImgCropper from "../../utils/img-cropper-component/ImgCropper";

import defaultImg from "../../../img/pantallaSinImagen02.png";

import { useAuth } from "../../../context/AuthContext";

import Swal from 'sweetalert2';

export default function UserAccount() {
  const { user } = useAuth()
  const { user_id } = useParams()
  const navigate = useNavigate()

  const [featuredCreations, setFeaturedCreations] = useState([
    {
      title: 'Creacion 1',
      img: 'https://via.placeholder.com/150'
    },
  ])

  const [pfpImgData, setPfpImgData] = useState('https://via.placeholder.com/150') // used to display uploaded image on screen after selecting it
  const [tempImgData, setTempImgData] = useState('') // used to display uploaded image on screen after selecting it
  // const [inputFilePFP, setInputFilePFP] = useState(null)
  const [aboutMe, setAboutMe] = useState('')

  const [isSavingChanges, setIsSavingChanges] = useState(false)
  const [toggleCropper, setToggleCropper] = useState(false)

  const [userNickname, setUserNickname] = useState("")

  const [changeTracker, setChangeTracker] = useState({
    profile_picture: false,
    nickname: false,
    about_me: false,
  })

  const SaveConfirmation = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })

  useEffect(() => {
    if (!user) return
    fetchUserData()
  } , [user])
  
  const fetchUserData = async () => {
    try {
      const response = await getUserDetails(user.id)
      console.log(response.data)
      setAboutMe(response.data.user_description)
      setUserNickname(response.data.user_nickname)
      setPfpImgData(response.data.user_picture)
    } catch (error) {
      console.error(error)
    }
  }


  const imageLoader = (e) => {
    // setInputFilePFP(e.target.files[0])
    
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setTempImgData(reader.result?.toString() || '')
    })

    reader.readAsDataURL(e.target.files[0])
    setToggleCropper(true)
  }

  const profilePicSetter = (imgData) => {
    setChangeTracker({...changeTracker, profile_picture: true})
    setPfpImgData(imgData)
    toggleCropperPopup()
  }

  const handleSaveChanges = async () => {
    setIsSavingChanges(true)
    let msg = {
      user_id: user.id,
      pfp_img_data: null,
      user_nickname: null,
      user_description: null,


      // pfp_width: img.width,
      // pfp_height: img.height,
      // pfp_img_name: ,
    }

    if (changeTracker.profile_picture) {
      msg.pfp_img_data = pfpImgData
    }
    if (changeTracker.nickname) {
      msg.user_nickname = userNickname
    }
    if (changeTracker.about_me) {
      msg.user_description = aboutMe
    }

    try{
      const response = await updateUserDetails(user.id, msg)
      console.log(response)
      SaveConfirmation.fire({
        icon: "success",
        title: "Cambios guardados exitosamente!"
      })
      
    } catch (error) {
      console.log('Error:', error)
      SaveConfirmation.fire({
        icon: "error",
        title: "Error guardando los cambios"
      })
    } finally {
      setIsSavingChanges(false)
    }
  
  }
  
  const handleNicknameChange = (e) => {
    setUserNickname(e.target.value)
    setChangeTracker({...changeTracker, nickname: true})
  }

  const handleAboutMeChange = (e) => {
    setAboutMe(e.target.value)
    setChangeTracker({...changeTracker, about_me: true})
  }
  
  const toggleCropperPopup = () => {
    setToggleCropper(!toggleCropper)
  }
  

  // MARK: - Render
  return (
    <UserAccountStyle>
      <div className="container">
        <h1>Configuracion de perfil</h1>
        <div className="profile_banner">
          { user &&
            <>
              <img 
                src={pfpImgData} 
                alt="profile_picture" 
              />
              <input type="file" accept="image/*" onChange={imageLoader} />

              <div className="user_nickname">
                <p>Apodo</p>
                <input type="text" value={userNickname} onChange={(e) => handleNicknameChange(e)}/>
              </div>
            </> 
          }
        </div>
        <div className="page_content">
          <div className="about_section">
            <p>About me</p>
            <textarea 
              className="about_me_textarea"
              value={aboutMe}
              onChange={(e) => handleAboutMeChange(e)}
            />
            <button className="save_button" onClick={handleSaveChanges}>Save</button>
          </div>

          <div className="featured_creations">
            <p>Featured creations</p>
            {featuredCreations.map((creation, index) => (
              <div key={index} className="creation">
                <img 
                  src={creation.img} 
                  alt={creation.title} 
                />
                <p>{creation.title}</p>
              </div>
            ))}
          </div>

        </div>
        {toggleCropper && 
          <ProfilePicCropper 
            mode={"profile"}
            imageSetter={profilePicSetter}
            selectedImg={tempImgData}
            togglePopup={toggleCropperPopup}
          />
        }

      </div>
    </UserAccountStyle>
  )
}

const UserAccountStyle = styled.div`
.profile_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
`;