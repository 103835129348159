import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import ReactCrop, { 
    // Crop, //used for defining data type. Only used for typescript
    centerCrop,
    makeAspectCrop,
    // PixelCrop, //used for defining data type. Only used for typescript
    convertToPixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'


export default function ImgCropper ({ mode, img = {src: 'https://via.placeholder.com/150', id: 0}, imageSetter}) {
    const ASPECT_LIST = [{mode: "cover", aspect: (163 / 250)}, {mode: "banner", aspect: (722 / 250)}, {mode: "profile", aspect: (130 / 130)}]
    const [ aspect, setAspect ] = useState(ASPECT_LIST.find(item => item.mode === mode).aspect)

    // const [imgSrc, setImgSrc] = useState('https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg')
    const imgRef = useRef(null)
    const selectedImg = img.src
    const selectedImgID = img.id
    const [crop, setCrop] = useState('')
    const [completedCrop, setCompletedCrop] = useState('')


    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            const c = centerAspectCrop(width, height, aspect)
            setCrop(c)
            setCompletedCrop(convertToPixelCrop(c, width, height)) // WIP: I think I'll need to create my own crop
        }
    }

    const confirmCrop = () => {
        const image = imgRef.current;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const x = completedCrop.x * scaleX; // Coordenada x de inicio del recorte
        const y = completedCrop.y * scaleY; // Coordenada y de inicio del recorte
        const width = completedCrop.width * scaleX; // Ancho del recorte
        const height = completedCrop.height * scaleY; // Alto del recorte
        

        // ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
        if(mode === "cover"){
            canvas.width = 163;
            canvas.height = 250;
            ctx.drawImage(image, x, y, width, height, 0, 0, 163, 250);
        } else if (mode === "banner"){
            canvas.width = 722;
            canvas.height = 250;
            ctx.drawImage(image, x, y, width, height, 0, 0, 722, 250);
        } else if (mode === "profile"){
            canvas.width = 130;
            canvas.height = 130;
            ctx.drawImage(image, x, y, width, height, 0, 0, 130, 130);
        }

        const cropPreview = document.getElementById('cropped-preview')
        const cropData = canvas.toDataURL('image/jpeg')
        cropPreview.src = cropData
        imageSetter(cropData, selectedImgID)
    }
    

    return (
        <ImgCropperContainer>
            <div>
                <div className="controls">
                    {/**
                    <input type="file" accept="image/*" onChange={onSelectFile} />
                    */}
                    <button onClick={confirmCrop}>Confirm</button>
                </div>
                <ReactCrop 
                    crop={crop} 
                    aspect={aspect}
                    onComplete={(c) => { setCompletedCrop(c) }}
                    onChange={c => { setCrop(c) }}
                >
                    <img
                        crossOrigin="anonymous"
                        ref={imgRef}
                        alt="Crop me"
                        style={{ maxWidth: '400px', maxHeight: '400px' }}
                        src={selectedImg}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
                <img 
                    id="cropped-preview" 
                    // src={croppedImgURL} 
                    alt="cropped" 
                    style={{ height: completedCrop.height, width: completedCrop.width }}
                />
            </div>
            
        </ImgCropperContainer>
    )
}

const ImgCropperContainer = styled.div`
   
`;