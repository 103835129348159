import React, { useEffect, useState } from "react";
import {aplicarAjusteAltura} from '../../../functions/textAreaHeight';

export default function OtherServiceItemComponent(props) {

    useEffect(() => {
        aplicarAjusteAltura('textarea.max-height-250');
      }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded
    
  return (
    <div className="misma-linea fondo-gris" key={props.index}>
        <div className="flex-column">
            <div className="additional_text">
                <textarea className=" textareaInput max-height-250" 
                onChange={(e) => props.updateAdditionalText(props.index, e.target.value)}
                value={props.text}
                />
            </div>
                
            <div className="misma-linea justify-content-end ">
                <div className="margin-right-10">
                    <p className="sin-margen align-right count font-weight-bold">Palabras</p>
                </div>
                <p className="sin-margen align-right count">{props.words}</p> 
            </div>
        </div>

        <div className="margin-right-10">
            <button className="remove-element-btn" onClick={() => props.removeAdditionalText(props.index)}></button>
        </div>
    </div>
  );
}