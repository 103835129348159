import React, { useState } from "react";

import StoryTabBar from "./StoryTabBar";
import StoryEditDetails from "./StoryEditDetails";
import StoryEditChapters from "./StoryEditChapters";
import StoryEditOutline from "./StoryEditOutline";

import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from '../style/buttonsStyle';

export default function StoryInfo() {
    const categories = ['Detalles', 'Capítulos'];//, 'Escaleta'];
    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 0:
                return <StoryEditDetails />;
            case 1:
                return <StoryEditChapters />;
            // case 2:
            //     return <StoryEditOutline />;
            default:
                return null;
        }
    };
    return (
        <GeneralStyle>
            <ButtonsStyle>
                <div className='container'>
                    <div className='intContainer'>
                       <div className="title-n-select">
                            <h1 className="title">Modificar historia</h1>
                            <div className="selectContainer">
                                {/* <label htmlFor="state">State</label> */}
                                <select name="state" id="state">
                                    <optgroup label="Estado">
                                        <option value="paused">En pausa</option>
                                        <option value="ongoing">En curso</option>
                                        <option value="finished">Finalizada</option>
                                        <option value="dropped">Abandonada</option>
                                    </optgroup>
                                </select>
                                {/* <label htmlFor="language">Idioma</label> */}
                                <select name="language" id="language">
                                    <optgroup label="Idioma">
                                        <option value="en">English</option>
                                        <option value="es">Spanish</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        
                        <hr className='separador'/>
                                
                        <StoryTabBar categories={categories} onTabChange={handleTabChange}/>
                        
                        {renderContent()}
                    </div>
                </div>
            </ButtonsStyle>
        </GeneralStyle>
    )
}
