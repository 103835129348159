import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import {getInfosheet, removeInfosheet, createInfosheet, updateInfosheet} from '../../api/content'
import {getInfosheetTypeList} from '../../api/type'

import GalleryAndCropper from "../utils/img-cropper-component/GalleryAndCropper"
import InfosheetEditableContent from "./infosheet-content/InfosheetEditableContent"
import { useAuth } from "../../context/AuthContext"

import GeneralStyle from "../style/generalStyle"
import ButtonsStyle from "../style/buttonsStyle"

import defaultCover01 from '../../img/sinPortada01.png'
import defaultCover02 from '../../img/sinPortada02.png'

import Swal from 'sweetalert2'

export default function InfosheetEditor() {
    const { user } = useAuth()
    const navigate = useNavigate()

    const { infosheet_id } = useParams();

    const [img_sel_Popup, setImg_sel_Popup] = useState(false)
    const [portraitData, setPortraitData] = useState(null)
    const [portraitID, setPortraitID] = useState(null)
    const [publicTitle, setPublicTitle] = useState('')
    const [publicTitleCount, setPublicTitleCount] = useState(publicTitle.length);
    const [privateTitle, setPrivateTitle] = useState('')
    const [privateTitleCount, setPrivateTitleCount] = useState(privateTitle.length)
    const [infosheetType, setInfosheetType] = useState(0) //this will be used to filter infosheets by type [0=none, 1=character, 2=item, 3=location]
    const [infosheetContent, setInfosheetContent] = useState({
        categories: [
            {
                name: '',
                rows: [
                    {
                        fields: [
                            {
                                name: '',
                                value: '',
                            }
                        ]
                    }
                ]
            },
        ]
    })

    const [changeTracker, setChangeTracker] = useState({
        portrait: false,
        publicTitle: false,
        privateTitle: false,
        infosheetType: false,
        infoSheetContent: false,
    })

    const [infosheetTypeList, setInfosheetTypeList] = useState([])

    const [isSavingChanges, setIsSavingChanges] = useState(false)

    const SaveConfirmation = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
        }
    })

    useEffect(() => {
        if (!user) return
        fetchInfosheetTypeList();

        if (infosheet_id) {
            fetchInfosheet();
        } else {
            setImg_sel_Popup(false)
            setPublicTitle('')
            setPublicTitleCount(0)
            setPrivateTitle('')
            setPrivateTitleCount(0)
            setPortraitData(null)
            setPortraitID(null)
            setInfosheetType(0)
            setInfosheetContent({
                categories: [
                    {
                        name: '',
                        rows: [
                            {
                                fields: [
                                    {
                                        name: '',
                                        value: '',
                                    }
                                ]
                            }
                        ]
                    },
                ]
            })
        }
    }, [user, infosheet_id])


    const fetchInfosheet = async () => {
        const response = await getInfosheet(infosheet_id)
        setInfosheetContent(response.data.content)
        setPublicTitle(response.data.publicTitle)
        setPublicTitleCount(response.data.publicTitle.length) //this is to update the character title count
        setPrivateTitle(response.data.privateTitle)
        setPrivateTitleCount(response.data.privateTitle.length) //this is to update the character title count
        if (response.data.portraitID) {
            setPortraitID(response.data.portraitID)
            setPortraitData(response.data.portraitData)
        }
        setInfosheetType(response.data.infosheetType)
    }

    const fetchInfosheetTypeList = async () => {
        try{
            const response = await getInfosheetTypeList()
            setInfosheetTypeList(response.data)
            // console.log(response.data)
        } catch (error) {
            console.log(error)
        }
    }


    const saveInfoSheet = () => {
        if (infosheet_id) {
            updateInfoSheet()
        } else {
            createNewInfoSheet()
        }

    }



    const deleteInfoSheet = async () => {
        const msg = {
            infosheet_id: infosheet_id,
        }
        try{
            const response = await removeInfosheet(msg)
            console.log(response.data)
            navigate('/mycreations/infosheets')
        } catch (error) {
            console.log(error)
        }
    }

    const createNewInfoSheet = async () => {
        const msg = {
            ...infosheetContent,
            user_id: user.id,
            portraitData: portraitData,
            portraitID: portraitID,
            publicTitle: publicTitle,
            privateTitle: privateTitle,
            infosheetType: infosheetType,
            wordCount: infosheetContentWordCount(),
        }
        try{
            const response = await createInfosheet(msg)
            // console.log(response.data)
            navigate(`/infosheet/${response.data.message.infosheet_id}`)

        } catch (error) {
            console.log(error)
        }
    }

    const updateInfoSheet = async () => {
        setIsSavingChanges(true)
        let msg = {
            user_id: user.id,
            wordCount: infosheetContentWordCount(),
        }
        if (changeTracker.publicTitle) {
            msg.publicTitle = publicTitle
        }
        if (changeTracker.privateTitle) {
            msg.privateTitle = privateTitle
        }
        if (changeTracker.infoSheetContent) {
            msg.content = infosheetContent
        }
        if (changeTracker.infosheetType) {
            msg.infosheetType = infosheetType
        }
        if (changeTracker.portrait) {
            msg.portraitID = portraitID
            msg.portraitData = portraitData
        }

        try{
            const response = await updateInfosheet(infosheet_id, msg)

            SaveConfirmation.fire({
                icon: "success",
                title: "Cambios guardados exitosamente!",
            })
            console.log(response.data)
        } catch (error) {
            console.log(error)
            SaveConfirmation.fire({
                icon: "error",
                title: "Error al guardar cambios",
            })
        } finally {
            setIsSavingChanges(false)
        }
    }

    const toggleImgSelectPopup = () => { 
        setImg_sel_Popup(!img_sel_Popup)
    }

    const showPlaceholderImg = () => {
        return (
            <div className="placeholder_img">
                <img src="" alt="" >
                    
                </img>
            </div>
        )
    }
    
    const updatePublicTitle = (newTitle) => {
        changeTracker.publicTitle = true
        setPublicTitle(newTitle)
        
        setPublicTitleCount(newTitle.length);
    }

    const updatePrivateTitle = (newTitle) => {
        changeTracker.privateTitle = true
        setPrivateTitle(newTitle)

        setPrivateTitleCount(newTitle.length);
    }

    const updateInfoSheetContent = (content) => {
        changeTracker.infoSheetContent = true
        setInfosheetContent(content)
    }

    const updateInfoSheetType = (type) => {
        changeTracker.infosheetType = true
        setInfosheetType(type)
    }

    const setPortrait = (img_data, original_img_id) => {
        changeTracker.portrait = true
        setPortraitData(img_data)
        setPortraitID(original_img_id)
    }

    const wordCount = (text) => {
        return text.split(/\s+/).filter((word) => word !== '').length
    }

    const infosheetContentWordCount = () => {
        let count = 0
        count += wordCount(publicTitle);
        infosheetContent.categories.forEach((category) => {
            count += wordCount(category.name)
            category.rows.forEach((row) => {
                row.fields.forEach((field) => {
                    count += wordCount(field.name)
                    count += wordCount(field.value)
                })
            })
        })
        return count
    }

    const [coverIsHovered, setCoverIsHovered] = useState(false);

    const coverHandleMouseEnter = () => {
        setCoverIsHovered(true);
    };
    
    const coverHandleMouseLeave = () => {
        setCoverIsHovered(false);
    };

    const setDefaultCover = (imageData) => {
        if(imageData === null){
            return coverIsHovered ?  defaultCover02 :  defaultCover01;
        }
        return imageData
    }
    
    // MARK: - RENDER
    return (
        <GeneralStyle>
            <ButtonsStyle>
                <div className="container">
                    <div className="leftNullContainer"></div>

                    <div className="intContainer">
                        <div className="title-n-select">
                            {!infosheet_id && <h1 className="title">Nueva ficha</h1>}
                            {infosheet_id && <h1 className="title">Escribir ficha</h1>}
                            {/* select infosheetContent type from: [0=none, 1=character, 2=item, 3=location]  */}
                            <div className="selectContainer">
                                <select id="infosheet_type" value={infosheetType} onChange={(e) => updateInfoSheetType(e.target.value)}>
                                    <optgroup label="Tipo">
                                        {infosheetTypeList.map((type, type_index) => (
                                            <option key={type_index} value={type_index}>{type.name}</option>
                                        ))}
                                        {/* <option value="0">Ninguno</option>
                                        <option value="1">Personaje</option>
                                        <option value="2">Lugar</option>
                                        <option value="3">Objeto</option> */}
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <hr className='separador'/>                 

                        <div className="flex-row">
                            {/* <div className=""> */}
                                <button className='img_btn cover_img_btn ancho-minimo-cover' onClick={() => toggleImgSelectPopup()} onMouseEnter={coverHandleMouseEnter} onMouseLeave={coverHandleMouseLeave}>
                                    <img id='cover_img' className='cover_img' alt='portrait' src={setDefaultCover(portraitData)} draggable="false"/>
                                </button>

                            {/* </div> */}

                            <div className="flex-column">
                                <div className="flex-row gap-10">
                                    <div className="mismoAncho">
                                        <h3 className="subtitle margin-top-0">Título público</h3>
                                        <input type="text" className="inlineInput" placeholder="Título público" maxLength="100" 
                                            value={publicTitle}
                                            onChange={(e) => updatePublicTitle(e.target.value)}/>
                                        <p className='count'>{publicTitleCount}/100</p>
                                    </div>

                                    <div className="mismoAncho">
                                        <h3 className="subtitle margin-top-0">Título privado</h3>
                                        <input type="text" className="inlineInput" placeholder="Private Title" 
                                            value={privateTitle}
                                            onChange={(e) => updatePrivateTitle(e.target.value)}/>
                                        <p className='count'>{privateTitleCount}/100</p>
                                    </div>
                                </div>

                                <div className="display-block">
                                    <hr className='separador'/>
                                </div>                                

                                <InfosheetEditableContent
                                    infosheetContent={infosheetContent}
                                    setInfosheetContent={updateInfoSheetContent}
                                />
                            </div>                            
                        </div>

                        <hr className='separador margin-bottom-0'/>

                        <div className="word_count">
                            <p className="count">{infosheetContentWordCount()} palabras</p>
                        </div>

                        <div className="btns">
                            <button className="save_btn circleButton" onClick={() => saveInfoSheet()} disabled={isSavingChanges}></button>
                            {infosheet_id && <button className="delete_btn circleButton" onClick={() => deleteInfoSheet()}></button>}
                        </div>

                        {/* <div className="title-n-select"> */}
                            
                        {/* </div> */}
                            
                        {/* <button onClick={() => console.log(infosheetContent)}>log info sheet</button> */}
                    </div>

                </div>

                {img_sel_Popup && 
                    <GalleryAndCropper mode={"cover"} togglePopup={toggleImgSelectPopup} imageSetter={setPortrait} />
                }
            </ButtonsStyle>
        </GeneralStyle>
    )
}