import React, { useState, useEffect } from "react";

import PopupStyle from "../../style/popupStyle";
import defaultPortrait from "../../../img/sinPortada01.png";
import SelectedInfosheets from "../../chapter-components/AttachInfosheet/style/SelectedInfosheetsStyle";

import InfosheetDisplay from "../../infosheet-components/InfosheetViewer";
import ItemCard from "../../general-components/ItemCard";
import Overlay from "../../chapter-components/AttachInfosheet/SelectedInfosheets/InfosheetReadOverlay";

import { BACKEND_HOST } from "../../../api/host";

/**
* Objects in infosheetList are as follows:
{
  "id_infosheet": 4,
  "infosheet_ref": "./content/infosheets/4.json",
  "infosheet_public_name": "the public",
  "infosheet_private_name": "the private",
  "infosheet_portrait_ref": "./content/infosheets/10_portraitImg.jpeg",
  "infosheet_portrait_original_img": null
  "infosheet_type": 2
}
*/
export default function ChapterInfosheetViewer({ chapter_id, setInfosheetPopup, includedInfosheets}) { //need new name for this! 
  // const [includedInfosheets, setIncludedInfosheets] = useState([]);
  const [typeFilter, setTypeFilter] = useState('1');
  const [selectedInfosheet, setSelectedInfosheet] = useState(null);
  // typeFilter is a number (as a string) that represents the infosheet type: [0=all, 1=character, 2=item, 3=location]
  const [typeFilteredInfosheets, setTypeFilteredInfosheets] = useState(includedInfosheets);

  const hasCharacters = includedInfosheets.some(infosheetItem => infosheetItem.type_id === parseInt('1'))
  const hasLocations = includedInfosheets.some(infosheetItem => infosheetItem.type_id === parseInt('2'))
  const hasItems = includedInfosheets.some(infosheetItem => infosheetItem.type_id === parseInt('3'))  
  
  useEffect(() => {
    console.log(includedInfosheets)
    console.log(hasCharacters, hasLocations, hasItems)
    // setTypeFilteredInfosheets(includedInfosheets)
  }, [])

  useEffect(() => {
    if (typeFilter === '0') {
      setTypeFilteredInfosheets(includedInfosheets)
    } else {
      const filtered = includedInfosheets.filter(infosheet => {
        return infosheet.type_id === parseInt(typeFilter)
      })
      setTypeFilteredInfosheets(filtered)
    }
  }, [typeFilter, includedInfosheets])
  

  const sourceImg = (infosheet) => {
    if (infosheet.cropped_reference) {
      const imgsrc = BACKEND_HOST + (infosheet.cropped_reference).substring(1)
      return imgsrc
    } else {
      return defaultPortrait
    }
  }

  const handleItemClick = (id) => {
    // handleSelectedObject(id)
    setSelectedInfosheet(id)
    console.log('id: ', id)
  }


  return (
    <PopupStyle>
      <SelectedInfosheets>
      <div className="overlay">
        <div className="popup-containter-n-btns">
          <div className="leftNullContainer-40"></div>

          <div className="popup-container">
            {selectedInfosheet && 
              // <>
                <div className="width-100">
                  <InfosheetDisplay 
                    infosheet_id={selectedInfosheet}
                  />
                </div>
              // </>
            }
            {!selectedInfosheet && 
              <div className="unique-column">
                <div className="gallery-container">
                    <>
                      {typeFilteredInfosheets.map((infosheet, index) => (
                        <ItemCard key = {infosheet.infosheet_id} 
                          id={infosheet.infosheet_id} 
                          item_title={infosheet.public_title}
                          img={sourceImg(infosheet)}
                          handleClick = {() => handleItemClick(infosheet.infosheet_id)}
                          overlay={
                            <Overlay 
                              id={infosheet.infosheet_id}
                              title={infosheet.public_title}
                            />
                          }
                        />
                      ))}
                    </>
                </div>
              </div>
            }
      </div>

          <div className="btns-10">
            <button className="close-btn circleButton" onClick={() => setInfosheetPopup(false)}></button>
            {/* <button onClick={() => setTypeFilter('0')}>All</button> */}
            {!selectedInfosheet && 
              <>
                {hasCharacters &&
                  <button onClick={(e) =>{
                    if (e.currentTarget.classList.contains('activo')) {
                      setTypeFilter("0");
                    } else {
                        setTypeFilter("1");
                    }}}
                    className={`personaje-btn circleButton ${typeFilter === '1' ? 'activo' : ''}`}>
                  </button>
                }
                {hasLocations &&
                  <button 
                    onClick={(e) =>{
                      if (e.currentTarget.classList.contains('activo')) {
                        setTypeFilter("0");
                      } else {
                        setTypeFilter("2");
                      }}}
                      className={`lugar-btn circleButton ${typeFilter === '2' ? 'activo' : ''}`}>
                  </button>
                }
                {hasItems &&
                  <button onClick={(e) =>{
                    if (e.currentTarget.classList.contains('activo')) {
                      setTypeFilter("0");
                    } else {
                      setTypeFilter("3");
                    }}}
                    className={`objeto-btn circleButton ${typeFilter === '3' ? 'activo' : ''}`}>
                  </button>
                }
              </>
            }
            {selectedInfosheet && 
              <button className="retroceder_btn circleButton" onClick={() => setSelectedInfosheet(null)}></button>
            }
          </div>

        </div>
      </div>
      </SelectedInfosheets>
    </PopupStyle>
  )
}

