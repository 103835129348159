import React from "react";
import { Link, useNavigate  } from 'react-router-dom';

import buttonsStyle from "../../../style/buttonsStyle";
import OverlayStyle from "../../../UserCreations/style/OverlayStyle";

export default function InfosheetReadOverlay (props) {
  /**
   * expected props:
   * - title
   * - removeItem (function)
   */

  return(
    <OverlayStyle>
      <div className="item-overlay manito">
          <div className="item-overlay-title-m">
            <p className="subtitle public-title-overlay-s" title={props.title}>{props.title}</p>
          </div>
      </div>
    </OverlayStyle>
  )
}
