import React from "react";

import ItemCardStyle from "./style/itemCardStyle";

export default function ItemCard ({
  handleClick, 
  img, 
  item_title, 
  item_private_title,
  overlay
}) {
  /**
   * expected props:
   * - id
   * - img
   * - item_title
   * - overlay (component) 
   * - handleClick (function)
   * */  
  
  return(
    <ItemCardStyle>
      <div className="gallery-item" onClick={handleClick}>
        <div className="item-content">
          <img className="card_img" 
            src={img} 
            alt={item_title} 
          />
          {/* <div className="item-footer">
            <p className="subtitle public-title">{item_title}</p>
            <p className="text private-title">{item_private_title}</p>
          </div> */}
        </div>
        {overlay}
      </div>

    </ItemCardStyle>
  )
}