import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

// import styled from 'styled-components';
import ChapterItemBoxStyle from '../../style/chapterItemBoxStyle';

import vistas from '../../../../img/vistas.png';
import meGusta from '../../../../img/meGusta.png';

export default function StoryReadChapterItemBoxStyle({story_id, itemNumber, chapter, publishingState, readsNumber, likesNumber, commentsNumber}) {
  const navigate = useNavigate()
  const goReadChapter = (chapter_id) => {
    navigate(`/${story_id}/${chapter_id}/read`)
  };
  return (
  <ChapterItemBoxStyle>
    <div className='chapter-item-box-container text item-change-color' onClick={() => goReadChapter(chapter.chapter_id)}>
      <div className='column col-read-chapter flex-column '>
        <div className='chapter-number margin-bottom-5' >{chapter.title}</div>
        <div>Mar 21, 1999</div>
      </div>
      <div className='flex-column'>
          <div className='align-right candado'>🔓</div>
          <div className='column col-read-stats'>
            <div className='column-read mismoAncho'><img className='icon margin-right-5' src={vistas}></img>{`${chapter.views} lecturas`}</div>
            <div className='column-read mismoAncho'><img className='icon margin-right-5' src={meGusta}></img>{`${chapter.likes} likes`}</div>
          </div>
      </div>
    </div>
  </ChapterItemBoxStyle>
  )
}
