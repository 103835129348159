import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'

import GeneralStyle from '../style/generalStyle';
import lupa from "../../img/lupa.png";
import TabBar from '../utils/TabBar';
import MyStories from './MyStories/MyStories'
import MyIlustrations from './MyIlustrations/MyIlustrations'
import MyInfosheets from './MyInfosheets/MyInfosheets'
import MyTranslations from './MyTranslations/MyTranslations'
import { useAuth } from '../../context/AuthContext'

export default function UserCreations() {
    const { user } = useAuth()
    const { contentType } = useParams()
    const navigate = useNavigate()

    const categories = [
        { name: 'Historias', icon:'tabBarEscritor_btn bubble-menu-btn circleButton', value: 'stories'}, 
        { name: 'Fichas', icon:'tabBarFichas_btn bubble-menu-btn circleButton', value: 'infosheets'}, 
        { name: 'Ilustraciones', icon:'tabBarIlustrador_btn bubble-menu-btn circleButton', value: 'ilustrations'},
        // { display: 'Translations', value: 'translations'},
    ]

    const languages = [
        { display: 'Español', value: '1'},        
        { display: 'English', value: '2'},       
        { display: 'Italiano', value: '3'},
    ];

    const [selectedCreationType, setSelectedCreationType] = useState(
        { name: 'Historias', icon:'', value: 'stories'}, 
    );
      

    const handleTabChange = (selectedContentType) => {
        //navigate(`/mycreations/${selectedContentType}`);
        navigate(`/mycreations/${categories[selectedContentType].value}`);
        setSelectedCreationType(categories[selectedContentType].value);
        console.log('Tab changed to:', categories[selectedContentType].value);
    }

    const renderContent = () => {
        switch (contentType) {
            case 'stories':
                return <MyStories />
            case 'infosheets':
                return <MyInfosheets />
            case 'ilustrations':
                return <MyIlustrations />
            // case 'translations':
            //     return <MyTranslations />
            default: return null
        }
    }
 
    return (
        <GeneralStyle>
            <div className='container'>
                {/* <div className='leftNullContainer'></div> */}
                <div className='intContainer'>

                    <div className='category-header'>
                        <div className='title-n-select'>
                            <h1 className='title'>Mis creaciones</h1>
                            <div className='selectContainer'>                            
                                {/* <select onChange={(e) => handleTabChange(e.target.value)} defaultValue={contentType}>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category.value}>{category.display}</option>
                                        ))}
                                </select> */}
                                <select>
                                    <optgroup label="Idioma">
                                        {languages.map((language, index) => (
                                            <option key={index} value={language.value}>{language.display}</option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <hr className='separador'></hr>

                        
                        <TabBar 
                            categories={categories} 
                            onTabChange={handleTabChange}
                            defaultTab= {categories.findIndex((category) => category.value === contentType)}
                        />

                    </div>
                    {/* <div className=''> */}
                        {renderContent()}
                    {/* </div> */}
                </div>
            </div>
        </GeneralStyle>
    );
}

