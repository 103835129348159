import React, { useEffect, useState , Component } from 'react'

import { useParams } from 'react-router-dom'
// import StarterKit from '@tiptap/starter-kit'
import { EditorContent , useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import HardBreak from '@tiptap/extension-hard-break'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
// import CharacterCount from '@tiptap/extension-character-count'

import {getStory, getChapter, getInfosheetsFromChapter, updateStoryFollow} from '../../api/content'

import { changeContentLike } from '../../api/service'

import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from '../style/buttonsStyle';
import WriteChapterStyle from './style/WriteChapterStyle';

import ChapterInfosheetViewer from './ChapterInfosheetViewer/ChapterInfosheetViewer'
import CommentSection from '../user-profile/profile-pages/comment-list/CommentList'
import { useAuth } from '../../context/AuthContext'
import FollowButton from '../follow-button/FollowButton'

export default function ReadChapter () {
  const { user } = useAuth()

  const [infosheetPopup, setInfosheetPopup] = useState(false)
  const [linkedInfosheets, setLinkedInfosheets] = useState([])
  const [storyTitle, setStoryTitle] = useState('Title')
  const [chapterTitle, setChapterTitle] = useState('Chapter Title')
  const [chapterInfo, setChapterInfo] = useState({})
  const [storyInfo, setStoryInfo] = useState({})

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Heading,
      HardBreak,
      Bold,
      Italic,
      Strike,
      Underline,
      Image.configure({
        HTMLAttributes: {
          class: 'miImage',
          style: 'max-width: 500px; max-height: 500px; display: block; margin-left: auto; margin-right: auto;',
        }
      }),
      TextAlign.configure({
        // defaultAlignment: 'right',
        types: ['heading', 'paragraph'],
      }),
      // CharacterCount,
    ],
    content: '<p>Loading...</p>',
    editable: false,
  })

  const { story_id, chapter_id } = useParams()

  // MARK: - Fetch data
  useEffect(() => {
    if (!user) return;
    if (editor) {
    
    fetchData();
    }
  }, [editor, user])

  const fetchData = async () => {
    try {
      const storyResponse = await getStory(story_id, user.id);
      setStoryInfo(storyResponse.data);
      setStoryTitle(storyResponse.data.title);

      const chapterResponse = await getChapter(chapter_id, user.id);
      editor.commands.setContent(JSON.parse(chapterResponse.data.content));
      setChapterTitle(chapterResponse.data.chapterTitle);

      const infosheetsResponse = await getInfosheetsFromChapter(chapter_id);
      setLinkedInfosheets(infosheetsResponse.data);

      setChapterInfo(chapterResponse.data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  if (!editor) {
    // console.log('test log: Editor is null')
    return
  }

  const toggleLikeForContent = async () => {
    try {
      const result = await changeContentLike({
        user_id: user.id,
        content_type_id: 4,
        content_specific_id: chapterInfo.chapter_id,
        like_value: !chapterInfo.browsing_user_liked,
      })
      console.log(result)
      setChapterInfo({...chapterInfo, browsing_user_liked: !chapterInfo.browsing_user_liked})
    } catch (error) {
      console.error('Error toggling like for content', error)
    }
  }

  const followStory = async () => {
    try {
      const result = await updateStoryFollow({
        user_id: user.id,
        story_id: story_id,
        follow_value: !storyInfo.browsing_user_is_following,
      })
      // console.log(result)
      setStoryInfo({...storyInfo, browsing_user_is_following: !storyInfo.browsing_user_is_following})
    } catch (error) {
      console.error('Error toggling follow for story', error)
    }
  }

  // MARK: - Render
  return (
    <GeneralStyle>
    <ButtonsStyle>
      {infosheetPopup && 
        <ChapterInfosheetViewer 
          // chapter_id={chapter_id}
          setInfosheetPopup={setInfosheetPopup}
          includedInfosheets={linkedInfosheets}
        />
      }
      <div className='container'>
        <div className='leftNullContainer'></div>
        <div className='intContainer'>
          
            <div className='flex-wrap'>
              <p className='text-light align-left' id='story-title' title={storyTitle}>({storyTitle})</p>
              <h2 className='title center' id='chapter-title' title={chapterTitle}>{chapterTitle}</h2>
            </div>
            {/* <FollowButton 
              user_is_following={storyInfo.browsing_user_is_following} 
              submit_follow_action={followStory} 
              type = "historia"
            /> */}
            
            <div className='flex flex-row gap-10 flex-center'>
              <FollowButton 
                  user_is_following={storyInfo.browsing_user_is_following} 
                  submit_follow_action={followStory} 
                  type = "historia"
                />
              {chapterInfo.browsing_user_liked && 
                <button className='circleButton-20 dislike_btn manito' title='me gusta' onClick={() => toggleLikeForContent()}></button>   
              }
              {!chapterInfo.browsing_user_liked && 
                <button className='circleButton-20 like_btn manito' title='no me gusta' onClick={() => toggleLikeForContent()}></button>   
              }
              {/* <button className='btn-2'>Seguir historia</button> */}

            </div>


          <hr className='separador'></hr>
          
          <WriteChapterStyle>
            <div className='text'>
              <EditorContent editor={editor} />
            </div>
          </WriteChapterStyle>
          
          {/* <button className='btn-1' onClick={() => toggleLikeForContent()}>
            Like  
            {chapterInfo.browsing_user_liked && 
              <div>🖤</div>
            }
          </button> */}
          <div className='flex flex-row gap-10 flex-center'>
            <FollowButton 
                user_is_following={storyInfo.browsing_user_is_following} 
                submit_follow_action={followStory} 
                type = "historia"
              />
            {chapterInfo.browsing_user_liked && 
              <button className='circleButton-20 dislike_btn manito' title='me gusta' onClick={() => toggleLikeForContent()}></button>   
            }
            {!chapterInfo.browsing_user_liked && 
              <button className='circleButton-20 like_btn manito' title='no me gusta' onClick={() => toggleLikeForContent()}></button>   
            }
            {/* <button className='btn-2'>Seguir historia</button> */}

          </div>
          <div className='chapter-navigation flex flex-row gap-10 flex-center margin-top-10'>
            <button className='textButton '> 🡸 Capítulo anterior</button>
            <button className='textButton '>Capítulo siguiente 🡺</button>
          </div>

          <hr className='separador'></hr>

          <p className='subtitle'>Comentarios</p>
          <CommentSection 
            content_type_id={4} 
            content_specific_id={chapter_id} 
            user={user}
          />

        </div>
        <div className='btns'>
          {linkedInfosheets.length !== 0 && <button className='fichas_btn circleButton' onClick={() => setInfosheetPopup(!infosheetPopup)}></button>}
        </div>
      </div>
    </ButtonsStyle>
    </GeneralStyle>
  )
}  
