import React, { useEffect , useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'

import Swal from 'sweetalert2';
import styled from "styled-components";

import {getStoryDetails, updateStoryDetails, removeStory, getContentCollaborators} from '../../api/content';
import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from "../style/buttonsStyle";
import CollaboratorManager from "./collab-manager/CollaboratorManager";

import StoryDetailsDisplay from "./StoryDetailsDisplay";

import { useAuth } from "../../context/AuthContext";


export default function StoryDetails() {
    const { user } = useAuth();
    
    const navigate = useNavigate();
    
    const { story_id } = useParams();
    const [tagList, setTagList] = useState([]) // [ tag1, tag2, tag3, ...]
    const [genreList, setGenreList] = useState([]) // [ gen1, gen2, gen3, ...

    const [collabList, setCollabList] = useState([
        {
            user_name: 'user1',
            roles: []
        },
    ])
    const [toggleCollabManager, setToggleCollabManager] = useState(false)


    const [isSavingChanges, setIsSavingChanges] = useState(false)

    const [storyDetails, setStoryDetails] = useState({
        title: '',
        description: '',
        coverImgData: '',
        coverImgID: '',
        bannerImgData: '',
        bannerImgID: '',
        // genres: [],
        // tags: [],
    })

    const [changeTracker, setChangeTracker] = useState({
        title: false,
        description: false,
        cover: false,
        banner: false,
        genres: false,
        tags: false,
    })

    const ROLE_OPTIONS = [
        { value: 1, label: 'Escritor' },
        { value: 2, label: 'Editor' },
        { value: 3, label: 'Traductor' },
        { value: 4, label: 'Ilustrador' },
        { value: 5, label: 'Músico' },
        { value: 6, label: 'Doblador' },
    ]

    const SaveConfirmation = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
        }
    })

    // MARK: - Fetch Data
    useEffect(() => {
        fetchStoryData();
        // fetchCollabData();
    }, [])
    
    // const fetchCollabData = async () => {
    //     try {
    //         const response = await getContentCollaborators(storyDetails.content_type_id, storyDetails.content_specific_id);
    //         console.log(response.data[0].collaborator_list);
    //         setCollabList(response.data[0].collaborator_list);
    //     } catch (error) {
    //         console.log('Error with fetching collab data: ', error);
    //     }
    // }

    

  const sourcePFP = (user_pic) => {
    // console.log("collaborator_user: ", user_pic)
    if (user_pic) {
      return user_pic
    } else {
      return 'https://via.placeholder.com/30'
    }
  }

    const fetchStoryData = async () => {
        try {
            const response = await getStoryDetails(story_id, user.id);
            console.log("responseeee",response.data)
            setStoryDetails({
                content_type_id: response.data.content_type_id,
                content_specific_id: response.data.content_specific_id,
                title: response.data.title,
                
                description: response.data.description,
                coverImgData: response.data.coverImgURL,
                coverImgID: response.data.coverImgID,
                bannerImgData: response.data.bannerImgURL,
                bannerImgID: response.data.bannerImgID,
                // genres: response.data.genres,
                // tags: response.data.tags,
            })
            setGenreList(response.data.genres.filter(genre => genre != null))
            setTagList(response.data.tags.filter(tag => tag != null))

            const collabDataResponse = await getContentCollaborators(response.data.content_type_id, response.data.content_specific_id, user.id);
            console.log("aiudaa: ",collabDataResponse.data);
            setCollabList(collabDataResponse.data);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }
    
    const goRead = (id) => {
        ///:story_id/overview
        navigate(`/${id}/overview`)
    }

    const saveChanges = async () => {
        setIsSavingChanges(true);
        let msg = {
            user_id: user.id,
        };

        // la forma elegante, pero tiene problemas con cover, banner, tags y genres ya que no estan representados 1:1 en el objeto storyDetails:
        // for (const key in changeTracker) {
        //     if (changeTracker[key]) {
        //         msg[key] = storyDetails[key];
        //     }
        // }

        if (changeTracker.title) {
            msg.title = storyDetails.title;
        }
        if (changeTracker.description) {
            msg.description = storyDetails.description;
        }
        if (changeTracker.cover) {
            msg.coverImgData = storyDetails.coverImgData;
            msg.originalCoverImgID = storyDetails.coverImgID;
        }
        if (changeTracker.banner) {
            msg.bannerImgData = storyDetails.bannerImgData;
            msg.originalBannerImgID = storyDetails.bannerImgID;
        }
        if (changeTracker.genres) {
            msg.genres = genreList;
        }
        if (changeTracker.tags) {
            msg.tags = tagList;
        }

        try{
            const response = await updateStoryDetails(story_id, msg)
            console.log('Update Story req success:', response.data.message)
            SaveConfirmation.fire({
                icon: 'success',
                title: 'Cambios guardados exitosamente!'
            })
        } catch (error) {
            console.log('Error with POST req:', error);
            SaveConfirmation.fire({
                icon: 'error',
                title: 'Error al guardar cambios'
            })
        } finally {
            setIsSavingChanges(false)
        }
    }

    const deleteStory = async () => {
        const msg = {
            user_id: user.id,
            story_id: story_id,
        };

        try{
            const response = await removeStory(msg);
            console.log('Delete Story req success:', response.data.message)
            navigate('/mycreations/stories');
        } catch (error) {
            console.log('Error with Delete Story req:', error);
        }
    }

    const openCollabManagerPopup = () => {
        setToggleCollabManager(true)
    }

    const closeCollabManagerPopup = () => {
        fetchStoryData()
        setToggleCollabManager(false)
    }


// MARK: - Layout

    return (
        <GeneralStyle>
            <ButtonsStyle>       
                <div className="flex-row">
                    {/* <div className='leftNullContainer'></div> */}
                    <div>
                        <StoryDetailsDisplay 
                            displayMode={'edit'}
                            storyDetails={{ details: storyDetails, setDetails: setStoryDetails }} 
                            genres={{ list: genreList, setList: setGenreList }}
                            tags={{ list: tagList, setList: setTagList }}
                            changeTracker={{ state: changeTracker, setState: setChangeTracker }}
                        />
                        
                        <hr className='separador'/>

                        <ColabStyles>
                        

                            <div className="misma-linea">
                                <h3 className="subtitle margin-right-10">Colaborador</h3>
                                <button className="add-element-button-small" onClick={() => openCollabManagerPopup()}></button>
                            </div>

                            {/* <div className="collaborator-card">
                                <button className="add-collab-btn"
                                    onClick={() => openCollabManagerPopup()}
                                >Agregar colaborador</button>
                            </div> */}
                            {/**
                             * if role exists, display role name and list collaborators with that role
                             */}
                            
                            <div className="flex flex-column gap-10">
                                {collabList.length === 0 && <p className="text align-left cursiva">No hay colaboradores</p>}
                                {collabList.map((collab, index) => (
                                // <UserInfoCardMini
                                //   // browsing_user_id={user.id}
                                //   target_user_id={collab}
                                //   key={index}
                                // >
                                    <div className="flex flex-row gap-10" key={index}>
                                        <img className="CircleImage image-xs" src={sourcePFP(collab.user_profile_picture)} alt="placeholder user pic"/>
                                        <p className="text align-content-center">{`${collab.user_name}`}</p>
                                        {collab.user_roles && (
                                            <div className="text-light align-content-center cursiva">
                                                ({collab.user_roles.map((role, index) => (
                                                    ROLE_OPTIONS.find(option => option.value === role).label
                                                )).join(', ')})
                                            </div>
                                        )}
                                    {/* <div className="margin-bottom-10"> */}
                                    {/* </div> */}
                                    </div>

                                // </UserInfoCardMini>
                                ))}
                            </div>
                        </ColabStyles>
                        
                        <hr className='separador'/>
                        
                        <div className="btns">
                            <button className="preview_btn circleButton" onClick={() => goRead(story_id)}></button>
                            <button className="save_btn circleButton" onClick={saveChanges} disabled={isSavingChanges}></button>
                            <button className='delete_btn circleButton' onClick={deleteStory}></button>
                        </div>
                    </div>

                    {/* <div className="sin-ancho"> //para dejar botones al lado derecho
                    </div> */}

                </div>
                {toggleCollabManager &&
                    <CollaboratorManager 
                        content_type_id={storyDetails.content_type_id}
                        content_specific_id={storyDetails.content_specific_id}
                        closePopup={() => closeCollabManagerPopup()}
                    />
                }
            </ButtonsStyle>
        </GeneralStyle>
    )
}

const ColabStyles = styled.div`
.collaborators-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  // width: 30%;
  height: 100%;
  outline: 1px solid green;
  margin-left: 20px;
}
`
