import React, { useEffect, useState, useRef} from "react";
// import { Link } from 'react-router-dom';

import axios from 'axios'
import styled from 'styled-components';

// import InfosheetViewer from '../infosheet-components/infosheet-content/InfosheetReadableContent';
import InfosheetEditor from '../infosheet-components/infosheet-content/InfosheetEditableContent';
import { useAuth } from "../../context/AuthContext";


export default function TranslateInfoSheet () {
  const { user } = useAuth();

  const originalInfosheet_id = 2;
  const [originalInfosheetId, setOriginalInfosheetId] = useState(2) // Esto no deberia poder ser modificado por el traductor
  const [originalPublicTitle, setOriginalPublicTitle] = useState('')
  const [originalPrivateTitle, setOriginalPrivateTitle] = useState('')
  const [originInfosheetContent, setOriginInfosheetContent] = useState({
    categories: [
      {
        name: '',
        rows: [
          {
            fields: [
              {
                name: '',
                value: '',
              }
            ]
          }
        ]
      },
    ]
  })

  const [translatedInfosheetId, setTranslatedInfosheetId] = useState('') // Esto no deberia poder ser modificado por el traductor
  const [translatedPublicTitle, setTranslatedPublicTitle] = useState('')
  const [translatedPrivateTitle, setTranslatedPrivateTitle] = useState('')
  const [translatedInfosheetContent, setTranslatedInfosheetContent] = useState({
    categories: [
      {
        name: '',
        rows: [
          {
            fields: [
              {
                name: '',
                value: '',
              }
            ]
          }
        ]
      },
    ]
  })

  const [targetLanguage, setTargetLanguage] = useState({ name:'english', id:2 });
  const [portraitData, setPortraitData] = useState('') // Esto no deberia poder ser modificado por el traductor
  const [portraitID, setPortraitID] = useState('') // Esto no deberia poder ser modificado por el traductor

  const [infosheetType, setInfosheetType] = useState('') //this will be used to filter infosheets by type [0=none, 1=character, 2=item, 3=location]

  
  
  const stripInfosheetValues = (infosheet) => {
    const strippedInfosheet = JSON.parse(JSON.stringify(infosheet))

    strippedInfosheet.categories.forEach(category => {
      // recordar limpiar el nombre de las categorias tambien
      category.name = ''
      category.rows.forEach(row => {
        row.fields.forEach(field => {
          field.name = ''
          field.value = ''
        })
      })
    })
    setTranslatedInfosheetContent(strippedInfosheet)
  }

  useEffect(() => {
    axios.get(`http://localhost:5000/api/get_infosheet/${originalInfosheet_id}`)
    .then(response => {
      console.log(response.data)
      setOriginInfosheetContent(response.data.content)
      setOriginalPublicTitle(response.data.publicTitle)
      setOriginalPrivateTitle(response.data.privateTitle)
      setPortraitData(response.data.portraitData)
      setPortraitID(response.data.portraitID)
      setInfosheetType(response.data.infosheetType)

      stripInfosheetValues(response.data.content)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])

  const saveProgress = () => {
    // endpoint expects the following:
    // translator_id: req.body.translator_id,
    // originalInfosheet_id: req.body.originalInfosheet_id,
    // newLanguage_id: req.body.newLanguage_id,
    // newPublicTitle: req.body.newPublicTitle,
    // newPrivateTitle: req.body.newPrivateTitle,
    // // newImage_id: req.body.newImage_id,
    const msg = {
      translator_id: user.id,
      originalInfosheet_id: originalInfosheet_id,
      newLanguage_id: targetLanguage.id,
      categories: translatedInfosheetContent.categories,
      newPublicTitle: translatedPublicTitle,
      newPrivateTitle: translatedPrivateTitle,
    }
    
    axios.post(`http://localhost:5000/api/save_infosheet_translation_progress`,
      JSON.stringify(msg), 
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((res) => {
      console.log('Server response:', res.data)
    }).catch((err) => {
      console.log('Error:', err)
    })
  }


  const updateTranslatedPublicTitle = (newTitle) => {
    setTranslatedPublicTitle(newTitle)
  }

  const updateTranslatedPrivateTitle = (newTitle) => {
    setTranslatedPrivateTitle(newTitle)
  }

  return (
    <TranslateInfoSheetContainer>
      <div className='page-container'>
        <div className='category-header'>
          <h1>Translate Chapter</h1>
        </div>
        <div className='content-comparator'>
          <div className='editor-container reference-editor'>
            <div className="sheet_titles_container">
                <div className="infosheet_title">
                  <input 
                    type="text" 
                    className={`infosheet_title public_title not_editable`} 
                    placeholder="Public Title" 
                    value={originalPublicTitle}
                    readOnly
                  />
                </div>
                <div className="infosheet_title">
                  <input 
                    type="text" 
                    className={`infosheet_title private_title not_editable`} 
                    placeholder="Private Title" 
                    value={originalPrivateTitle}
                    readOnly
                  />
                </div>
            </div>
            <InfosheetEditor 
              infosheetContent={originInfosheetContent}
              lockFields={true}
              editable={false}
            />
          </div>
          <div className='editor-container translation-editor'>
            <div className="sheet_titles_container">
                <div className="infosheet_title">
                  <input 
                    type="text" 
                    className={`infosheet_title public_title editable`} 
                    placeholder="Public Title" 
                    value={translatedPublicTitle}
                    onChange={(e) => updateTranslatedPublicTitle(e.target.value)}
                  />
                </div>
                <div className="infosheet_title">
                  <input 
                    type="text" 
                    className={`infosheet_title private_title editable`} 
                    placeholder="Private Title" 
                    value={translatedPrivateTitle}
                    onChange={(e) => updateTranslatedPrivateTitle(e.target.value)}
                  />
                </div>
            </div>
            <InfosheetEditor
              infosheetContent={translatedInfosheetContent}
              setInfosheetContent={setTranslatedInfosheetContent}
              lockFields={true}
              editable={true}
            />
          </div>
        </div>
      </div>
      <button onClick={saveProgress}>Guardar</button>
      <button onClick={() => console.log("Finalizar Traduccion")}>Enviar traduccion</button>
    </TranslateInfoSheetContainer>        
  )
}

const TranslateInfoSheetContainer = styled.div`
.page-container {
  width: 100%;
}
.editor-container {
  width: 100%;
}
.content-comparator {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  width: 100%;
}
`
