import React from 'react';
import UserInfoCardMini from '../../../utils/user-card-popup/UserInfoCardMini';

export default function ReviewContentCommentComponent({comment, index }) {

    return (
    <div className="misma-linea fondo-gris-borde gap-20" key={index}>
        <div className="felx-column width-100">
            <div className="width-100 flex-row margin-bottom-10 gap-10 align-center flex-end">
                {/* <UserInfoCardMini
                    target_user_id = {comment.commenter_id}
                > */}
                    <div className="margin-auto flex-row gap-10 align-center">
                        <img src="https://via.placeholder.com/30" alt="user profile" className="CircleImage " />
                        <p className="text">{`${comment.commenter_username}`}</p>
                    </div>
                {/* </UserInfoCardMini> */}
                <p className="time">{`${comment.time_elapsed_since}`}</p>
            </div>
            <hr className='separador sin-margen margin-top-10 margin-bottom-10'/>
            <div className="flex-column gap-20">
                <p className="text justify">{`${comment.content}`}</p>
            </div>
            
        </div>
    </div>
    );
  }
