import styled from "styled-components";
import OverlayStyle from "../../../../UserCreations/style/OverlayStyle";
import React, { useEffect, useState } from "react";

export default function ReviewImageReferenceItemComponent({index, reference, name, setFullScreenView, downloadAttachmentFromUrl}) {

    const [isHovered, setIsHovered] = useState(false);

    return (
    <XXX>
      <div className="misma-linea " key={index} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {reference && 
            <img className='imagen-ajustada' 
                src={reference} 
                alt={name} 
                onClick={() => setFullScreenView(true)}
            />
        }
        {isHovered && (
            <div className="aaa flex-end manito" onClick={() => downloadAttachmentFromUrl(reference, name)}>
                <div className="flex-arriba-der">
                    {/* <button className="boton_rapido" onClick={() => props.downloadAttachment(props.index)}>
                    ⭳
                    </button> */}
                </div>
                <div className="bbb "> 
                    <p className="text public-title-overlay" title={`${name}`}>{`🡇 ${name}`}</p>
                </div>
            </div>
        )}
        
      </div>
    </XXX>
    );
  }

  const XXX = styled.div`

    .imagen-ajustada {
        width: 100px; /* Ancho deseado */
        height: 100px; /* Altura deseada */
        object-fit: cover; /* Para estirar o cortar la imagen */
    }

    .aaa{
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100px; /* Ancho deseado */
        height: 100px; /* Altura deseada */   
    }

    .bbb{
        background-color: rgba(221, 221, 221, 0.8);
    }

    
  .public-title-overlay{
    padding: 0px 5px;
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 45px;
    max-width: 100px;
  }


`

/*

    
    <div className="file_item_detail">
        <p>{`Tipo: ${file.type}  |`}</p>
    </div>
    <div className="file_item_detail">
    </div>
*/