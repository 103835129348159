import React, { useEffect, useState, useRef} from "react";


import { EditorContent , useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import HardBreak from '@tiptap/extension-hard-break'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
import CharacterCount from '@tiptap/extension-character-count'

import styled from 'styled-components';
import WriteChapterStyle from '../../../chapter-components/style/WriteChapterStyle';

export default function ChapterPreview({ title, content }) {
  const editorExtentions = [
    Document,
    Paragraph,
    Text,
    Heading,
    HardBreak,
    Bold,
    Italic,
    Strike,
    Underline,
    Image.configure({
      HTMLAttributes: {
        class: 'miImage', 
        style: 'max-width: 500px; max-height: 500px; display: block; margin-left: auto; margin-right: auto;',
      }
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    CharacterCount,
  ]

  const previewEditor = useEditor({
    extensions: editorExtentions,
    // content: JSON.stringify(chapterContentRef),
    editable: false,
  })

  useEffect(() => {
    if (!previewEditor) return
    previewEditor.commands.setContent(JSON.parse(content))
  }, [previewEditor, content])

  return (
    <WriteChapterStyle>
      <div className="width-100">
        <div className="content-title width-100">
          <div className="title margin-top-0 margin-bottom-20">
            {`${title}`}
          </div>
        </div>
        <div className="text width-100">
          <EditorContent editor={previewEditor} />
        </div>
      </div>
    </WriteChapterStyle>
  )
}