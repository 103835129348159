import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazy-load';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {getStories} from '../../api/content'
import { BACKEND_HOST } from '../../api/host';

import styled from 'styled-components'

import ItemCard from '../general-components/ItemCard';
import PublicStoryOverlay from '../general-components/PublicStoryOverlay';
import TagComponent from '../tag-components/TagComponent';

import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from '../style/buttonsStyle';
import SelectedInfosheetsStyle from '../chapter-components/AttachInfosheet/style/SelectedInfosheetsStyle';
import ItemCardStyle from '../general-components/style/itemCardStyle';

import defaultImg from '../../img/sinPortada01.png'

export default function Homepage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [stories, setStories] = useState([
    {
      story_id: null,
      story_title: "",
      language_id: null,
      language_name: "",
      state_id: null,
      state_name: "",
      cover_id: null,
      cover_reference: "",
      genre_list: [],
      tag_list: [],
    },
  ]);
  const [pageSize, setPageSize] = useState(12);

  
  // For this example, a URL would look something like this: http://mysite/stories?genres=fantasy+action+comedy&page=2 
  const queryParams = new URLSearchParams(location.search);

  const genre_filter_string = queryParams.get('genres'); // genre_filter_string = "fantasy action comedy" (string)
  const genres = genre_filter_string ? genre_filter_string.split(' ') : []; // genres = ["fantasy", "action", "comedy"] (array)

  const tag_filter_string = queryParams.get('tags'); // tag_filter_string = "magic dragons" (string)
  const tags = tag_filter_string ? tag_filter_string.split(' ') : []; // tags = ["magic", "dragons"] (array)

  const page = parseInt(queryParams.get('page')) || 1; // page = 2 (number)

  // console.log(genres, page);  

  // This is the structure of the data that you will be receiving from the server.
  // [
  //     {
  //         story_id: 1,
  //         story_title: 'Bienvenidos a los Himalayas',
  //         cover_reference: './stories/1/1_info.json',
  //         genre_list: ['aventura' , ' fantasia' , 'accion']
  //     },
  //     {
  //         story_id: 2,
  //         story_title: '50 shades of Gray',
  //         cover_reference: './stories/2/2_info.json'
  //         genre_list: ['aventura' , ' fantasia' , 'accion']
  //     },
  //     {
  //         story_id: 3,
  //         story_title: 'Paper Planes',
  //         cover_reference: './stories/3/3_info.json'
  //         genre_list: ['aventura' , ' fantasia' , 'accion']
  //     }
  // ]

  useEffect(() => {
    const reqParams = {
      pageSize: pageSize,
      page: page,
    }

    if (genres.length > 0) {
      // console.log(queryParams.get("genres").replace(/ /g, '+'));
      reqParams.genres = genres.join(' ');
    }

    if (tags.length > 0) {
      reqParams.tags = tags.join(' ');
    }

    const fetchData = async () => {
      try {
        const response = await getStories(reqParams);
        console.log(response.data);
        setStories(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [page, genre_filter_string, tag_filter_string]);


  const sourceImg = (story) => {
    if (story.cover_reference) {
      const imgsrc = BACKEND_HOST + (story.cover_reference).substring(1)
      return imgsrc
    } else {
      return defaultImg
    }
  }

  const handleClick = (story) => {
    navigate(`/${story.story_id}/overview`)
    console.log(story.story_title)
    // console.log(story.story_title, story.story_id, story.genre_list)
  }

  const loadNextPage = () => {
    const nextPage = page + 1;
    
    queryParams.set("page", nextPage);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ search: newSearch });
    // window.location.reload()
  }


  const loadPreviousPage = () => {
    if (page === 1) return;
    const previousPage = page - 1;
    
    queryParams.set("page", previousPage);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ search: newSearch });
    // window.location.reload();
  }

  const changeGenreFilter = (newGenreList) => {
    // queryParams.delete("page");
    queryParams.set("page", 1);
    // console.log(newGenreList);

    if (newGenreList.length === 0) {
      queryParams.delete("genres");
      const newSearch = `?${queryParams.toString()}`;
      navigate({ search: newSearch });
      return;
    }

    const new_genre_filter_string = newGenreList.join(' ');
    queryParams.set("genres", new_genre_filter_string);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ search: newSearch });
    // window.location.reload();
  }

  const changeTagFilter = (newTagList) => {
    queryParams.set("page", 1);

    if (newTagList.length === 0) {
      queryParams.delete("tags");
      const newSearch = `?${queryParams.toString()}`;
      navigate({ search: newSearch });
      return;
    }

    const new_tag_filter_string = newTagList.join(' ');
    queryParams.set("tags", new_tag_filter_string);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ search: newSearch });
  }

  const languages = [
    { display: 'Español', value: '1'},        
    { display: 'English', value: '2'},       
    { display: 'Italiano', value: '3'},
  ];

  const states = [
    { display: 'Finalizado', value: '1'},        
    { display: 'En curso', value: '2'},       
    { display: 'En pausa', value: '3'},      
    { display: 'Abandonado', value: '4'},
  ];
  

  return (
    <HomepageContainer>
      <GeneralStyle>
        <ButtonsStyle>
          <div className='container'>
            <div className='intContainer'>
              
            <div className='title-n-select'>
                <h1 className='title'>Catálogo de historias</h1>
                <div className='selectContainer'>
                  <select>
                    <optgroup label="Idioma">
                      {languages.map((language, index) => (
                        <option key={index} value={language.value}>{language.display}</option>
                      ))}
                    </optgroup>
                  </select>
                  <select>
                    <optgroup label="Estado">
                      {states.map((state, index) => (
                        <option key={index} value={state.value}>{state.display}</option>
                      ))}
                    </optgroup>
                  </select>
                </div>
            </div>

            <hr className='separador'></hr>

            <div className='flex-row gap-10 margin-bottom-30 flex-wrap'>              
              <div className='genre flex-1'>
                <h3 className="subtitle">Filtrar por géneros</h3>
                <TagComponent tagList={genres} setTagList={changeGenreFilter} id="genre_component" type="genres" placeholder="Buscar..." />
              </div>
                        
              <div className='tags flex-1'>
                <h3 className="subtitle">Filtrar por tags</h3>
                <TagComponent tagList={tags} setTagList={changeTagFilter} id="genre_component" type="tags" placeholder="Buscar..." />
              </div>
            </div>

            {/* <hr className='separador'></hr> */}

            <SelectedInfosheetsStyle>
              <ItemCardStyle>
                <div className='gallery-container card-img-grande'>

                  {stories.map(story => (
                    <ItemCard key={story.story_id} 
                      id={story.story_id} 
                      item_title={story.story_title}
                      img={sourceImg(story)}
                      overlay={
                        <PublicStoryOverlay 
                          id={story.story_id}
                          title={story.story_title}
                          author={`${story.author_list}`}
                          // desc={story.story_description}
                          desc={`${story.story_description}`}
                          genres={story.genre_list}
                          tags={story.tag_list}
                          stats={{"likes": story.likes, "views": story.views}}
                        />
                      }
                      handleClick = {() => handleClick(story)}
                    />
                  // <LazyLoad key={story.story_id} height={10}>
                  // </LazyLoad>
                ))}
                </div>
              </ItemCardStyle>
            </SelectedInfosheetsStyle>


            </div>
          </div>
        </ButtonsStyle>
      </GeneralStyle>

      <div className="gallery">

      </div>
      <div className='page-nav-container'>
        <button className='page-nav-button previous-page-btn' onClick={() => loadPreviousPage()}>⬅ Previous Page</button>
        <div className='page-nav-indexer'> {page} </div>
        <button className='page-nav-button next-page-btn' onClick={() => loadNextPage()}>Next Page ➡</button>
      </div>
    </HomepageContainer>
  )
}

const HomepageContainer = styled.div`

.page-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-nav-indexer {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}

.page-nav-button {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
}


`