import styled from "styled-components";

import { colors } from "./colors";

const PopupStyle = styled.div`
  .overlay {    
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1;
  };

  .popup-containter-n-btns{
    position: relative;
    display: flex;
    width: 100%;
    max-width: 984px;
    min-height: 300px;
    height: 80vh;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .small-popup-containter-n-btns{
    position: relative;
    display: flex;
    width: 100%;
    max-width: 800px;
    min-height: 300px;
    height: 80vh;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .popup-container {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 900px;
    min-height: 300px;
    height: 80vh;
    background-color: ${colors.c0};
    
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .small-popup-container {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 680px;
    min-height: 300px;
    height: 80vh;
    background-color: ${colors.c0};
    
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .left-column {
    width: 100%;
    max-width: 228px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
  }

  .left-column-150{
    width: 100%;
    max-width: 150px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
  }

  .left-column-180{
    width: 100%;
    max-width: 180px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    // margin-left: 20px;
    padding-bottom:2px; 
    padding-right: 2px;
    overflow-x: hidden;
    position: relative;
    // background: green;
  }

  .right-column::-webkit-scrollbar {
    width: 0;  /* Ancho de la barra de desplazamiento */
    height: 0; /* Altura de la barra de desplazamiento */
  }

  .unique-column {
    display: flex;
    flex-direction: row;
    width: 100%;
    // height: 100%;
    // outline: 1px solid black;
    overflow-y: hidden;
    box-sizing: border-box;
    padding-bottom:2px; 
    padding-right: 2px;
    box-sizing: border-box;
    // max-width: 822px;
  }

  .alto-detalle-ficha{
    max-height: calc((80vh) - 80px);
    // position: absolute;
  }
  
  .prueba{
    display: flex;
    // position: absolute;
    height: 100%;
    width: 100%;
    background: red;
    margin: auto;
    flex: 1;
  }

`;

export default PopupStyle;