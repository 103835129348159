import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom';

// import styled from 'styled-components'

// import TabBar from '../utils/TabBar';
import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from '../style/buttonsStyle';

import { useAuth } from '../../context/AuthContext'

import TranslatorsCatalog from './translator-catalog/TranslatorCatalog'
import EditorsCatalog from './editor-catalog/EditorCatalog'
import IllustratorsCatalog from './illustrator-catalog/IllustratorCatalog'

import WriterCatalog from './writer-catalog/WriterCatalog'


import proximamente from "../../img/proximamente.jpg";
// import lupa from "../../img/lupa.png";

export default function UsersCatalog() {
  const { user } = useAuth()
  const { serviceType } = useParams()
  const navigate = useNavigate()

  const categories = [
    // { name: "escritura", id: 1 },
    { name: "Ilustradores", icon: "tabBarIlustrador_btn bubble-menu-btn circleButton", id: 4, value: 'illustrators'}, 
    { name: "Traductores", icon: "tabBarTraductor_btn bubble-menu-btn circleButton", id: 3, value: 'translators'},
    { name: "Editores", icon: "tabBarEditor_btn bubble-menu-btn circleButton", id: 2, value: 'editors'}, 
    // { name: "música", id: 5 },
    // { name: "doblaje", id: 6 },
  ];

  const languages = [
    { display: 'Español', value: '1'},        
    { display: 'English', value: '2'},       
    { display: 'Italiano', value: '3'},
  ]
  
  const handleTabChange = (selectedServiceType) => {
    // console.log(selectedServiceType)
    // const selectedCategory = categories.find(category => category.id === selectedServiceType)
    navigate(`/userscatalog/${categories[selectedServiceType].value}`)
  }

  const renderContent = () => {
    switch (serviceType) {
      case 'editors':
        return (
          <div className='align-left flex flex-column gap-10'>
            <img className="" alt="proximamente" src={proximamente}/>
            <h1 className='subtitle cursiva'>Aviso Importante</h1>
            <p className='text cursiva'>La pantalla actual no está disponible en estos momentos. Estamos trabajando arduamente en mejorar nuestras funcionalidades para brindarte una mejor experiencia.</p>
            <p className='text cursiva'>Si deseas colaborar con nosotros, te invitamos a enviarnos un correo a <a href="mailto:seithfed.app@gmail.com">seithfed.app@gmail.com</a> para coordinar una entrevista. Queremos conocer tus opiniones y sugerencias sobre lo que te gustaría ver en futuras actualizaciones.</p>
            <p className='text cursiva'>¡Gracias por tu paciencia y colaboración!</p>
          </div>
        )
        // <EditorsCatalog />
      case 'translators':
        return (
          <div className='align-left flex flex-column gap-10'>
            <img className="" alt="proximamente" src={proximamente}/>
            <h1 className='subtitle cursiva'>Aviso Importante</h1>
            <p className='text cursiva'>La pantalla actual no está disponible en estos momentos. Estamos trabajando arduamente en mejorar nuestras funcionalidades para brindarte una mejor experiencia.</p>
            <p className='text cursiva'>Si deseas colaborar con nosotros, te invitamos a enviarnos un correo a <a href="mailto:seithfed.app@gmail.com">seithfed.app@gmail.com</a> para coordinar una entrevista. Queremos conocer tus opiniones y sugerencias sobre lo que te gustaría ver en futuras actualizaciones.</p>
            <p className='text cursiva'>¡Gracias por tu paciencia y colaboración!</p>
          </div>
        )
        // <TranslatorsCatalog />
      case 'illustrators':
        return <IllustratorsCatalog />

      case 'writers':
        return <WriterCatalog />
      default: 
        return (
          <div className='align-left flex flex-column gap-10'>
            <img className="" alt="proximamente" src={proximamente}/>
            <h1 className='subtitle cursiva'>Aviso Importante</h1>
            <p className='text cursiva'>La pantalla actual no está disponible en estos momentos. Estamos trabajando arduamente en mejorar nuestras funcionalidades para brindarte una mejor experiencia.</p>
            <p className='text cursiva'>Si deseas colaborar con nosotros, te invitamos a enviarnos un correo a <a href="mailto:seithfed.app@gmail.com">seithfed.app@gmail.com</a> para coordinar una entrevista. Queremos conocer tus opiniones y sugerencias sobre lo que te gustaría ver en futuras actualizaciones.</p>
            <p className='text cursiva'>¡Gracias por tu paciencia y colaboración!</p>
          </div>
        )
    }
  };

  // MARK: - render
  return (
    <GeneralStyle>
      <ButtonsStyle>
      <div className='container'>
        <div className='intContainer'>
          <div className='category-header'>
            <div className='title-n-select'>
              {serviceType === 'writers' && <h1 className='title'>Catálogo de escritores</h1>}
              {serviceType === 'editors' && <h1 className='title'>Catálogo de editores</h1>}
              {serviceType === 'translators' && <h1 className='title'>Catálogo de traductores</h1>}
              {serviceType === 'illustrators' && <h1 className='title'>Catálogo de ilustradores</h1>}
              {serviceType === 'musicians' && <h1 className='title'>Catálogo de músicos</h1>}
              {serviceType === 'voiceovers' && <h1 className='title'>Catálogo de dobladores</h1>}
              {/* <h1 className='title'>Catálogo</h1> */}
              <select>
                <optgroup label="Idioma de habla">
                  {languages.map((language, index) => (
                    <option key={index} value={language.value}>{language.display}</option>
                  ))}
                </optgroup>
              </select>
            </div>

            <hr className='separador'></hr>

            {/* <TabBar 
              categories={categories} 
              onTabChange={handleTabChange}
              defaultTab= {categories.findIndex((category) => category.value === serviceType)}
            /> */}
          </div>
          
          {renderContent()}


        </div>
      </div>
      </ButtonsStyle>
    </GeneralStyle>
  )
}

