import styled from "styled-components";

export const FormContainer = styled.div`
.form_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: calc(100vw - 17px);
    background-color: #f5f5f5;
};
.h1_form{
    margin: 0px;
    padding: 5px;
};
.p_form{
    margin: 0px;
    padding: 0px;
};
.login_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30vw;
    border-radius: 10px;
};
.div_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 10px;
};
.label_form{
    font-weight: bold;
};
.input_form{
    width: calc(100% - 20px);
    padding: 10px 10px;
    border: 1px solid #ccc;
    outline: none;
};
.enviar{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: none;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
};
.error{
    margin: 0px;
    padding: 0px;
    color: red;
    font-size: 12px;
};
.div_error{
    align-items: center;
    width: 30vw;
    justify-content: center;
    background: red;
    padding: 10px;
    margin: 10px;
    border: 1px solid red;
    color: white
}:
`;