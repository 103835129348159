import styled from "styled-components";


const TestingContainer = styled.div`
.testingContainer{
    position: fixed;
    // left: 20px; /* Puedes ajustar la posición según tus necesidades */
    right: 20px; /* Puedes ajustar la posición según tus necesidades */
    bottom: 20px; /* Puedes ajustar la posición según tus necesidades */
    z-index: 1; /* Asegura que el botón flotante esté encima de otros elementos */
    //width: 30px; /* Establece el ancho del contenedor */
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna */
    align-items: flex-start; /* Alinea los elementos a la izquierda */
    gap: 1px; /* Espacio entre los elementos (puedes ajustar según tus necesidades) */
  }

`;

export default TestingContainer;