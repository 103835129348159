import styled from "styled-components";

import { colors } from "../../../style/colors";
import archivo01 from "../../../../img/archivo-01.png";
import archivo02 from "../../../../img/archivo-02.png";

const ContentListStyle = styled.div`
    .infosheet-list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        // border: 1px solid pink;
    }

    .item-container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;
    }

    //ultimo item-container sin marginBottom
    .item-container:last-child {
        margin-bottom: 0px;
    }

    .items-list-container{
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px;
        border: 1px solid ${colors.c6};
        overflow: auto;
        box-sizing: border-box;
        // max-width: 200px;
        width: 100%;
        overflow-x: hidden;
    }


    .items-list-chapter-container{
        // display: flex;
        // flex-direction: column;
        // flex: 1;
        display: inline-table;
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid ${colors.c6};
        overflow: auto;
        box-sizing: border-box;
        // max-width: 200px;
        width: 100%;
        overflow-x: hidden;
    }

    .item-info{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        margin-left: 10px;
    }

    .public-title{
        max-width: 165px;
        color: ${colors.c1};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
    }

    .private-title{
        max-width: 165px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;

    }
    
    .item-container:hover h2{
        color: ${colors.c2};
    
    }

    
    .chapter-item-container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        // border: 1px solid black;
        margin: 0px;
        cursor: pointer;
        // transition: 0.3s;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
        margin-top: 10px;
    }
    .chapter-item-container:first-child {
        margin-top: 0; /* Elimina el margin-top del primer elemento */
      }

    .stories{
        background-color: ${colors.c1};
        // color: ${colors.c0};
    }

    .stories:hover{
        background-color: ${colors.c2};
        // color: ${colors.c0};
    }

    // .item-container img{
    //     content: url(${archivo01});
    //     background-size: cover;
    //     // width: 50px;
    //     // height: 50px;
    // }

    .item-container:hover img{
        content: url(${archivo02});
        background-size: cover;
    
    }

    .item-container:hover p{
        color: ${colors.c6};
    }

    .chapter-item-container:hover img{
        content: url(${archivo02});
        background-size: cover;
    
    }

    .chapter-item-container:hover h2{
        color: ${colors.c2};    
    }
`;

export default ContentListStyle;