import React, {useEffect} from "react"; 
import { useAuth } from "../context/AuthContext";

export default function HomeComponent (props) {

    const {user} = useAuth();
    if(!user) return <h1>Loading...</h1>;
    console.log(user);


    return(
        <h1>Home</h1>
    )
}