export default function ReviewServiceItemComponent({index, serviceName, wordsCount, pricePerWord, serviceDescription, serviceImg, initial_price, current_price}) {

    return (
      <div className="misma-linea fondo-gris" key={index}>
        <div className="misma-linea flex-1 margin-right-10">
            <p className="text font-weight-bold margin-right-10">{`${serviceName}`}</p>
        </div>

        {wordsCount &&
            <div className="misma-linea flex-1 flex-wrap margin-right-10">
                <p className="text font-weight-bold align-center margin-right-10">{`Palabras`}</p>
                <p className="text align-center margin-right-10">{`${wordsCount}`}</p>
            </div>
        }

        {serviceDescription &&
            <div className="misma-linea flex-3 flex-wrap margin-right-10">
                <p className="text align-center">{`${serviceDescription}`}</p>
            </div>
        }

        {serviceImg &&
            <div className="misma-linea flex-1 flex-wrap margin-right-10"> 
                <div className="text">
                    <img className="file_attachment_preview" 
                        // src={props.serviceImg} 
                        src="https://via.placeholder.com/90"
                    alt="service reference"/>
                </div>
            </div>
        }

        <div className="misma-linea flex-1 flex-wrap margin-right-10">
            <p className="text align-center">{`$${initial_price}`}</p>
            {wordsCount &&
            <>
                <p className="margin-right-10"></p>
                <p className="text align-center">{`($${pricePerWord} / palabra)`}</p>
            </>
            }
        </div>

        {/* mostrar solo si es el prestador de servicios */}
        <input type="number" className="numericInput"
            placeholder="Nuevo precio"
            // value={current_price}
            defaultValue={current_price}
        />        
      </div>
    );
  }
