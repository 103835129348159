//import './styles.scss'
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState , Component } from 'react'

import { EditorContent , useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import CharacterCount from '@tiptap/extension-character-count'
import {getChapter, updateChapter, removeChapter} from '../../api/content'
import {getChapterStates} from '../../api/type'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import SampleTextButton from './SampleText/SampleTextButton'
import AttatchInfosheet from './AttachInfosheet/AttachInfosheet'
import TextEditor from './ChapterTextEditor'
import { useAuth } from '../../context/AuthContext'

import GeneralStyle from '../style/generalStyle'
import ButtonsStyle from '../style/buttonsStyle'
import WriteChapterStyle from './style/WriteChapterStyle'

import Swal from 'sweetalert2'


export default function WriteChapter () {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [infosheetPopup, setInfosheetPopup] = useState(false);


  const [isSavingChapter, setIsSavingChapter] = useState(false);

  const [chapterStates, setChapterStates] = useState([]);
  const [selectedChapterState, setSelectedChapterState] = useState(1);

  // const [storyInfo, setStoryInfo] = useState({});//[title, setTitle] = useState('');
  const [chapterInfo, setChapterInfo] = useState({
    story_id: null, 
    chapter_id: null, 
    storyTitle:'', 
    chapterTitle: ''
  });

  const SaveConfirmation = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })
  
    
  const editor = useEditor({
    extensions: [
      StarterKit, 
      /**StarterKit incluye:
       *  Nodes:
       *      Document,
       *      Paragraph,
       *      Text,
       *      Heading,
       *      Blockquote,
       *      BulletList,
       *      CodeBlock,
       *      HardBreak,
       *      HorizontalRule,
       *      ListItem,
       *      OrderedList
       *  Marks:
       *      Bold,
       *      Code,
       *      Italic,
       *      Strike
       *  Extensions:
       *      Dropcursor,
       *      Gapcursor,
       *      History
       */
      Underline,
      Image.configure({
        HTMLAttributes: {
          class: 'miImage', 
          style: 'max-width: 500px; max-height: 500px; display: block; margin-left: auto; margin-right: auto;',
        }
      }),

      Placeholder.configure({
        placeholder: 'Write something …',
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CharacterCount,
    ],
    /*
    onUpdate: ({ editor }) => {
        const json = editor.getJSON()
        console.log(json)
        // send the content to an API here
    },
    */
    /*
    content: `
        <p>Hey, try to select some text here.</p>
    `,
    editable: true, // default is true
    */    
  })

  const {story_id, chapter_id } = useParams();

  useEffect(() => {
    // console.log("running useEffect")
    if (editor) {     
      fetchData();
      fetchChapterStates();
    }
  }, [editor]); 

  if (!editor) {
    // console.log('test log: Editor is null')
    return
  }
  
  const fetchData = async () => {
    try {
      const response = await getChapter(chapter_id);
      // console.log('Chapter Info:', response.data)
      // console.log('Chapter States:', response.data.state_id);
      setChapterInfo(response.data);
      setSelectedChapterState(response.data.state_id)
      editor.commands.setContent(JSON.parse(response.data.content));

    } catch (error) {
      console.log('Error fetching data:', error);
    }
  } 

  const fetchChapterStates = async () => {
    try {
      const response = await getChapterStates();
      setChapterStates(response.data);
      console.log('Chapter States:', response.data);
    } catch (error) {
      console.log('Error fetching Chapter States:', error);
    }
  }

  const wordCount = (text) => {
    return text.split(/\s+/).filter((word) => word !== '').length
  }

  //function to save the json content from the editor using axios
  const saveChapter = async () => {
    setIsSavingChapter(true)
    const chapterContent = editor.getJSON()

    const includedImages = new Set()
    chapterContent.content.forEach((node) => {
      if (node.type === 'image') {
        const title = node.attrs.title
        includedImages.add(title)
      }
    })

    const msg = {
      user_id: user.id,
      state_id: selectedChapterState,
      title: chapterInfo.chapterTitle,
      content: chapterContent,
      includedImages: Array.from(includedImages),
      wordCount: editor.storage.characterCount.words() + wordCount(chapterInfo.chapterTitle),
    }

    console.log('Save Chapter req:', msg)
  
    try {
      const response = await updateChapter(chapter_id, msg);
      console.log('Server response:', response.data);
      SaveConfirmation.fire({
        icon: "success",
        title: "Cambios guardados exitosamente!"
      })
    } catch (error) {
      SaveConfirmation.fire({
        icon: "error",
        title: "Error al guardar cambios"
      })
      console.log('Error:', error);
    } finally {
      setIsSavingChapter(false)
    }
  }

  const deleteChapter = async () => {
    const msg = {
      user_id: user.id,
      chapter_id: chapter_id,
    }

    try {
      const response = await removeChapter(msg)
      console.log('Delete Chapter req success:', response)
      navigate(`/${story_id}/info`)
    } catch (error) {
      console.log('Error with Delete Chapter req:', error)
    }

  }

  return (
    <GeneralStyle>
      <ButtonsStyle>
          <WriteChapterStyle>          
            <div className='container'>   
              <div className='leftNullContainer'></div>       
              <div className='intContainer'>
                
                <div className="title-n-select">
                  <div className='misma-linea'>
                    <h2 className='title margin-right-10'>Escribir capítulo</h2>
                    <h2 className='text-light align-left cursiva' id='storyTitleBox'>({chapterInfo.storyTitle})</h2>
                  </div>
                  <div className="selectContainer">
                    <select className="" name="target_type" id="target_type" value={selectedChapterState} onChange={(e)=>{setSelectedChapterState(e.target.value)}}>
                      <optgroup label="Estado">
                        {chapterStates.map((state, state_index) => (
                          <option key={state_index} value={state.id_state}>{state.name}</option>
                        ))}
                      </optgroup>
                    </select>
                    {/* <select name="language" id="language">
                      <optgroup label="Idioma">
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                      </optgroup>
                    </select> */}
                  </div>
                </div>
                
                <hr className='separador'></hr>

                {/* <div className=' align-left'>
                  <h3 className="subtitle">Estado</h3>
                  
                  <select name="state" id="state">
                    <optgroup label="Estado">
                      <option value="ongoing">En curso</option>
                      <option value="paused">En pausa</option>
                      <option value="finished">Finalizada</option>
                      <option value="dropped">Abandonada</option>
                    </optgroup>
                  </select>
                </div>
                <hr className='separador'></hr> */}

                <TextEditor 
                  editor={editor}
                  // storyInfo={storyInfo}
                  data={chapterInfo}
                  setData={setChapterInfo}
                />

                
                <div className='btns'>
                  {/**Boton para guardar el capitulo */}
                  <button className='save_btn circleButton' onClick={saveChapter} disabled={isSavingChapter}></button>
                  
                  {/**Boton para añadir fichas al editor */}
                  <button className='fichas_btn circleButton' onClick={() => setInfosheetPopup(!infosheetPopup)}></button>

                  {/**Boton para eliminar el capitulo */}
                  <button className='delete_btn circleButton' onClick={deleteChapter}></button>
                </div>
              
                {/* <button onClick={() => console.log(chapterInfo.chapterTitle)}>Title update test</button> */}
                {
                  infosheetPopup && 
                  <AttatchInfosheet 
                    closePopup={() => setInfosheetPopup(!infosheetPopup)}
                    chapter_id={chapter_id}
                    story_id={story_id}
                  />
                }
              </div>
            </div>
          </WriteChapterStyle>
      </ButtonsStyle>
    </GeneralStyle>
  )
}