import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";

import {getUserSettings, updateUserSettings} from "../../api/setting";
import { useAuth } from "../../context/AuthContext";

export default function UserSettings() {
  const { user } = useAuth();
  const [roleList, setRoleList] = useState([
    // {
    //   "role_id": 1,
    //   "role_name": "escritor",
    //   "description": null,
    //   "role_is_active": null,
    //   "user_has_role_id": null,
    //   "services_are_active": null
    // }, 
  ])

  const [languageList, setLanguageList] = useState([
    // {
    //   "language_id": 1,
    //   "language_name": "español",
    //   "language_is_active": null,
    //   "user_speaks_language_id": null // usaremos esto?
    // },
  ])

  const NotificationBubble = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })

  useEffect(() => {
    if(!user) return

    const fetchData = async () => {
      try {
        const response = await getUserSettings(user.id);
        if (response.data.user_roles){
          const userRoles = response.data.user_roles.map(role => {
            role.role_is_active = role.role_is_active ? true : false
            return role
          })
          setRoleList(userRoles)
        }

        if (response.data.user_languages) {
          const userLanguages = response.data.user_languages.map(language => {
            language.language_is_active = language.language_is_active ? true : false
            return language
          })
          setLanguageList(userLanguages)
        }
      }
      catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [user])

  const saveChanges = async () => {
    const msg = {
      new_role_state: roleList,
      new_language_state: languageList
    }

    try{
      const response = await updateUserSettings(user.id, msg);
      NotificationBubble.fire({
        icon: "success",
        title: "Cambios guardados"
      });
      console.log(response.data)
    } catch (error) {
      NotificationBubble.fire({
        icon: "error",
        title: "Error al guardar cambios"
      });
      console.log(error)
    }
  }

  const isRoleActive = (role) => {
    return role.role_is_active
  }


  const isLanguageActive = (language) => {
    return language.language_is_active
  }

  const toggleRole = (index) => {
    let newList = [...roleList]
    if (newList[index].role_is_active == null) {
      newList[index].role_is_active = true
    } else {
      newList[index].role_is_active = !newList[index].role_is_active
    }
    setRoleList(newList)

  }

  const toggleLanguage = (index) => {
    let newList = [...languageList]
    if (newList[index].language_is_active == null) {
      newList[index].language_is_active = true
    } else {
      newList[index].language_is_active = !newList[index].language_is_active
    }
    setLanguageList(newList)
  }
  
  
  return (
    <div>
      <div>
        <h1>Activacion de roles</h1>
      </div>
      {roleList.map((role, index) => (
        <div key={index}>
          <input 
            type="checkbox" 
            id={index} 
            name={index} 
            // value={role.role_is_active} 
            checked={isRoleActive(role)}
            onChange={() => toggleRole(index)}
            // onClick={() => toggleRole(index)}
          />
          <label htmlFor={role.role_name}>{role.role_name}</label>
        </div>
      ))}

      <hr />

      <div>
        <h1>Idiomas manejados</h1>
      </div>

      {languageList.map((language, index) => (
        <div key={index}>
          <input 
            type="checkbox" 
            id={index} 
            name={index} 
            // value={language.language_is_active} 
            checked={isLanguageActive(language)}
            onChange={() => toggleLanguage(index)}
          />
          <label htmlFor={language.language_name}>{language.language_name}</label>
        </div>
      ))}

      <div >
        <button onClick={() => saveChanges()}>Save Changes</button>
      </div>
    </div>
  );
}