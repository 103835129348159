import React from "react"; 
import { Link } from "react-router-dom";

import HeaderStyle from "./style/NavbarStyle";
import logoCorfo from "../../img/logo-corfo01.png";
import logoGobierno from "../../img/logo-gobiernocl-01.png"
import GeneralStyle from "../style/generalStyle";

import { useAuth } from "../../context/AuthContext";

export default function FooterComponent () {
    const {user} = useAuth();

    return(
        <GeneralStyle>
        <HeaderStyle>
            <div className="navbar_container">
                <div className="footer" >
                    <div className="logoContainer">
                    </div>
                    <div className="links">
                    </div>
                    <div className="links">
                        <a href="https://www.corfo.cl/sites/cpp/homecorfo">
                            <img src={logoCorfo} className="margin-right-10" alt="Logo de Corfo"/>
                        </a>
                        <a href="https://www.gob.cl/">
                            <img src={logoGobierno} className="margin-right-10" alt="Logo de Gobierno"/>
                        </a>
                    </div>
                </div>
            </div>
        </HeaderStyle>
        </GeneralStyle>
    )
}
