import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {getImagesFromUser} from "../../api/content";
import { BACKEND_HOST } from "../../api/host";
import { Gallery } from "react-grid-gallery";
import { useAuth } from "../../context/AuthContext";

export default function UserImgGallery(prop) {
    const { user } = useAuth()

    const [imgList, setImgList] = useState([]) // [ { src: "", width: 0, height: 0, isSelected: false, caption: "", title: "" }, ...
    // Structure the data for the photo gallery
    // [{
    //     "id_image": 30,
    //     "title": "Frac 3",
    //     "width": 4400,
    //     "height": 5000,
    //     "image_reference": "/content/images/20240124193403734/20240124193403734.jpeg",
    // },
    // {
    //     "id_image": 31,
    //     "title": "Frac 22222",
    //     "width": 5500,
    //     "height": 4500,
    //     "image_reference": "/content/images/20240124193403783/20240124193403783.jpeg",
    // }]

    useEffect(() => {
        if (!user) return

        const fetchData = async () => {
            try {
                const response = await getImagesFromUser(user.id)
                const formattedImages = response.data.map((imgInfo) => {
                    // preprocess the data to fit the gallery component
                    const img_path = imgInfo.image_reference.substring(1)
                    return {
                        src: BACKEND_HOST + img_path,
                        width: imgInfo.width,
                        height: imgInfo.height,
                        title: imgInfo.title,
                        imgId: imgInfo.id_image,
                        imgRef: img_path,
                    }
                });
                setImgList(formattedImages)
            } catch (err) {
                console.log('Error with getting image list: ', err)
            }
        }
        fetchData()
    }, []);


    const handleClick = (index) => {
        prop.loadImage(imgList[index])
    }
    
    return (
        <GalleryContainer>
            <input />
            <div className="gallery-holder">
                <Gallery images={imgList} enableImageSelection={false} onClick={handleClick} />
            </div>
        </GalleryContainer>
    )
}

const GalleryContainer = styled.div`
    .gallery-holder {
        max-height: 500px;
        overflow-y: auto;
    }
`