export default function ReviewAccessPermissionItemComponent({index, title, type, language, words, content, setReferenceContentPopup}) {

    return (
      <div className="misma-linea fondo-gris" key={index}>
        <div className="misma-linea flex-3 margin-right-10">
            <div className="misma-linea">
            <img className="file-btn-white margin-right-10" ></img>
            <div>
                <p className="text sharedContentTitle align-left width-100" title={title}>{title}</p>
            </div>
            </div>
        </div>
        <p className="text flex-1"></p>
        <div className="misma-linea flex-2 flex-wrap margin-right-10">
            <p className="text font-weight-bold margin-right-10">Tipo </p>
            <p className="text">{type}</p>
        </div>
        <div className="misma-linea flex-2 flex-wrap margin-right-10">
            <p className="text font-weight-bold margin-right-10">Idioma </p>
            <p className="text">{language}</p>
        </div>
        <div className="misma-linea flex-2 flex-wrap margin-right-10">
            <p className="text font-weight-bold margin-right-10">Palabras </p>
            <p className="text">{words}</p>
        </div>
      
        <div className="margin-right-10">
            <button className="preview_btn_table circleButton" onClick={() => setReferenceContentPopup(content)}></button>
        </div>  
      </div>
    );
  }
  
  /*
        <div className="content_item_detail item_preview">
        <button onClick={() => setReferenceContentPopup(content)}>Ver</button>
        </div>
  */