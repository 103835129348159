import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import {getUserRoleServiceList, submitServiceRequest} from '../../../../api/service'
import GeneralStyle from "../../../style/generalStyle";
import ButtonsStyle from "../../../style/buttonsStyle";
import AccessPermissionItemComponent from "../AccessPermissionItemComponent";
import ImageReferenceItemComponent from "../ImageReferenceItemComponent";
import { TagContainer } from '../../../tag-components/style/tagStyle'
import IlustrationServiceItemComponent from "./IlustrationServiceItemComponent";

import { useParams } from 'react-router-dom';

import AttachReferenceContent from "../../attach-reference/AttachReferenceContent";
import { useAuth } from "../../../../context/AuthContext";


export default function IllustrationRequest() {
  const { user } = useAuth();
  const { provider_id } = useParams();

  const [referenceContentPopup, setReferenceContentPopup] = useState(false);
  const [selectedSharedContentBox, setSelectedSharedContentBox] = useState(null)

  const [sharedContent, setSharedContent] = useState([
    // {title: "select content", type: "-----", language: "-----", words: "1000"},
  ])

  const NotificationBubble = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })


  const newSharedContent_Empty = {
    title: "select content", 
    type: {name: "---", id: null}, 
    language: {name: "---", id: null}, 
    words: "---"
  }
  
  
  // const [fileAttachment_ID_sequence, setFileAttachment_ID_sequence] = useState(0)
  const [fileAttachments, setFileAttachments] = useState([
    // {file_name: "file1", size: "1000", type: "pdf", data: [DATA URL]},
    // {file_name: "file2", size: "2000", type: "jpg", data: [DATA URL]},
  ])


  const [selectedService_index, setSelectedService_index] = useState(null)
  const [selectedIllustrationService, setSelectedIllustrationService] = useState(null)
  const [illustrationServiceList, setIllustrationServiceList] = useState([
    // {name:"Bust", description: "hola esta es la descripcion del servicio... :v hue hue hue", img: "https://placehold.co/150x100", price: "10000"},
    // {name:"Full Body", description: "hola esta es la descripcion del servicio... :v hue hue hue", img: "https://placehold.co/150x100", price: "20000"}, 
    // {name:"Full Body + Background", description: "hola esta es la descripcion del servicio... :v hue hue hue", img: "https://placehold.co/150x100", price: "30000"},
  ])

  const sourceImg = (ref) => {
    if (ref !== null) {
      return ref
    }
    return "https://via.placeholder.com/150"
  }

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getUserRoleServiceList(provider_id, 4)
        if (response.data.services) {
          const initializeServices = response.data.services.map(service => {
            return {
              name: service.service_name,
              description: service.service_description,
              price: service.price,
              // attachmentData: service.example_reference,
              img: sourceImg(service.example_reference),
              serviceID: service.service_ilustrator_id,
            }
          })
          setIllustrationServiceList(initializeServices)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])


  const selectService = (service, index) => {
    setSelectedService_index(index)
    setSelectedIllustrationService(service)

  }
  const [message, setMessage] = useState("")

  
  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    message ? message.length : 0
  );


  const submitRequest = async () => {
    if (selectedIllustrationService === null) {
      NotificationBubble.fire({
        icon: "warning",
        title: "No se ha seleccionado un servicio",
        // text: "No se ha seleccionado un servicio"
      })
      console.log("No service selected")
      return
    }
    const service = selectedIllustrationService

    // service provider role id's:
    // "escritor" 1	
    // "editor" 2	
    // "traductor" 3	
    // "ilustrador" 4	
    // "músico" 5	
    // "doblador" 6	
    let requestContent = {
      requester_id: user.id,
      provider_id: provider_id,
      provider_role_id: 4,
      selectedService: service,
      initial_price: service.price,
      current_price: service.price,
      
      // message: document.getElementById('message').value,
      message: message,
      sharedContentList: sharedContent,
      fileAttachments: fileAttachments,
    }

    try{
      const response = await submitServiceRequest(requestContent)
      NotificationBubble.fire({
        icon: "success",
        title: "Solicitud enviada"
      })
      // TODO: redirect to user services page
      // console.log(response)
    } catch (error) {
      NotificationBubble.fire({
        icon: "error",
        title: "Error al enviar solicitud"
      })
      console.log(error)
    }    
  }

  const loadSharedContentInfo = (info) => {
    let newList = [...sharedContent]
    newList[selectedSharedContentBox].title = info.title
    newList[selectedSharedContentBox].type = info.type
    newList[selectedSharedContentBox].language = info.language
    newList[selectedSharedContentBox].words = info.words
    newList[selectedSharedContentBox].content_id = info.content_id
    newList[selectedSharedContentBox].content_specific_id = info.content_specific_id

    setSharedContent(newList)
  }

  const addSharedContent = () => {
    setSharedContent([...sharedContent, newSharedContent_Empty])
  }

  const removeSharedContent = (index) => {
    setSharedContent(sharedContent.filter((content, i) => i !== index))
  }


  const openContentSharePopup = (index) => {
    setSelectedSharedContentBox(index)
    setReferenceContentPopup(true)
  }

  const closeContentSharePopup = () => {
    setReferenceContentPopup(false)
    setSelectedSharedContentBox(null)
  }

  const removeFileAttachment = (index) => {
    setFileAttachments(fileAttachments.filter((item, i) => i !== index))
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0]
    let newList = [...fileAttachments]

    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function(e) {
      const file_info = {
        // id: fileAttachment_ID_sequence,
        name: file.name,
        size: file.size,
        type: file.type,
        // extension: file.name.split('/')[0],
        data: reader.result
      }
      // console.log(file)
      newList.push(file_info)
      setFileAttachments(newList)
    }

    const inputField = document.getElementById('file_input_field')
    inputField.value = ""
  }

  const readableBytes = (bytes) => {
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round((bytes / Math.pow(1024, i)) * 10, 2) / 10 + ' ' + sizes[i]
  }


  //MARK: Render
  return (
      <GeneralStyle>
        <ButtonsStyle>
          <div className="container">
            <div className="leftNullContainer"></div>

            <div className="intContainer">
              <div className="title-n-select">
                <h1 className="title">Solicitar ilustración</h1>
              </div>

              <hr className='separador'/>
          
              <div className="text advertencia misma-linea">
                <img className="info_btn margin-right-10"></img>
                <p  className="sin-margen">Puedes dar acceso anticipado de lectura a tus creaciones para que el ilustrador las utilice como referencia.</p>
              </div>

              <h3 className="subtitle">Descripción</h3>
              <textarea type="text" name="message" id="message" className="textareaInput" placeholder="Descripción" maxLength="1000"
                  value={message} onChange={(e) => {setMessage(e.target.value);  setCharacterDescriptionCount(e.target.value.length)}}/>
              <p className='count'>{characterDescriptionCount}/1000</p>

              <hr className='separador'/>

              <div className="misma-linea">
                <h3 className="subtitle margin-right-10">Permiso de acceso</h3>
                <button className="add-element-button-small" onClick={() => addSharedContent()}></button>
              </div>

              <div className="flex-column gap-10">
                {sharedContent.map((content, index) => (
                  <AccessPermissionItemComponent
                    index = {index}
                    openContentSharePopup = {openContentSharePopup}
                    removeSharedContent = {removeSharedContent}
                    words = {`${content.words}`}
                    language = {`${content.language.name}`}
                    type = {`${content.type.name}`}
                    title = {`${content.title}`}
                  />
                ))}
              </div>

              <hr className='separador'/>

              <div className="misma-linea">
                <h3 className="subtitle">Referencias</h3>              
                <input type="file" name="file_input_field" id="file_input_field" accept="image/*" className="reference-input-hidden inputfile inputfile-reference" onChange={(e) => handleFileUpload(e)} max="1"/>
                <label htmlFor="file_input_field">
                  <span className="subtitle upload_button_text"></span>
                </label>
                {/* <button onClick={() => {console.log(fileAttachments)}}>test</button> */}
              </div>
              
              <TagContainer>
                <div className="tag-list">
                  {fileAttachments.map((item, index) => (
                    <ImageReferenceItemComponent
                      index = {index}
                      item = {item}
                      readableBytes = {readableBytes}
                      removeFileAttachment = {removeFileAttachment}
                    />
                  ))}
                </div>
              </TagContainer>

              <hr className='separador'/>

              <div className="misma-linea">
                <h3 className="subtitle margin-right-10">Servicios</h3>
              </div>

              <div className="flex-column gap-10">
                {illustrationServiceList.map((service, index) => (
                  <IlustrationServiceItemComponent
                    editable = {true}
                    index = {index}
                    service = {service} 
                    serviceName = {service.name} 
                    serviceDescription = {service.description}
                    serviceImg = {service.img}
                    servicePrice = {service.price}
                    selectService = {selectService}     
                    selectedService_index = {selectedService_index}           
                  />
                ))}
              </div>

            <hr className='separador'/>

            <div className="btns">
              <button className='solicitarServicio_btn circleButton' title="Solicitar servicio" type="submit" onClick={() => submitRequest()}></button>
            </div>
            </div>

          </div>
          
        { 
          referenceContentPopup &&
          <AttachReferenceContent
            closePopup={() => closeContentSharePopup()}
            loadContent={(info) => loadSharedContentInfo(info)}
          />
        }
        </ButtonsStyle>
      </GeneralStyle>
  );
}
