import React, { useState, useEffect } from "react";

import {getUserRoleServiceList, updateUserRoleServiceList} from '../../../../api/service';
import { useAuth } from "../../../../context/AuthContext";
import {aplicarAjusteAltura} from '../../../../functions/textAreaHeight';
import EditorServiceItemComponent from './EditorServiceItemComponent';

// import { useParams } from 'react-router-dom';


export default function EditorServices() {
  const { user } = useAuth();

  const [roleDescription, setRoleDescription] = useState("")

  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    roleDescription ? roleDescription.length : 0
  );

  useEffect(() => {
    if (roleDescription) {
      setCharacterDescriptionCount(roleDescription.length);
    }
  }, [roleDescription]);

  // const emptyGeneralService = {serviceTitle: "", serviceDescription: "", servicePrice: "10000"};
  const emptyPerWordFee = {service_name: null, service_description: null, service_price: 100};

  // const [generalServiceList, setGeneralServiceList] = useState([{serviceTitle: "", serviceDescription: "", servicePrice: "10000"}])
  const [editorServiceList, setEditorServiceList] = useState([
    // {service_name: "serv name", service_description: "serv desc", service_price: "10"}
  ])

  // MARK: - useEffect
  useEffect(() => {
    // const ttt = true;
    // if (ttt) return; // remove this line to enable the useEffect


    if(!user) return
      const fetchData = async () => {
        try{
          const response = await getUserRoleServiceList(user.id, 2);
          if(response.data.services) {
            const initializeServices = response.data.services.map(service => {
              return {
                service_name: service.service_name,
                service_description: service.service_description,
                service_price: service.price
              }
            })
            setEditorServiceList(initializeServices);
            // setRoleDescription(response.data.user_role_description.description);
          }
          if(response.data.user_role_description) {
            setRoleDescription(response.data.user_role_description);
          }
        } catch (error) {
          console.log(error)
        }
      }
      fetchData()
  }, [user])

  const updateListOfServices = async () => {

    const newServiceList = editorServiceList.map((service) => {
      return {
        name: service.service_name,
        description: service.service_description,
        price: service.service_price,
      }
    })

    const msg = {
      user_id: user.id,
      role_id: 2, // 2 is the role_id for editor
      new_service_list: newServiceList,
      role_description: roleDescription// {description: roleDescription},
    }

    try{
      const response = await updateUserRoleServiceList(msg);
      // console.log(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const test = () => {
    console.log(editorServiceList)
  }

  const updateRoleDescription = (e) => {
    setRoleDescription(e.target.value)
  }  

  const addService = () => {
    setEditorServiceList([...editorServiceList, emptyPerWordFee])
  }

  const removeService = (index) => {
    setEditorServiceList(editorServiceList.filter((fee, i) => i !== index))
  }


  const updateServiceName = (index, e) => {
    const newServiceList = [...editorServiceList];
    newServiceList[index].service_name = e.target.value;
    setEditorServiceList(newServiceList);
  }

  const updateServiceDescription = (index, e) => {
    const newServiceList = [...editorServiceList];
    newServiceList[index].service_description = e.target.value;
    setEditorServiceList(newServiceList);
  }

  const updatePricePerWord = (index, e) => {
    const newServiceList = [...editorServiceList];
    newServiceList[index].service_price = e.target.value;
    setEditorServiceList(newServiceList);
  }

  useEffect(() => {
    aplicarAjusteAltura('textarea.textareaInputMedium', 200, true);
  }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded


  // MARK: - Render
  return (
      <div className="flex-row">
        <div className="width-100">

          <h3 className="subtitle">Descripción</h3>
          <textarea type="text" name="message" id="message" className="textareaInput textareaInputMedium" placeholder="Descripción" maxLength="1000"
              value={roleDescription} onChange={(e) => {updateRoleDescription(e);  setCharacterDescriptionCount(e.target.value.length)}}/>
          <p className='count'>{characterDescriptionCount}/1000</p>

          <hr className='separador'/>

          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Servicios</h3>
            <button className="add-element-button-small" onClick={() => addService()}></button>
            {/* <button onClick={() => test()}>Test</button> */}
          </div>

          {/* {editorServiceList.length <= 0 && <>
              <div>No hay servicios</div>
            </>
          } */}
          {editorServiceList && editorServiceList.map((fee, index) => (
              <EditorServiceItemComponent
                index={index}
                editorServiceList={editorServiceList}
                updateServiceName={updateServiceName}
                updateServiceDescription={updateServiceDescription}
                updatePricePerWord={updatePricePerWord}
                removeService={removeService}
              />
          ))}

          <hr className='separador'/>

            <div className="btns">
                <button className="save_btn circleButton" onClick={() => updateListOfServices()}></button>
            </div>
        </div>
        <div className="sin-ancho">
        </div>
      </div>
  );
}
