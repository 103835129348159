//import logo from './logo.svg';
import './App.css';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
// import {useEffect, useState} from 'react';


import LoginComponent from './components/auth-components/LoginComponent';
import RegisterComponent from './components/auth-components/RegisterComponent';
import ProtectedRouteComponent from './components/ProtectedRouteComponent';
import { AuthProvider } from './context/AuthContext';
import HeaderComponent from './components/navbar-components/HeaderComponent';
import FooterComponent from './components/navbar-components/FooterComponent';

import CreateNewStory from './components/story-components/CreateNewStory';
import WriteChapter from './components/chapter-components/WriteChapter';
import UserCreations from './components/UserCreations/UserCreations';
import StoryInfo from './components/story-components/StoryInfo';
import CreateSheet from './components/infosheet-components/InfosheetEditor';
import ReadChapter from './components/chapter-components/ReadChapter';
// import InfosheetGallery from './components/infosheet-components/InfosheetGallery_old';
import InfosheetViewer from './components/infosheet-components/InfosheetViewer';
import Homepage from './components/homepage-components/Homepage';
import TranslateChapter from './components/translator-components/TranslateChapter';
import StoryOverview from './components/story-components/StoryOverview';

import EditImage from './components/UserCreations/MyIlustrations/ViewImage/EditImage';
import ViewImage from './components/UserCreations/MyIlustrations/ViewImage/ViewImage';

import TranslateInfosheet from './components/translator-components/TranslateInfoSheet';
import HomeComponent from './components/HomeComponent';
import CreateServices from './components/user-services-components/create-service/CreateServices';

import ViewServiceRequestList from './components/user-services-components/view-service-requests/ViewServiceRequestList';
import ViewTranslationRequest from './components/user-services-components/view-service-requests/review-request/ReviewTranslationRequest';
import ViewIllustrationRequest from './components/user-services-components/view-service-requests/review-request/ReviewIllustrationRequest';
import ViewEditionRequest from './components/user-services-components/view-service-requests/review-request/ReviewEditionRequest';


import TestingComponent from './components/testing/TestingComponent';

import TranslationRequest from './components/user-services-components/request-service/translation-request/TranslationRequest'
import IllustrationRequest from './components/user-services-components/request-service/illustration-request/IllustrationRequest';
import EditionRequest from './components/user-services-components/request-service/edition-request/EditionRequest';

import UserSettings from './components/user-settings/UserSettings';
import UserProfile from './components/user-profile/UserProfile';
import UserAccount from './components/user-profile/profile-pages/UserAccount';

import UsersCatalog from './components/user-catalog-components/UserCatalog';


function App() {
  return (
    <AuthProvider>
      <div className='App'>
        <BrowserRouter>
          <HeaderComponent/>
          <TestingComponent/>

          <Routes>
            <Route path='/' element={<Homepage/>} />
            <Route path='/stories' element={<Homepage />} />
            <Route path='/login' element={<LoginComponent />} />
            <Route path='/register' element={<RegisterComponent />} />
            <Route path='/:story_id/:chapter_id/read' element={<ReadChapter />} />

            <Route path='/:story_id/info' element={<StoryInfo />} />
            {/* <Route path='/infosheetgallery' element={<InfosheetGallery />} /> */} 
            <Route path='/infosheetviewer/:infosheet_id' element={<InfosheetViewer />} />
            <Route path='/:story_id/overview' element={<StoryOverview />} />
            <Route path='/myservices/:serviceType' element={<CreateServices />} />

            <Route path='/reviewtransrequest/:service_req_id' element={<ViewTranslationRequest />} />
            <Route path='/reviewillustrequest/:service_req_id' element={<ViewIllustrationRequest />} />
            <Route path='/revieweditrequest/:service_req_id' element={<ViewEditionRequest />} />


            <Route path='/viewrequestlist' element={<ViewServiceRequestList />} />
            <Route path='/translatechapter' element={<TranslateChapter />} />
            <Route path='/translateinfosheet' element={<TranslateInfosheet />} />


            <Route path='/illustrationrequest/:provider_id' element={<IllustrationRequest />} />
            <Route path='/editionrequest/:provider_id' element={<EditionRequest />} />
            <Route path='/translationrequest/:provider_id' element={<TranslationRequest />} />

            <Route path='/usersettings' element={<UserSettings />} />
            <Route path='/:user_id/:page_type' element={<UserProfile />} />
            <Route path='/:user_id' element={<UserProfile />} />
            <Route path='/user/:user_id' element={<UserAccount />} />

            <Route path='/userscatalog/:serviceType' element={<UsersCatalog />} />

           {/* si no está logueado manda a login */}
            <Route element={<ProtectedRouteComponent/>}>            
              <Route path='/newstory' element={<CreateNewStory />} />
              <Route path='/newsheet' element={<CreateSheet />} />
              <Route path='/infosheet/:infosheet_id' element={<CreateSheet />} />
              <Route path='/:story_id/:chapter_id/edit' element={<WriteChapter />} />

              <Route path='/mycreations/:contentType' element={<UserCreations />} />
              <Route path='/mycreations/myimages/:img_id' element={<EditImage />} />
              <Route path='/mycreations/myimages/preview/:img_id' element={<ViewImage />} />


              <Route path='/protegida' element={<h3>Ruta protegida</h3>} />
              <Route path='/task' element={<h3>Task</h3>} />
            </Route>
          </Routes>
          
          <FooterComponent/>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}
export default App;
