import styled from "styled-components";

import { colors } from "./colors";

const GeneralStyle = styled.div`
    /*##################################################### CONTENEDORES EXTERNOS #############################################################*/
    //contenedor externo, con 1100px de ancho como máximo
    .container{ 
        display: flex;
        flex-direction: row;
        justify-content: flex-start; /* Alinea los elementos internos a la izquierda */
        min-height: 100vh;
        max-width: 1200px;
        width: 75vw;
        margin: 40px auto;
    };

    //contenedor interno, con 900px de ancho como máximo
    .intContainer{
        max-width: 900px;
        width: 100%;
        margin: 0px auto;
        // margin-left: 60px;
    }

    .leftNullContainer{
        width: 60px;
    }

    .leftNullContainer-40{
        width: 40px;
    }

    .leftNullContainer-90{
        width: 90px;
    }

    /*############################################# BOTONES PARA SELECCIONAR IMAGEN ###################################################*/
    //boton para seleccionar imagen (banner y portada)
    .img_btn {
        height: 100%;
        margin: 0px;
        padding: 0px;
        border: 1px solid ${colors.c5};
        border-radius: 10px; //bordes redondeados
        max-height:250px;
        cursor: pointer;
        background: none;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    //boton para seleccionar cover, para dar margen de 10px a la derecha
    .cover_img_btn {
        margin-right: 20px;
    }
    .cover_img {
        max-width: 100%;
        max-height: 100%;
        margin: 0px;
        padding: 0px;
        border-radius: 10px;
    }

    //boton para seleccionar imagen (banner y portada) con foco (al hacer click)
    .img_btn:hover {
        //border: 1px solid ${colors.c2};
    }

    .ancho-minimo-cover{
        min-width: 100px;
    }

    .show-comments-btn{
        background: none;
        margin: 0px;
        font-size: 12px;
        color: ${colors.c4};
        text-align: left;
        border: none;
        cursor: pointer;
        padding: 0px;
    }
    /*############################################# TEXTOS DE TÍTULO Y DEMÁS ###################################################*/
    //títulos
    .title{
        text-align: left;
        font-size: 24px;
        color: ${colors.c1};
        margin: 10px 0px;
    }

    //subtítulos
    .subtitle{
        text-align: left;
        font-size: 16px;
        color: ${colors.c3};
        margin: 10px 0px;
        font-weight: bold;
    }
    .subtitle:focus{
        outline: none;
    }

    .time{
        text-align: right;
        font-size: 12px;
        color: ${colors.c4};
        margin: 0px;
        min-width: 150px;
    }

    //contador de caracteres
    .count{
        text-align: right;
        font-size: 12px;
        color: ${colors.c5};
        height: 10px;
        margin-top: 10px;
    }

    .sinAlto{
        height: 0;
    }

    //texto propuesto
    ::placeholder {
        color:  ${colors.c5};
    }

    //texto normal
    .text{
        margin: 0px;
        font-size: 12px;
        color: ${colors.c3};
    }


    //texto claro
    .text-light{
        margin: 0px;
        font-size: 12px;
        color: ${colors.c5};
        // font-style: italic; 
    }

    //texto medio claro
    .text-medium-light{
        margin: 0px;
        font-size: 12px;
        color: ${colors.c4};
    }

    .numericInput{
        text-align: left;
        border: 1px solid ${colors.c5};
        color: ${colors.c3};
        margin: 0px;
        padding: 0px 10px;
        font-size: 12px;
        height: 30px;
        width: 70px;
        text-align: left;
        padding: 0px 10px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }


    .numericInput:focus {
        outline: none;
    }

    .advertencia{
        color: ${colors.c0};
        background: ${colors.c1};
        text-align: left;
        padding: 10px;
        margin-bottom: 30px;
    }

    .sharedContentTitle{
        margin: 0px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 15px;
        text-align: left;
        width: 100%;
        min-width: 54px;
    }

    .title-service-min-width{
        min-width: 100px;
    }

    .description-service-min-width{
        min-width: 170px;
        // white-space: pre-line;
    }

    p{
        // white-space: pre-line; // Esto causaba problemas en el editor de tiptap en ciertos computadores. 
    }

    .price-service-min-width{
        min-width: 50px;
    }

    .img-service-min-width{
        min-width: 90px;
    }
    /*###################################################### INPUTS ############################################################*/
    //inputs de una sola línea
    .inlineInput{
        text-align: left;
        border: 1px solid ${colors.c5};
        color: ${colors.c3};
        margin: 0px;
        padding: 0px 10px;
        font-size: 12px;
        height: 30px;
        width: calc(100% - 20px);
    }

    //inputs de una sola línea con foco (al hacer click)
    .inlineInput:focus {
        //border: 1px solid ${colors.c2};
        //border: 1px solid ${colors.c3};
        outline: none; /* Elimina el contorno predeterminado del input en algunos navegadores */
    }

    //inputs de una sola línea con hover (al pasar el mouse)
    .inlineInput:hover {
        //border: 1px solid ${colors.c2};
        //border: 1px solid ${colors.c3};
    }

    //linea separadora horizontal
    .separador{
        margin-top: 10px;
        margin-bottom: 30px;
        border-top: 1px;
        border-color: ${colors.c5};
    }

    //inputs de multiples líneas
    .textareaInput{
        text-align: left;
        border: 1px solid ${colors.c5};
        color: ${colors.c3};
        width: 100%;
        padding: 10px;
        font-size: 12px;
        margin: 0px;
        height: 150px;
        resize: none; /* Evita la capacidad de redimensionar */
        box-sizing: border-box; /* Incluye el padding y el borde en el tamaño total */
    }

    //inputs de multiples líneas con foco (al hacer click)
    .textareaInput:focus{
        outline: none;
    }

    .subtitleInput{
        text-align: left;
        border: 1px solid ${colors.c5};
        color: ${colors.c3};
        margin: 0px;
        padding: 0px 10px;
        font-size: 16px;
        height: 30px;
        // font-weight: semi-bold;
    }

    .subtitleInput:focus {
        outline: none;
    }

    .inputHover:hover {
        outline: none;
        background: ${colors.c7};
    }

    .textareaRead{
        text-align: left;
        // border: 1px solid ${colors.c5};
        border: none;
        color: ${colors.c3};
        width: 100%;
        padding: 10px;
        font-size: 12px;
        margin: 0px;
        height: 150px;
        resize: none; /* Evita la capacidad de redimensionar */
        box-sizing: border-box; /* Incluye el padding y el borde en el tamaño total */
        background-color: ${colors.c7};
    }

    .textareaRead:focus{
        outline: none;
    }

    //tags

    //buscador de tags
    .tagInput{
        text-align: left;
        border: 1px solid ${colors.c5};
        color: ${colors.c3};
        margin: 0px;
        padding-left: 40px;
        padding-right: 10px;
        font-size: 12px;
        height: 30px;
        width: 150px;
        border-radius: 10px;
        margin-right: 10px;
        text-transform: lowercase;
    }

    //buscador de tags con foco (al hacer click)
    .tagInput:focus {
        outline: none;
    }

    //texto propuesto para buscador
    .tagInput::placeholder {
        text-transform: none;
    }

    .lupa{
        position: absolute;
        margin: 10px;    
    }

    //buscador general
    .buscadorInput{
        text-align: left;
        border: 1px solid ${colors.c7};
        background-color: ${colors.c7};
        color: ${colors.c3};
        margin: 0px;
        padding-left: 40px;
        padding-right: 10px;
        font-size: 12px;
        height: 34px;
        width: 100%;
        border-radius: 10px;
        text-transform: lowercase;
        box-sizing: border-box; /* Incluye el padding y el borde en el tamaño total */
    }

    .buscadorInput:focus {
        outline: none;
    }

    //texto propuesto para buscador
    .buscadorInput::placeholder {
        text-transform: none;
    }

    .search-bar-container{
        align-items: center;
        margin-bottom: 10px;
    }
    
    /*################################################# OPCIONES DESPLEGABLES ###########################################################*/
    //opción desplegable
    select{
        //appearance: none; /* Elimina los estilos predeterminados */
        box-shadow: none; 
        text-align: left;
        border: none;
        color: ${colors.c3};
        margin: 0px;
        padding: 0px 10px;
        font-size: 12px;
        height: 30px;
        width: 'auto';
        outline: none;
        cursor: pointer;
        border: 1px solid ${colors.c5};
    }

    .selectContainer {
        text-align: right;
        display: flex;
        gap: 10px;
        justify-content: space-around;
    }

    .title-n-select{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }



    .icon{
        height: 11px;
    }

    .S-icon{
        width: 9px;
    }

    .mismoAncho{
        flex: 1;
        flex-basis: 0;
    }

    .margin-auto{
        margin: auto !important;
    }

    .align-left{
        text-align: left !important;
    }

    .align-right{
        text-align: right !important;
    }

    .align-center{
        text-align: center;
        align-items: center;
    }

    .justify{        
        text-align: justify;
    }

    .justify-content-end{
        justify-content: flex-end;
    }

    .center{
        text-align: center;
    }

    .align-content-center{
        align-content: center;
    }

    .flex-column {
        width: 100%;
        display: flex;
        flex-direction: column; 
    }

    .flex-row {
        width: 100%;
        display: flex;
        flex-direction: row; 
        //space between
        // justify-content: space-between;
    }

    .flex-start{
        justify-content: flex-start;
    }

    .underline{
        text-decoration: underline;
    }

    .manito{
        cursor: pointer !important;
    }

    
    .sin-margen{
        margin: 0px;
    }

    .margin-top-0{
        margin-top: 0px;
    }

    .margin-top-5{
        margin-top: 5px;
    }

    .margin-top-10{
        margin-top: 10px;
    }

    .margin-top-20{
        margin-top: 20px;
    }

    .margin-bottom-0{
        margin-bottom: 0px;
    }

    .margin-bottom-5{
        margin-bottom: 5px;
    }

    .margin-bottom-10{
        margin-bottom: 10px;
    }

    .margin-bottom-20{
        margin-bottom: 20px;
    }

    .margin-bottom-30{
        margin-bottom: 30px;
    }
    


    .margin-right-5{
        margin-right: 5px;
    }

    .margin-right-10{
        margin-right: 10px;
    }

    .margin-right-20{
        margin-right: 20px;
    }

    .margin-right-auto{
        margin-right: auto;
    }

    .margin-left-10{        
        margin-left: 10px; 
    }

    .margin-left-20{
        margin-left: 20px;
    }

    .sin-borde{
        border: none;
    }

    .cursiva{
        font-style: italic;
    }

    .misma-linea{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .sin-ancho{
        display: flex;
        width: 0px;
    }

    .sin-padding{
        padding: 0px !important;
    }

    .gap-10{
        gap: 10px;
    }

    .gap-20{
        gap: 20px;
    }

    .gap-5{
        gap: 5px;
    }



    .display-block{
        display: block;
    }

    .padding-top-10{
        padding-top: 10px !important;
    }

    .padding-bottom-10{
        padding-bottom: 10px !important;
    }

    .padding-10{
        padding: 10px;
    }

    .margin-auto{
        margin: auto;
    }

    .width-100{
        width: 100%;
    }

    .width-120px{
        width: 120px;
    }

    .font-weight-bold{
        font-weight: bold;
    }

    .font-weight-normal{
        font-weight: normal;
    }

    .border-c1{
        border: 1px solid ${colors.c1};
    }

    .width-200{
        width: 200px;
    }

    .absolute{
        position: absolute;
    }

    .max-height-250{
        max-height: 250px;
    }

    .sin-overflow{
        overflow: hidden;
    }

    //texto que sobre 200px complete con ...
    .ellipsis-15{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 10%;
        max-width: 250px;
    }

    .ellipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ellipsis-180{
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 180px;
    }

    .flex-wrap{
        flex-wrap: wrap;
    }

    .margin-left-right-150{
        padding: 0px 150px;
    }

    .overfloy-y-auto{
        overflow-y: auto;
    }

    .overflow-y-hidden{
        overflow-y: hidden;
    }

    .overflow-y-auto{
        overflow-y: auto;
    }


    .flex-1{
        flex: 1;
    }

    .flex-2{
        flex: 2;
    }

    .flex-3{
        flex: 3;
    }

    .flex-4{
        flex: 4;
    }

    .height-100{
        height: 100%;
    }

    .sin-alto{
        height: 0px;
    }

    .margin-top-40{
        margin-top: 40px;
    }

    .margin-bottom-10{
        margin-bottom: 10px;
    }

    .height-90{
        height: 90%;
    }

    .max-width-300{
        max-width: 300px;
    }

    .max-height-30{
        max-height: 70px;
    }

    .max-height-150{
        max-height: 150px;
    }

    .height-300{
        height: 300px;
    }

    .max-height-300{
        max-height: 300px;
        box-sizing: border-box;
    }

    .min-width-150px{
        min-width: 150px;
    }


    .border-box{
        box-sizing: border-box;
    }

    .fondo-gris{
        background-color: ${colors.c7};
        border-radius: 10px;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 50px;
        // text-align: left;
    }

    .fondo-blanco{
        background-color: ${colors.c0};
        border-radius: 10px;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 50px;
        border: 1px solid ${colors.c5};
        display: flex;
        flex-direction: row;

    }

    .fondo-blanco-borde{
        background-color: ${colors.c0};
        // border-radius: 10px;
        padding: 5px;
        border: 1px solid ${colors.c5};
    }

    .fondo-gris-borde{
        background-color: ${colors.c7};
        border-radius: 10px;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid ${colors.c5};
    }

    .flex-end{
        justify-content: flex-end;
    }

    .flex-start{
        justify-content: flex-start;
    }

    .flex-center{
        justify-content: center;
    }

    .file_attachment_preview{
        max-width: 90px;
        max-height: 90px;
    }

    .block{
        display: block !important;
    }

    .sin-borde{
        border: none !important;
    }

    .white{
        color: ${colors.c0} !important;
    }

    .miImage{
        max-width: 900px;
        // max-height: 500px;
        display: block;
        width: 100%;
        height: 100%
        margin-left: auto;
        margin-right: auto;
    }

    .BannerImage{
        border: 2px solid ${colors.c1};
    }

    .CircleImage{
        border-radius: 50%;
    }

    .image-xs{
        height: 30px;
        weigth: 30px;
    }

    .image-s{
        height: 50px;
        weigth: 50px;
    }

    .desplegarOpciones:hover img{
        border: 2px solid ${colors.c2};
    }

    .desplegarOpciones:hover p{
        color: ${colors.c2};
    }

    .z-index-1{
        z-index: 1;
    }

    .start{
        align-self: start;
    }

    .self_center{
        align-self: center;
    }

    .left-container-fijo{
        position: fixed;
        box-sizing: border-box;
        max-width: 150px;
        // background-color: ${colors.c1};
        z-index: 1;
        height: 100%;
        // word-break: break-all;
    }

    .word-break{
        word-break: break-all;
    }

    .leftNullContainer-150{
        min-width: 150px;
    }

    .sinOutline{
        outline:none;
    }
    

    
`;

export default GeneralStyle;