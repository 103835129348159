import React, { useEffect, useState } from "react";
import {aplicarAjusteAltura} from '../../../../../functions/textAreaHeight';

export default function ReviewOtherServiceItemComponent({index, text, words}) {

    
    useEffect(() => {
        aplicarAjusteAltura('textarea.max-height-250');
      }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded
    

  return (
    <div className="misma-linea fondo-gris" key={index}>
        <div className="flex-column">
            <div className="additional_text">
                <textarea 
                    className="textareaRead max-height-250" 
                    value={text}
                    readOnly
                />
            </div>
                
            <div className="misma-linea justify-content-end ">
                <div className="margin-right-10">
                    <p className="sin-margen align-right count font-weight-bold">Palabras</p>
                </div>
                <p className="sin-margen align-right count">{words}</p> 
            </div>
        </div>

        {/* <div className="margin-right-10">
            <button className="remove-element-btn" onClick={() => props.removeAdditionalText(props.index)}></button>
        </div> */}
    </div>
  );
}