export default function IlustrationServiceItemComponent({editable, index, service, serviceName, serviceDescription, serviceImg, servicePrice, selectedService_index, selectService}) {

    return (
        <div className="misma-linea fondo-gris gap-10 flex-wrap" key={index}>
            <div className="flex-row flex-1">
                {editable && 
                    <div className="misma-linea flex-1 gap-10">
                        <div className="misma-linea">
                            <input 
                                className="radio"
                                type="radio" 
                                id={`option${index}`} 
                                name="options" 
                                onChange={() => selectService(service, index)}
                                checked={selectedService_index === index}
                            />
                            <div>
                                <p className="text font-weight-bold align-left title-service-min-width">{`${serviceName}`}</p>
                            </div>
                        </div>
                    </div>
                }

                {!editable &&
                    <div className="misma-linea flex-1">
                        <p className="text font-weight-bold align-left title-service-min-width">{`${serviceName}`}</p>
                    </div>
                }
            </div>

            <div className="flex-row flex-4 gap-10">
                {/* <textarea type="text" name="message" id="message" className="textareaInput textareaInputSmall overflow-y-hidden" placeholder="Descripción" maxLength="280"
                value={`${serviceDescription}`} disabled/> */}
                <div className="misma-linea flex-3 flex-wrap">
                    <p className="text align-left description-service-min-width">{`${serviceDescription}`}</p>
                </div>

                <div className="misma-linea flex-1 gap-10"> 
                    <div className="text flex-1 img-service-min-width">
                        <img className="file_attachment_preview" src={serviceImg} alt="service reference"/>
                    </div>

                    <div className="misma-linea flex-1 flex-wrap flex-end price-service-min-width">
                        <p className="text align-center">{` $${servicePrice}`}</p>
                    </div>
                </div>
            </div>


      </div>
    );
  }
  