import React, {useEffect, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';

import {getInfosheetsFromUser} from "../../../api/content";
import { BACKEND_HOST } from "../../../api/host";

// import InfosheetGallery from "../../infosheet-components/InfosheetGallery";
import ItemCard from "../../general-components/ItemCard";

import defaultPortrait from "../../../img/sinPortada01.png";
import ButtonsStyle from "../../style/buttonsStyle";
import lupa from "../../../img/lupa.png";
import SelectedInfosheetsStyle from "../../chapter-components/AttachInfosheet/style/SelectedInfosheetsStyle";
import ItemCardStyle from "../../general-components/style/itemCardStyle";
import PrivateInfosheetOverlay from "../MyInfosheets/InfosheetCardOverlay/PrivateInfosheetOverlay";

import { useAuth } from "../../../context/AuthContext";

export default function MyInfosheets() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [infosheetList, setInfosheetList] = useState([])
  /**
   * Objects in infosheetList are as follows:
    {
      'infosheet_id', infosheet.infosheet_id,
      'type_id', infosheet.id_type,
      'type_name', infosheet_type.name,
      'language_id', infosheet.id_language,
      'language_name', language.name,
      'public_title', infosheet.public_title,
      'private_title', infosheet.private_title,
      'cropped_reference', f_retrieve_content_reference(infosheet.infosheet_id,7),
      'cropped_image_id', cropped.id_image
    }
   */

  useEffect(() => {
    if (!user) return
    // would the use local sotrage look like this? ${localStorage.getItem('user_id')}
    const fetchData = async () => {
      try {
        const response = await getInfosheetsFromUser(user.id)
        setInfosheetList(response.data)
      } catch (err) {
        console.log('Error with getting infosheet list: ', err)
      }
    }
    fetchData()
  }, [user])

  const handleClick = (infosheet) => {
    console.log(infosheet.private_title)
    navigate(`/infosheet/${infosheet.infosheet_id}`)
  }

  const sourceImg = (infosheet) => {
    if (infosheet.cropped_reference) {
      const imgsrc = BACKEND_HOST + (infosheet.cropped_reference).substring(1)
      return imgsrc
    } else {
      return defaultPortrait
    }
  }

  return (
    <ButtonsStyle>
      <div className="flex-column">
        <div className="flex-row max-height-30">
          <div className="width-100">
            <div className='search-bar-container margin-bottom-20 max-width-300'>
              <img className="lupa" alt="Lupa" src={lupa}/>
              <input 
                  type="text" 
                  className='buscadorInput'
                  placeholder="Buscar historia..."
              />
            </div>
          </div>
          <div className="sin-ancho">
            <div className="btns">
              <Link to='/newsheet'>
                <button className="add-element-button" title="Nueva ficha"></button>
              </Link>
            </div>
          </div>
        </div>

        <SelectedInfosheetsStyle>
          <ItemCardStyle>
            <div className="gallery-container card-img-grande">
              {infosheetList.map(infosheet => (
                <ItemCard 
                  key = {infosheet.infosheet_id} 
                  id={infosheet.infosheet_id} 
                  item_title={infosheet.public_title}
                  img={sourceImg(infosheet)}
                  overlay={
                    <PrivateInfosheetOverlay 
                      id={infosheet.infosheet_id}
                      title={infosheet.public_title}
                      private_title={infosheet.private_title}
                      author={"author name"}
                      // desc={story.story_description}
                      // tags={story.tag_list}
                      partes={"60 de 75 partes publicadas"}
                      stats={"7842 Lecturas - 234 Votos"}
                    />
                  }
                  handleClick = {() => handleClick(infosheet)}
                >
                </ItemCard>
              ))}
            </div>
          </ItemCardStyle>
        </SelectedInfosheetsStyle>
      </div>
    </ButtonsStyle>
  );
}

