import React, { useEffect, useState } from "react";

import {getUsersCatalog} from "../../../api/content";
import { useAuth } from "../../../context/AuthContext";

import { useNavigate } from "react-router-dom";

import UserCatalogInfoItemComponent from "../UserCatalogItemInfoComponent";
import IlustrationServiceItemComponent from "../../user-services-components/request-service/illustration-request/IlustrationServiceItemComponent";


export default function WriterCatalog (prop) {
  const { user } = useAuth()

  const navigate = useNavigate()

  const [writerList, setWriterList] = useState([
    {
      user_id: 21,
      user_name: "John Doe (with paper id)",
      user_description: "Aliquam auctor nunc a magna malesuada feugiat",
      user_languages: ["English", "Spanish", "Italian"],
      user_price_range: {min: 10, max: 20},
      user_rating: 4.5,
      user_reviews: 10,
      user_image: "https://via.placeholder.com/50",
      service_list: [
        { service_name: "Eng a Spa", service_price: 10 },
        { service_name: "Spa a Eng", service_price: 5 },
        { service_name: "Spa a Ita", service_price: 20 },
      ]
    },
  ])


  const languageList = [
    {name:"N/A", id: 0},
    {name:"Español", id:1},
    {name:"English", id:2},
    {name:"Italiano", id:3},
    {name:"Frances", id:4},
  ]

  // Role id's:
  // 1	"escritor"
  // 2	"editor"
  // 3	"traductor"
  // 4	"ilustrador"
  // 5	"músico"
  // 6	"doblador"


  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getUsersCatalog(1)
        setWriterList(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, [])

//   const handleItemClick = (item) => {
//     navigate(`/illustrationrequest/${item.user_id}`)
//   }

  return (
    <div className="flex-row gap-20 flex-wrap">        
      {writerList.map((writer, index) => (
          <div className="misma-linea center">
            <UserCatalogInfoItemComponent
              user={writer}
            //   handleItemClick={handleItemClick}
              index={index}
              user_name={writer.user_name}
              user_image={writer.user_image}
              user_rating='--.--'//{writer.user_rating}
              user_likes='10k'
              user_followers='9k'
              user_jobs='100'
              user_description='Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. 
                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, 
                                ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.'//{editor.user_description}
            //   user_range_price={`Desde $${writer.user_price_range.min} a $${writer.user_price_range.max}`}
            />
            {/* <div className="max-height-300 overflow-y-auto fondo-gris width-100 height-300">
              <div className="flex-column gap-10 start">
                
                {illustrator.service_list.map((service, index) => (
                  <>
                    <IlustrationServiceItemComponent
                      index={index}
                      serviceName={service.service_name}
                      serviceDescription={service.service_description}
                      serviceImg={service.example_reference}
                      servicePrice={service.service_price}
                    />
                    <div>
                      <hr className='separador sin-margen'/>
                    </div>                    
                  </>
                ))}
              </div>
            </div> */}
          </div>
        ))}
    </div>
  )
}
