import React, { useState, useEffect } from "react";

import {getInfosheetsFromChapter} from "../../../../api/content";
import { BACKEND_HOST } from "../../../../api/host";
import SelectedInfosheetsStyle from "../style/SelectedInfosheetsStyle";

import ItemCard from "../../../general-components/ItemCard";
import Overlay from "./InfosheetSelectOverlay";

import defaultPortrait from "../../../../img/sinPortada01.png";


/**
* Objects in infosheetList are as follows:
{
  "infosheet_id": 4,
  "infosheet_ref": "./content/infosheets/4.json",
  "public_title": "the public",
  "infosheet_private_name": "the private",
  "cropped_reference": "./content/infosheets/10_portraitImg.jpeg",
  "infosheet_portrait_original_img": null
  "type_id": 2
}
*/
export default function SelectedInfosheets({ chapter_id, typeFilter, removeInfosheet, includedInfosheets, setIncludedInfosheets}) {
  // typeFilter is a number (as a string) that represents the infosheet type: [0=all, 1=character, 2=item, 3=location]
  const [typeFilteredInfosheets, setTypeFilteredInfosheets] = useState([]);
  // const [loading, setLoading] = useState(false);

  // get_infosheets_from_user returns an array of objects with the following properties
  // {
  //   'infosheet_id',
  //   'type_id',
  //   'type_name',
  //   'language_id',
  //   'language_name',
  //   'public_title',
  //   'private_title',
  //   'cropped_reference',
  //   'cropped_image_id',
  // }
  
  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      try {
        const response = await getInfosheetsFromChapter(chapter_id);
        setIncludedInfosheets(response.data);
        setTypeFilteredInfosheets(response.data);
        // setLoading(false)
      } catch (error) {
        console.log(error);
      }
      // console.log(typeFilter)
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (typeFilter === '0') {
      setTypeFilteredInfosheets(includedInfosheets)
    } else {
      const filtered = includedInfosheets.filter(infosheet => {
        return infosheet.type_id === parseInt(typeFilter)
      })
      setTypeFilteredInfosheets(filtered)
    }
  }, [typeFilter, includedInfosheets])
  

  const sourceImg = (infosheet) => {
    if (infosheet.cropped_reference) {
      const imgsrc = BACKEND_HOST + (infosheet.cropped_reference).substring(1)
      return imgsrc
    } else {
      return defaultPortrait
    }
  }

  const handleItemClick = (id) => {
    // handleSelectedObject(id)
    console.log('id: ', id)
  }


  return (
    <SelectedInfosheetsStyle>
      <div className="gallery-container">
        {/* {loading && <p>Loading...</p>} */}
        {typeFilteredInfosheets.map((infosheet, index) => (
          <ItemCard key = {infosheet.infosheet_id} 
            id={infosheet.infosheet_id} 
            item_title={infosheet.public_title}
            item_private_title={infosheet.private_title}
            img={sourceImg(infosheet)}
            overlay={
              <Overlay 
                id={infosheet.infosheet_id}
                title={infosheet.public_title}
                private_title={infosheet.private_title}
                removeItem={removeInfosheet}
              />
            }
            handleClick = {() => handleItemClick(infosheet.infosheet_id)}
          />
        ))}

        {/* {typeFilteredInfosheets.map((infosheet, index) => (
          <div className="gallery-item" key={index}>
            <button className="gallery-item-button">
              <img className="gallery-item-portrait" 
                src={sourceImg(infosheet)} 
                alt={infosheet.public_title} 
                onClick={() => handleItemClick(infosheet.infosheet_id)}
              />
              <div className="item-title-overlay">
                <p>{infosheet.public_title}</p>
              </div>
              <button className="remove-button" onClick={() => removeInfosheet(infosheet.infosheet_id)}>X</button>
            </button>
          </div>
        ))} */}
      </div>
    </SelectedInfosheetsStyle>
  )
}
