import styled from "styled-components";
import { colors } from "../../style/colors";

const TabBarStyle = styled.div`
    //contenedor tabs, los deja en la misma línea
    .tabContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-around;
        width: 100%;
    };

    //tamaño, color y cursor de los tabs
    .tab{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: ${colors.c5};
        cursor: pointer;
    }

    //linea inferior tabs
    .lineaInfTab{
        border: 1px solid ${colors.c5};
        margin-bottom: 30px;
    }

    //estilo para elementos de tab activa
    .tab.active p {
        color: ${colors.c1};
    }

    .tab.active hr {
        border: 1px solid ${colors.c1};
    }

    //estilos para elementos de tab hover
    .tab:hover p {
        color: ${colors.c2};
    }

    .tab:hover hr {
        border: 1px solid ${colors.c2};
    }

`;

export default TabBarStyle;
