// eslint-disable-next-line no-unused-vars
import React, { useCallback, useState } from "react";
import { FloatingMenu } from '@tiptap/react'
import styled from 'styled-components'

import EditorAttachImage from "./EditorAttachImage";


export default function MiFloatingMenu({ editor, chapter_id }) {
  const [dialog, setDialog] = useState(false);

  const loadImage = (img) => {
    // const msg = {
    //   chapter_id: chapter_id,
    //   image_id: img.imgId,
    // }
    // axios.post(`http://localhost:5000/api/add_image_to_chapter`, 
    //   msg,
    //   {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }
    // ).then((res) => {
    //   console.log(res.data)
    // }).catch((err) => {
    //   console.log(err)
    // })
    const url = img.src
    if (url) {
      editor.chain()
      .focus()
      .setImage({ src: url, title: img.imgId })
      .createParagraphNear()
      .insertContent({ type: 'paragraph' })
      .run()
    }
    toggleDialog()
  }

  const toggleDialog = () => {
    setDialog(!dialog);
  }

  return (
    <>
      {editor &&
      <FloatingMenu 
        editor={editor} 
        tippyOptions={{ duration: 100, hideOnClick: false }} 
      >
        <MiFloatingMenuStyles>
          {!dialog &&
            <button 
              className={(editor.isActive({ textAlign: 'left' }) || editor.isActive({ textAlign: 'justify' })) ? 'miFloatingMenu mFM-Show attach-illustration-btn circleButton' : 'miFloatingMenu mFM-Hide attach-illustration-btn circleButton'} 
              onClick={toggleDialog}
            >
              {/* <b>+</b> */}
            </button>
          }        
        </MiFloatingMenuStyles>                
      </FloatingMenu>}

      {dialog && 
      <EditorAttachImage 
        toggleDialog={toggleDialog}
        loadImage={loadImage}
      />  
      }
    </>
  )
}
const MiFloatingMenuStyles = styled.div`
.miFloatingMenu {
  position: relative;
}

.mFM-Show {
  left: -60px;
}
.mFM-Hide {
  display: none;
}
`
