import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { getImageDetails, uploadImage, updateImageDetails } from "../../../../api/content";
import { changeContentLike } from "../../../../api/service";
import { BACKEND_HOST } from "../../../../api/host";

import styled from "styled-components";

import TagComponent from "../../../tag-components/TagComponent";
import GeneralStyle from "../../../style/generalStyle";
import ButtonsStyle from "../../../style/buttonsStyle";
import UploadImageOverlay from "./UploadImageOverlay";
import OverlayStyle from "../../style/OverlayStyle";

import CommentSection from "../../../user-profile/profile-pages/comment-list/CommentList";

import defaultImg from "../../../../img/pantallaSinImagen02.png";

import { useAuth } from "../../../../context/AuthContext";

export default function ViewImage() {
  const { user } = useAuth();
  const { img_id } = useParams();

  const navigate = useNavigate();
  
  const [fullScreenView, setFullScreenView] = useState(false)

  const [imgData, setImgData] = useState(defaultImg)
  const [inputFile, setInputFile] = useState(null)
  const [tagList, setTagList] = useState([])
  const [imgDetails, setImgDetails] = useState({
    img_id: img_id, 
    img_title: "",
    img_description: "",
    img_url: "",
    image_likes: "",
    image_views: "",
    browsing_user_liked: "",
  })

  const [characterTitleCount, setCharacterTitleCount] = useState(
    imgDetails.img_title ? imgDetails.img_title.length : 0
  );

  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    imgDetails.img_description ? imgDetails.img_description.length : 0
  );

  useEffect(() => {
    if (!user) return
    
    const id = img_id.split('.')[0]
    const fetchData = async () => {
      try {
        const response = await getImageDetails(id, user.id)
        console.log(response.data)
        setImgDetails(
          {
            img_id: response.data.id_image,
            img_title: response.data.title,
            img_description: response.data.description || '',
            img_url: response.data.image_url,
            image_likes: response.data.likes,
            image_views: response.data.views,
            browsing_user_liked: response.data.my_like,
          }
        )
        setCharacterTitleCount(response.data.title.length);
        if(response.data.description){
          setCharacterDescriptionCount(response.data.description.length);
        }
        setTagList(response.data.tag_list.filter(tag => tag != null))
        setImgData("existing_image")
      }
      catch (error) {
        console.log('Error:', error)
      }
    }
    fetchData()
  }, [user, img_id])

  const toggleLikeForContent = async () => {
    try {
      const result = await changeContentLike({
        user_id: user.id,
        content_type_id: 1,
        content_specific_id: imgDetails.img_id,
        like_value: !imgDetails.browsing_user_liked,
      })
      console.log(result)
      setImgDetails({...imgDetails, browsing_user_liked: !imgDetails.browsing_user_liked})
    } catch (error) {
      console.error('Error toggling like for content', error)
    }
  }


  const openImage = () => {
    if (imgData !== defaultImg) {
      setFullScreenView(true)
    } else {

    }
  }


  const goRead = (id) => {
    ///:story_id/overview
    navigate(`/mycreations/myimages/${id}`)
  }

  const [isHovered, setIsHovered] = useState(false);

  // MARK: - Render
  return (
    <GeneralStyle>
      <ButtonsStyle>
        <ViewImageContainer>
          {fullScreenView && 
            <div className="overlay" onClick={() => setFullScreenView(false)}>
              <div className="overlay-content">
                <img 
                  className="img-display-fullscreen"
                  src={imgDetails.img_url} 
                  alt={`img_${img_id}`}
                />
              </div>
            </div>
          }
          <div className="container">
            {/* <div className="leftNullContainer"></div> */}

            <div className="intContainer">
              <div className="title-n-select">
                <h1 className="subtitle">{`${imgDetails.img_title}`}</h1>

              </div>

              {/* <h3 className="subtitle">Título</h3> */}
    
              {/* <hr className='separador'/>  */}

              <div className="view_image_container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <img className="img-display center margin-auto" src={imgDetails.img_url}  alt={`img_${img_id}`} onClick={openImage}></img>
              </div>

              <button className='btn-1' onClick={() => toggleLikeForContent()}>
                Like  
                {imgDetails.browsing_user_liked && 
                  <div>🖤</div>
                }
              </button>
              
              <hr className='separador'/> 

              <h3 className="subtitle">Descripción</h3>
              <div className="description_text_area">
                {`${imgDetails.img_description}`}
              </div>
                
              {/* <p className='count'>{characterDescriptionCount}/1000</p> */}

              <hr className='separador'/> 
                    
              <div className="tags">
                <h3 className="subtitle">Etiquetas</h3>
                <TagComponent 
                  tagList={tagList} 
                  setTagList={setTagList} 
                  id="img-tags" 
                  type="tags" 
                  placeholder="Buscar..."
                  editable={false}
                />
              </div>

              <CommentSection 
              content_type_id={1}
              content_specific_id={img_id}
              user={user}
            />

            </div>

          </div>
          <div className="comment_section_container">
            
          </div>

        </ViewImageContainer>
      </ButtonsStyle>
    </GeneralStyle>
  );
}

const ViewImageContainer = styled.div`
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  cursor: zoom-out;
  align-items: center;
}
.overlay-content {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow-x: auto;
}

.img-display-fullscreen {
  // position: absolute;
  // display: flex;
  // flex-shrink: 0; 
}

.upload_image_container{
  position: relative; 
  width: 100%;
  max-height: 100%;
  max-width: 100%; /* Ancho del contenedor */
  min-height: 300px;
}

.view_image_container{
  position: relative; 
  width: 100%;
  max-height: 100%;
  max-width: 100%; /* Ancho del contenedor */
}

.img-display {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  // cursor: zoom-in;
  border-radius: 10px;
}

.upload_image_overlay {
  position: absolute; /* Posición absoluta con respecto al contenedor */
  top: 0;
  left: 0;
  width: 100%; /* Ocupar todo el ancho del contenedor */
  height: 100%; /* Ocupar toda la altura del contenedor */
  background-color: rgba(221, 221, 221, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  border-radius: 10px;
}

.description_text_area {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}
`