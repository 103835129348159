import React, { useState, useEffect } from 'react';

import {getStoriesFromUser} from "../../../api/content";
import { BACKEND_HOST } from '../../../api/host';
import { Link, useNavigate } from 'react-router-dom';

import lupa from "../../../img/lupa.png";
import ButtonsStyle from '../../style/buttonsStyle';

import Gallery from '../../general-components/CardGallery';
import ItemCard from '../../general-components/ItemCard';
import PrivateStoryOverlay from './StoryCardOverlay/PrivateStoryOverlay';
import SelectedInfosheetsStyle from '../../chapter-components/AttachInfosheet/style/SelectedInfosheetsStyle';
import ItemCardStyle from '../../general-components/style/itemCardStyle';

import defaultImg from '../../../img/sinPortada01.png';
import { useAuth } from '../../../context/AuthContext';

export default function MyStories() {
  const { user } = useAuth();
  const navigate = useNavigate();
  // This is the structure of the data that you will be receiving from the server.
  // [
  //   {
  //     'story_id', story.id_story,
  //     'language_id', story.id_language,
  //     'language_name', language.name,
  //     'state_id', story.id_state,
  //     'state_name', story_state.name,
  //     'story_title', story.title,
  //     'tag_list', tag_list,
  //     'genre_list', genre_list,
  //     'cover_id', cropped_cover.id_image,
  //     'cover_reference', f_retrieve_content_reference(story.id_story,5)
  //   },
  //   ...
  // ]
  const [stories, setStories] = useState([]);

  useEffect(() => {
    if (!user) return

    const fetchData = async () => {
      try {
        const response = await getStoriesFromUser(user.id)
        setStories(response.data)
        console.log(response.data)
      } catch (err) {
        console.log('Error with getting story list: ', err)
      }
    }
    fetchData()
  }, [user]);

  const sourceImg = (story) => {
    if (story.coverImgURL) {
      // const imgsrc = BACKEND_HOST + (story.cover_reference).substring(1)
      return story.coverImgURL
    } else {
      return defaultImg
    }
  }

  const handleClick = (story) => {
    navigate(`/${story.story_id}/info`)
    console.log(story.story_title)
  }

  return (
    <ButtonsStyle>
      <div className='flex-column'>
        <div className='flex-row max-height-30'>
          <div className='width-100'> 
            <div className='search-bar-container margin-bottom-20 max-width-300'>
              <img className="lupa" alt="Lupa" src={lupa}/>
              <input 
                type="text" 
                className='buscadorInput'
                placeholder="Buscar historia..."
              />
            </div>
          </div>
          <div className='sin-ancho'>
            <div className='btns'>
              {/* <h1>Mis Historias</h1> */}
              <Link to='/newstory'>
                <button className='add-element-button' title='Nueva historia'></button>
              </Link>
            </div>
          </div>
        </div>
        
        <SelectedInfosheetsStyle>
          <ItemCardStyle>
              <div className="gallery-container card-img-grande">
                {stories.map(story => (
                  <ItemCard key = {story.story_id} 
                    id={story.story_id} 
                    item_title={story.story_title}
                    img={sourceImg(story)}
                    overlay={
                      <PrivateStoryOverlay
                        id={story.story_id}
                        title={story.story_title}
                        author={"author name"}
                        // desc={story.story_description}
                        tags={story.tag_list}
                        parts={"60 de 75 partes publicadas"}
                        stats={{likes: 0, views: 0}}
                      />
                    }
                    handleClick = {() => handleClick(story)}
                  />
                ))}
              </div>
          </ItemCardStyle>
        </SelectedInfosheetsStyle>
      </div>
    </ButtonsStyle>
  );
}


