import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {getServiceRequestsByUser} from "../../../api/service";
import styled from "styled-components";

import GeneralStyle from "../../style/generalStyle";

import TabBar from "../../utils/TabBar";
import { useAuth } from "../../../context/AuthContext";

import RequestList from "./ServiceRequestList/ServiceRequestList";
import ButtonsStyle from "../../style/buttonsStyle";

export default function ViewServiceRequestList() {
  const { user } = useAuth();
  const navigate = useNavigate();
  // const location = useLocation();


  const categories = [
    // { name: "escritura", id: 1 },
    { name: "Illustrations", icon:'tabBarIlustrador_btn bubble-menu-btn circleButton', id: 4 }, 
    { name: "Translations", icon:'tabBarTraductor_btn bubble-menu-btn circleButton', id: 3 },
    { name: "Editions", icon:'tabBarEditor_btn bubble-menu-btn circleButton', id: 2 }, 
    // { name: "música", id: 5 },
    // { name: "doblaje", id: 6 },
  ];

  const [requestType, setRequestType] = useState("sent");
  const [selectedServiceType, setSelectedServiceType] = useState(
    // { name: "Editions", icon: "", id: 2}
    // { name: "Translations", icon: "", id: 3 }
    { name: "Illustrations", icon: "", id: 4 }
  );

  const [serviceRequestList, setServiceRequestList] = useState([
    {
      service_request_id : "",
      requester_id : "",
      requester_name : "",
      provider_id : "",
      provider_name : "",
      provider_role_id : "",
      provider_role_name : "",
      state_id : "",
      state_name : "",
      original_language_id : "",
      original_language_name : "",
      destination_language_id : "",
      destination_language_name : "",
      initial_price : "",
      current_price : "",
    }
  ]);

  // const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (!user) return;
    
    let reqParams = {
      provider_role_id: selectedServiceType.id,
    }

    if (requestType === 'sent') {
      reqParams['requester_id'] = user.id;
    } else if (requestType === 'received') {
      reqParams['provider_id'] = user.id;
      // reqParams = {
      //   provider_id: user.id,
      // }
    }

    const fetchData = async () => {
      try {
        const response = await getServiceRequestsByUser(reqParams);
        setServiceRequestList(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user, requestType, selectedServiceType]);

  const handleTabChange = (index) => {
    setSelectedServiceType(categories[index]);
    console.log('Tab changed to:', index);
  }

// 'service_request_id', service_request.id_service_request,
// 'requester_id', service_request.id_requester,
// 'requester_name', requester.name,
// 'provider_id', service_request.id_provider,
// 'provider_name', provider.name,
// 'provider_role_id', service_request.id_provider_role,
// 'provider_role_name', role.name,
// 'state_id', service_request.id_state,
// 'state_name', service_request_state.name,
// 'original_language_id', service_request.id_original_language,
// 'original_language_name', original_language.name,
// 'destination_language_id', service_request.id_destination_language,
// 'destination_language_name', destination_language.name,
// 'initial_price', service_request.initial_price,
// 'current_price', service_request.current_price

  const reviewServiceRequest = (serviceRequest) => {
    console.log(serviceRequest.provider_role_name);
    switch (serviceRequest.provider_role_name) {
      case "editor":
        navigate(`/revieweditrequest/${serviceRequest.service_request_id}`);
        break;
      case "traductor":
        navigate(`/reviewtransrequest/${serviceRequest.service_request_id}`);
        break;
      case "ilustrador":
        navigate(`/reviewillustrequest/${serviceRequest.service_request_id}`);
        break;
      default:
        break;
    }
  }

  const changeRequestType = (e) => {
    setRequestType(e.target.value);
  }

  return (
    <GeneralStyle>
      <ButtonsStyle>
        <div className="container">
          <div className="intContainer">
            <div className='category-header'>
              <div className='title-n-select'>
                <h1 className='title'>Mis solicitudes</h1>
                <select name="category" id="category" onChange={(e) => changeRequestType(e)}>
                  <option value="sent">Realizadas</option>
                  <option value="received">Recibidas</option>
                </select>
              </div>

              <hr className='separador'></hr>

              <TabBar 
                categories={categories} 
                onTabChange={handleTabChange}
                defaultTab= {categories.findIndex((category) => category.id === selectedServiceType.id)}
                // defaultTab={0}
              />
            </div>
              <RequestList className
                serviceRequestList={serviceRequestList}
                reviewServiceRequest={reviewServiceRequest}
                mode={requestType}
              />
          </div>
        </div>
      </ButtonsStyle>
    </GeneralStyle>
  );
}

const ViewServiceRequestsContainer = styled.div`

.page_container {
  display: flex;
  flex-direction: column;
  
  align-items: center;
  // padding: 1rem;
  // border: 1px solid red;
  outline: 3px solid red;
}
.hr {
  width: 100%;
  border: 1px solid black;
}

.title_bar {
  display: flex;
  flex-direction: row;
}

.tab_bar {
  width: 100%;
}

.middle_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 1rem;
  // border: 1px solid red;
}

.request_list {
  width: 100%;
}

`