import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getUserCommunityPosts, submitCommunityPost, changeContentLike } from "../../../api/service";

import styled from "styled-components";
import { useAuth } from "../../../context/AuthContext";

import CommentList from "./comment-list/CommentList";
import NewUserPostComponent from "./NewUserPostComponent";
import ReviewUserPostComponent from "./ReviewUserPostComponent";


export default function UserPosts({targetUser}) {
  const { user } = useAuth() // browsing user

  const TEST_ROLE_TAGS = [1, 2]

  const [newCommunityPost, setNewCommunityPost] = useState({
    title: '',
    content: '',
    role_id_tags: [],
  })


  const [userPostList, setUserPostList] = useState([
    {
      community_post_id: 1,
      username: 'user1',
      title: 'post title',
      content: 'bla bla bla bla bla bla bla bla bla bla bla bla ', 
      date: '2021-09-01',
      like_count: 5,
      comment_count: 2,
      displayComments: false,
    },
    {
      username: 'user1',
      title: 'post title1',
      content: 'bla bla bla bla', 
      date: '2021-08-01',
      like_count: 5,
      comment_count: 2,
      displayComments: false,
    },
  ])


  // MARK: useEffect
  useEffect(() => {
    // if (!user) return;
    fetchTargetUserPosts()
  }, [
    // user, 
    targetUser
  ])
    
  const fetchTargetUserPosts = async () => {
    try {
      const result = await getUserCommunityPosts( targetUser.user_id, user.id, TEST_ROLE_TAGS)

      const processedPosts = result.data.map((post) => {
        // console.log(post)
        return {
          user_name: post.user_name,
          title: post.title,
          content: post.content,
          date: post.date_modif,
          like_count: post.likes,
          liked_by_current_user: post.my_like,
          comment_count: post.comment_count,
          displayComments: false,
          community_post_id: post.community_post_id,
          time_elapsed_since_posted: post.time_elapsed_since,
          my_like: post.my_like,
        }
      })

      setUserPostList(processedPosts)

    } catch (error) {
      console.error('Error fetching user posts', error)
    }
  }

  const submitPost = async () => {
    console.log('submitting post')
    try {
      const test_tags = [1, 2]
  
      console.log('submitting post', newCommunityPost)
      const result = await submitCommunityPost({
        user_id: user.id,
        post_title: newCommunityPost.title,
        post_content: newCommunityPost.content,
        role_id_tags: test_tags,
        user_modif: user.id,
      })

      fetchTargetUserPosts()

    } catch (error) {
      console.error('Error submitting post', error)
    }
  }

  const updateNewPostTitle = (e) => {
    setNewCommunityPost({...newCommunityPost, title: e.target.value})
  }

  const updateNewPostContent = (e) => {
    setNewCommunityPost({...newCommunityPost, content: e.target.value})
  }

  const toggleLikeForContent = async (index) => {
    try {
      const result = await changeContentLike({
        user_id: user.id,
        content_type_id: 5,
        content_specific_id: userPostList[index].community_post_id,
        like_value: !userPostList[index].liked_by_current_user,
      })
      console.log(result)
      const updatedPosts = userPostList.map((post, i) => {
        if (i === index) {
          if (post.liked_by_current_user) {
            return {...post, like_count: post.like_count - 1, liked_by_current_user: !post.liked_by_current_user}
          } else {
            return {...post, like_count: post.like_count + 1, liked_by_current_user: !post.liked_by_current_user}
          }
        }
        return post
      })
      setUserPostList(updatedPosts)
    } catch (error) {
      console.error('Error toggling like for content', error)
    }
  }

  const toggleDisplayPostComments = (index) => {
    const updatedPosts = userPostList.map((post, i) => {
      if (i === index) {
        return {...post, displayComments: !post.displayComments}
      }
      return post
    })
    setUserPostList(updatedPosts)
  }

  // MARK: Render
  return (
    <UserPostsContainer>
      <div>
        { (user.id === targetUser.user_id) && 
          <NewUserPostComponent
            user={user}
            submitPost={submitPost}
            newCommunityPost={newCommunityPost}
            updateNewPostTitle={updateNewPostTitle}
            updateNewPostContent={updateNewPostContent}
          />
        }

        <div className="flex-column gap-10 margin-top-10">
          {userPostList.map((post, index) => (
            <ReviewUserPostComponent
              key={index} 
              index={index}
              user={user}
              user_name={post.user_name}
              time_elapsed_since_posted={post.time_elapsed_since_posted}
              title={post.title}
              content={post.content}
              displayComments={post.displayComments}
              post={post}
              toggleLikeForContent={toggleLikeForContent}
              toggleDisplayPostComments={toggleDisplayPostComments}
              myLike = {post.my_like}
            />
          ))}
        </div>
      </div>
    </UserPostsContainer>
  );
}

const UserPostsContainer = styled.div`
.post-item-card {
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}
.post-header {
  display: flex;
  justify-content: space-between;
  outline: 1px solid red;
}
.user-name-card {
  display: flex;
  align-items: center;
  outline: 1px solid green;
}
.post-options {
  outline: 1px solid blue;
}
.post-content {
  display: flex;
  flex-direction: column;
  outline: 1px solid yellow;
}
.post-footer {
  display: flex;
  justify-content: space-between;
  outline: 1px solid purple;
}
.post-comments {
  cursor: pointer;
  outline: 1px solid orange;
}
`
