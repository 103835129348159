import styled from "styled-components";

import { colors } from "../../style/colors";

const ItemCardStyle = styled.div`
    .gallery-item {
        display: flex;
        // flex: 0 0 190px; /* Adjust the item width and margin */
        // max-width: 163px;
        // width: 300px;
        position: relative;
        cursor: pointer;
        // border: 2px solid ${colors.c1};
        border-radius: 10px;
        box-sizing: border-box;
        margin-left: 2px;
        margin-top: 2px;
    }

    .item-content{
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
    }

    /*No se estáutilizando, porque las card no tienen footer por defecto, solo al pasar el cursor por encima
    .item-footer {
        position: absolute;
        // bottom: 4px;
        left: 0;
        right: 0;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        //alinear al centro
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        background-color: rgba(221, 221, 221, 0.8);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    */

    .card_img {
        max-width: 100%;
        max-height: 100%;
        width: 188px;
        min-width: 100px;
        margin: 0px;
        padding: 0px;
        border-radius: 10px;
        display: flex;
        // background: red;
    }

    .card-img-grande .card_img{
        width: 208px;
        background: red;
    }

    .public-title{
        max-width: 130px;
        color: ${colors.c1};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
    }

    .private-title{
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
        font-weight: bold;
        text-align: left;
    }

    .gallery-item:hover .item-overlay {
        opacity: 1;
    }
    
    .gallery-item:hover .item-footer {
        opacity: 0;
    }

`;

export default ItemCardStyle;