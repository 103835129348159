import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function UserCatalogInfoItemComponent({
    index, 
    user,
    user_name, 
    user_image, 
    user_likes, 
    user_followers, 
    user_jobs,
    handleItemClick,
    user_rating, 
    user_description,
    user_range_price,
}) {
    const navigate = useNavigate();


    const handleViewProfileClick = () => {
        // console.log("handleViewProfileClick: ", user)
        navigate(`/${user.user_id}`);
    }
    
    return (
        <X>
            <div className='fondo-gris userInfo margin-right-10' key={index}>
                <div className="flex-column">
                    <p className="subtitle">{user_name}</p>
                    <div className="misma-linea">
                        <div className="">
                            <img src={user_image} alt={user_name} className="CircleImage margin-right-10"/>
                        </div>
                        <div className="width-100">
                            <div className="flex-row">
                                <div className="flex-column text margin-right-10">
                                    <p className="sin-margen">Likes</p>
                                    <p className="sin-margen">{user_likes}</p>
                                </div>
                                <div className="flex-column text margin-right-10">
                                    <p className="sin-margen">Seguidores</p>
                                    <p className="sin-margen">{user_followers}</p>
                                </div>
                                <div className="flex-column text">
                                    <p className="sin-margen">Trabajos</p>
                                    <p className="sin-margen">{user_jobs}</p>
                                </div>
                            </div>
                            <button className="textButton margin-top-10 margin-right-10" title="Ver perfil"
                                onClick={() => handleViewProfileClick()}
                            >
                                Ver perfil
                            </button>
                            {handleItemClick &&
                                <button className="solicitarServicio_btn circleButton" title="Solicitar servicio" onClick={() => handleItemClick(user)}></button>
                            }
                        </div>
                    </div>
                    <p className="text align-left margin-top-5">{user_rating} / 5 ★</p>
                    <p className="text justify margin-top-10">{user_description}</p> {/*justify align-left*/}
                    <p className="text-light margin-top-10 margin-bottom-10">{user_range_price}</p>
                    
                </div>
            </div>
        </X>
    );
}

const X = styled.div`
    .userInfo{
        width: 276px;
        height: 300px;
        box-sizing: border-box;
    }
    .CircleImage{
        max-width: 50px;
        max-height: 50px;
    }
`
