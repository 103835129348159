import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { submitComment, getComments } from "../../../../api/service";

import styled from "styled-components";
import { useAuth } from "../../../../context/AuthContext";
import NewContentCommentComponent from "./NewContentCommentComponent";
import ReviewContentCommentComponent from "./ReviewContentCommentComponent";
import UserInfoCardMini from "../../../utils/user-card-popup/UserInfoCardMini";


export default function CommentSection({content_type_id, content_specific_id, user = {}}) {
  // const { user } = useAuth()

  const [newComment, setNewComment] = useState('')
  const [commentList, setCommentList] = useState([
    // {
    //   user_name: 'example_user',
    //   content: 'example_comment',
    //   date: '2021-09-01',
    // },
  ])

  //MARK: first load
  useEffect(() => {

    // console.log(user)
    loadComments()
  }, [])

  // {/* <p>🤍 🖤</p> */}

  const loadComments = async () => {
    try {
      const res = await getComments(content_type_id, content_specific_id)
      setCommentList(res.data)
      // console.log('loadComments:', res.data)
    } catch (error) {
      console.log('loadComments error:', error)
    }
  }

  const submitCommentHandler = async (comment) => {
    try {
      const res = await submitComment({
        user_id: user.id,
        content_specific_id: content_specific_id,
        content_type_id: content_type_id,
        comment_content: newComment,
      })
      loadComments()
      setNewComment('')
    } catch (error) {
      console.log('submitCommentHandler error:', error)
    }
  }

  // MARK: render
  return (
    <CommentsContainer>
      <div>
        <NewContentCommentComponent
          user={user}
          submitCommentHandler={submitCommentHandler}
          newComment={newComment}
          setNewComment={setNewComment}
        />
          
        {commentList.length === 0 && 
          <p className="text align-left cursiva margin-top-10">Aún no hay comentarios para este contenido...</p>
        }

        <div className="flex-column gap-10 margin-top-10">
          {commentList.length > 0 &&
            commentList.map((comment, index) => (
              <ReviewContentCommentComponent
                comment= {comment}
                // user_name={comment.commenter_username}
                // time_elapsed_since_posted={comment.time_elapsed_since}
                // content={comment.content}
                key={index}
              />
            ))            
          }
        </div>
      </div>
    </CommentsContainer>
  )

}

const CommentsContainer = styled.div`
.comment-item-card {
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}
.comment-header {
  display: flex;
  justify-content: space-between;
  outline: 1px solid red;
}
.user-name-card {
  display: flex;
  align-items: center;
  outline: 1px solid green;
}
.comment-options {
  outline: 1px solid blue;
}
.comment-content {
  display: flex;
  flex-direction: column;
  outline: 1px solid yellow;
}
.comment-footer {
  display: flex;
  justify-content: space-between;
  outline: 1px solid purple;
}
`