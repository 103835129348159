import styled from "styled-components";
import { colors } from "../../style/colors";

const WriteChapterStyle = styled.div`

    .tiptap p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    .ProseMirror {
        text-align: left;
        width: 100%;
        min-height: 60vh;
    }
    
    //quitar borde negro a editor de tiptap
    .ProseMirror-focused{
        outline: none;
    }

    //texto por defecto cuando el editor está vacío
    .tiptap p.is-editor-empty:first-child::before {
        margin: 0px;
        font-size: 12px;
        color: ${colors.c5};
      }
`;

export default WriteChapterStyle;
