import styled from "styled-components";
import { colors } from "../../style/colors";

import tabBarEditor01 from "../../../img/tabBar/tabBar-editor-01.png";
import tabBarEditor02 from "../../../img/tabBar/tabBar-editor-02.png";
import tabBarEditor03 from "../../../img/tabBar/tabBar-editor-03.png";
import tabBarEditor04 from "../../../img/tabBar/tabBar-editor-04.png";

import tabBarEscritor01 from "../../../img/tabBar/tabBar-escritor-01.png";
import tabBarEscritor02 from "../../../img/tabBar/tabBar-escritor-02.png";
import tabBarEscritor03 from "../../../img/tabBar/tabBar-escritor-03.png";
import tabBarEscritor04 from "../../../img/tabBar/tabBar-escritor-04.png";

import tabBarFichas01 from "../../../img/tabBar/tabBar-fichas-01.png";
import tabBarFichas02 from "../../../img/tabBar/tabBar-fichas-02.png";
import tabBarFichas03 from "../../../img/tabBar/tabBar-fichas-03.png";
import tabBarFichas04 from "../../../img/tabBar/tabBar-fichas-04.png";

import tabBarIlustrador01 from "../../../img/tabBar/tabBar-ilustrador-01.png";
import tabBarIlustrador02 from "../../../img/tabBar/tabBar-ilustrador-02.png";
import tabBarIlustrador03 from "../../../img/tabBar/tabBar-ilustrador-03.png";
import tabBarIlustrador04 from "../../../img/tabBar/tabBar-ilustrador-04.png";

import tabBarTraductor01 from "../../../img/tabBar/tabBar-traductor-01.png";
import tabBarTraductor02 from "../../../img/tabBar/tabBar-traductor-02.png";
import tabBarTraductor03 from "../../../img/tabBar/tabBar-traductor-03.png";
import tabBarTraductor04 from "../../../img/tabBar/tabBar-traductor-04.png";

const TabBarStyle = styled.div`
    //contenedor tabs, los deja en la misma línea
    .tabContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-around;
        width: 100%;
    };

    //tamaño, color y cursor de los tabs
    .tab{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: ${colors.c5};
        cursor: pointer;
    }

    //linea inferior tabs
    .lineaInfTab{
        border: 1px solid ${colors.c5};
        margin-bottom: 30px;
    }

    //estilo para elementos de tab activa
    .tab.active p {
        color: ${colors.c1};
    }

    .tab.active hr {
        border: 1px solid ${colors.c1};
    }

    
    //estilos para elementos de tab hover
    .tab:hover p {
        color: ${colors.c2};
    }

    .tab:hover hr {
        border: 1px solid ${colors.c2};
    }

    .tabBarEditor_btn{background-image: url(${tabBarEditor02})}
    .tab.active .tabBarEditor_btn{background-image: url(${tabBarEditor04})}
    .tab:hover .tabBarEditor_btn{background-image: url(${tabBarEditor03})}

    .tabBarEscritor_btn{background-image: url(${tabBarEscritor02})}
    .tab.active .tabBarEscritor_btn{background-image: url(${tabBarEscritor04})}
    .tab:hover .tabBarEscritor_btn{background-image: url(${tabBarEscritor03})}

    .tabBarFichas_btn{background-image: url(${tabBarFichas02})}
    .tab.active .tabBarFichas_btn{background-image: url(${tabBarFichas04})}
    .tab:hover .tabBarFichas_btn{background-image: url(${tabBarFichas03})}

    .tabBarIlustrador_btn{background-image: url(${tabBarIlustrador02})}
    .tab.active .tabBarIlustrador_btn{background-image: url(${tabBarIlustrador04})}
    .tab:hover .tabBarIlustrador_btn{background-image: url(${tabBarIlustrador03})}

    .tabBarTraductor_btn{background-image: url(${tabBarTraductor02})}
    .tab.active .tabBarTraductor_btn{background-image: url(${tabBarTraductor04})}
    .tab:hover .tabBarTraductor_btn{background-image: url(${tabBarTraductor03})}

    


`;

export default TabBarStyle;
