import React, {useEffect} from "react"; 
import { FormContainer } from "./style/formStyle";
//import { registerRequest } from "../api/auth";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";

export default function RegisterComponent () {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [errorName, setErrorName] = React.useState("");
    const [errorEmail, setErrorEmail] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");

    const {register, isAuthenticated, errors} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated) navigate("/"); //si está autenticado, lo redirige a la página principal
    }, [isAuthenticated]);

    const validarFormulario = async (e) => {
        e.preventDefault();
    
        let nameError = "";
        let emailError = "";
        let passwordError = "";
    
        if (!/^[a-zA-Z0-9]{5,}$/.test(name)) {
          nameError = "El nombre de usuario debe tener al menos 5 caracteres";
        }
    
        if (!/\w+@\w+\.[a-z]{2,}$/.test(email)) {
          emailError = "El correo debe tener un formato válido";
        }
    
        if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
          passwordError =
            "La contraseña debe tener al menos 8 caracteres, un número y una letra";
        }
    
        setErrorName(nameError);
        setErrorEmail(emailError);
        setErrorPassword(passwordError);
    
        // si no hay errores
        if (!nameError && !emailError && !passwordError) {
          // la llamada a register debe estar dentro de este bloque
          register({ username: name, email, password });
          //navigate("/");
        }
    };

    return(
        <FormContainer>
            <div className="form_container">
                <h1 className="h1_form">Register</h1>
                <p className="p_form">Si ya tienes cuenta, <Link to="/login">Login</Link></p>
                {errors && <div className="div_error"> {errors}</div>}
                <form className="login_form">
                    <div className="div_form">
                        <label htmlFor="nombre" className="label_form">Nombre de usuario</label>
                        <input type="text" id="nombre" className="input_form" placeholder="Nombre de usuario" 
                                onChange={(e)=>{setName(e.target.value)}}/>
                        <p id="error_nombre" className="error">{errorName}</p>
                    </div>
                    <div  className="div_form">
                        <label htmlFor="correo" className="label_form">Correo</label>
                        <input type="text" id="correo" className="input_form" placeholder="nombre@email.com"
                                onChange={(e)=>{setEmail(e.target.value)}}/>
                        <p id="error_correo" className="error">{errorEmail}</p>
                    </div>
                    <div  className="div_form">
                        <label htmlFor="contraseña" className="label_form">Contraseña</label>
                        <input type="password" id="contraseña" className="input_form" placeholder="Contraseña"
                                onChange={(e)=>{setPassword(e.target.value)}}/>
                        <p id="error_contraseña" className="error">{errorPassword}</p>
                    </div>
                    <input type="submit" id="enviar" className="enviar" value="Enviar" onClick={(e)=>{
                        validarFormulario(e);
                    }}/>
                </form>
            </div>
        </FormContainer>
    )
}