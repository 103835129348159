import React, { useState, useEffect } from "react";

import {getInfosheet, getChapter} from "../../../../../api/content";

import InfosheetPreview from "../../../../user-services-components/attach-reference/content-previews/InfosheetPreview";
import ChapterPreview from "../../../../user-services-components/attach-reference/content-previews/ChapterPreview";
import PopupStyle from "../../../../style/popupStyle";

export default function ViewReferenceContent({closePopup, contentInfo}) {
  const [contentTypeList, setContentTypeList] = useState([
    // {name: 'IMAGE', id: 1}, 
    // {name: 'INFOSHEET', id: 2}, 
    // {name: 'STORY', id: 3}, 
    // {name: 'CHAPTER', id: 4}
  ])

  const [previewContent, setPreviewContent] = useState(null);


  useEffect(() => {
    if (!contentInfo) return;
    // console.log('contentInfo: ', contentInfo);
    const fetchData = async () => {
      switch (contentInfo.type.id) {
        case 2: // (infosheet)
          try {
            const response = await getInfosheet(contentInfo.content_specific_id);
            setPreviewContent(response.data);
          } catch (error) {
            console.log(error);
          }
          break;
        case 4: // (chapter)
          try{
            const response = await getChapter(contentInfo.content_specific_id);
            setPreviewContent(response.data);
          } catch (error) {
            console.log(error);
          }
          break;
        default: return null
      }
    }
    fetchData();
  }, [contentInfo])



  const renderPreviewWindow = () => {
    switch (contentInfo.type.id) {
      case 2: // infosheet
        return (
          <>
            { previewContent &&
              <>
                <InfosheetPreview
                  title={previewContent.publicTitle}
                  // privateTitle={previewContent.private_title}
                  content={previewContent.content}
                />
              </>
            }
          </>
        )
      case 4: // chapter
        return (
          <>
            { previewContent && 
              <>
                <ChapterPreview 
                  title={previewContent.chapterTitle}
                  content={previewContent.content}
                />
              </>
            }
          </>
        )
      default: return null
    }
  }

  return (
    <PopupStyle>
      <div className="overlay">
        <div className="popup-containter-n-btns">
          <div className="leftNullContainer-40"></div>

          <div className="popup-container">
            <div className="right-column">
              {renderPreviewWindow()} 
            </div>

          </div>

          <div className="btns-10">
            <button onClick={closePopup} className="close-btn circleButton"></button>            
          </div>
        </div>
      </div>
    </PopupStyle>
  )
}

