import React, { useEffect, useState } from 'react';
import { getChapters, createChapter, swapContentOrder } from '../../api/content';
import { useParams, useNavigate, Link } from 'react-router-dom';

import ChapterItemBox from './StoryEditChapters/ChapterItemBox/ChapterItemBox';

import ChaptersListStyle from './style/chaptersListStyle';

import { useAuth } from '../../context/AuthContext';

import vistas from '../../img/vistas.png';
import meGusta from '../../img/meGusta.png';
import comentarios from '../../img/comentarios.png';
import precio from '../../img/precio.png';

export default function StoryChapterList() {
    const { user } = useAuth();
    const [chapters, setChapters] = useState([]);
    const { story_id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!user) return;
        fetchData();
    }, [story_id, user]);
        
    const fetchData = async () => {
        const msg = {
            user_id: user.id,
            // chapter_state_id: 1,
        }
        try {
            const response = await getChapters(story_id, msg);
            setChapters(response.data); 
            setIsLoading(false);
        }
        catch (error) {
            if (error.response.status === 403) {
                navigate('/');
            }
        }
    }


    const createNewChapter = async () => {
        const msg = {
            user_id: user.id,
            story_id: story_id,
        }
        try{
            const response = await createChapter(msg);
            console.log('Server response:', response.data.message.chapterId)
            //setFirstChapterID(response.data.message.chapterId)
            console.log('New chapter created')
            navigate(`/${story_id}/${response.data.message.chapterId}/edit`)
        } catch (error) {
            console.log('Error with POST req:', error);
        }
    }

    const swapChapterOrder = async (chapter_A, direction) => {
        if (!isAbleToSwap(chapter_A, direction)) return;

        const chapter_B = chapters.find(chap => chap.order === (chapter_A.order + direction));
        const msg = {
            user_id: user.id,
            content_A_id: chapter_A.content_id,
            content_B_id: chapter_B.content_id,
        }
        try{
            const response = await swapContentOrder(msg);
            console.log('Server response:', response.data.message)
            fetchData();
        } catch (error) {
            console.log('Error with POST req:', error);
        }
        // console.log('msg sent')
    }

    const isAbleToSwap = (chapter_A, direction) => {
        if (chapter_A.order === 1 && direction === -1) return false;
        if (chapter_A.order === chapters.length && direction === 1) return false;
        return true;
    }

    
    if (isLoading) {
        return; // O un spinner o indicador de carga
    }
    
    return (
        <ChaptersListStyle>
            {/* <div className='precioContainer text align-left'>
                Precio por capítulo
                <input type="number" id="numero" name="numero" min="0" className='numericInput'></input>
            </div> */}
            <div className='listHeader'>
                <div className='column col-chapter'>Capítulo</div>
                <div className='column col-stats'>
                    <div className='column mismoAncho'>Estado</div>
                    <div className='column mismoAncho'><img src={vistas} alt='vistas' className='icon'/></div>
                    <div className='column mismoAncho'><img src={meGusta} alt='meGusta' className='icon'/></div>
                    <div className='column mismoAncho'><img src={comentarios} alt='comentarios' className='icon'/></div>
                    <div className='column mismoAncho'><img src={precio} alt='precio' className='S-icon'/></div>
                </div>
                <div className='column col-actions'></div>
            </div>
            <div>
                {chapters.map((chapter, index) => (
                    <ChapterItemBox 
                        itemNumber={index + 1}
                        story_id={story_id} 
                        chapter={chapter}
                        key={chapter.chapter_id}
                        swapChapterOrder={swapChapterOrder}
                        isAbleToSwap={isAbleToSwap}
                    />
                    // < Link to={`/${story_id}/${chapter.id_chapter}/edit`} key={chapter.id_chapter}>
                    //     <li >{chapter.chapter_title}</li>
                    // </Link>
                ))}
                <div className='create-new-chapter-box'>
                    <button className='new_chapter_list_btn' onClick={createNewChapter}>+</button>
                </div>
            </div>
        </ChaptersListStyle>
    );
}

