import React, { useEffect, useState } from "react";

import {getUsersCatalog} from "../../../api/content";
import { useAuth } from "../../../context/AuthContext";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import UserCatalogInfoItemComponent from "../UserCatalogItemInfoComponent";
import EditorCatalogServiceCard from "./EditorCatalogServiceCard";


export default function EditorCatalog (prop) {
  const { user } = useAuth()

  const navigate = useNavigate()

  const [editorList, setEditorList] = useState([
    {
      user_id: 21,
      user_name: "John Doe (using paper's id)",
      user_description: "Aliquam auctor nunc a magna malesuada feugiat",
      user_languages: ["English", "Spanish", "Italian"],
      user_price_range: {min: 10, max: 20},
      user_rating: 4.5,
      user_reviews: 10,
      user_image: "https://via.placeholder.com/50",
      service_list: [
        { service_name: "Eng a Spa", service_price: 10 },
        { service_name: "Spa a Eng", service_price: 5 },
        { service_name: "Spa a Ita", service_price: 20 },
      ]
    },
  ])


  const languageList = [
    {name:"N/A", id: 0},
    {name:"Español", id:1},
    {name:"English", id:2},
    {name:"Italiano", id:3},
    {name:"Frances", id:4},
  ]

  // Role id's:
  // 1	"escritor"
  // 2	"editor"
  // 3	"traductor"
  // 4	"ilustrador"
  // 5	"músico"
  // 6	"doblador"


  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getUsersCatalog(2)
        setEditorList(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, [])

  const handleItemClick = (item) => {
    navigate(`/editionrequest/${item.user_id}`)
  }

  return (
    
    <CatalogContainer>
      {/* <h1>Lista de Editores</h1> */}

      <div className="flex-row gap-20 flex-wrap">
        {editorList.map((editor, index) => (
          <div className="misma-linea">
            <UserCatalogInfoItemComponent
              index={index}
              user_name={editor.user_name}
              user_image={editor.user_image}
              user_rating={editor.user_rating}
              user_likes='10k'
              user_followers='9k'
              user_jobs='100'
              user_description='Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. 
                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, 
                                ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.'//{editor.user_description}
              user_range_price={`Desde $${editor.user_price_range.min} a $${editor.user_price_range.max}`}
            />
            <div className="flex-column gap-10 start max-height-300 overflow-y-auto border-box">
              {editor.service_list.map((service, index) => (
                <EditorCatalogServiceCard
                  index={index}
                  service_name={service.service_name}
                  service_description={service.service_description}
                  service_price={`$${service.service_price}`}
                />
              ))}
              <div>
                <button className="textButton" onClick={() => handleItemClick(editor)}>Solicitar</button>
              </div>
              {/* <p>aaa</p> */}
            </div>
          </div>
        ))}
      </div>

      {/* {editorList.map((editor, index) => {
        return (
          <div className="editor_card" key={index}>
            <div className="editor_info">
              <h2>{editor.user_name}</h2>
              <img src={editor.user_image} alt={editor.user_name} />
              <p>{editor.user_description}</p>
              <p>{editor.user_languages.join(', ')}</p>
              <p>Desde: ${editor.user_price_range.min} a ${editor.user_price_range.max}</p>
              <div className="rating_and_reviews">
                <p>{editor.user_rating}★, </p>
                <p>{editor.user_reviews}</p>
              </div>

            </div>

            <div className="editor_services">
              <div className="service_header">
                <h3>Servicios</h3>
              </div>
              <div className="service_list">
                <ul>
                  {editor.service_list.map((service, index) => {
                    return (
                      <div className="service_item" key={index}>
                        <p>{service.service_name}</p>
                        <p>{service.service_description}</p>
                        <p>{service.service_price}</p>
                      </div>
                    )
                  })}
                </ul>
                <button onClick={() => handleItemClick(editor)}>Solicitar un Servicio</button>
              </div>
            </div>
          </div>
        )
      })} */}
    </CatalogContainer>
  )
}

const CatalogContainer = styled.div`
.filter_bar {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  margin: 10px;
}
.editor_card {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}

.editor_card img {
  width: 100px;
  height: 100px;
}

.editor_info {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.editor_services {
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: 1px solid red;
}

.service_item {
  display: flex;
  flex-direction: row;
  outline: 1px solid blue;
}

.service_item p {
  margin: 10px;
}

.rating_and_reviews {
  display: flex;
  flex-direction: row;
}
.hr {
  width: 100%;
  border: 1px solid black;
}

`