import React from "react";
import { Link, useNavigate  } from 'react-router-dom';

import buttonsStyle from "../../../style/buttonsStyle";
//import PrivateOverlayStyle from "../../../UserCreations/style/OverlayStyle";
import OverlayStyle from "../../../UserCreations/style/OverlayStyle";

export default function InfosheetSelectOverlay (props) {
  /**
   * expected props:
   * - title
   * - removeItem (function)
   */

  return(
    <OverlayStyle>
      <div className="item-overlay">
        {/* <div className="overlay-header"> */}
          {/* <div> */}
            <button className="close-btn circleButton-20 item-overlay-button manito" onClick={() => props.removeItem(props.id)}>
              {/* X */}
            </button> 
          {/* </div> */}
          <div className="item-overlay-title-m">
            <p className="subtitle public-title-overlay-s">{props.title}</p>
            <p className="text private-title-overlay">{props.private_title}</p>
          </div>
          {/* <div className="item-footer">
            <p className="subtitle public-title">{props.title}</p>
            <p className="text private-title">{item_private_title}</p>
          </div> */}
        {/* </div> */}

      </div>
    </OverlayStyle>
  )
}
