import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { getUserDetails } from "../../../api/user";
// import defaultImg from "../../../img/default_profile.png";

export default function UserInfoCardMini({ children,  browsing_user_id, target_user_id}) {
  const [userDetails, setUserDetails] = useState({
    user_nickname: 'loading...',
    user_description: 'loading...',
    user_picture: 'https://via.placeholder.com/150',
  });


  useEffect(() => {
    // fetchData();
  }, [userDetails])

  const fetchData = async () => {
    try {
      console.log("target_user_id: ",target_user_id)
      const response = await getUserDetails(target_user_id);
      console.log("response",response.data)
      // userDetails = response.data;
      setUserDetails(response.data);
    } catch (error) { 
      console.log('Error fetching data:', error);
    } finally {
      console.log("userDetails",userDetails)
    }
  }

  const showPopup = (e) => {
    const popup = document.getElementById('popup');
    fetchData()
    // console.log(popup.style.left, popup.style.top)
    // console.log(e.originalTarget.offsetLeft, e.originalTarget.offsetTop)
    const xOffset = (popupWidth + (popupPadding*2) - e.currentTarget.clientWidth)/2;
    // const yOffset = e.originalTarget.offsetHeight;
    const yOffset = popupHeight;

    popup.style.display = 'block';
    popup.style.left = `${e.currentTarget.offsetLeft - xOffset}px`;
    popup.style.top = `${e.currentTarget.offsetTop - yOffset}px`;
  };

  const hidePopup = () => {
    const popup = document.getElementById('popup');
    popup.style.display = 'none';
  };

  const handleTargetMouseEnter = (e) => {
    // console.log("target mouse enter")
    // const t = e.currentTarget
    // console.log(e.currentTarget)
    showPopup(e);
  }

  const handleTargetMouseLeave = (e) => {
    const popup = document.getElementById('popup');
    if (!popup.matches(':hover')) {
      hidePopup();
    }
    // setTimeout(() => {
    // }, 100);
  }

  const handlePopupMouseLeave = (e) => {
    // console.log("popup mouse leave")
    // console.log(e)
    hidePopup();
  }


  return (
    <div >
      <CardContainer>

        <div id="popup" className="popup"
          onMouseLeave={(e) => handlePopupMouseLeave(e)}
        >
          <div className="popup-content">
            <img src={userDetails.user_picture} alt="user profile"/>
            <p>{`${userDetails.user_nickname}`}</p>
            <p>{`${userDetails.user_description}`}</p>
            
          </div>
        </div>

        <div id="hover-target" className="hover-target"
          onMouseEnter={(e) => handleTargetMouseEnter(e)}
          onMouseLeave={(e) => handleTargetMouseLeave()}
        >
          { children }
        </div>
      </CardContainer>

    </div>
  )
}

const popupWidth = 200;
const popupHeight = 200;
const popupPadding = 10;
const CardContainer = styled.div`
.info-card-container {
  // display: inline-block;
  // position: relative;
}
.hover-target {
  display: inline-block;
  // background-color: #007BFF;
  cursor: pointer;
}

.popup {
  display: none;
  position: absolute;
  background-color: #333;
  width: ${popupWidth}px;
  height: ${popupHeight}px;
  color: white;
  padding: ${popupPadding}px;
  border-radius: 5px;
  z-index: 1000;
  white-space: nowrap;
`
