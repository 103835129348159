import styled from "styled-components";
import {TagContainer} from "../../../../tag-components/style/tagStyle";
import ImageReferenceItemComponent from "../../../../user-services-components/request-service/ImageReferenceItemComponent";
export default function NewCommentComponent({user, updateNewCommentText, handleFileUpload, newCommentAttachments, removeFileAttachment, submitMessage, messageTypes, setSelectedMessageType}) {

    const readableBytes = (bytes) => {
        const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB']
        if (bytes === 0) return '0 Byte'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        return Math.round((bytes / Math.pow(1024, i)) * 10, 2) / 10 + ' ' + sizes[i]
    }

    return (
    <XXX>
    <div className="misma-linea fondo-blanco gap-20">
        <div className="felx-column">
            
            <div className="width-100 flex-row margin-bottom-10 gap-10 align-center">
                
                <div className="margin-auto flex-row gap-10 align-center">
                    <img src="https://via.placeholder.com/30" alt="user profile" className="CircleImage " />
                    {user && <p className="text">{`${user.username}`}</p>}
                </div>
                {messageTypes && 
                    <select className="" name="target_type" id="target_type" onChange={(e)=>{setSelectedMessageType(e.target.value)}}>
                        <optgroup label="Tipo mensaje">
                            {messageTypes.map((type, type_index) => (
                                <option key={type_index} value={type_index}>{type.name}</option>
                            ))}
                        </optgroup>
                    </select>
                }
            </div>
            {/* <hr className='separador sin-margen margin-top-10 margin-bottom-10'/> */}

            <div className="flex-row gap-20">

                <textarea 
                    className="textareaInput textareaInputSmall  max-height-30"
                    name="message" 
                    id="message" 
                    cols="200" 
                    rows="3"
                    maxLength="280"
                    placeholder="Escribe un comentario"
                    onChange={(e) => updateNewCommentText(e)}
                />
                
                <div className="margin-auto">
                    <button type="submit" className="save_btn circleButton" onClick={() => submitMessage()}></button>
                </div>
            </div>
            
            {handleFileUpload &&
                <>
                    <hr className='separador sin-margen margin-top-10 margin-bottom-10'/>
                    <div className="misma-linea margin-bottom-10">
                        <h3 className="text font-weight-bold ">Adjuntos</h3>              
                        <input type="file" name="file_input_field" id="file_input_field" accept="image/*" className="reference-input-hidden inputfile inputfile-reference"  onChange={(e) => handleFileUpload(e)} max="1"/>
                        <label htmlFor="file_input_field">
                            <span className="subtitle upload_button_text"></span>
                        </label>
                    </div>
                </>
            }
            <div className="flex-row">
                <TagContainer>
                    <div className="tag-list">
                        {newCommentAttachments && newCommentAttachments.map((file, attach_index) => (
                            <ImageReferenceItemComponent
                                index = {attach_index}
                                item = {file}
                                readableBytes = {readableBytes}
                                removeFileAttachment = {removeFileAttachment}
                            />
                        ))}
                    </div>
                </TagContainer>
            </div>
        </div>
    </div>
    </XXX>
    );
  }

  const XXX = styled.div`

`