import styled from "styled-components";

import { colors } from "../../style/colors";

const ReactGridGalleryStyle = styled.div`

.custom-overlay__caption {
    // background-color: green;
    // max-height: 240px;
    overflow: hidden;
    position: absolute;
    // bottom: 0;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 90%;
    border-radius: 10px;

    
    border: 2px solid ${colors.c1};
    border-radius: 15px;
    top: -2px;
    bottom: 0px;
    left: -2px;
    right: 0px;
  }
  .custom-overlay__tag {
    word-wrap: break-word;
    display: inline-block;
    background-color: white;
    height: auto;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    padding: .2em .6em .3em;
    border-radius: .25em;
    color: black;
    vertical-align: baseline;
    // margin: 2px;
  }

  div.gallery-image-container img {
    -webkit-user-drag: none;
    // user-select: none; /* Para navegadores que requieren prefijo */
  }
  
  .ReactGridGallery_tile-viewport{
    border-radius: 10px;
  }
  
  .ReactGridGallery_tile{
    border-radius: 10px;
    margin: 0px 10px 20px 10px !important;
    // margin-right: 10px !important;
    // margin-left: 10px !important;
  }
  
  .ReactGridGallery{
    // margin-right: 5px;
    // margin-left: 5px;
    // margin-bottom: 10px;
  }
  
  .con-gap{
    display: flex;
    gap: 10px;
  }
  
  //para que la galería sobresalga del contenedor interno
  .gallery-image-container{
    margin-left: -10px;
    margin-right: -10px;
    // background-color: red;
  }


`;

export default ReactGridGalleryStyle;