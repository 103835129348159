import React, { useState, useEffect } from "react";
import {aplicarAjusteAltura} from '../../../../functions/textAreaHeight';

export default function IllustratorServiceItemComponent({
    isEditable = true,
    index, 
    serviceList, 
    updateServiceName,
    updateServiceDescription,
    updateAttachement,
    sourceServiceImg,
    removeAttachement,
    updateServicePrice,
    removeService,
}) {

    useEffect(() => {
        aplicarAjusteAltura('textarea.textareaInputSmall', 90, true);
      }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded
    
  return (
    <div className='misma-linea fondo-gris' key={index}>
        <div className="misma-linea flex-1 margin-right-10">
            {isEditable &&
                <textarea type="text" name="message" id="message" className="textareaInput textareaInputSmall overflow-y-hidden" placeholder="Nombre" maxLength="100"
                    value={serviceList[index].serviceName}  
                    onChange={(e) => updateServiceName(index, e.target.value)}
                />
            }
            {!isEditable &&
                <div className="textareaInput textareaInputSmall overflow-y-hidden">{`${serviceList[index].serviceName}`}</div>
            }
        </div>
        <div className="misma-linea flex-2 margin-right-10">
            {!isEditable && 
                <div className="textareaInput textareaInputSmall overflow-y-hidden">{`${serviceList[index].serviceDescription}`}</div>
            }
            {isEditable &&
                <textarea type="text" name="message" id="message" className="textareaInput textareaInputSmall overflow-y-hidden" placeholder="Descripción" maxLength="280"
                    value={serviceList[index].serviceDescription}  
                    onChange={(e) => updateServiceDescription(index, e.target.value)}
                />
            }
        </div>
        <div className='width-120px margin-right-10'>
            {((!((serviceList[index].attachment.data) || (serviceList[index].attachment.reference))) && isEditable) &&
            <>
                <input type="file" className="reference-input-hidden inputfile  inputfile-service" name={index} id={index} max="1"
                onChange={(e) => updateAttachement(index, e)}
                />
                <label htmlFor={index}>
                    {/* <img className="info_btn margin-right-10"></img> */}
                    <span className="text ">adjuntar ejemplo</span>
                </label>
            </>
            }
            {((serviceList[index].attachment.data) || (serviceList[index].attachment.reference)) && 
            <>
                {isEditable && 
                    <img src={sourceServiceImg(index)} alt="reference" className="file_attachment_preview" onClick={() => removeAttachement(index)}/>
                }
                {!isEditable &&
                    <img src={sourceServiceImg(index)} alt="reference" className="file_attachment_preview"/>
                }
                {/* <button className='remove-btn' onClick={() => removeAttachement(index)}>✖</button> */}
            </>
            }
        </div>

        {isEditable &&
            <>
                <input type="number" className="numericInput"
                    onChange={(e) => updateServicePrice(index, e.target.value)}
                    value={serviceList[index].servicePrice}
                    placeholder="Precio"
                />
                <button className='remove-element-btn' onClick={() => removeService(index)}></button>
            </>
        }
        {!isEditable &&
            <div className="numericInput">{serviceList[index].servicePrice}</div>
        }

    </div>
  );
}
