import React, { useEffect, useState, useRef} from "react";

import InfosheetEditor from "../../../infosheet-components/infosheet-content/InfosheetEditableContent";

export default function InfoSheetPreview({ title, content }) {
  return (
    <div className="width-100">
      <div className="content-title">
        <div className="title margin-top-0 margin-bottom-20">
          {`${title}`}
        </div>
      </div>
      <InfosheetEditor 
        infosheetContent={content}
        lockFields={true}
        editable={false}
      />
    </div>
  )
}

