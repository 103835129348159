import React, { useEffect, useState} from "react"
import { Link, useNavigate } from 'react-router-dom'

import {getImagesFromUser} from '../../../api/content'
import { BACKEND_HOST } from "../../../api/host";

import { Gallery } from "react-grid-gallery";
import PrivateStoryOverlay from "../../UserCreations/MyStories/StoryCardOverlay/PrivateStoryOverlay";
import ReactGridGalleryStyle from "../style/ReactGridGalleryStyle";
import OverlayStyle from "../style/OverlayStyle";
import ButtonsStyle from "../../style/buttonsStyle";

import IlustrationGallery from '../../utils/UserImgGallery';
import ButtonStyle from '../../style/buttonsStyle';
import lupa from "../../../img/lupa.png";
import { useAuth } from "../../../context/AuthContext";

export default function MyIlustrations() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [imgList, setImgList] = useState([]) // [ { src: "", width: 0, height: 0, isSelected: false, caption: "", title: "" }, ...
  
  const handleImgOptionsClick = (imgId) => {
    console.log('image:', imgId)
  }

  useEffect(() => {
    if (!user) return

    const fetchData = async () => {
      try {
        const response = await getImagesFromUser(user.id)
        const formattedImages = response.data.map((imgInfo) => {
          console.log(imgInfo)
          const imgRef = (imgInfo.image_reference).substring(1)
          const entry = {
            src: BACKEND_HOST + imgRef,
            width: imgInfo.width,
            height: imgInfo.height,
            title: imgInfo.title,
            customOverlay: (
              <div className="custom-overlay__caption ">
                <button className="edit_btn_blue circleButton-20 item-overlay-button"
                />
                {/* <button 
                  className="justaButton" 
                  onClick={() => handleImgOptionsClick(imgInfo.id_image)}
                >testbutton</button> */}
                <div className="item-overlay-title-l">
                  <p className="subtitle public-title-overlay">{imgInfo.title}</p>
                  <p className="text info-extra partes">30 julio 2022</p>
                  <p className="text info-extra estadísticas">{`${imgInfo.views} vistas, ${imgInfo.likes} likes`}</p>
                </div>
              </div>
            ),
            imgId: imgInfo.id_image, // You may want to fetch or generate titles for each image
            imgRef: imgRef,
            imgFileName: imgInfo.title,
            // tags: [{value: "tag1", title: "tag1"}, {value: "tag2", title: "tag2"}]
          }
          return entry
        });
        setImgList(formattedImages)
      } catch (error) {
        console.error('Error fetching images:', error)
      }
    }
    fetchData()
  }, [user]);



  const handleClick = (index) => {
    console.log('Going to image')
    // console.log(selectedImgRoute)
    navigate(`/mycreations/myimages/${imgList[index].imgId}`)
  }

  return (
    <ButtonsStyle>
      <div className="flex-column">
        <div className="flex-row max-height-30">
          <div className='width-100'> 
            <div className='search-bar-container margin-bottom-20 max-width-300'>
              <img className="lupa" alt="Lupa" src={lupa}/>
              <input 
                  type="text" 
                  className='buscadorInput'
                  placeholder="Buscar historia..."
              />
            </div>
          </div>
          <div className='sin-ancho'>
            <div className='btns'>
              {/* <h1>Mis Historias</h1> */}
              <Link to='/mycreations/myimages/new'>
                <button className='add-element-button' title="Nueva ilustración"></button>
              </Link>
            </div>
          </div>
        </div>
        <ReactGridGalleryStyle>
          <OverlayStyle>
            <div className="gallery-image-container">
              <ButtonsStyle>
                <Gallery 
                  images={imgList} 
                  enableImageSelection={false} 
                  margin={10} 
                  onClick={handleClick}
                /> 
              </ButtonsStyle>
            </div>
          </OverlayStyle>
      </ReactGridGalleryStyle>
      </div>
      
    </ButtonsStyle>
  );
}
