import styled from "styled-components";

import { colors } from "./colors";

import guardar01 from "../../img/circleButton/guardar-01.png";
import guardar02 from "../../img/circleButton/guardar-02.png";

import eliminar01 from "../../img/circleButton/eliminar-01.png";
import eliminar02 from "../../img/circleButton/eliminar-02.png";

import vistaPreviaTabla01 from "../../img/circleButton/vistaPrevia-01.png";
import vistaPreviaTabla02 from "../../img/circleButton/vistaPrevia-02.png";

import previsualizar01 from "../../img/circleButton/previsualizar-01.png";
import previsualizar02 from "../../img/circleButton/previsualizar-02.png";

import editar01 from "../../img/circleButton/editar-01.png";
import editar02 from "../../img/circleButton/editar-02.png";
import editar03 from "../../img/circleButton/editar-03.png";
import editar04 from "../../img/circleButton/editar-04.png";

import fichas01 from "../../img/circleButton/fichas-01.png";
import fichas02 from "../../img/circleButton/fichas-02.png";

import cerrar01 from "../../img/circleButton/cerrar-01.png";
import cerrar02 from "../../img/circleButton/cerrar-02.png";
import cerrar03 from "../../img/circleButton/cerrar-03.png";

import archivo01 from "../../img/archivo-01.png";
import archivo02 from "../../img/archivo-02.png";

import personaje01 from "../../img/circleButton/personaje1-01.png";
import personaje02 from "../../img/circleButton/personaje1-02.png";
import personaje03 from "../../img/circleButton/personaje1-03.png";

import objeto01 from "../../img/circleButton/objeto-01.png";
import objeto02 from "../../img/circleButton/objeto-02.png";
import objeto03 from "../../img/circleButton/objeto-03.png";

import lugar01 from "../../img/circleButton/lugar-01.png";
import lugar02 from "../../img/circleButton/lugar-02.png";
import lugar03 from "../../img/circleButton/lugar-03.png";

import retroceder01 from "../../img/circleButton/retroceder-01.png";
import retroceder02 from "../../img/circleButton/retroceder-02.png";

import solicitarServicio01 from "../../img/circleButton/solicitarservicio-01.png";
import solicitarServicio02 from "../../img/circleButton/solicitarservicio-02.png";
import solicitarServicio03 from "../../img/circleButton/solicitarservicio-03.png";
import solicitarServicio04 from "../../img/circleButton/solicitarservicio-04.png";

//botones del menú flotante
import b01 from "../../img/bubbleMenu/b-01.png";
import b02 from "../../img/bubbleMenu/b-02.png";
import b03 from "../../img/bubbleMenu/b-03.png";

import i01 from "../../img/bubbleMenu/I-01.png";
import i02 from "../../img/bubbleMenu/I-02.png";
import i03 from "../../img/bubbleMenu/I-03.png";

import s01 from "../../img/bubbleMenu/T-01.png";
import s02 from "../../img/bubbleMenu/T-02.png";
import s03 from "../../img/bubbleMenu/T-03.png";

import u01 from "../../img/bubbleMenu/U-01.png";
import u02 from "../../img/bubbleMenu/U-02.png";
import u03 from "../../img/bubbleMenu/U-03.png";

import h101 from "../../img/bubbleMenu/h1-01.png";
import h102 from "../../img/bubbleMenu/h1-02.png";
import h103 from "../../img/bubbleMenu/h1-03.png";

import h201 from "../../img/bubbleMenu/h2-01.png";
import h202 from "../../img/bubbleMenu/h2-02.png";
import h203 from "../../img/bubbleMenu/h2-03.png";

import h301 from "../../img/bubbleMenu/h3-01.png";
import h302 from "../../img/bubbleMenu/h3-02.png";
import h303 from "../../img/bubbleMenu/h3-03.png";

import AlignLeft01 from "../../img/bubbleMenu/alignLeft-01.png";
import AlignLeft02 from "../../img/bubbleMenu/alignLeft-02.png";
import AlignLeft03 from "../../img/bubbleMenu/alignLeft-03.png";

import AlignCenter01 from "../../img/bubbleMenu/alignCenter-01.png";
import AlignCenter02 from "../../img/bubbleMenu/alignCenter-02.png";
import AlignCenter03 from "../../img/bubbleMenu/alignCenter-03.png";

import AlignRight01 from "../../img/bubbleMenu/alignRight-01.png";
import AlignRight02 from "../../img/bubbleMenu/alignRight-02.png";
import AlignRight03 from "../../img/bubbleMenu/alignRight-03.png";

import AlignJustify01 from "../../img/bubbleMenu/alignJustify-01.png";
import AlignJustify02 from "../../img/bubbleMenu/alignJustify-02.png";
import AlignJustify03 from "../../img/bubbleMenu/alignJustify-03.png";

import xtag01 from "../../img/xtag01.png";
import xtag02 from "../../img/xtag02.png";
import xtag03 from "../../img/xtag03.png";

import adjuntarIlustracion01 from "../../img/circleButton/ilustracion-01.png";
import adjuntarIlustracion02 from "../../img/circleButton/ilustracion-02.png";

import mas01 from "../../img/+01.png";
import mas02 from "../../img/+02.png";

import masChicoA01 from "../../img/+chico-A-01.png";
import masChicoA02 from "../../img/+chico-A-02.png";
import masChicoA03 from "../../img/+chico-A-03.png";
import masChicoA04 from "../../img/+chico-A-04.png";

import masChicoB01 from "../../img/+chico-B-01.png";
import masChicoB02 from "../../img/+chico-B-02.png";
import masChicoB03 from "../../img/+chico-B-03.png";
import masChicoB04 from "../../img/+chico-B-04.png";

import info01 from "../../img/info-01.png";

import meGusta02 from '../../img/megusta02.png'

import seguirHistoria01 from "../../img/seguirHistoria-01.png";
import seguirHistoria02 from "../../img/seguirHistoria-02.png";
import seguirHistoria03 from "../../img/seguirHistoria-03.png";
import seguirHistoria04 from "../../img/seguirHistoria-04.png";

import seguirUsuario01 from "../../img/seguirUsuario-01.png";
import seguirUsuario02 from "../../img/seguirUsuario-02.png";
import seguirUsuario03 from "../../img/seguirUsuario-03.png";
import seguirUsuario04 from "../../img/seguirUsuario-04.png";

import corazon01 from "../../img/corazon1.png";
import corazon02 from "../../img/corazon2.png";
import corazon03 from "../../img/corazon3.png";
import corazon04 from "../../img/corazon4.png";

import libro01 from "../../img/circleButton/libro-01.png";
import libro02 from "../../img/circleButton/libro-02.png";


const ButtonsStyle = styled.div`
    .checkbox{
        cursor: pointer;
        margin: 0px;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        appearance: none;
        border: 1px solid ${colors.c5};
    }

    .checkbox:checked {
        background-color: ${colors.c3};
    }

    .radio{
        cursor: pointer;
        margin: 0px;
        margin-right: 10px;
        min-width: 10px;
        min-height: 10px;
        border-color: red;
        appearance: none;
        border: 1px solid ${colors.c5};
        border-radius: 10px;
    }

    .radio:checked {
        background-color: ${colors.c3};
    }

    .classification {
        text-align: left;
    }

    //botón circular
    .circleButton{
        width: 30px;
        height: 30px;
        // margin-right: 10px;
        background-size: cover;
        border: none;
        vertical-align: middle; 
        background: none;
    }

    //botón circular con hover (al pasar el mouse)
    .circleButton:hover{
        cursor: pointer;
    }

    .circleButton-20{
        width: 20px;
        height: 20px;
        // margin-right: 10px;
        background-size: cover;
        border: none;
        vertical-align: middle; 
        background: none;
    }


    .save_btn{background-image: url(${guardar01}); background-size: cover}
    .save_btn:hover{background-image: url(${guardar02})}
    
    .seguirHistoria_btn{background-image: url(${seguirHistoria01}) ; background-size: cover}
    .seguirHistoria_btn:hover{background-image: url(${seguirHistoria02})}
    
    .desSeguirHistoria_btn{background-image: url(${seguirHistoria04}) ; background-size: cover}
    .desSeguirHistoria_btn:hover{background-image: url(${seguirHistoria03})}
    
    .seguirUsuario_btn{background-image: url(${seguirUsuario01}) ; background-size: cover}
    .seguirUsuario_btn:hover{background-image: url(${seguirUsuario02})}
    
    .desSeguirUsuario_btn{background-image: url(${seguirUsuario04}) ; background-size: cover}
    .desSeguirUsuario_btn:hover{background-image: url(${seguirUsuario03})}
    
    .like_btn{background-image: url(${corazon01}) ; background-size: cover}
    .like_btn:hover{background-image: url(${corazon02})}
    
    .dislike_btn{background-image: url(${corazon04}) ; background-size: cover}
    .dislike_btn:hover{background-image: url(${corazon03})}

    .cancel_btn{}

    .delete_btn{background-image: url(${eliminar01}); background-size: cover}
    .delete_btn:hover{background-image: url(${eliminar02})}

    .fichas_btn{background-image: url(${fichas01})}
    .fichas_btn:hover{background-image: url(${fichas02})}
    .fichas_btn.activo{background-image: url(${eliminar02})}

    .retroceder_btn{background-image: url(${retroceder01})}
    .retroceder_btn:hover{background-image: url(${retroceder02})}

    .solicitarServicio_btn{background-image: url(${solicitarServicio04})}
    .solicitarServicio_btn:hover{background-image: url(${solicitarServicio03})}

    .capitulos_btn{background-image: url(${libro01})}
    .capitulos_btn:hover{background-image: url(${libro02})}

    //libro01

    .follow_btn{background-image: url(${meGusta02}); background-size: cover}
    // .follow_btn:hover{background-image: url(${meGusta02})}

    .btns{
        display: flex;
        // flex-direction: column; /* Alinea los elementos en una columna */
        // padding-top: 10px; /* Espacio interno de los elementos */
        // padding-left: 30px; /* Espacio interno de los elementos */
        gap: 10px; /* Espacio entre los elementos (puedes ajustar según tus necesidades) */
        // height: 240px; //<<---------------------------------------------------------------------------------------revisar
    }

    .btnsVertical{
        display: flex;
        flex-direction: column; /* Alinea los elementos en una columna */
        padding-top: 10px; /* Espacio interno de los elementos */
        padding-left: 30px; /* Espacio interno de los elementos */
        gap: 10px; /* Espacio entre los elementos (puedes ajustar según tus necesidades) */
        position: fixed;
        // height: 240px; //<<---------------------------------------------------------------------------------------revisar
    }

    .btns-10{
        display: flex;
        flex-direction: column; /* Alinea los elementos en una columna */
        // padding-top: 10px; /* Espacio interno de los elementos */
        padding-left: 10px; /* Espacio interno de los elementos */
        gap: 10px; /* Espacio entre los elementos (puedes ajustar según tus necesidades) */
        // height: 240px; //<<---------------------------------------------------------------------------------------revisar
    }

    .user-icon{
        width: 50px;
        height: 50px;
    }

    .preview_btn_table{background-image: url(${vistaPreviaTabla01}); background-size: cover}
    .preview_btn_table:hover{background-image: url(${vistaPreviaTabla02})}

    .preview_btn{background-image: url(${previsualizar01}); background-size: cover}
    .preview_btn:hover{background-image: url(${previsualizar02})}
    
    .edit_btn{background-image: url(${editar01}); background-size: cover}
    .edit_btn:hover{background-image: url(${editar02})}

    .edit_btn_blue{background-image: url(${editar03}); background-size: cover}
    .edit_btn_blue:hover{background-image: url(${editar04})}

    .info_btn{content: url(${info01}); background-size: cover;}


    .new_chapter_list_btn{
        height: 40px;
        width: 100%;
        border: none;
        background-color: ${colors.c5};
        color: ${colors.c3};
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
    }

    .new_chapter_list_btn:hover{background-color: ${colors.c6}}

    .close-btn{background-image: url(${cerrar01}); background-size: cover}
    .close-btn:hover{background-image: url(${cerrar02})}

    // .close-btn:active{
    //     background-image: url(${cerrar03});
    // }

    .personaje-btn{background-image: url(${personaje01})}
    .personaje-btn:hover{background-image: url(${personaje02})}
    .personaje-btn.activo{background-image: url(${personaje03})}

    .objeto-btn{background-image: url(${objeto01})}
    .objeto-btn:hover{background-image: url(${objeto02})}
    .objeto-btn.activo{background-image: url(${objeto03})}

    .lugar-btn{background-image: url(${lugar01})}
    .lugar-btn:hover{background-image: url(${lugar02})}
    .lugar-btn.activo{background-image: url(${lugar03})}
    
    // .close-btn:active{
    //     background-image: url(${cerrar03});
    // }

    .file-btn{
        content: url(${archivo01});
        background-size: cover;
    }

    .file-btn-white{
        content: url(${archivo02});
        background-size: cover;
    }

    // .file-btn:hover{
    //     content: url(${archivo02});
    // }

    //para que las imágenes en botones de bubble menu no se deformen
    .bubble-menu-btn{
        background-size: auto; /* Mantiene el tamaño original de la imagen de fondo */
        background-repeat: no-repeat; /* Evita que la imagen de fondo se repita */
        background-position: center; /* Centra la imagen de fondo dentro del botón */
    }
    
    //botones del menú flotante
    .bold-btn{background-image: url(${b01})}
    .bold-btn.is-active{background-image: url(${b03})}
    .bold-btn:hover{background-image: url(${b02})}

    .italic-btn{background-image: url(${i01})}
    .italic-btn.is-active{background-image: url(${i03})}
    .italic-btn:hover{background-image: url(${i02})}

    .strike-btn{background-image: url(${s01})}
    .strike-btn.is-active{background-image: url(${s03})}
    .strike-btn:hover{background-image: url(${s02})}

    .underline-btn{background-image: url(${u01})}
    .underline-btn.is-active{background-image: url(${u03})}
    .underline-btn:hover{background-image: url(${u02})}

    .h1-btn{background-image: url(${h101})}
    .h1-btn.is-active{background-image: url(${h103})}
    .h1-btn:hover{background-image: url(${h102})}

    .h2-btn{background-image: url(${h201})}
    .h2-btn.is-active{background-image: url(${h203})}
    .h2-btn:hover{background-image: url(${h202})}

    .h3-btn{background-image: url(${h301})}
    .h3-btn.is-active{background-image: url(${h303})}
    .h3-btn:hover{background-image: url(${h302})}

    .align-left-btn{background-image: url(${AlignLeft01})}
    .align-left-btn.is-active{background-image: url(${AlignLeft03})}
    .align-left-btn:hover{background-image: url(${AlignLeft02})}

    .align-center-btn{background-image: url(${AlignCenter01})}
    .align-center-btn.is-active{background-image: url(${AlignCenter03})}
    .align-center-btn:hover{background-image: url(${AlignCenter02})}

    .align-right-btn{background-image: url(${AlignRight01})}
    .align-right-btn.is-active{background-image: url(${AlignRight03})}
    .align-right-btn:hover{background-image: url(${AlignRight02})}
    
    .align-justify-btn{background-image: url(${AlignJustify01})}
    .align-justify-btn.is-active{background-image: url(${AlignJustify03})}
    .align-justify-btn:hover{background-image: url(${AlignJustify02})}

    //boton para adjuntar imagen en capítulo
    .attach-illustration-btn{background-image: url(${adjuntarIlustracion01})}
    .attach-illustration-btn:hover{background-image: url(${adjuntarIlustracion02})}


    //botones de eliminar y añadir elementos 
    
    .remove-element-btn {
        background: none;
        background-image: url(${xtag01});
        background-repeat: no-repeat;
        width: 7px;
        height: 7px;
        margin: 0;
        padding: 0;
        border: none;
        margin-left: 10px; 
    }

    .remove-element-btn:hover {
        background-image: url(${xtag02});
        cursor: pointer;
    }

    .remove-element-btn-white{
        background: none;
        background-image: url(${xtag03});
        background-repeat: no-repeat;
        width: 7px;
        height: 7px;
        margin: 0;
        padding: 0;
        border: none;
    }
    .add-element-button{
        width: 30px;
        height: 30px;
        padding: 0px;
        background-image: url(${mas01});
        background-size: cover;
        border: none;
        vertical-align: middle; 
        border-radius: 10px;
    }
    
    .add-element-button:hover{
        background-image: url(${mas02});
        cursor: pointer;
    }

    .add-element-button-small{
        width: 10px;
        height: 10px;
        padding: 0px;
        background-image: url(${masChicoB01});
        background-size: cover;
        border: none;
        vertical-align: middle;
        border-radius: 10px;
    }

    .add-element-button-small:hover{
        background-image: url(${masChicoB02});
        cursor: pointer;
    }

    .add-btn-ext-position{
        display: flex;
        margin-top: 14px;
        flex-direction: column; /* Alinea los elementos en una columna */
        gap: 10px; /* Espacio entre los elementos (puedes ajustar según tus necesidades) */
        margin-left: 10px;
    }

    //boton para añdir filas en ficha
    .new_row_list_btn{
        height: 40px;
        width: 100%;
        border: none;
        background-color: ${colors.c5};
        color: ${colors.c3};
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .new_row_list_btn:hover{
        background-color: ${colors.c6};
    }

    .new_category_list_btn{
        height: 30px;
        width: 100%;
        border: none;
        background: ${colors.c2};
        color: ${colors.c0};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        align-items: center;
        font-weight: bold;
    }

    .new_category_list_btn:hover{
        background: ${colors.c2_2};
        // color: ${colors.c6};
    }



    //para boton de adjuntar referencias cuando se solicita una ilustración como servicio
  
    
    .inputfile-reference + label {
      background-color: green;
      border-radius: 10px;
      padding: 0px;
      margin: 0px;
      min-width: 10px;
      min-height: 10px;
      width: 10px;
      height: 10px;
      background-image: url(${masChicoB01});
    }
  
    .inputfile-reference:focus + label,
    .inputfile-reference.has-focus + label,
    .inputfile-reference + label:hover {
        background-image: url(${masChicoB02});
    }

    .inputfile-service + label {
        // background-color: green;
        border-radius: 10px;
        padding: 0px;
        margin: 0px;
        // min-width: 10px;
        // min-height: 10px;
        // width: 10px;
        // height: 10px;
      }
    
    .reference-input-hidden{
        width: 10px;
        position: relative;
        right: 10px;
        opacity: 0;
        border-radius: 5px;
        cursor: pointer;
    }

    .textButton{
        background: ${colors.c1};
        color: ${colors.c0};
        border: none;
        padding: 5px 20px;
        border-radius: 10px;
    }
    
    .textButton:hover{
        background: ${colors.c2};
        cursor: pointer;
    }

`;

export default ButtonsStyle;