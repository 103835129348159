import ReviewImageReferenceItemComponent from './ReviewImageReferenceItemComponent';

export default function ReviewCommentComponent({index, user_name, content, comment_attachments, downloadAttachmentFromUrl, type_name, date_creation}) {

    return (
    <div className="misma-linea fondo-gris-borde gap-20" key={index}>
        <div className="felx-column">
            <div className="width-100 flex-row margin-bottom-10 gap-10 align-center flex-end">
                <div className="margin-auto flex-row gap-10 align-center">
                    <img src="https://via.placeholder.com/30" alt="user profile" className="CircleImage " />
                    <p className="text">{`${user_name}`}</p>
                </div>
                <p className="time">{`${date_creation}`}</p>
                {/* <p className="text">{`${type_name}`}</p> */}
            </div>
            <hr className='separador sin-margen margin-top-10 margin-bottom-10'/>
            <div className="flex-row gap-20">
                {/* <div className="margin-auto">
                    <img src="https://via.placeholder.com/50" alt="user profile" className="CircleImage " />
                    <p className="text">{`${user_name}`}</p>
                </div> */}

                <textarea 
                    className="textareaRead max-height-30"
                    name="message" 
                    id="message" 
                    cols="200" 
                    readOnly
                    value={content}
                />
            </div>
            {downloadAttachmentFromUrl && comment_attachments && comment_attachments.length > 0 &&
                <hr className='separador sin-margen margin-top-10 margin-bottom-10'/>
            }
            <div className="flex-row gap-10">
                {downloadAttachmentFromUrl && comment_attachments && comment_attachments.map((file, index) => (
                    // console.log(file),
                    <ReviewImageReferenceItemComponent
                        key={index}
                        index = {`${index}`}
                        reference = {`${file.reference}`}
                        name = {`${file.name}`}
                        downloadAttachmentFromUrl = {downloadAttachmentFromUrl}
                    />
                ))}  
            </div>
            <div className="flex-row">
                {/* imagenes */}
            </div>
        </div>
    </div>
    );
  }
