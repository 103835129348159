import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";
import GeneralStyle from "../style/generalStyle";
import ButtonsStyle from "../style/buttonsStyle";
import PopupStyle from "../style/popupStyle";

import { getUserDetails, updateUserDetails, getUserFollowUser } from "../../api/user";

import TabBar from "../utils/TabBar";
import FollowButton from "../follow-button/FollowButton";

import UserPosts from "./profile-pages/UserPosts";
// import UserServices from "../user-services-components/create-service/CreateServices";
import UserServices from "../user-services-components/create-service/illustrator-services/IllustratorServices";
import {updateUserFollow} from '../../api/content'

export default function UserProfile() {
  const navigate = useNavigate()
  
  const { user } = useAuth()
  const { user_id, page_type } = useParams()

  const [targetUser, setTargetUser] = useState(null)
  // const [targetUserBio, setTargetUser] = useState(null)
  const [iFollowUser, setIFollowUser] = useState(false)

  const categories = [
    {name: 'Posts', value: 'posts'}, 
    {name: 'Services', value: 'services'},
    // {name: 'Gallery', value: 'gallery'},
  ]

  const [activeTab, setActiveTab] = useState(0)

  const [toggleEditUserBio, setToggleEditUserBio] = useState(false)
  const [selectedPage, setSelectedPage] = useState(
    {name: 'Posts', icon:'', value: 'posts'},
    // {name: 'Services', value: 'services'},
  );

  useEffect(() => {
    console.log('user_id:', user_id)
    // console.log('page_type:', page_type)
    if (!user) return
    fetchTargetUserData()
    fetchUserFollowUserData()
  }, [user, user_id])

  const fetchTargetUserData = async () => {
    try {
      if (user_id) {
        const response = await getUserDetails(user_id)
        console.log('response:', response.data)
        setTargetUser(response.data)
      } else {
        console.log('you should not be here')
        // const response = await getUserDetails(user.id)
        // setTargetUser(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchUserFollowUserData = async () => {
    try {
      if (user_id) {
        const response = await getUserFollowUser(user_id, user.id)
        // console.log('response123:', response.data)
        // targetUser = {...targetUser, my_follow: response.data.my_follow}
        // setTargetUser({...targetUser, my_follow: response.data.my_follow})
        setIFollowUser(response.data.my_follow)
      } else {
        console.log('you should not be here')
        // const response = await getUserDetails(user.id)
        // setTargetUser(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

	const updateUserFollowers = (value) => {
		console.log("value", value)
		setTargetUser({...targetUser, followers: value})
	}

  //getUserFollowUser

  const sourcePFP = () => {
    if (targetUser.user_picture) {
      return targetUser.user_picture
    } else {
      return 'https://via.placeholder.com/130'
    }
  }

  const handleTabChange = (index) => {
    setActiveTab(index)
    navigate(`/${user_id}/${categories[index].value}`);
    // console.log('Tab changed to:', categories[selectedContentType].value);
  }

  const renderContent = () => {
    if (!page_type) {
      return (
        <UserPosts
          targetUser={targetUser}
        />
      )
    }
    switch (activeTab) {
      case 0:
        return (
          <UserPosts
            targetUser={targetUser}
          />
        )
        
      case 1:
        return (
          <UserServices
            targetUser={targetUser}
          />
        )
      // case "gallery":
        // return < />;
      default:
        return null;
    }
  }

  const handleBiosChange = (e) => {
    // setTargetUser(e.target.value)
  }

  const handleToggleEditUserBio = () => {
    setToggleEditUserBio(!toggleEditUserBio)
  }

  const followUser = async () => {
    try {
      const result = await updateUserFollow({
        target_user_id: targetUser.user_id,
        browsing_user_id: user.id,
        follow_value: !iFollowUser,
      })
      // console.log(result)
      // updateCollabFollowStatus(collab.user_id, !collab.browsing_user_is_following_user)
      setIFollowUser(!iFollowUser)
    } catch (error) {
      console.error('Error toggling follow for user', error)
    }
  }
  

  return (
    <UserProfileContainer>
      <GeneralStyle>
        <ButtonsStyle>
          <PopupStyle>
          {targetUser && <>
          <div className="container">
            {/* <div className="leftNullContainer"></div> */}
            <div className="intContainer">
              {/* <div className="title-n-select">
                <h1 className="title">Mi perfil</h1>
                <div className="selectContainer">
                </div>
              </div>

              <hr className='separador'/>   */}

              <div className="flex-row gap-20">
                {/* <div className="left-column-180 "></div> */}
                <div className="leftNullContainer-150"></div>
                <div className="left-container-fijo flex-column align-center">
                  <div className="margin-bottom-10">
                    <img className="CircleImage" src={sourcePFP()} alt="placeholder profile pic"/>
                  </div>
                  <p className="subtitle font-weight-bold margin-bottom-20 align-center">{`${targetUser.user_nickname}`}</p>
                  {(parseInt(user_id) !== parseInt(user.id)) &&
                    <FollowButton
                      user_is_following={!iFollowUser}
                      submit_follow_action={() => {followUser(); 
                        updateUserFollowers(Math.max(0, iFollowUser ? targetUser.followers + 1 : targetUser.followers - 1));
                      }}
                      type = "usuario"
                    />
                  }
                  <p className="text margin-bottom-10 margin-top-10">{`Se unio en: ${targetUser.date_creation}`}</p>
                  <div className="flex-row gap-10 margin-bottom-20">
                    <div className="flex-column">
                      <p className="text">Seguidores</p>
                      <p className="text">{`${targetUser.followers}`}</p>
                    </div>
                    <div className="flex-column">
                      <p className="text">Seguidos</p>
                      <p className="text">{`${targetUser.followed_users}`}</p>
                    </div>
                  </div>
                  {/* {(parseInt(user_id) === parseInt(user.id)) && 
                    <button className="textButton margin-bottom-20 width-100" onClick={() => handleToggleEditUserBio()}>Editar Bio</button>
                  } */}
                  
                  {toggleEditUserBio ? (
                    <input
                      className="user-bio-input"
                      type="text"
                      value={targetUser.user_description}
                      onChange={(e) => handleBiosChange(e)}
                      // onBlur={toggleEditability} // Save and toggle editability when focus is lost
                      autoFocus
                    />
                  ) : (
                    <p className="text justify">{`${targetUser.user_description}`}</p>
                  )}
                </div>

                <div className="width-100">
                  <TabBar
                    categories={categories}
                    onTabChange={handleTabChange}
                    defaultTab={0}
                  />
                  {renderContent()}
                </div>
              </div>

            </div>

          </div>
          </>}
          </PopupStyle>
        </ButtonsStyle>
      </GeneralStyle>
    </UserProfileContainer>
  )
}

const UserProfileContainer = styled.div`
.complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-container {
  outline: 1px dashed black;
  width: 80%;
  display: flex;
}
.user-info-column {
  display: flex;
  flex-direction: column;
  width: 20%;
  outline: 1px solid red;
}

.user-follow-stats {
  display: flex;
  justify-content: space-between;
  outline: 1px solid green;
}

.user-status {
  outline: 1px dotted black;
}

.user-bio {
  outline: 1px solid pink;
}
.user-bio-input {
  width: 100%;
  multiline: true;
}
.user-page-column {
  width: 80%;
  outline: 1px solid red;
}
`