import axios from '../api/axios';

export const getUserRoleServiceList = (user_id, role_id) => axios.get(`/get_user_role_service_list/${user_id}`, { params: { role_id: role_id},});

export const updateUserRoleServiceList = (msg) => axios.post(`/update_user_role_service_list`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const submitServiceRequest = (msg) => axios.post(`/submit_service_request`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getServiceRequestsByUser = (reqParams) => axios.get(`/get_service_requests_by_user`, { params: reqParams,});

export const getServiceRequest = (reqParams) => axios.get(`/get_service_request`, { params: reqParams,});

export const getServiceRequestMessages = (reqParams) => axios.get(`/get_service_request_messages`, { params: reqParams,});

export const submitServiceRequestMessage = (msg) => axios.post(`/submit_service_request_message`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});


// community posts y comentarios 
export const submitCommunityPost = (msg) => axios.post(`/create_community_post`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getUserCommunityPosts = (author_id, browsing_user_id, role_id_tags) => axios.get(`/get_user_community_posts/${author_id}`, 
{ params: { browsing_user_id: browsing_user_id, role_id_tags: role_id_tags},});

export const submitComment = (msg) => axios.post(`/submit_comment`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getComments = (content_type_id, content_specific_id) => axios.get(`/get_comments`, { params: {content_specific_id: content_specific_id, content_type_id: content_type_id}});

export const changeContentLike = (msg) => axios.post(`/update_content_like`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});