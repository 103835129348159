export default function NewUserPostComponent({user, submitPost, newCommunityPost, updateNewPostTitle, updateNewPostContent}) {

    return (
        <div className="misma-linea fondo-blanco gap-20">
            <div className="felx-column">
                
                <div className="width-100 flex-row margin-bottom-10 gap-10 align-center">
                    
                    <div className="margin-auto flex-row gap-10 align-center">
                        <img src="https://via.placeholder.com/30" alt="user profile" className="CircleImage " />
                        {user && <p className="text">{`${user.username}`}</p>}
                    </div>
                </div>
                {/* <hr className='separador sin-margen margin-top-10 margin-bottom-10'/> */}

                <div className="flex-row gap-20">
                    <div className="flex-column">
                        <input 
                            className="subtitleInput margin-bottom-10" 
                            type="text" 
                            placeholder="Post title" 
                            onChange={(e) => updateNewPostTitle(e)} value={newCommunityPost.title}
                        />
                        <textarea 
                            className="textareaInput textareaInputSmall max-height-150" 
                            name="message" 
                            id="message" 
                            cols="200" 
                            // rows="3"
                            maxLength="280"
                            placeholder="Post content" 
                            onChange={(e) => updateNewPostContent(e)} 
                            value={newCommunityPost.content}
                        />
                    </div>
                    
                    <div className="margin-auto">
                        <button type="submit" className="save_btn circleButton" onClick={() => submitPost()}></button>
                    </div>
                </div>
                
            </div>
        </div>
    );
  }
