import React, { useEffect, useState } from 'react'
// import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import {createStory} from '../../api/content'

import StoryDetailsDisplay from './StoryDetailsDisplay';
import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from '../style/buttonsStyle';

import { useAuth } from "../../context/AuthContext";
import axios from 'axios';
import { getLanguageList } from '../../api/type';

export default function CreateNewStory({storyDisplayMode}) {
    const navigate = useNavigate()
    const { user } = useAuth();
    
    const [languageList, setLanguageList] = useState([]) // [ {name: lang1, id_language: 1}, {name: lang2, id_language: 2}, ...
    const [tagList, setTagList] = useState([]) // [ tag1, tag2, tag3, ...]
    const [genreList, setGenreList] = useState([]) // [ gen1, gen2, gen3, ...
    const [selectedLanguage, setSelectedLanguage] = useState(1) // [ tag1, tag2, tag3, ...

    const [storyDetails, setStoryDetails] = useState({
        title: '',
        description: '',
        coverImgData: '',
        coverImgID: null,
        bannerImgData: '',
        bannerImgID: null,
        // genres: [],
        // tags: [],
    })

    useEffect(() => {
        fetchGeneralInfo()
    }, [])

    const fetchGeneralInfo = async () => {
        try {
            const langList = await getLanguageList()
            setLanguageList(langList.data)
        } catch (error) {
            console.log('Error fetching general info:', error)
        }
    }
    
    async function postCreateStory() {
        const msg = {
            user_id: user.id,
            language_id: selectedLanguage,
            state: 1,
            title: storyDetails.title,
            description: storyDetails.description,
            coverImgData: storyDetails.coverImgData,
            originalCoverImgID: storyDetails.coverImgID,
            bannerImgData: storyDetails.bannerImgData,
            originalBannerImgID: storyDetails.bannerImgID,
            genres: genreList,
            tags: tagList,
        }

        try {
            const response = await createStory(msg)
            console.log('New story id: ' + response.data.message.storyId + ' | New chapter id: ' + response.data.message.chapterId)
            navigate(`/${response.data.message.storyId}/${response.data.message.chapterId}/edit`)
        } catch (error) {
            console.log('Error with Create New Story req:', error)
        }
    }

    const handleLanguageSelect = (e) => {
        console.log('Selected language:', e.target.value)
        setSelectedLanguage(e.target.value)
    }

    return (
        <GeneralStyle>
            <ButtonsStyle>
                <div className='container'>
                    <div className="leftNullContainer"></div>
                    <div className='intContainer'>
                        
                        <div className="title-n-select">
                            <h1 className="title">Nueva historia</h1>
                            <div className="selectContainer">
                                {/* Queda pendiente manejar esats opciones seleccionables */}
                                <select name="state" id="state">
                                    <optgroup label="Estado">
                                        <option value="ongoing">En curso</option>
                                        <option value="paused">En pausa</option>
                                        <option value="finished">Finalizada</option>
                                        <option value="dropped">Abandonada</option>
                                    </optgroup>
                                </select>
                                <select name="language" id="language" defaultValue={1} onChange={(e) => handleLanguageSelect(e)}>
                                    <optgroup label="Idioma">
                                        {languageList && languageList.map((lang) => (
                                            <option key={lang.id_language} value={`${lang.id_language}`}>{`${lang.name}`}</option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                                
                        <hr className='separador'/>

                        <StoryDetailsDisplay 
                            displayMode={"new"}
                            genres={{ list: genreList, setList: setGenreList }}
                            tags={{ list: tagList, setList: setTagList }}
                            storyDetails={{ details: storyDetails, setDetails: setStoryDetails }}
                        />

                        <hr className='separador'/>

                        
                        <div className="btns">
                                <button className='save_btn circleButton' onClick={postCreateStory}>
                                    {/* Guardar */}
                                </button>
                                {/* <button className='delete_btn circleButton'>
                                </button> */}
                        </div>
                    </div>
                </div>
            </ButtonsStyle>
        </GeneralStyle>
    )
}
