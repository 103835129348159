import React, { useEffect, useState, useRef} from "react";
// import { Link } from 'react-router-dom';

import { EditorContent , useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import HardBreak from '@tiptap/extension-hard-break'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
import CharacterCount from '@tiptap/extension-character-count'


// import axios from 'axios'
import {getStory, getChapter, getInfosheetsFromChapter} from '../../api/content'
import styled from 'styled-components';

import ChapterTextEditor from "../chapter-components/ChapterTextEditor";

export default function TranslateChapter (prop) {
  const editorExtentions = [
    Document,
    Paragraph,
    Text,
    Heading,
    HardBreak,
    Bold,
    Italic,
    Strike,
    Underline,
    Image.configure({
      HTMLAttributes: {
        class: 'miImage', 
        style: 'max-width: 500px; max-height: 500px; display: block; margin-left: auto; margin-right: auto;',
      }
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    CharacterCount,
  ]

  const story_id = 5;
  const chapter_id = 3;

  const [originalChapterData, setOriginalChapterData] = useState({chapterTitle: '', chapterContent: ''});
  const [translatedcCapterData, setTranslatedChapterData] = useState({chapterTitle: 'Translated Title', chapterContent: ''});  

  const referenceEditor = useEditor({
    extensions: editorExtentions,
    // content: JSON.stringify(chapterContentRef),
    editable: false,
  })

  const translationEditor = useEditor({
    extensions: editorExtentions,
    content: 'Your translation here',
    editable: true,
  })

  useEffect(() => {
    if (referenceEditor) {
      // Solicitar detalles de la historia
      const fetchData = async () => {
        try {
          const storyResponse = await getStory(story_id)
          document.getElementById('story-title').textContent = storyResponse.data.title

          const chapterResponse = await getChapter(chapter_id)
          setOriginalChapterData({ chapterTitle: chapterResponse.data.title, chapterContent: chapterResponse.data.content})
          referenceEditor.commands.setContent(JSON.parse(chapterResponse.data.content))

          const infosheetsResponse = await getInfosheetsFromChapter(chapter_id)
          // setLinkedInfosheets(infosheetsResponse.data)

        } catch (error) {
          console.log('Error with GET req1 (getting story details):', error)
        }
      }
      fetchData();
      // axios.get(`http://localhost:5000/api/get_story/${story_id}`)
      // .then(response => {
      //     document.getElementById('story-title').textContent = response.data.title;
      // }).catch((err) => {
      //     console.log('Error with GET req1 (getting story details):', err)
      // })

      // Solicitar detalles del capítulo
      // axios.get(`http://localhost:5000/api/get_chapter/${chapter_id}`)
      // .then(response => {
      //   // chapterContentRef = response.data.content;
      //   setOriginalChapterData({ chapterTitle: response.data.title, chapterContent: response.data.content})
      //   referenceEditor.commands.setContent(JSON.parse(response.data.content))
      // })
      // .catch(error => {
      //   console.log(error)
      // })

      // Solicitar lista de fichas vinculadas al capítulo
      // axios.get(`http://localhost:5000/api/getInfosheetsFromChapter/${chapter_id}`)
      // .then(response => {
      //   // setLinkedInfosheets(response.data)
      // })
    }
  }, [referenceEditor])


  return (
    <TranslateChapterContainer>
      <div className='page-container'>
        <div className='category-header'>
          <h1>Translate Chapter</h1>
        </div>
        <div className='content'>
          <div className='editor-container reference-editor'>
            {/* <h2 id='story-title'>{}</h2> */}
            <h2 className='chapter-title'>{originalChapterData.chapterTitle}</h2>
            <EditorContent editor={referenceEditor} />
          </div>
          <div className='editor-container translation-editor'>
            <ChapterTextEditor editor={translationEditor} insertImages={false} data={translatedcCapterData} setData={setTranslatedChapterData}/>
          </div>
        </div>
      </div>

    </TranslateChapterContainer>        
  )
}

const TranslateChapterContainer = styled.div`
.ProseMirror {
  /*background-color: #ffffff;*/
  text-align: left;
  /*outline: none;*/
  /*height: 50vh;*/
  width: 100%;
  // min-height: 50vh;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.editor-container {
  width: 45%;
  height: 100%;
  border: 1px solid black;
}

.translation-editor {
  // min-height: ;
  // border: 1px solid red;
}
`
