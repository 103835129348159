import styled from "styled-components";

import { colors } from "../../style/colors";

const OverlayStyle = styled.div`
  .item-overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -2px;
    bottom: 0px;
    left: -2px;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    flex-shrink: 0;
    border: 2px solid ${colors.c1};
    border-radius: 15px;
    cursor: default;
  }
  
  .overlay-header {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .item-overlay-title-xl{
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    background-color: rgba(221, 221, 221, 0.8);
    border-radius: 12px;
  }

  .item-overlay-title-l {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color: rgba(221, 221, 221, 0.8);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .item-overlay-title-m {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color: rgba(221, 221, 221, 0.8);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .story-title-overlay{
    padding: 0px 10px;
    color: ${colors.c1};
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 35px;
    margin-bottom: 10px;
    font-weight: bold;
    // text-align: center;
  }

  .public-title-overlay{
    padding: 0px 10px;
    color: ${colors.c1};
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 35px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
  }

  .public-title-overlay-s{
    padding: 0px 10px;
    max-width: 170px;
    color: ${colors.c1};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
  }

  .private-title-overlay{
    padding: 0px 10px;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    font-weight: bold;
    text-align: left;
  }

  .tags-overlay{
    padding: 0px 10px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    // font-weight: bold;
    text-align: center;
    // place-content: center;
  }

  .stats-overlay{
    padding: 0px 10px;
    max-width: 190px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin: 0px;
    font-weight: bold;
    text-align: center;
    place-content: center;
  }

  .story-description-overlay{
    padding: 0px 10px;
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 155px;
    margin-bottom: 10px;
    text-align: justify;
  }

  .info-extra{    
    padding: 0px 10px;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    font-weight: normal;
    text-align: left;
  }

  .partes{
    margin-bottom: 10px;
  }

  .estadísticas{
    color: ${colors.c1};
  }

  .item-overlay-button {
    display: flex;
    position absolute;
    align-content: right;
    margin-left: auto;
    margin-right: 10px;
    margin-top: 10px;
  }




  /*############################################# OVERLAY PARA SUBIR IMAGEN ####################################################*/
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .upload_button_text{
    color: ${colors.c0};
  }
    
  .inputfile-1 + label {
    color: ${colors.c0};
    background-color: ${colors.c1};
    border-radius: 10px;
  }

  .inputfile-1:focus + label,
  .inputfile-1.has-focus + label,
  .inputfile-1 + label:hover {
    background-color: ${colors.c2};
  }

  .sinImagen{
    width: 100px;
    margin: auto;
  }


`;

export default OverlayStyle;