import {React, useState} from "react"; 
import { Link } from "react-router-dom";

import NavbarStyle from "./style/NavbarStyle";
import LogoutComponente from "./LogoutComponent";
import GeneralStyle from "../style/generalStyle";

import { useAuth } from "../../context/AuthContext";

export default function HeaderComponent () {
    const {user, logout} = useAuth();

    const [divLogout, setDivLogout] = useState();

    //estado para mostrar u ocultar el boton de logout
    const [mostrarLogout, setMostrarLogout] = useState(false);
    //funcion para mostrar u ocultar el boton de logout
    const mostrarLogoutHandler = () => {
        setMostrarLogout((mostrarLogout) => !mostrarLogout);
    };

    const sourcePFP = () => {
      if (user.user_picture) {
        return user.user_picture
      } else {
        return 'https://via.placeholder.com/130'
      }
    }

    // console.log('q wa',user)

    return(
        <GeneralStyle>
            <NavbarStyle>
                <div className="navbar_container">
                    <div className="header" >
                        <div className="title-n-select">
                        <div className="misma-linea">                            
                            <div className="logoContainer">
                                <Link to="/" className="logo"></Link>
                            </div>
                            <div className="links">
                                {/* <p className="optionBanner">Escritores</p> */}
                                <Link to="/userscatalog/writers" className="optionBanner">Escritores</Link>
                                <Link to="/userscatalog/illustrators" className="optionBanner">Ilustradores</Link>
                                <Link to="/userscatalog/editors" className="optionBanner">Editores</Link>
                                <Link to="/userscatalog/translators" className="optionBanner">Traductores</Link>
                                <Link to="/userscatalog/musicians" className="optionBanner">Músicos</Link>
                                <Link to="/userscatalog/voiceovers" className="optionBanner">Dobladores</Link>
                                {/* <p className="optionBanner">Músicos</p> */}
                                {/* <p className="optionBanner">Dobladores</p> */}
                            </div>
                        </div>
                        <div>
                            {!user &&
                                <Link to="/login" className="optionBanner margin-right-10 manito">Login</Link>
                            }
                            {user &&
                                <div className="manito desplegarOpciones" onClick={mostrarLogoutHandler}>
                                    <div className="flex flex-row center">
                                        {/* <img src="https://via.placeholder.com/50" alt="user profile" className="CircleImage BannerImage margin-right-10"/> */}
                                        
                                        <img className="CircleImage BannerImage margin-right-10 image-s" src={sourcePFP()} alt="placeholder profile pic"/>
                                        <p className="title align-content-center margin-right-10">▾</p>
                                    </div>
                                </div>
                            }
                            <LogoutComponente
                                user={user}
                                logout={logout}
                                mostrarLogout={mostrarLogout}
                                setMostrarLogout={mostrarLogoutHandler}
                                setDivLogout={setDivLogout}
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </NavbarStyle>
        </GeneralStyle>
    )
}
