// eslint-disable-next-line no-unused-vars
import React, { useCallback } from "react";

export default function SampleTextButton(prop) {
    const sampleLines = [
        "Hello darkness my old friend, I've come to talk with you again...",
        "I used to rule the world, seas would rise when I gave the word...",
        "Ground control to Major Tom, take your protein pills and put your helmet on...",
        "Dirty deeds done dirt cheap, Dirty deeds and they're done dirt cheap...",
        "I'm TNT, I'm dynamite, TNT, and I'll win the fight...",
        "Do you breathe the name of your savior in your hour of need...",
        "Mi unicornio azul ayer se me perdió, pastando lo deje y desapareció...",
        "Nunca estamos conformes del quehacer de los demás, y vivimos a solas sin pensar en los demás...",
        "If I could save time in a bottle, the first thing that I'd like to do is to save every day, 'til eternity passes away...",
        "En la torre de Babel vivian 50 cigarros, vivian amontonados, hechos todos de papel...",
        "Yesterday, all my troubles seemed so far away, now it looks as though they're here to stay...",
        "Fly me to the moon, let me play among the stars, let me see what spring is like on Jupiter and Mars...",
        "I'm so bored, time to sheath the sword. Why is this game so damn adored?...",
        "Hear the sledges with the bells, silver bells, what a world of merriment their melody foretells...",
    ]

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    
    const addSampleText = useCallback(() => {
        prop.editor.chain().focus().insertContent(sampleLines[getRandomInt(sampleLines.length)]).run()
    }, [prop.editor])

    return (
        <button onClick={addSampleText} id={prop.id} className={prop.className}>
            Add Sample Text
        </button>
    );
}