export default function AccessPermissionItemComponent(props) {

  return (
    <div className="misma-linea fondo-gris" key={props.index}>
      <div className="misma-linea fondo-blanco-borde flex-3 manito margin-right-10" onClick={() => props.openContentSharePopup(props.index)}>
        <div className="misma-linea">
          <img className="file-btn-white margin-right-10" ></img>
          <div>
            <p className="text sharedContentTitle align-left width-100" title={props.title}>{props.title}</p>
          </div>
        </div>
      </div>
      <p className="text flex-1"></p>
      <div className="misma-linea flex-2 flex-wrap margin-right-10">
        <p className="text font-weight-bold margin-right-10">Tipo </p>
        <p className="text">{props.type}</p>
      </div>
      <div className="misma-linea flex-2 flex-wrap margin-right-10">
        <p className="text font-weight-bold margin-right-10">Idioma </p>
        <p className="text">{props.language}</p>
      </div>
      <div className="misma-linea flex-2 flex-wrap margin-right-10">
        <p className="text font-weight-bold margin-right-10">Palabras </p>
        <p className="text">{props.words}</p>
      </div>
      
      <div className="margin-right-10">
        <button className="remove-element-btn" onClick={() => props.removeSharedContent(props.index)}></button>
      </div>
    </div>
  );
}