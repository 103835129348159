import axios from '../api/axios';

export const getTagList = (type) => axios.get(`/list${type}`);

export const getRoleList = () => axios.get(`/listRoles`);

export const getContentTypeList = () => axios.get(`/get_content_type_list`);

export const getLanguageList = () => axios.get(`/get_languages`);

export const getMessageTypeList = () => axios.get(`/get_message_type_list`);

export const getInfosheetTypeList = () => axios.get(`/get_infosheet_type_list`);

export const getChapterStates = () => axios.get(`/get_chapter_states`);