// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import styled from "styled-components";

import UserImgGallery from "../utils/UserImgGallery";


export default function EditorAttachImage({ toggleDialog, loadImage }) {

    // const loadImage = (img) => {
    //     // /api/add_image_to_story
    //     const msg = {
    //         story_id: 1,
    //         img: img.src
    //     }
    //     axios.post(`http://localhost:5000/api/add_image_to_story`, 
    //         msg,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         }
    //     ).then((res) => {
    //         console.log(res.data)
    //         const url = img.src
    //         if (url) {
    //             editor.chain().focus().setImage({ src: url }).createParagraphNear().insertContent({
    //                 type: 'paragraph',
    //             }).run()
    //         }
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    //     toggleDialog()
    // }

    return (
        <EditorAttatchImageContainer>
            <div className="overlay">
                <div className="dialog-content">
                    <button onClick={toggleDialog} className="close-icon">&times;</button>
                    <UserImgGallery loadImage={loadImage}/>
                    {/*
                    <input/>
                    <Gallery images={imageList} enableImageSelection={false} onClick={addImage}/>
                    */}
                </div>
            </div>
        </EditorAttatchImageContainer>
    )
}

const EditorAttatchImageContainer = styled.div`
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
    .dialog-content {
        width: 850px;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        justify-content: center;
        align-items: center;
    }

    .dialog-content img {
        width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 400px;
        margin-bottom: 10px;
    }

    .close-icon {
        float: right;
        color: #999;
        cursor: pointer;
    }
`
