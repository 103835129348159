export const adjustTextareaHeight = (textarea, alto, mostrar) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';

    // Verifica si el scrollHeight es mayor o igual a 250px
    if (textarea.scrollHeight >= alto) {
        textarea.style.height = alto + 'px';
        console.log('scrollHeight ', textarea.scrollHeight, ' alto ', alto)
        if (mostrar) {
            textarea.style.overflowY = 'auto'; // Muestra el overflow vertical
        }
    } else {
        console.log('else: scrollHeight ', textarea.scrollHeight, ' alto ', alto)
        textarea.style.overflowY = 'hidden'; // Oculta el overflow vertical
    }

}

export const aplicarAjusteAltura = (nombreClase, alto, mostrar) => {
    document.querySelectorAll(nombreClase).forEach(function(textarea) {
        adjustTextareaHeight(textarea, alto, mostrar);
        
        textarea.addEventListener('input', function() {
            adjustTextareaHeight(textarea, alto, mostrar);
        });
    });
}