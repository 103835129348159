// import React, { useState, useEffect } from "react";
// import {aplicarAjusteAltura} from '../../../../functions/textAreaHeight';

export default function TranslatorServiceItemComponent(props) {

    // useEffect(() => {
    //     aplicarAjusteAltura('textarea.textareaInputSmall', 90, false);
    //   }, []); // Este efecto se ejecutará solo una vez, equivalente al evento DOMContentLoaded
    
    
  return (
    <div className='misma-linea fondo-gris' key={props.index}>
        <div className="misma-linea flex-3 margin-right-10">
            <select className="margin-right-10" name="target_language" id="target_language" value={props.perWordServiceList[props.index].ol_index} onChange={(e) => props.updateOriginLanguage(props.index, e.target.value)}>
                <optgroup label="Idioma origen">
                    {props.languages.map((language, lang_index) => (
                        <option key={lang_index} value={lang_index}>{language.name}</option>
                    ))}
                </optgroup>
            </select>

            <div className="margin-right-10 text">
                ⭢
            </div>

            <select className="margin-right-10" name="target_language" id="target_language" value={props.perWordServiceList[props.index].tl_index} onChange={(e) => props.updateTargetLanguage(props.index, e.target.value)}>
                <optgroup label="Idioma destino">
                    {props.languages.map((language, lang_index) => (
                        <option key={lang_index} value={lang_index}>{language.name}</option>
                    ))}
                </optgroup>
            </select>
        </div>

        <div className="misma-linea flex-1 margin-right-10 flex-end">
            <input type="number" className="numericInput"
                onChange={(e) => props.updatePricePerWord(props.index, e.target.value)}
                value={props.perWordServiceList[props.index].pricePerWord}
                placeholder="Precio"
            />
        </div>

        <button className='remove-element-btn' onClick={() => props.removePerWordFee(props.index)}></button>  

    </div>
  );
}
