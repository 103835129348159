import { createContext, useState, useContext, useEffect } from "react";
import { registerRequest, loginRequest, verifyTokenRequest, logoutRequest } from "../api/auth";
import Cookies from 'js-cookie';

export const AuthContext = createContext();

//uso del contexto, validamos si existe el contexto
export const useAuth = () => {
    const context = useContext(AuthContext)
    if(!context){
        throw new Error('useAuth debe estar dentro del proveedor AuthContext')
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    //usaurio que será leido en toda la app
    const [user, setUser] = useState(null);
    //para saber si el usuario está autenticado
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(true);

    //funcion que recibe los datos el usuario registrado
    const register = async (user) => {
        try{
            const res = await registerRequest(user);
            setUser(res.data);
            setIsAuthenticated(true);
        }catch(error){
            //console.log(error.response.data.message);
            setErrors(error.response.data.message);
        }
    }

    const login = async (user) => {
        try{
            const res = await loginRequest(user);
            //console.log(res.data);
            setUser(res.data);
            setIsAuthenticated(true);
        }catch(error){
            //console.log(error.response.data.message);
            setErrors(error.response.data.message);
        }
    }

    //funcion para logout
    const logout = async () => {
        try{
            await logoutRequest();
            setIsAuthenticated(false);
            setUser(null);
        }catch(error){
            console.log(error);
        }
    }

    //después de 5 segundos se elimina el error
    useEffect(() => {
        if(errors){
            const timer = setTimeout(() => {
                setErrors(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [errors]);

    useEffect(() => {
        async function checkLogin (){
            const cookies = Cookies.get();
            if(!cookies.token){
                setIsAuthenticated(false);
                setUser(null);
                setLoading(false);
                return;
            };

            try{
                const res = await verifyTokenRequest(cookies.token);
                if(!res.data) {
                    setIsAuthenticated(false);
                    setUser(null);
                    setLoading(false);
                }
                setIsAuthenticated(true);
                setUser(res.data);
                setLoading(false);
            }catch(error){
                setIsAuthenticated(false);
                setUser(null);
                setLoading(false);
            };
        };
        
        checkLogin();
    },[]);

    return (
        <AuthContext.Provider value={{
            user,
            loading,
            register,
            login,
            logout,
            isAuthenticated,
            errors,
        }}>
            {children}
        </AuthContext.Provider>
    );
};