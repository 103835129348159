import styled from "styled-components";

export default function EditorCatalogServiceCard(props) {
    
  return (
    <X>
        <div className='fondo-gris height-100 serviceInfo' key={props.index}>
            <div className="flex-column">
                <p className="subtitle">{props.service_name}</p>
                <p className="text">{props.service_description}</p>
                <hr className="separador width-100 margin-bottom-10"></hr>
                <p className="text">{props.service_price}</p>
            </div>

        </div>
    </X>
  );
}

const X = styled.div`
    .serviceInfo{
        max-width: 165px;
        box-sizing: border-box;
    }

`
