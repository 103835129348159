import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'

import {getUsersCatalog} from "../../../api/content"
import { useAuth } from "../../../context/AuthContext"

import styled from "styled-components"


export default function TranslatorCatalog (prop) {
  const { user } = useAuth()

  const navigate = useNavigate()

  const [translatorList, setTranslatorList] = useState([
    {
      user_id: 1,
      user_name: "John Doe",
      user_description: "Aliquam auctor nunc a magna malesuada feugiat",
      user_languages: ["English", "Spanish", "Italian"],
      user_price_range: {min: 10, max: 20},
      user_rating: 4.5,
      user_reviews: 10,
      user_image: "https://via.placeholder.com/50",
      service_list: [
        { service_name: "Eng a Spa", service_price: 10 },
        { service_name: "Spa a Eng", service_price: 5 },
        { service_name: "Spa a Ita", service_price: 20 },
      ]
    },
  ])


  const languageList = [
    {name:"N/A", id: 0},
    {name:"Español", id:1},
    {name:"English", id:2},
    {name:"Italiano", id:3},
    {name:"Frances", id:4},
  ]

  // Role id's:
  // 1	"escritor"
  // 2	"editor"
  // 3	"traductor"
  // 4	"ilustrador"
  // 5	"músico"
  // 6	"doblador"

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getUsersCatalog(3)
        setTranslatorList(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  const handleItemClick = (item) => {
    navigate(`/translationrequest/${item.user_id}`)
  }

  return (
    
    <CatalogContainer>
      <h1>Lista de Traductores</h1>
      <div className="filter_bar">
        <p>Traducir de </p>
        <select name="origin_language" id="origin_language">
          {languageList.map((language, index) => {
            return (
              <option key={index} value={language.id}>{language.name}</option>
            )
          })}
        </select>
        <p> a </p>
        <select name="destination_language" id="destination_language">
          {languageList.map((language, index) => {
            return (
              <option key={index} value={language.id}>{language.name}</option>
            )
          })}
        </select>

      </div>
      {translatorList.map((translator, index) => {
        return (
          <div className="translator_card" key={index}>
            <div className="translator_info">
              <h2>{translator.user_name}</h2>
              <img src={translator.user_image} alt={translator.user_name} />
              <p>{translator.user_description}</p>
              <p>{translator.user_languages.join(', ')}</p>
              <p>Desde: ${translator.user_price_range.min} a ${translator.user_price_range.max}</p>
              <div className="rating_and_reviews">
                <p>{translator.user_rating}★, </p>
                <p>{translator.user_reviews}</p>
              </div>

            </div>

            <div className="translator_services">
              <div className="service_header">
                <h3>Servicios</h3>
              </div>
              {/* <hr /> */}
              <div className="service_list">
                <ul>
                  {translator.service_list.map((service, index) => {
                    return (
                      <div className="service_item" key={index}>
                        <p>{service.service_name}</p>
                        <p>{service.service_price}</p>
                      </div>
                    )
                  })}
                </ul>
                <button onClick={() => handleItemClick(translator)}>Solicitar un Servicio</button>

              </div>
            </div>
          </div>
        )
      })}
    </CatalogContainer>
  )
}

const CatalogContainer = styled.div`
.filter_bar {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  margin: 10px;
}
.translator_card {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}

.translator_card img {
  width: 100px;
  height: 100px;
}

.translator_info {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.translator_services {
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: 1px solid red;
}

.service_item {
  display: flex;
  flex-direction: row;
  outline: 1px solid blue;
}

.service_item p {
  margin: 10px;
}

.rating_and_reviews {
  display: flex;
  flex-direction: row;
}
.hr {
  width: 100%;
  border: 1px solid black;
}

`