import axios from '../api/axios';

export const getInfosheetList = () => axios.get(`/getInfosheetList`);
export const addInfosheetToChapter = (msg) => axios.post(`/add_infosheet_to_chapter`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const removeInfosheetFromChapter = (msg) => axios.post(`/remove_infosheet_from_chapter`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const uploadImage = (msg) => axios.post(`/uploadImage`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const getImageDetails = (image_id, browsing_user_id) => axios.get(`/get_image_details/${image_id}`, { params: { browsing_user_id: browsing_user_id},});
export const updateImageDetails = (msg) => axios.post(`/update_img_details`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getChapter = (chapter_id, browsing_user_id) => axios.get(`/get_chapter/${chapter_id}`, { params: { browsing_user_id: browsing_user_id},});
export const createChapter = (msg) => axios.post(`/create_new_chapter`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const updateChapter = (chapter_id, msg) => axios.post(`/update_chapter/${chapter_id}`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const removeChapter = (msg) => axios.post(`/remove_chapter`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getInfosheetsFromChapter = (chapter_id) => axios.get(`/get_infosheets_from_chapter/${chapter_id}`);
export const getInfosheetsFromUser = (user_id) => axios.get(`/get_infosheets_from_user/${user_id}`);
export const getChaptersFromUser = (user_id) => axios.get(`/get_chapters_from_user/${user_id}`);
export const getImagesFromUser = (user_id) => axios.get(`/get_images_from_user/${user_id}`);
export const getStoriesFromUser = (user_id) => axios.get(`/get_stories_by_user/${user_id}`);

export const getStories = (reqParams) => axios.get(`/stories`, { params: reqParams,});
export const getChapters = (story_id, msg) => axios.get(`/${story_id}/chapters`,  { params: msg,});

export const getInfosheet = (infosheet_id) => axios.get(`/get_infosheet/${infosheet_id}`);
export const createInfosheet = (msg) => axios.post(`/create_infosheet`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const updateInfosheet = (infosheet_id, msg) => axios.post(`/update_infosheet/${infosheet_id}`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const removeInfosheet = (msg) => axios.post(`/remove_infosheet`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getStory = (story_id, browsing_user_id) => axios.get(`/get_story/${story_id}`, { params: { browsing_user_id: browsing_user_id},});
export const createStory = (msg) => axios.post(`/create_new_story`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const getStoryDetails = (story_id, browsing_user_id) => axios.get(`/get_story_details/${story_id}`, { params: { browsing_user_id: browsing_user_id},});
export const updateStoryDetails = (story_id, msg) => axios.post(`/update_story_details/${story_id}`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const removeStory = (msg) => axios.post(`/remove_story`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const swapContentOrder = (msg) => axios.post(`/update_content_order`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getUsersCatalog = (role_id) => axios.get(`/get_users_catalog/${role_id}`);

export const updateStoryFollow = (msg) => axios.post(`/update_story_follow`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
export const updateUserFollow = (msg) => axios.post(`/update_user_follow`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});

export const getContentCollaborators = (content_type_id, content_specific_id, browsing_user_id) => axios.get(`/get_content_collaborators`, 
  { 
    params : {
      content_type_id: content_type_id, 
      content_specific_id: content_specific_id, 
      browsing_user_id: browsing_user_id,
    }
  });
export const updateContentCollaborator = (msg) => axios.post(`/update_content_collaborator`, JSON.stringify(msg), {headers: {'Content-Type': 'application/json'}});
