import React, { useState, useEffect} from "react";

import { useParams } from "react-router-dom";

import {getUserRoleServiceList, submitServiceRequest} from '../../../../api/service';
import GeneralStyle from "../../../style/generalStyle";
import ButtonsStyle from "../../../style/buttonsStyle";

import AttachReferenceContent from "../../attach-reference/AttachReferenceContent";
import { useAuth } from "../../../../context/AuthContext";
import AccessPermissionItemComponent from "../AccessPermissionItemComponent";
import OtherServiceItemComponent from "../OtherServiceItemComponent";
import TranslationServiceItemComponent from "./TranslationServiceItemComponent";


export default function TranslationRequest() {
  const { user } = useAuth();
  const { provider_id } = useParams();

  const [referenceContentPopup, setReferenceContentPopup] = useState(false);
  const [selectedSharedContentBox, setSelectedSharedContentBox] = useState(null)
  const [selectedTranslationService, setSelectedTranslationService] = useState(null)

  const [requestSrcLanguage, setRequestSrcLanguage] = useState({name:"N/A", id: 0})
  const [requestDestLanguage, setRequestDestLanguage] = useState({name:"N/A", id: 0})

  const [message, setMessage] = useState("")

  const languageList = [
    {name:"N/A", id: 0},
    {name:"Español", id:1},
    {name:"English", id:2},
    {name:"Italiano", id:3},
    {name:"Frances", id:4},
  ]


  const [sharedContent, setSharedContent] = useState([
    // {title: "select content", type: {name: "---", id: null}, language: {name: "N/A", id: 0}, words: "1000"},
  ])

  const newSharedContent_Empty = {
    title: "select content", 
    type: {name: "---", id: null}, 
    language: {name: "---", id: null}, 
    words: "---"
  }


  const [additionalTextList, setAdditionalTextList] = useState([
    // {text: "doom", words: "1"},
  ])
  const newAdditionalText_Empty = {
    text: "", 
    words: "0",
  } 

  const [translationServiceList, setTranslationServiceList] = useState([
    // {name:"English a Español", originLanguage: {name:"English", id:"2"}, targetLanguage: {name:"Español", id:"1"}, pricePerWord: "15"}, 
    // {name:"Español a English", originLanguage: {name:"Español", id:"1"}, targetLanguage: {name:"English", id:"2"}, pricePerWord: "10"},

  ])

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await getUserRoleServiceList(provider_id, 3)
        const initializeServices = response.data.services.map(service => {
          return {
            name: `${service.source_language_name} a ${service.destination_language_name}`, // no usado
            originLanguage: {
              id: service.source_language_id,
              name: service.source_language_name,
            },
            targetLanguage: {
              id: service.destination_language_id,
              name: service.destination_language_name,
            },
            pricePerWord: service.price,
          }
        })
        setTranslationServiceList(initializeServices)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  // Funciones para manejo de contenido compartido (shared content)
  const addSharedContent = () => {
    setSharedContent([...sharedContent, newSharedContent_Empty])
  }
  
  const removeSharedContent = (index) => {
    setSharedContent(sharedContent.filter((content, i) => i !== index))
  }
  
  
  // Funciones para manejo de texto adicional (additional text)
  const addAdditionalText = () => {
    setAdditionalTextList([...additionalTextList, newAdditionalText_Empty])
  }
  
  const removeAdditionalText = (index) => {
    setAdditionalTextList(additionalTextList.filter((text, i) => i !== index))
  }

  const updateAdditionalText = (index, newText) => {
    let newList = [...additionalTextList]
    newList[index].text = newText
    newList[index].words = wordCount(newText)
    setAdditionalTextList(newList)
  }
  
  const updateAdditionalTextLang = (index, newLang) => {
    console.log(newLang)
    let newList = [...additionalTextList]
    newList[index].language = newLang
    setAdditionalTextList(newList)
  }
  
  
  const wordCount = (text) => {
    return text.split(/\s+/).filter((word) => word !== '').length
  }
  
  const loadSharedContentInfo = (info) => {
    // console.log(info)
    // {
      //   title: selectedContent.public_title || selectedContent.chapter_title,
      //   type: selectedType,
      //   language: selectedContent.language_name,
      //   words: selectedContent.word_count,
      //   content_id: selectedContent.content_id,
      //   content_specific_id: selectedContent.infosheet_id || selectedContent.chapter_id
      // }
      console.log("info: ", info)
      let newList = [...sharedContent]
      newList[selectedSharedContentBox].title = info.title
      newList[selectedSharedContentBox].type = info.type
      newList[selectedSharedContentBox].language = info.language
      newList[selectedSharedContentBox].words = info.words
      newList[selectedSharedContentBox].content_id = info.content_id
      newList[selectedSharedContentBox].content_specific_id = info.content_specific_id
      
      console.log(info.language)
      setRequestSrcLanguage(info.language)
      setSharedContent(newList)
    }
    
    const [selectedService_index, setSelectedService_index] = useState(null)
    
    const selectService = (service, index) => {
      changeOriginLanguage(service.originLanguage)
      setRequestDestLanguage(service.targetLanguage)
      setSelectedTranslationService(service)
      setSelectedService_index(index)
    }
    
    const contentServiceCompatibility = (newLang) => {
      if (newLang.id !== requestSrcLanguage.id) {
        // Deberiamos darle una advertencia al usuario antes de dejarlo continuar con esto.
        setSharedContent([])
        setSelectedService_index(null)
        setSelectedTranslationService(null)
        console.log("Hay contenido compartido incompatible con el servicio seleccionado. Se ha eliminado el contenido compartido.")
      }
      
    }
    
    const changeOriginLanguage = (newLang) => {
      contentServiceCompatibility(newLang)
      setRequestSrcLanguage(newLang)
    }
    
    const changeTargetLanguage = (newLang) => {
      setRequestDestLanguage(newLang)
    }
    
    const submitRequest = async () => {
      if (selectedTranslationService === null) {
        console.log("No service selected")
        return
      }
      // const serviceIndex = selectedTranslationService
      const service = selectedTranslationService
      const serviceCost = calcTotalServiceCost(selectedService_index)
      
      // service provider role id's:
      // 1	"escritor"
      // 2	"editor"
      // 3	"traductor"
      // 4	"ilustrador"
      // 5	"músico"
      // 6	"doblador"
      let requestContent = {
        requester_id: user.id,
        provider_id: provider_id,
        provider_role_id: 3,
        selectedService: service,
        original_language_id: service.originLanguage.id,
        destination_language_id: service.targetLanguage.id,
        initial_price: serviceCost,
        current_price: serviceCost,
        word_count: totalWordCount(),
        
        // message: document.getElementById('message').value,
        message: message,
        sharedContentList: sharedContent.filter((content) => content.content_id !== undefined),
        additionalTextList: additionalTextList,
    }

    // console.log(requestContent)
    try{
      const response = await submitServiceRequest(requestContent)
      // console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  const calcTotalServiceCost = (serviceIndex) => {
    // if (serviceIndex === null) return 0
    console.log(serviceIndex)
    console.log(translationServiceList)
    let fee = parseInt(translationServiceList[serviceIndex].pricePerWord)
    let words = totalWordCount()

    return words * fee
  }

  const totalWordCount = () => {
    let words = 0
    for (let i = 0; i < sharedContent.length; i++) {
      if (isNaN(sharedContent[i].words)) continue;
      let content = sharedContent[i]
      words += parseInt(content.words)
    }

    for (let i = 0; i < additionalTextList.length; i++) {
      let text = additionalTextList[i]
      words += parseInt(text.words)
    }

    return words
  }

  const openContentSharePopup = (index) => {
    setSelectedSharedContentBox(index)
    setReferenceContentPopup(true)
  }

  const closeContentSharePopup = () => {
    setReferenceContentPopup(false)
    setSelectedSharedContentBox(null)
  }

  const checkServiceIfNotCompatible = (index) => {
    // Retorna verdadero si el servicio NO es compatible con el idioma de origen o destino seleccionado

    if (requestSrcLanguage.id !== 0) {
      if (translationServiceList[index].originLanguage.id !== requestSrcLanguage.id ) return true
    }

    return false
  }
  const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
    message ? message.length : 0
  );
  
  // MARK: - Render
  return (
    <GeneralStyle>
      <ButtonsStyle>
      <div className="container">
        <div className="leftNullContainer"></div>

        <div className="intContainer">
          <div className="title-n-select">
            <h1 className="title">Solicitar traducción</h1>
            {/* <div className="selectContainer">
              <select name="language" id="language">
                <optgroup label="Idioma">
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                </optgroup>
              </select>
            </div> */}
          </div>

          <hr className='separador'/>
          
          <div className="text advertencia misma-linea">
            <img className="info_btn margin-right-10"></img>
            <p  className="sin-margen">Puedes dar acceso anticipado de lectura a tus creaciones para que el traductor las trabaje.</p>
          </div>

          <h3 className="subtitle">Descripción</h3>
          <textarea type="text" id="desc_input_box" className="textareaInput" placeholder="Descripción" maxLength="1000"
              value={message} onChange={(e) => {setMessage(e.target.value);  setCharacterDescriptionCount(e.target.value.length)}}/>
          <p className='count'>{characterDescriptionCount}/1000</p>

          <hr className='separador'/>

          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Permiso de acceso</h3>
            <button className="add-element-button-small" onClick={() => addSharedContent()}></button>
          </div>

          <div className="flex-column gap-10">
            {sharedContent.map((content, index) => (
              <AccessPermissionItemComponent
                index = {index}
                openContentSharePopup = {openContentSharePopup}
                removeSharedContent = {removeSharedContent}
                words = {`${content.words}`}
                language = {`${content.language.name}`}
                type = {`${content.type.name}`}
                title = {`${content.title}`}
              />
            ))}
          </div>

          <hr className='separador'/>
          
          <div className="misma-linea">
            <h3 className="subtitle margin-right-10">Pedido Libre</h3>
            <button className="add-element-button-small" onClick={() => addAdditionalText()}></button>
          </div>

          <div className="flex-column gap-10">
            {additionalTextList.map((item, index) => (
              <OtherServiceItemComponent
                index = {index}
                text = {`${item.text}`}
                words = {`${item.words}`}
                updateAdditionalText = {updateAdditionalText}
                removeAdditionalText = {removeAdditionalText}
              />
            ))}
          </div>

          <hr className='separador'/>
          
          <div className="title-n-select">
            <h1 className="subtitle margin-right-10">Servicios</h1>
            <div className="selectContainer">
              <select name="origin_language" id="origin_language" value={requestSrcLanguage.id} onChange={(e)=>changeOriginLanguage(e.target.value)}>
                <optgroup label="Idioma origen">
                  {languageList.map((lang, index) => (
                    <option key={index} value={index}>{lang.name}</option>
                  ))}
                </optgroup>
              </select>
              <select name="target_language" id="target_language" value={requestDestLanguage.id} onChange={(e)=>changeTargetLanguage(e.target.value)}>
                <optgroup label="Idioma destino">
                  {languageList.map((lang, index) => (
                    <option key={index} value={index}>{lang.name}</option>
                  ))}
                </optgroup>
              </select>
            </div>
          </div>

          <div className="flex-column gap-10">
            {translationServiceList.map((service, index) => (
              <TranslationServiceItemComponent
                index = {`${index}`}
                service = {service}
                checkServiceIfNotCompatible = {checkServiceIfNotCompatible}
                selectService = {selectService}
                selectedService_index = {selectedService_index}
                totalWordCount = {totalWordCount}
                calcTotalServiceCost = {calcTotalServiceCost}
              />
            ))}
          </div>

        </div>
                              
        <div className="btns">
          {/* CAMBIAR IMAGEN DE BOTÓN */}
          <button className='solicitarServicio_btn circleButton' title="Solicitar servicio" type="submit" onClick={() => submitRequest()}></button>
        {/* <button type="submit" onClick={() => submitRequest()}>Enviar Solicitud</button> */}
        </div>
      </div>

        { 
          referenceContentPopup &&
          <AttachReferenceContent
            closePopup={() => closeContentSharePopup()}
            loadContent={(info) => loadSharedContentInfo(info)}
          />
        }
      </ButtonsStyle>
    </GeneralStyle>
  );
}

