import React, { useState , useEffect } from 'react'
// import styled from "styled-components";

import StoryDetailsStyle from './style/storyDetailsStyle';
import GeneralStyle from '../style/generalStyle';  

import defaultBanner01 from '../../img/sinBanner01.png'
import defaultBanner02 from '../../img/sinBanner02.png'

import defaultCover01 from '../../img/sinPortada01.png'
import defaultCover02 from '../../img/sinPortada02.png'

import GalleryAndCropper from '../utils/img-cropper-component/GalleryAndCropper';
import TagComponent from '../tag-components/TagComponent';

// const StoryDetailsStyle = React.lazy(() => import('../styles/storyDetailsStyle'));

export default function StoryDetailsDisplay({ 
    displayMode,  // display modes are "new" and "edit" (unused for now)
    storyDetails, // { details, setDetails }
    genres,       // { list, setList }
    tags,         // { list, setList }
    changeTracker // { state, setState } (optional)
}) {

    const [characterTitleCount, setCharacterTitleCount] = useState(
        storyDetails.details.title ? storyDetails.details.title.length : 0
    );
    useEffect(() => {
        if (storyDetails.details.title) {
            setCharacterTitleCount(storyDetails.details.title.length);
        }
    }, [storyDetails.details.title]);

    const [characterDescriptionCount, setCharacterDescriptionCount] = useState(
        storyDetails.details.description ? storyDetails.details.description.length : 0
    );
    useEffect(() => {
        if (storyDetails.details.description) {
            setCharacterDescriptionCount(storyDetails.details.description.length);
        }
    }, [storyDetails.details.description]);
    
    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        setCharacterTitleCount(newTitle.length);

        // Actualizar el estado de storyDetails
        if (changeTracker) {
            changeTracker.setState((prevState) => ({
                ...prevState,
                title: true,
            }));
        };

        storyDetails.setDetails((prevDetails) => ({
            ...prevDetails,
            title: newTitle,
        }));
    };
    
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setCharacterDescriptionCount(newDescription.length);
        
        // Actualizar el estado de storyDetails
        if (changeTracker) {
            changeTracker.setState((prevState) => ({
                ...prevState,
                description: true,
            }));
        }

        storyDetails.setDetails((prevDetails) => ({
            ...prevDetails,
            description: newDescription,
        }));
    };

    const [imageSelectMode, setImgSelectMode] = useState(''); // modes are 'cover' and 'banner'
    const [cropperPopup, setCropperPopup] = useState(false);

    const togglePopup = () => {
        setCropperPopup(!cropperPopup);
    }

    const openSelectCover = () => {
        setImgSelectMode("cover")
        togglePopup()
    }

    const openSelectBanner = () => {
        setImgSelectMode("banner")
        togglePopup()
    }

    const setCoverBanner = (img_data, original_img_id) => {
        const cover_banner_img = document.getElementById(`${imageSelectMode}_img`)
        cover_banner_img.src = img_data
        if (imageSelectMode === "cover") {
            if (changeTracker) {
                changeTracker.setState((prevState) => ({
                    ...prevState,
                    cover: true,
                }));
            }

            storyDetails.setDetails(prevState => ({
                ...prevState, 
                coverImgData: img_data, 
                coverImgID: original_img_id
            }));
        } else if (imageSelectMode === "banner") {
            if (changeTracker){
                changeTracker.setState((prevState) => ({
                    ...prevState,
                    banner: true,
                }));
            }

            storyDetails.setDetails(prevState => ({
                ...prevState,
                bannerImgData: img_data,
                bannerImgID: original_img_id
            }));
        }
    }

    const genreListSetter = (newList) => {
        if (changeTracker) {
            changeTracker.setState((prevState) => ({
                ...prevState,
                genres: true,
            }));
        }

        // If "genres" list was part of story details, we'd update it like this:
        // storyDetails.setDetails((prevState) => ({
        //     ...prevState,
        //     genres: newList,
        // })); 
        genres.setList(newList)
    }

    const tagListSetter = (newList) => {
        if (changeTracker) {
            changeTracker.setState((prevState) => ({
                ...prevState,
                tags: true,
            }));
        }

        tags.setList(newList)
    }

    const [bannerIsHovered, setBannerIsHovered] = useState(false);

    const bannerHandleMouseEnter = () => {
        setBannerIsHovered(true);
    };
    
    const bannerHandleMouseLeave = () => {
        setBannerIsHovered(false);
    };
    
    const setDefaultBanner = (imageData) => {
        if(imageData === ''){
            return bannerIsHovered ?  defaultBanner02 :  defaultBanner01;
        }
        return imageData
    }

    const [coverIsHovered, setCoverIsHovered] = useState(false);

    const coverHandleMouseEnter = () => {
        setCoverIsHovered(true);
    };
    
    const coverHandleMouseLeave = () => {
        setCoverIsHovered(false);
    };

    const setDefaultCover = (imageData) => {
        if(imageData === ''){
            return coverIsHovered ?  defaultCover02 :  defaultCover01;
        }
        return imageData
    }

    // MARK: - Render
    return (
        <GeneralStyle>
            <StoryDetailsStyle>
                <h3 className="subtitle">Título</h3>
                <input type="text" id="title_input_box" className="inlineInput" placeholder="Título" maxLength="100" 
                    defaultValue={storyDetails.details.title} onChange={handleTitleChange}/>
                <p className='count'>{characterTitleCount}/100</p>
                
                <hr className='separador'/>

                <div className="img_selecting_thing" >
                    <button className='img_btn cover_img_btn' onClick={openSelectCover} onMouseEnter={coverHandleMouseEnter} onMouseLeave={coverHandleMouseLeave}>
                        <img id='cover_img' className='cover_img' alt='' src={setDefaultCover(storyDetails.details.coverImgData)} draggable="false"/>
                        {/* Select cover image */}
                    </button>
                    <button className='img_btn banner_img_btn' onClick={openSelectBanner} onMouseEnter={bannerHandleMouseEnter} onMouseLeave={bannerHandleMouseLeave}>
                        <img id='banner_img' className='banner_img' alt='' src={setDefaultBanner(storyDetails.details.bannerImgData)} draggable="false"/>
                        {/* Select banner image */}
                    </button>
                </div>

                <hr className='separador'/>
                
                {/* <div className='description'> */}
                <h3 className="subtitle">Descripción</h3>
                <textarea type="text" id="desc_input_box" className="textareaInput" placeholder="Descripción" maxLength="1000"
                    defaultValue={storyDetails.details.description} onChange={handleDescriptionChange}/>
                <p className='count'>{characterDescriptionCount}/1000</p>
                {/* </div> */}
                
                <hr className='separador'/>
                <div className='genre'>
                    <h3 className="subtitle">Géneros</h3>
                    <TagComponent tagList={genres.list} setTagList={genreListSetter} id="genre_component" type="genres" placeholder="Buscar..." />
                </div>

                <hr className='separador'/>
                
                <div className="tags">
                    <h3 className="subtitle">Etiquetas adicionales</h3>
                    <TagComponent tagList={tags.list} setTagList={tagListSetter} id="tag_component" type="tags" placeholder="Buscar..." />
                </div>
                
                <hr className='separador'/>

                <div className='classification'>
                    <h3 className="subtitle">Clasificación</h3>
                    <input type="checkbox" id="checkbox" name="checkbox" className='checkbox'></input>
                    <label htmlFor="checkbox" className='text'>Adulto</label>
                </div>

                {/* <hr className='separador'/> */}
                {cropperPopup && 
                    <GalleryAndCropper 
                        mode={imageSelectMode} 
                        togglePopup={togglePopup} 
                        imageSetter={setCoverBanner} 
                    />}
            </StoryDetailsStyle>
        </GeneralStyle>
    )
}

