import React , { useState } from 'react';
import TabBarStyle from './style/tabBarStyle';

export default function StoryTabBar({ categories, onTabChange }) {
    const [activeTab, setActiveTab] = useState(1);
    const handleTabClick = (index) => {
        setActiveTab(index);
        onTabChange(index);
    }

    return (
        <TabBarStyle>
            <div className='tabContainer'>
                {categories.map((category, index) => (
                    <div
                    key={index}
                    className={`tab ${index === activeTab ? 'active' : ''}`}
                    onClick={() => handleTabClick(index)}>
                        <p>{category}</p>
                        <hr className={`lineaInfTab ${index === activeTab ? 'active' : ''}`} />
                    </div>
                ))}
            </div>
        </TabBarStyle>
    )
}
