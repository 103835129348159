import React, {useEffect} from "react"; 
import { FormContainer } from "./style/formStyle";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";

export default function LoginComponent (props) {
    //const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    //const [errorName, setErrorName] = React.useState("");
    const [errorEmail, setErrorEmail] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");

    const {login, errors, isAuthenticated} = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(isAuthenticated) navigate("/"); //si está autenticado, lo redirige a la página principal
    }, [isAuthenticated,navigate]);

    const validarFormulario = (e) => {
        e.preventDefault();
    
        let emailError = "";
        let passwordError = "";
    
        if (!/\w+@\w+\.[a-z]{2,}$/.test(email)) {
          emailError = "El correo debe tener un formato válido";
        }
    
        if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
          passwordError =
            "La contraseña debe tener al menos 8 caracteres, un número y una letra";
        }
    
        setErrorEmail(emailError);
        setErrorPassword(passwordError);
    
        // si no hay errores
        if (!emailError && !passwordError) {
          // la llamada a login debe estar dentro de este bloque
          login({ email, password });
          // navigate("/");
        }
    };

    return(
        <FormContainer>
            <div className="form_container">
                <h1 className="h1_form">Login</h1>
                <p className="p_form">Si no tienes cuenta, <Link to="/register">Register</Link></p>
                {errors && <div className="div_error"> {errors}</div>}
                <form className="login_form">
                    <div  className="div_form">
                        <label htmlFor="correo" className="label_form">Correo</label>
                        <input type="text" id="correo" className="input_form" placeholder="nombre@email.com"
                                onChange={(e)=>{setEmail(e.target.value)}}/>
                        <p id="error_correo" className="error">{errorEmail}</p>
                    </div>
                    <div  className="div_form">
                        <label htmlFor="contraseña" className="label_form">Contraseña</label>
                        <input type="password" id="contraseña" className="input_form" placeholder="Contraseña"
                                onChange={(e)=>{setPassword(e.target.value)}}/>
                        <p id="error_contraseña" className="error">{errorPassword}</p>
                    </div>
                    <input type="submit" id="enviar" className="enviar" value="Enviar" onClick={(e)=>{
                        validarFormulario(e);
                    }}/>
                </form>
            </div>
        </FormContainer>
    )
}