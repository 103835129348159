import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'

import styled from "styled-components";
import { getStoryDetails, getChapters, updateStoryFollow, updateUserFollow, getContentCollaborators } from '../../api/content'

import GeneralStyle from '../style/generalStyle';
import ButtonsStyle from "../style/buttonsStyle";
import StoryReadStyle from './style/storyReadStyle';
import { TagContainer } from '../tag-components/style/tagStyle';
import ChaptersListStyle from './style/chaptersListStyle';
import StoryReadChapterItemBoxStyle from '../story-components/StoryEditChapters/ChapterItemBox/StoryReadChapterItemBox';
import CommentSection from "../user-profile/profile-pages/comment-list/CommentList";
import { useAuth } from "../../context/AuthContext";
import FollowButton from "../follow-button/FollowButton";
import UserInfoCardMini from "../utils/user-card-popup/UserInfoCardMini";

import defaultImg from '../../img/sinPortada01.png';
import defaultBanner from '../../img/sinBanner01.png';


export default function StoryOverview() {
  const { story_id } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()

  // const collabList = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const [collabList, setCollabList] = useState([
    // {
    //   user_name: 'user1',
    //   user_id: 0,
    //   user_roles: [1, 4]
    // },
  ])
  
  const ROLE_OPTIONS = [
    { value: 1, label: 'Escritor' },
    { value: 2, label: 'Editor' },
    { value: 3, label: 'Traductor' },
    { value: 4, label: 'Ilustrador' },
    { value: 5, label: 'Músico' },
    { value: 6, label: 'Doblador' },
  ]


  const [storyInfo, setStoryInfo] = useState({
    title: '',
    description: '',
    coverImgData: '',
    coverImgID: '',
    bannerImgData: '',
    bannerImgID: '',
    view_count: 0,
    like_count: 0,
    follower_count: 0,
    browsing_user_is_following: false,
    genres: [],
    tags: [],
    
    // author_id: 0,
    author_nickname: '',
    author_profile_picture: '',
    browsing_user_is_following_author: false,
  })
  const [storyChapters, setStoryChapters] = useState([])
  const [authorInfo, setAuthorInfo] = useState({
    browsing_user_is_following: false,
  })


  useEffect(() => {
    // if (!user) return;
    fetchData();
    fetchCollabData();
  }, [])

// MARK: - Functions
  const fetchData = async () => {        
    try {
      let browsing_user_id = null
      if (user) {
        browsing_user_id = user.id
      }
      const msg = {
        user_id: null,
        chapter_state_id: 2,
      }

      const storyResponse = await getStoryDetails(story_id, browsing_user_id)
      setStoryInfo(storyResponse.data)
      // console.log(storyResponse.data)

      const chapterResponse = await getChapters(story_id, msg)
      setStoryChapters(chapterResponse.data)
    } catch (error) {
      console.log('Error fetching data:', error);
    }   
  }

  const fetchCollabData = async () => {
    try {
      // console.log("user.id", user.id)
      let browsing_user_id = null
      if (user) {
        browsing_user_id = user.id
      }
      // console.log("browsing_user_id: ", browsing_user_id)
      const response = await getContentCollaborators(3, story_id, browsing_user_id);
      console.log("collabList...", response.data);
      setCollabList(response.data);

    } catch (error) {
      console.log('Error with fetching collab data: ', error);
    }
  }

  const sourceBanner = () => {
    if (storyInfo.bannerImgURL) {
      return storyInfo.bannerImgURL
    } else {
      return defaultBanner
    }
  }

  const sourceCover = () => {
    if (storyInfo.coverImgURL) {
      return storyInfo.coverImgURL
    } else {
      return defaultImg
    }
  }

  const totalStoryViews = () => {
    return storyInfo.view_count    
  }

  const totalStoryLikes = () => {
    return storyInfo.like_count
  }

  const totalStoryChapters = () => {
    return storyChapters.length
  }

  const followStory = async () => {
    try {
      const result = await updateStoryFollow({
        user_id: user.id,
        story_id: story_id,
        follow_value: !storyInfo.browsing_user_is_following,
      })
      // console.log(result)
      setStoryInfo({...storyInfo, browsing_user_is_following: !storyInfo.browsing_user_is_following})
    } catch (error) {
      console.error('Error toggling follow for story', error)
    }
  }

  const followAuthor = async () => {
    try {
      const result = await updateUserFollow({
        target_user_id: storyInfo.author_id,
        browsing_user_id: user.id,
        follow_value: !storyInfo.browsing_user_is_following_author,
      })
      // console.log(result)
      setStoryInfo({...storyInfo, browsing_user_is_following_author: !storyInfo.browsing_user_is_following_author})
    } catch (error) {
      console.error('Error toggling follow for user', error)
    }
  }

  const followCollab = async (collab) => {
    try {
      let browsing_user_id = null
      if (user) {
        browsing_user_id = user.id
      }

      const result = await updateUserFollow({
        target_user_id: collab.user_id,
        browsing_user_id: browsing_user_id,
        follow_value: !collab.browsing_user_is_following_user,
      })
      // console.log(result)
      updateCollabFollowStatus(collab.user_id, !collab.browsing_user_is_following_user)
    } catch (error) {
      console.error('Error toggling follow for user', error)
    }
  }

  const updateCollabFollowStatus = (userId, newStatus) => {
    setCollabList((prevCollabList) =>
      prevCollabList.map((user) =>
        user.user_id === userId
          ? { ...user, browsing_user_is_following_user: newStatus }
          : user
      )
    );
  };
  const handleViewProfileClick = (user_id) => {
    // console.log("handleViewProfileClick: ", user)
    navigate(`/${user_id}`);
}
  
  const sourcePFP = (user_pic) => {
    // console.log("collaborator_user: ", user_pic)
    if (user_pic) {
      return user_pic
    } else {
      return 'https://via.placeholder.com/30'
    }
  }

  // MARK: - Layout
  return (
    <GeneralStyle>
      <ButtonsStyle>
      <StoryReadStyle>
        <Container>
          <div className='container'>
          
            <div className='intContainer'>
              <div className='container-info-n-colab'>
                <div className="content-info">
                  <div className="story-overview">

                    <div className="story-banner-container">
                      <img className="story-banner-img" src={sourceBanner()} alt="story banner" />
                    </div>

                    <div className="story-cover-n-info ">
                      <div className="story-cover-container">
                        <img className="story-cover-img" src={sourceCover()} alt="story cover" />
                      </div>

                      <div className="story-info">
                        <div className="story-info-header align-center gap-10">
                          <h1 className="title">{storyInfo.title}</h1>
                          <FollowButton 
                            user_is_following={storyInfo.browsing_user_is_following} 
                            submit_follow_action={followStory}
                            type = "historia"
                          />
                          <p className="count">{`${storyInfo.follower_count} Seguidor(es)`}</p>
                        </div>

                        <div className="story-tags-n-stats width-100">
                          <div className="story-genres">
                            {/* <button className="tag-button" onClick={() => console.log(storyInfo.genres)}>Print tags</button> */}
                            {storyInfo.genres.map((genre, index) => (
                              <p className="text-light" key={index}>{genre}</p>
                            ))}
                          </div>

                          <div className="story-stats">
                            <p className="text-light">{`${totalStoryViews()} Lecturas ・ ${totalStoryLikes()} Likes ・ ${totalStoryChapters()} Partes`}</p>
                            {/* <p className="text-light">{`${totalStoryLikes()} Likes`}</p> */}
                            {/* <p className="text-light">{`${totalStoryChapters()} Partes`}</p>                    */}
                          </div>
                        </div>

                        <div className="story-description">
                          <p className="text justify">{storyInfo.description}</p>
                        </div>
                        <TagContainer>
                        <div className="story-tags">
                          {storyInfo.tags.map((tag, index) => (
                            <div className="tag" key={index}>{tag}</div>
                          ))}
                        </div>
                        </TagContainer>

                      </div>
                    </div>
                  </div>
                  
                  <ChaptersListStyle>
                    <div className='listHeader'>              
                      <div className='column col-chapter'>Capítulos</div>
                    </div>
                    <div>
                      {storyChapters.map((chapter, index) => (
                        <StoryReadChapterItemBoxStyle 
                          itemNumber={index + 1}
                          story_id={story_id} 
                          chapter={chapter}
                          key={chapter.chapter_id}
                        />
                      ))}                     
                  
                    </div>
                    <hr className='separador'></hr>

                    <p className='subtitle'>Comentarios</p>
                    <CommentSection 
                      content_type_id={3} 
                      content_specific_id={story_id}
                      user={user}
                    />
                    </ChaptersListStyle>
                </div>
                
                <div className="collaborators-column">
                <h2 className="subtitle">Creador</h2>
                    {/* <UserInfoCardMini key={-1}
                      // browsing_user_id={user.id}
                      target_user_id={storyInfo.author_id}
                    > */}
                      <div className="flex flex-row gap-10 align-center">
                        <img className="CircleImage image-xs" src={`${storyInfo.author_profile_picture}`} alt="user profile" />
                        <div className="flex flex-column align-left">
                          <p className="text" onClick={() => handleViewProfileClick(storyInfo.author_id)}>{`${storyInfo.author_nickname}`}</p>
                          <p className="text-light align-content-center cursiva">(Escritor)</p>
                        </div>
                          <FollowButton
                            user_is_following={storyInfo.browsing_user_is_following_author}
                            submit_follow_action={followAuthor}
                            type = "usuario"
                          />

                      </div>

                    {/* </UserInfoCardMini> */}
                  <h2 className="subtitle">Colaboradores</h2>
                  <div className="flex flex-column gap-10">
                    {collabList.length > 0 && collabList.map((collab, index) => (
                      // <UserInfoCardMini
                      //   // browsing_user_id={user.id}
                      //   target_user_id={collab}
                      //   key={index}
                      // >
                        <div className="flex flex-row gap-10 align-center" key={index}>
                          {/* <p>{collab.user_name}</p> */}
                          <img className="CircleImage image-xs" src={sourcePFP(collab.user_profile_picture)} alt="placeholder user pic"/>
                          <div className="flex flex-column align-left">
                            <p className="text align-content-center" onClick={() => handleViewProfileClick(collab.user_id)}>{`${collab.user_name}`}</p>
                            {collab.user_roles && (
                              <div className="text-light align-content-center cursiva">
                                ({collab.user_roles.map((role, index) => (
                                  ROLE_OPTIONS.find(option => option.value === role).label
                                )).join(', ')})
                              </div>
                            )}

                          </div>
                          <FollowButton
                            user_is_following={collab.browsing_user_is_following_user}
                            submit_follow_action={()=>{followCollab(collab)}}
                            type = "usuario"
                          />
                        </div>

                      // </UserInfoCardMini>
                    ))}
                    {(collabList.length === 0) && <p>No hay colaboradores</p>}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container>
      </StoryReadStyle>
      </ButtonsStyle>
    </GeneralStyle>
  )
}

const Container = styled.div`



.story-info-header {
  display: flex;
  // outline: 1px solid green;
}
.title {
  // outline: 1px solid red;
}
.collaborators-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  // width: 30%;
  height: 100%;
  // outline: 1px solid green;
  margin-left: 20px;
}
.collaborator-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // align-content: center;
  width: 100%;
  // background-color: #F5F5F5;
  // margin: 10px 0;
  // border-radius: 10px;
  outline: 1px solid red;
}

.profile-pic-mini {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
`