export const colors = {
    "c0": "#ffffff",
    "c1": "#658fc2",
    "c2": "#9cbbd7",
    "c2_2": "#c2d5e7",
    "c3": "#555656",
    "c4": "#7f7f7f",
    "c5": "#c8c8c8",
    "c6": "#dddddd",
    "c7": "#f4f3f3",
};