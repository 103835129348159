import React from "react";

import styled from "styled-components";

import defaultImg from "../../img/sinPortada01.png"

import ItemCard from "./ItemCard"
import PublicOverlay from "./PublicStoryOverlay"
import PrivateOverlay from "../UserCreations/MyStories/StoryCardOverlay/PrivateStoryOverlay"

export default function CardGallery(props) {
  /**
   * expected props:
   * - img
   * - item_title
   * - id
   * - overlay (component) 
   * - handleClick (function)
   * */
  const myList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  const myTags = ["tag1", "tag2", "tag3", "tag4", "tag5", "tag6"]

  const longdesc = "story description, very lengthy to try out how it behaves in the card and how it wraps around and how much space it takes and stuff and stuff..."
  const shortdesc = "story description, a much shorter description"

  const handleClick = (item) => {
    console.log("clicked " + item)
  }

  return (
    <Container>

      <div className="test-div">
        <p>test</p>
      </div>
      <div className="gallery">
        {props.itemList.map(item => (
          <ItemCard key={item}
            id={item}
            item_title={item}
            img={defaultImg}
            overlay={<props.overlay />} //<= necesita estos props para funcionar: [prop1, prop2, prop3, prop4, prop5, prop6]

            handleClick={() => props.handleClick(item)}
          >

          </ItemCard>
        ))}

      </div>

    </Container>
  )
}

const Container = styled.div`
.gallery {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  // outline: 1px solid red;
  overflow: auto;
  gap: 10px;
}

`