import styled from "styled-components";
import { colors } from "../../style/colors";

const ChaptersListStyle = styled.div`

.listHeader {
    display: flex;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
    background: ${colors.c2};
    color: white;
    font-weight: bold;
    font-size: 12px;
    outline: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.col-stats {
    display: flex;
    flex: 45;
    flex-direction: row;
    outline: 1px solid green;
    justify-content: space-around;
}
.col-chapter {
    display: flex;
    flex-direction: row;
    flex: 45%;
    text-align: left;
    padding-left: 10px;
    width: 100%;
}
.col-actions {
    display: flex;
    flex: 10%;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 10px;
}
.column {
    outline: none;
    margin: auto;
    // min-width: 50px;
    white-space: pre-wrap;
}

.precioContainer{
    display: flex;
    flex-direction: row;
    background: ${colors.c7};
    align-items: center;
    // justify-content: space-around;
    width: calc(100% - 20px);
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    padding: 0px 10px;
    gap: 20px;
}

.column-read {
    outline: none;
    margin: auto;
    min-width: 60px;
    white-space: pre-wrap;
}

.col-read-chapter {
    display: flex;
    flex: 80%;
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.col-read-stats {
    display: flex;
    flex: 20%;
    width: 100%;
    flex-direction: row;
    // outline: 1px solid green;
    justify-content: space-around;
}
`;

export default ChaptersListStyle;
