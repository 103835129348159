import styled from "styled-components";

import { colors } from "../../../style/colors";

const SelectedInfosheetsStyle = styled.div`
    .gallery-container {
        display: flex;
        flex-wrap: wrap; //si no caben pasan para abajo
        gap: 20px; /* Adjust the gap between items */
        justify-content: left;
    }
`;

export default SelectedInfosheetsStyle;