/**
 * This component is used to display the infosheet in the infosheet viewer.
 * This is an example of an infosheet's content:
{
  "publicTitle": "ahora si tiene titulo",
  "privateTitle": "y uno privado tmb",
  "categories": [
    {
      "name": "",
      "rows": [
        {
          "fields": [
            {
              "name": "campo2",
              "value": "valor2"
            },
            {
              "name": "campo 3",
              "value": "valor 3"
            }
          ]
        },
        {
          "fields": [
            {
              "name": "Fila 2",
              "value": "valor F2"
            }
          ]
        }
      ]
    },
    {
      "name": "cate 2",
      "rows": []
    }
  ]
}
 */

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {getInfosheet} from '../../api/content'
import { BACKEND_HOST } from '../../api/host'

import defaultPortrait from '../../img/sinPortada01.png'

import PopupStyle from '../style/popupStyle'

// import InfosheetContentDisplay from './infosheet-content/InfosheetReadableContent'
import InfosheetEditableContent from './infosheet-content/InfosheetEditableContent'

export default function InfosheetViewer( prop ) {
  // const { infosheet_id } = useParams()
  const [portraitData, setPortraitData] = useState('')
  // const [portraitID, setPortraitID] = useState('')
  const [publicTitle, setPublicTitle] = useState('')
  // const [privateTitle, setPrivateTitle] = useState('')
  const [infoSheet, setInfoSheet] = useState({
    categories: [
      {
        name: '',
        rows: [
          {
            fields: [
              {
                name: '',
                value: '',
              }
            ]
          }
        ]
      },
    ]
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getInfosheet(prop.infosheet_id)
        setInfoSheet(response.data.content)
        setPublicTitle(response.data.publicTitle)
        // setPrivateTitle(response.data.privateTitle)
        console.log('response.data.portraitData: ', response.data.portraitData)
        if(response.data.portraitData === null){
          setPortraitData(defaultPortrait)
        }else{
          setPortraitData((response.data.portraitData))
        }
        // setPortraitID(response.data.portraitID)
      } catch (error) {
        console.log('Error with getting infosheet list: ', error)
      }
    }
    fetchData();
  }, [])

  return (
    <PopupStyle className='height-100'>
      <div className='flex-column height-100'>
        <h2 className='title margin-top-0 ellipsis absolute'>{publicTitle}</h2>
        <div className='flex-row height-100 margin-top-40'>
          <div className='left-column-150 flex-column margin-right-20'>
            <img className="cover_img" src={portraitData} alt="portrait" />
          </div>
          <div className="right-column height-90">
              <div className='category_row_container_sin_margen width-100'>
              <InfosheetEditableContent 
                infosheetContent={infoSheet}
                lockFields={true} //para que no aparezca el más para agregar campos/categorías
                editable={false}
                />
              </div>
          </div>
        </div>
      </div>
    </PopupStyle>
  )
}