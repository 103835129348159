import CommentList from './comment-list/CommentList';

export default function ReviewUserPostComponent({index, user, user_name, time_elapsed_since_posted, title , content, displayComments, post, toggleLikeForContent, toggleDisplayPostComments, myLike}) {

    return (
    <div className="misma-linea fondo-gris-borde gap-20" key={index}>
        <div className="felx-column width-100">
            <div className="width-100 flex-row margin-bottom-10 gap-10 align-center flex-end">
                <div className="margin-auto flex-row gap-10 align-center">
                    <img src="https://via.placeholder.com/30" alt="user profile" className="CircleImage " />
                    <p className="text">{`${user_name}`}</p>
                </div>
                <p className="time">{`${time_elapsed_since_posted}`}</p>
            </div>
            <hr className='separador sin-margen margin-top-10 margin-bottom-10'/>
            <div className="flex-column gap-20">
                <p className="subtitle font-weight-normal">{`${title}`}</p>
                <p className="text justify">{`${content}`}</p>
            </div>

            <hr className='separador sin-margen margin-top-10 margin-bottom-10'/>
            
            <div className='flex-row align-center margin-bottom-10'>
                {!myLike && 
                    <button className="circleButton-20 like_btn manito sin-padding margin-right-5" onClick={() => toggleLikeForContent(index)}></button>
                }
                {myLike && 
                    <button className="circleButton-20 dislike_btn manito sin-padding margin-right-5" onClick={() => toggleLikeForContent(index)}></button>
                }
                {/* <button className="post-likes sin-borde manito sin-padding" onClick={() => toggleLikeForContent(index)}>{`🤍 ${myLike}`}</button> */}
                <p className='text'>{`Les gusta a ${post.like_count} personas`}</p>
            </div>

            <div className='flex-column margin-bottom-10'>
                { !displayComments && 
                    <button className="show-comments-btn" onClick={() => toggleDisplayPostComments(index)}>{`Ver los ${post.comment_count} comentarios`}</button>
                }
                { displayComments &&
                    <button className="show-comments-btn" onClick={() => toggleDisplayPostComments(index)}>{`Ocultar comentarios`}</button>
                }
            </div>
            {/* <p>🤍 🖤</p> */}

            {displayComments && 
              <div>
                <CommentList 
                  post={post}
                  content_type_id={5} 
                  content_specific_id={post.community_post_id} 
                  user={user}
                />
              </div>
            }
        </div>
    </div>
    );
  }
