import React from "react";
import styled from "styled-components";

export default function StoryOutline() {
    return (
        <StoryOutlineContainer>
            {/* <h1>Story Outline</h1> */}
            <p className="text">"Escaleta"</p>
        </StoryOutlineContainer>
    )
}
const StoryOutlineContainer = styled.div`
`;