export default function NewContentCommentComponent({user, submitCommentHandler, newComment, setNewComment}) {

    return (
        <div className="misma-linea fondo-blanco gap-20">
            <div className="felx-column">
                
                <div className="width-100 flex-row margin-bottom-10 gap-10 align-center">                    
                    <div className="margin-auto flex-row gap-10 align-center">
                        <img src="https://via.placeholder.com/30" alt="user profile" className="CircleImage " />
                        {user && <p className="text">{`${user.username}`}</p>}
                    </div>
                </div>

                <div className="flex-row gap-20">
                    <div className="flex-column">
                        <textarea 
                            className="textareaInput textareaInputSmall max-height-30" 
                            name="message" 
                            id="message" 
                            cols="200" 
                            maxLength="280"
                            placeholder="Write a comment" 
                            onChange={(e) => setNewComment(e.target.value)} 
                            value={newComment}
                        />
                    </div>
                    
                    <div className="margin-auto">
                        <button type="submit" className="save_btn circleButton" onClick={() => submitCommentHandler()}></button>
                    </div>
                </div>
                
            </div>
        </div>
    );
  }
