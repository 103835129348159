import { useEffect, useRef } from "react";
// import styled from "styled-components";

import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function LogoutComponent ({ user, logout, mostrarLogout, setMostrarLogout, setDivLogout}) {
  const divLogout = useRef();
  // const { user } = useAuth();
  //efecto para setear el div de logout en el estado del padre
  useEffect(() => {
    setDivLogout(divLogout);
  }, [divLogout, user]);

  return (
    <>
      {mostrarLogout &&
        <div ref={divLogout} className="logout-container">
            <div className="flex-column width-100">
                {user && 
                  <Link to={`/user/${user.id}`} className="optionBanner">
                    <p className="text">{user.username}</p>
                  </Link>
                }

                <hr className="separador-banner width-100"></hr>
                <Link to={`/${user.id}`} className="optionBanner">
                    <p className="text margin-top-5">Mis posts</p>
                </Link>

                <Link to="/mycreations/stories" className="optionBanner">
                    <p className="text margin-top-5">Mis creaciones</p>
                </Link>
                <Link to="/myservices/ilustrador" className="optionBanner">
                    <p className="text margin-top-5">Mis servicios</p>
                </Link>
                <Link to="/viewrequestlist" className="optionBanner">
                    <p className="text margin-top-5">Mis solicitudes</p>
                </Link>


                <hr className="separador-banner width-100"></hr>
                <Link to="/usersettings" className="optionBanner">
                    <p className="text margin-top-5">Settings</p>
                </Link>
                <Link to="/login" className="optionBanner" onClick={()=>{logout(); setMostrarLogout(false);}}>
                    <p className="text  margin-top-5">Logout</p>
                </Link>

            </div>
        </div>
    }
    </>
  );
};

