import React, { useCallback, useEffect, useState } from "react";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

// import debounce from 'lodash/debounce';
import debounce from 'debounce-promise';

import styled from "styled-components";
// import PopupStyle from "../../style/popupStyle";
import { colors } from "../../style/colors";
import { getContentCollaborators, updateContentCollaborator } from "../../../api/content";
import { getUserInfoByUsername, getUserListPartialResults } from "../../../api/user";
import {getRoleList} from "../../../api/type"
import PopupStyle from '../../style/popupStyle'

export default function CollaboratorManager({content_type_id, content_specific_id, toggleCollabManager, closePopup }) {
  const animatedComponents = makeAnimated();

  const NotificationBubble = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })
  
  const ROLE_OPTIONS = [
    { value: 1, label: 'Escritor' },
    { value: 2, label: 'Editor' },
    { value: 3, label: 'Traductor' },
    { value: 4, label: 'Ilustrador' },
    { value: 5, label: 'Músico' },
    { value: 6, label: 'Doblador' },
  ]

  const [toggleEditCollabo, setToggleEditCollabo] = useState(null)
  const [toggleAddCollabPopup, setToggleAddCollabPopup] = useState(false)
  const [collabList, setCollabList] = useState([
    {
      user_name: 'user1',
      user_id: 0,
      roles: [1, 4]
    },
  ])
  // const [newCollaboID, setNewCollaboID] = useState('')
  const [newCollaboName, setNewCollaboName] = useState('')
  const [newCollaboData, setNewCollaboData] = useState(null)
  const [newCollaboRoleList, setNewCollaboRoleList] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [editCollaboRoleList, setEditCollaboRoleList] = useState([])
  const [roleListOption, setRoleListOption] = useState([])


  // service provider role id's:
  // "escritor" 1	
  // "editor" 2	
  // "traductor" 3	
  // "ilustrador" 4	
  // "músico" 5	
  // "doblador" 6	

  // MARK: - Fetch Data
  useEffect(() => {
    fetchCollabData()
    // fetchRoleListData()
  }, [])

  const fetchRoleListData = async () => {
    try {
      const response = await getRoleList();
      console.log("roleListData: ", response.data)
      // setRoleList(response.data)
    } catch (error) {
      console.log('Error with fetching role list data: ', error);
    }
  }

  const fetchCollabData = async () => {
    try {
      const response = await getContentCollaborators(content_type_id, content_specific_id);
      console.log("collabList...", response.data);
      setCollabList(response.data);

    } catch (error) {
      console.log('Error with fetching collab data: ', error);
    }
  }

  const updateNewCollaboData = (e) => {
    console.log('newCollaboData:', e)
    setNewCollaboData(e)
  }

  const updateCollaboRoleList = (e) => {
    const roleIdList = e.map(role => role.value)
    setNewCollaboRoleList(roleIdList)
    setSelectedRoles(e);
  }

  const handleAddCollaborator = async () => {
    try {
      if (newCollaboRoleList.length === 0) {
        console.log('No role selected')
        NotificationBubble.fire({
          icon: 'warning',
          title: 'No hay roles seleccionados'
        })
        return
      }

      const response = await updateContentCollaborator({
        content_type_id: content_type_id,
        content_specific_id: content_specific_id,
        collaborator_id: newCollaboData.value,
        // collaborator_username: newCollaboData.label,
        role_id_list: newCollaboRoleList,
      });
      setToggleAddCollabPopup(false)
      fetchCollabData()
      setNewCollaboName('')
      setNewCollaboRoleList([])

      setNewCollaboData(null)
      setSelectedRoles([]);
      
      
    } catch (error) {
      console.log('Error with adding collab data: ', error);
    }
  }

  const handleRemoveCollaborator = async (index) => {
    // console.log("collabList", collabList)
    try {
      const response = await updateContentCollaborator({
        content_type_id: content_type_id,
        content_specific_id: content_specific_id,
        collaborator_id: collabList[index].user_id,
        role_id_list: [],
      });
      fetchCollabData() 
    } catch (error) {
      console.log('Error with removing collab data: ', error);
    }
  }


  const getListOfRoles = (roleIdList) => {
    const roleList = []
    roleIdList.forEach(roleId => {
      const roleIndex = ROLE_OPTIONS.findIndex(option => option.value === roleId)
      roleList.push(ROLE_OPTIONS[roleIndex])
    })
    console.log('roleList:', roleList)
    return roleList
  }


  const setEditExistingCollabo = (index) => {
    setNewCollaboRoleList(collabList[index].user_roles)
    setNewCollaboName(collabList[index].user_id)
    setToggleEditCollabo(index)
  }

  const cancelEditCollabo = () => {
    setNewCollaboRoleList([])
    setNewCollaboName('')
    setToggleEditCollabo(null)
  }

  const promiseUserMatchesList = async (inputValue='') => {
    try { 
      // NOTA: Se debe limitar la cantidad de caracteres que se envían al backend
      const result = await getUserListPartialResults(inputValue)
      // console.log('result:', result.data)
      if (!result.data || result.data.length === 0) {
        // console.log('No user found with that username')
        return []
        // reject('No user found with that username')
      }
      if (result.data.length >= 1) {
        console.log('User found:', result.data)
        const mappedResult = result.data.map(user => {
          return { label: user.user_name, value: user.user_id }
        })
        return mappedResult
      }

    } catch (error) {
      console.log('Error getting partial list of results: ', error)
    }
  }

  const debouncedPromiseUserMatchesList = debounce(promiseUserMatchesList, 300)


  const saveEditCollabo = async () => {
    try {
      const response = await updateContentCollaborator({
        content_type_id: content_type_id,
        content_specific_id: content_specific_id,
        collaborator_id: newCollaboName,
        role_id_list: newCollaboRoleList,
      });
      fetchCollabData()
      console.log(
        'content_type_id:', content_type_id,
        'content_specific_id:', content_specific_id,
        'collaborator_id:', newCollaboName,
        'role_id_list:', newCollaboRoleList
      )
      setNewCollaboRoleList([])
      setNewCollaboName('')
      setToggleEditCollabo(null)
    } catch (error) {
      console.log('Error with saving collab data: ', error);
    }
  }

  const estiloSelector = {
    control: (provider) => ({
      ...provider,
      '&:hover':{
        // border: 'none'
        // overlay: 'none'
      },
      boxShadow: 'none',
      // border: 'none',
        borderColor: '#cccccc'

    })
  }
  
  const sourcePFP = (user_pic) => {
    // console.log("collaborator_user: ", user_pic)
    if (user_pic) {
      return user_pic
    } else {
      return 'https://via.placeholder.com/30'
    }
  }

  // MARK: - Layout
  return (
    <CollaboratorManagerStyle>
    <PopupStyle>
      <div className="overlay">
        <div className="popup-containter-n-btns">
          <div className="leftNullContainer-40"></div>
          <div className="popup-container">
            
            <div className="popup-header">
              <h1 className="title">Colaboradores</h1>
            </div>

            <hr className='separador width-100'/>

            <div className="popup-content">
              {/* <p>Collaborator Manager content</p> */}
              {/* <button onClick={() => setToggleAddCollabPopup(true)}>Add a Collaborator</button> */}



              <div className="flex flex-row gap-10 margin-bottom-20">
                {/* <p>interfaz aqui</p> */}
                {/* <input type="text" placeholder="Collaborator ID" onChange={(e) => updateCollaboId(e)}/>
                <input type="text" placeholder="Collaborator username" onChange={(e) => updateCollaboName(e)}/> */}
                <AsyncSelect 
                  cacheOptions={false} 
                  defaultOptions={false} 
                  loadOptions={debouncedPromiseUserMatchesList} 
                  onChange={(e) => updateNewCollaboData(e)}
                  placeholder="Buscar usuario..."
                  noOptionsMessage={() => 'No se encontraron usuarios'}
                  styles={estiloSelector}
                  className="text align-left flex-1"
                  isClearable= "true"
                  value={newCollaboData}
                />

                <Select 
                  options={ROLE_OPTIONS}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  onChange={(e) => updateCollaboRoleList(e)}
                  placeholder="Asignar roles..."
                  styles={estiloSelector}
                  className="text align-left flex-1"
                  isMulti
                  isClearable= "true"
                  value={selectedRoles}
                />
                <button className="circleButton save_btn margin-auto" onClick={() => handleAddCollaborator()}></button>
              </div>

            {/* <hr className='separador width-100'/> */}



              <div className="flex flex-column gap-10">                
                {collabList.length === 0 && <p className="text align-left cursiva">No hay colaboradores</p>}

                {collabList && collabList.map((collab, index) => (
                    <div className="flex flex-row gap-10" key={index}> 
                      <img className="CircleImage image-xs" src={sourcePFP(collab.user_profile_picture)} alt="placeholder user pic"/>  
                      <div className="text align-content-center">{`${collab.user_name}`}</div>
                      <div className="align-content-center">
                        {(toggleEditCollabo !== index) && collab.user_roles && 
                          // <div className="collabo-role-tag" key={index}>{ROLE_OPTIONS.find(option => option.value === role).label}</div>
                          <div className="text-light align-content-center cursiva">
                            ({collab.user_roles.map((role, index) => (
                              ROLE_OPTIONS.find(option => option.value === role).label
                            )).join(', ')})
                          </div>
                        }
                        {toggleEditCollabo === index &&
                          <Select 
                            options={ROLE_OPTIONS}                            
                            styles={estiloSelector}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={() => getListOfRoles(collab.user_roles)}
                            onChange={(e) => updateCollaboRoleList(e)}
                            isMulti
                            className="text align-left flex-1"
                          />
                        }
                      </div>
                      {/* <div className="flex flex-row gap-10"> */}
                        { (toggleEditCollabo !== index) &&
                          <>
                            <button className="circleButton-20 edit_btn_blue self_center" onClick={() => setEditExistingCollabo(index)}>{/* Edit */}</button>
                            <button className="circleButton-20 delete_btn self_center" onClick={() => handleRemoveCollaborator(index)}>
                              {/* X */}
                            </button>
                          </>
                        }
                        { (toggleEditCollabo === index) &&
                          <>
                            <button className="circleButton-20 save_btn self_center" onClick={() => saveEditCollabo()}>
                              {/* Save */}
                            </button>
                            {/* <button className="cancel-collab-btn" onClick={() => cancelEditCollabo()}>
                              Cancel
                            </button> */}
                          </>
                        }

                      {/* </div> */}
                    </div>
                  ))  
                }
              </div>
            </div>

            
          </div>
          <div className="btns-10">
            <button onClick={closePopup} className="close-btn circleButton"></button>
          </div>
        </div>
        {/**
         * MARK: - Secondary Popup
         */}
        {/* {toggleAddCollabPopup &&
          <div className="small-popup-overlay">
            <div className="small-popup-containter-n-btns">
              <div className="small-popup-container">
                <div className="popup-header">
                  <h1>Add a Collaborator</h1>
                </div>
                <div className="popup-content">
                  <AsyncSelect 
                    cacheOptions={false} 
                    defaultOptions={false} 
                    loadOptions={debouncedPromiseUserMatchesList} 
                    onChange={(e) => updateNewCollaboData(e)}
                    placeholder="Buscar usuario..."
                    noOptionsMessage={() => 'No se encontraron usuarios'}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        width: '250px', 
                      }),
                    }}
                  />

                  <Select 
                    options={ROLE_OPTIONS}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={(e) => updateCollaboRoleList(e)}
                    placeholder="Asignar roles..."
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        width: '250px', 
                      }),
                    }}
                    isMulti
                  />
                  <button onClick={() => handleAddCollaborator()}>Add</button>
                </div>
              </div>
              <div className="btns-10">
                <button onClick={() => setToggleAddCollabPopup(false)} className="close-btn circleButton"></button>
              </div>
            </div>
          </div>
        } */}
      </div>
      </PopupStyle>
    </CollaboratorManagerStyle>
  )
}

const CollaboratorManagerStyle = styled.div`
.popup-container {
  // position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  min-height: 300px;
  height: 80vh;
  background-color: ${colors.c0};
  // outline: 1px solid black;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}



`