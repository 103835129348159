import React , { useState } from 'react';
import TabBarStyle from './style/tabBarStyle';
import ButtonsStyle from '../style/buttonsStyle';

export default function TabBar({ categories, onTabChange, mode='icons', defaultTab=1}) {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (index) => {
    setActiveTab(index);
    onTabChange(index);
  }

  return (
    <TabBarStyle>
      <div className='tabContainer'>
        {categories.map((category, index) => (
          <div
            key={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {!category.icon && 
              <p>{category.name}</p>
            }
            {category.icon && 
              // <img src={category.icon} alt={category.name} />
              <ButtonsStyle>
                <button className={category.icon} title={category.name}></button>
              </ButtonsStyle>
            }
            <hr className={`lineaInfTab ${index === activeTab ? 'active' : ''}`} />
          </div>
        ))}
      </div>
    </TabBarStyle>
  )
}
